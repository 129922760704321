import React, { useContext, useEffect } from 'react';
import { ICallbackProps, IStringProps } from 'iApp';
import { IAddItemProps, ITowerList } from 'state/iState';
import {
  SitesContext,
  CanvasContext,
  ServerContext,
  DocumentContext,
} from 'state/context';
import {
  ISitesContext,
  ICanvasContext,
  IServerContext,
  IDocumentContext,
} from 'state/iContext';

const DrawingButtons = () => {
  const { activeTowerID, setAddItemProps } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const { setTowerList, fetchSiteData } = useContext(
    SitesContext
  ) as ISitesContext;
  const { deleteDocumentFromDB } = useContext(ServerContext) as IServerContext;
  const {
    activeFile,
    setActiveFile,
    documentList,
    setDocumentList,
    fetchDocuments,
  } = useContext(DocumentContext) as IDocumentContext;

  const { [+activeFile!]: toBeModified, ...rest } = documentList;

  useEffect(() => {}, [documentList]);

  const handler: ICallbackProps = {
    edit: () => {
      setAddItemProps((prev: IAddItemProps) => ({
        ...prev,
        edit: true,
        label: toBeModified.name,
        index: activeFile,
      }));
    },
    trash: async () => {
      setActiveFile('add');
      await fetchDocuments();
      const fetchedSiteData = await fetchSiteData(activeTowerID);
      await deleteDocumentFromDB(activeFile!.toString());
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...fetchedSiteData,
          documents: {
            ...fetchedSiteData.documents,
            folders: {
              ...fetchedSiteData.documents.folders,
              '1': {
                ...fetchedSiteData.documents.folders[1],
                files: fetchedSiteData.documents.folders[
                  '1'
                ].files.filter((id: string) => +id !== +activeFile!),
              },
            },
          },
        },
      }));
      setDocumentList(rest);
    },
  };

  const classes: IStringProps = {
    constiner: 'absolute top-0 right-0 flex',
    edit: 'fa-regular fa-pen-to-square text-lg m-1 font-bold text-stone-400 cursor-pointer hover:text-red-700',
    trash:
      'fa-regular fa-trash-can text-lg m-1 font-bold text-stone-400 cursor-pointer hover:text-red-700',
  };

  return (
    <div className={classes.container}>
      <i
        className={classes.edit}
        onClick={handler.edit}
      />
      <i
        className={classes.trash}
        onClick={handler.trash}
      />
    </div>
  );
};

export default DrawingButtons;
