import { IUserDropdownClasses, IUserDropdown } from './iUserDropdown';
import { IHeaderButtons, ITowerList } from 'state/iState';
import UserDropdownMenu from './UserDropdownMenu';
import profile from 'assets/header/profile.png';
import React, { useContext } from 'react';
import MenuArrow from '../MenuArrow';
import {
  ICanvasContext,
  IHeaderContext,
  IServerContext,
  ISettingsContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  CanvasContext,
  HeaderContext,
  ServerContext,
  SettingsContext,
  SitesContext,
  UserContext,
} from 'state/context';

const UserDropdown = () => {
  const { setIsSettingsOpen } =
    useContext(SettingsContext) as ISettingsContext;
  const { activeHeaderButton, setActiveHeaderButton } =
    useContext(HeaderContext) as IHeaderContext;
  const { towerList, setTowerList } = useContext(SitesContext) as ISitesContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { releaseCanvasLock } = useContext(ServerContext) as IServerContext;

  // Component Classes
  const classes: IUserDropdownClasses = {
    main: 'flex inline-block text-left w-15 cursor-pointer',
    button:
      'inline-flex w-full justify-center items-center bg-stone-100 px-2 py-3 text-sm font-medium text-gray-700',
    img: 'w-5 rounded-full bg-slate-400 invert',
  };

  const { account } = activeHeaderButton!;

  // Toggle account menu dropdown
  const clickHandler = () => {
    if (!activeHeaderButton.settings) {
      setIsSettingsOpen(false);
      setActiveHeaderButton(
        (prev: IHeaderButtons): IHeaderButtons => ({
          ...prev,
          account: !prev.account,
          siteTypes: false,
        })
      );
    }
  };

  const exitCanvasEdit = async() => {
    if (activeTowerID && towerList[activeTowerID].edit.user === user.userId) {
      await releaseCanvasLock(activeTowerID, user.userId);
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          edit: {
            active: false,
            user: null,
            time: null,
          },
        },
      }));
    }
  };

  // Props to pass to their respective elements
  const props: IUserDropdown = {
    buttonProps: {
      type: 'button',
      onClick: clickHandler,
      className: classes.button,
      id: 'account-menu-button',
    },
    imgProps: {
      src: profile,
      className: classes.img,
    },
  };

  return (
    <>
      <div
        className={classes.main}
        onClick={exitCanvasEdit}>
        <div {...props.buttonProps}>
          <img
            alt='user profile icon'
            {...props.imgProps}
          />
          <MenuArrow name={'account'} />
        </div>
      </div>
      {account && <UserDropdownMenu />}
    </>
  );
};

export default UserDropdown;
