import React, { useContext, useEffect, useState } from 'react';
import {
  CompanyContext,
  ServerContext,
  SitesContext,
  UserContext,
  WorkOrderContext,
} from 'state/context';
import {
  ICompanyContext,
  IServerContext,
  ISitesContext,
  IUserContext,
  IWorkOrderContext,
} from 'state/iContext';
import Select from 'react-select';
import { IInputOption, IWorkOrder } from '../iWorkOrders';
import ReactDatePicker from 'react-datepicker';
import { camelCaseStr } from 'helpers/helperFunctions';
import { IStringProps } from 'iApp';
import Creatable from 'react-select/creatable';
import WorkOrderOption from './WorkOrderOption';

const FormInputSection = ({ label }) => {
  const { userList } = useContext(UserContext) as IUserContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { domain } = useContext(ServerContext) as IServerContext;
  const { icons: assets } = useContext(CompanyContext) as ICompanyContext;
  const { workOrders, workOrderStates, setWorkOrderStates } = useContext(
    WorkOrderContext
  ) as IWorkOrderContext;

  const uniqueProjectNames = Array.from(
    new Set(
      Object.values(workOrders).map(
        (workOrder) => (workOrder as IWorkOrder).project
      )
    )
  );

  const assigneesList = workOrderStates.assigned.map(
    (assignee) => assignee.label
  );
  const additionalUsersOptions = Object.values(userList)
    .filter((user) => !assigneesList.includes(user.name))
    .map((user) => ({ value: user.id, label: user.name }));

  const handleChange = {
    Classification: (selectedClassification: IInputOption | null) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        classification: selectedClassification!.value,
      })),
    Priority: (selectedPriority: IInputOption | null) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        priority: selectedPriority!.value,
      })),
    'Start Date': (selectedStartDate: Date | null) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        startDate: selectedStartDate,
      })),
    'End Date': (selectedEndDate: Date | null) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        endDate: selectedEndDate,
      })),
    Project: (selectedProject: IInputOption | null) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        project: selectedProject,
      })),
    Assigned: (selectedAssigned: IInputOption[] | null) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        assigned: selectedAssigned,
      })),
    'Parts Assets': (selectedPartsAssets: IInputOption[] | null) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        partsAssets: selectedPartsAssets,
      })),
    Locations: (selectedLocations: IInputOption[] | null) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        locations: selectedLocations,
      })),
    Description: (description: string) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        description,
      })),
    Files: (selectedFiles: File[] | null) => {
      const updatedFiles = selectedFiles
        ? [...workOrderStates.fileList, ...selectedFiles]
        : workOrderStates.fileList;
      setWorkOrderStates((prevState) => ({
        ...prevState,
        fileList: updatedFiles,
      }));
    },
    Checklist: (selectedChecklist: IInputOption[] | null) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        checklist: selectedChecklist,
      })),
    Notes: (notes: string) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        notes,
      })),
    'Additional Users': (selectedAdditionalUsers: IInputOption[] | null) =>
      setWorkOrderStates((prevState) => ({
        ...prevState,
        additionalUsers: selectedAdditionalUsers,
      })),
  };

  const handleRemoveChecklistItem = (removedItem) => {
    const updatedChecklist = workOrderStates.checklist.filter(
      (item) => item.id !== removedItem.id
    );
    setWorkOrderStates((prev) => ({
      ...prev,
      checklist: updatedChecklist,
    }));
  };

  const sectionConfig = {
    Classification: {
      type: 'dropdown',
      options: [
        { value: 'Public', label: 'Public' },
        { value: 'Protected a', label: 'Protected A' },
        { value: 'Protected b', label: 'Protected B' },
        { value: 'Protected c', label: 'Protected C' },
      ],
    },
    Priority: {
      type: 'dropdown',
      options: [
        { value: 'Low', label: 'Low' },
        { value: 'Medium', label: 'Medium' },
        { value: 'High', label: 'High' },
      ],
    },
    'Start Date': {
      type: 'datepicker',
    },
    'End Date': {
      type: 'datepicker',
    },
    Project: {
      type: 'creatable',
      options: uniqueProjectNames.map((projectName) => ({
        value: projectName,
        label: projectName,
      })),
    },
    Assigned: {
      type: 'multiDropdown',
      options: Object.values(userList).map((user) => {
        return {
          value: user.id,
          label: user.name,
        };
      }),
    },
    'Parts Assets': {
      type: 'creatable',
      options: Object.entries(assets!).flatMap(([assetKey, asset]) => {
        const imagePath =
          asset.properties.find((property) => property.path)?.path || '';
        return {
          value: assetKey,
          label: asset.name,
          image: imagePath ? `${domain}${imagePath}` : '',
        };
      }),
      components: { Option: WorkOrderOption },
      isMulti: true,
    },
    Locations: {
      type: 'creatable',
      options: Object.keys(towerList).map((id) => ({
        value: towerList[+id].towerId,
        label: towerList[+id].name,
      })),
      isMulti: true,
    },
    Description: {
      type: 'textarea',
    },
    Files: {
      type: 'fileInput',
    },
    Checklist: {
      type: 'creatable',
      isMulti: true,
      options: workOrderStates.checklist || [],
      onKeyDown: (e) => {
        if (
          e.key === 'Enter' &&
          (!e.target.value || e.target.value.trim() === '')
        ) {
          e.preventDefault();
        }
      },
      placeholder: 'Type to Create Checklist Item',
      hideSelectedOptions: true,
      noOptionsMessage: ({ _ }) => (
        <span className={classes.checklist}>
          Enter key to create checklist item
        </span>
      ),
      getOptionLabel: true,
    },
    Notes: {
      type: 'textarea',
    },
    'Additional Users': {
      type: 'multiDropdown',
      options: additionalUsersOptions,
      isMulti: true,
    },
  };

  // const sectionType = sectionConfig[label]?.type || 'dropdown';
  const classes: IStringProps = {
    input:
      'my-1 p-1 w-full rounded border-gray-300 focus:outline-none focus:ring focus:border-blue-300 ',
    ul: 'mt-2 list-disc list-inside ',
    Classification: 'flex items-center ',
    Priority: 'flex items-center ',
    'Start Date': 'flex items-center ',
    'End Date': 'flex items-center ',
    Project: 'mb-4',
    Assigned: 'mb-4',
    'Parts Assets': 'mb-4',
    Locations: 'mb-4',
    Description: 'mb-4',
    Files: 'mb-4',
    Checklist: 'mb-4',
    Notes: 'mb-4',
    'Additional Users': 'mb-4',
  };

  const renderSection = {
    dropdown: (
      <Select
        onChange={handleChange[label]}
        value={sectionConfig[label]?.options?.filter(
          (option) => option.value === workOrderStates[label.toLowerCase()]
        )}
        options={sectionConfig[label]?.options || []}
        isMulti={sectionConfig[label]?.multi || null}
      />
    ),
    datepicker: (
      <ReactDatePicker
        className='my-1 p-1 w-full rounded border-gray-300 focus:outline-none focus:ring focus:border-blue-300 '
        selected={workOrderStates[camelCaseStr(label.toLowerCase())]}
        onChange={(date) => handleChange[label](date)}
        placeholderText='YYYY-MM-DD'
        dateFormat='yyyy-MM-dd'
      />
    ),
    creatable: (
      <Creatable
        isClearable
        isSearchable
        className='my-1 p-1 w-full rounded border-gray-300 focus:outline-none focus:ring focus:border-blue-300'
        options={sectionConfig[label]?.options || []}
        value={workOrderStates[camelCaseStr(label.toLowerCase())]}
        onChange={handleChange[label]}
        isMulti={sectionConfig[label]?.isMulti}
        components={sectionConfig[label]?.components}
        onKeyDown={sectionConfig[label]?.onKeyDown || null}
        placeholder={sectionConfig[label]?.placeholder || null}
        hideSelectedOptions={sectionConfig[label]?.hideSelectedOptions || null}
        noOptionsMessage={sectionConfig[label]?.noOptionsMessage || null}
        getOptionLabel={
          sectionConfig[label]?.getOptionLabel
            ? (option) => option.text || option.label
            : undefined
        }
      />
    ),
    multiDropdown: (
      <Select
        isMulti
        isSearchable
        value={workOrderStates[camelCaseStr(label.toLowerCase())]}
        options={sectionConfig[label]?.options || []}
        className={classes.input}
        closeMenuOnSelect={false}
        onChange={handleChange[label]}
      />
    ),
    textarea: (
      <textarea
        className='my-1 p-1 w-full rounded border-gray-300 focus:outline-none focus:ring focus:border-blue-300'
        value={workOrderStates[label.toLowerCase()]}
        onChange={(e) => handleChange[label](e.target.value)}
      />
    ),
    fileInput: (
      <input
        type='file'
        className='my-1 p-1 w-full rounded border-gray-300 focus:outline-none focus:ring focus:border-blue-300'
        onChange={(e) => handleChange.Files(Array.from(e.target.files || []))}
        multiple
      />
    ),
  };

  return (
    <div className={classes[label]}>
      <label className='block mx-1 text-sm font-medium text-gray-700 '>
        {label}:
        {label === 'Checklist' &&
          workOrderStates.checklist.length &&
          workOrderStates.checklist.length > 0 && (
            <span className='text-stone-400 text-xs mx-2 '>
              Enter checklist items separated by newlines
            </span>
          )}
      </label>
      {renderSection[sectionConfig[label]?.type]}
      {label === 'Checklist' && workOrderStates.checklist && (
        <ul className={classes.ul}>
          {workOrderStates.checklist.map((item) => (
            <div key={item.id}>
              <button
                className='fa-solid fa-square-xmark mr-2 text-red-600 hover:text-red-400'
                onClick={() => handleRemoveChecklistItem(item)}
              />
              <span>{item.text || item.value}</span>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FormInputSection;
