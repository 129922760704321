import { IServerContext, IUserContext } from 'state/iContext';
import { ServerContext, UserContext } from 'state/context';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

const SupportPage = () => {
  const { user } = useContext(UserContext) as IUserContext;
  const { domain } = useContext(ServerContext) as IServerContext;
  const [textareaContent, setTextareaContent] = useState('');
  const [subjectInput, setSubjectInput] = useState('');

  const getCsrfToken = async () => {
    try {
      const response = await axios.get(`${domain}get_csrf_token/`);
      return response.data.csrf_token;
    } catch (error) {
      console.error('Failed to fetch CSRF token:', error);
      return null;
    }
  };

  const sendEmail = async () => {
    try {
      const csrfToken = await getCsrfToken();
      if (!csrfToken) {
        console.error('CSRF token not available.');
        return;
      }

      const emailData = {
        from_email: user.email,
        to_email: 'alvin.tolentino+tms@rigstar.ca',
        subject: `TMS Support - ${subjectInput}`,
        message: `{\n
          SENDER: ${user.name} | ${user.email}, \n
          TIMESTAMP: ${new Date()}, \n
          CONTENT: ${textareaContent},
          \n}`,
      };

      const response = await axios.post(`${domain}send_email/`, emailData, {
        headers: {
          'X-CSRFToken': csrfToken,
        },
      });

      console.log('Email sent successfully:', response.data.message);
      window.alert(
        'Ticket sent successfully. Our team will reach out to you to confirm we have received your message'
      );
      setSubjectInput('');
      setTextareaContent('');
    } catch (error) {
      console.error('Failed to send the email:', error);
      window.alert('An error occurred attempting to send the ticket');
    }
  };

  useEffect(() => {
    setSubjectInput('');
    setTextareaContent('');
  }, []);

  return (
    <div>
      <div className='mb-5'>
        <h2 className='text-2xl text-stone-500 font-bold underline offset-8 mb-5'>
          Contact Support
        </h2>
        <p className='text-lg text-stone-600 font-semibold mx-2'>
          If you have found a bug or error in the web app, please describe the
          issue below, and we will address the issue.
        </p>
      </div>
      <div className='flex flex-col m-1'>
        <label
          htmlFor='subject'
          className='m-0.5'>
          Subject:
        </label>
        <input
          type='text'
          id='subject'
          placeholder='Enter the subject'
          value={subjectInput}
          onChange={(e) => setSubjectInput(e.target.value)}
          className='border border-gray-300 p-2 focus:border-stone-500 focus:ring focus:ring-stone-200'
        />
      </div>
      <div className='flex flex-col m-1'>
        <label
          htmlFor='issueDescription'
          className='m-0.5'>
          Issue Description:
        </label>
        <textarea
          id='issueDescription'
          rows={5}
          cols={50}
          placeholder='Describe the issue here'
          value={textareaContent}
          onChange={(e) => setTextareaContent(e.target.value)}
          className='flex-1 border border-gray-300 p-2 focus:border-stone-500 focus:ring focus:ring-stone-200'
        />
      </div>
      <div className='my-10'>
        <button
          onClick={sendEmail}
          disabled={textareaContent.length === 0}
          className='flex rounded-md px-3 border border-red-700 text-red-700 justify-center transition ease-in-out delay-100 hover:bg-red-100 disabled:text-gray-400 disabled:border-gray-400 disabled:bg-stone-200'>
          Submit Ticket
        </button>
      </div>
    </div>
  );
};

export default SupportPage;
