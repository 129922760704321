import React, { useContext } from 'react';
import { SettingsContext, SitesContext } from 'state/context';
import { ISettingsContext, ISitesContext } from 'state/iContext';
import { saveAs } from 'file-saver';
import { measurementConversion } from 'helpers/helperFunctions';

const ExcelExport = () => {
  const { towerList, filteredSiteIds, favoriteSites, favoritesTab } =
    useContext(SitesContext) as ISitesContext;
  const { scale, metric } =
    useContext(SettingsContext) as ISettingsContext;
  const siteIds: number[] = favoritesTab ? favoriteSites : filteredSiteIds;
  const classes = {
    button: 'm-4 p-1 bg-blue-500 text-white text-xs rounded',
  };

  const handleExportToExcel = () => {
    // Header row
    const headerColumn: string[][] = [
      [
        'Site',
        'Tower Type',
        'Code',
        `Height ( ${scale} )`,
        'Asset Container',
        'Assets',
        `Asset Elevation ( ${scale} | U )`,
        'Asset Property Name',
        'Asset Property Detail',
      ],
    ];

    siteIds.forEach((id) => {
      const tower = towerList[id];

      if (tower) {
        const towerAssetContainerIds = Object.keys(tower.layout.tower.legs);
        const rackIds = Object.keys(tower.layout.data.shelves);

        if (towerAssetContainerIds.length === 0) {
          // Add data for towers without asset containers
          headerColumn.push([
            tower.name,
            tower.type.site === 'Monopole' || tower.type.site === 'Rooftop'
              ? tower.type.site
              : `${tower.type.site} | ${tower.type.tower}`,
            tower.code,
            measurementConversion(metric, tower.height),
            '',
            'No assets to show',
            '',
            '',
            '',
          ]);
        }

        towerAssetContainerIds.forEach((legId) => {
          const leg = tower.layout.tower.legs[legId];

          if (leg) {
            Object.keys(leg.icons)
              .filter((assetId) => leg.icons[assetId].i >= 0)
              .forEach((assetId) => {
                const asset = leg.icons[assetId];

                if (asset) {
                  if (asset.properties) {
                    Object.keys(asset.properties).forEach((propertyId) => {
                      const property = asset.properties[propertyId];

                      // if (property && property.name) {
                        headerColumn.push([
                          tower.name,
                          tower.type.site === 'Monopole' ||
                          tower.type.site === 'Rooftop'
                            ? tower.type.site
                            : `${tower.type.site} | ${tower.type.tower}`,
                          tower.code,
                          measurementConversion(metric, tower.height),
                          leg.legGrid.name,
                          asset.name,
                          `${measurementConversion(
                            metric,
                            tower.height - asset.y - asset.h
                          )} - ${measurementConversion(
                            metric,
                            tower.height - asset.y
                          )}`,
                          property.name,
                          property.value,
                        ]);
                      // }
                    });
                  } else {
                    headerColumn.push([
                      tower.name,
                      tower.type.site === 'Monopole' ||
                      tower.type.site === 'Rooftop'
                        ? tower.type.site
                        : `${tower.type.site} | ${tower.type.tower}`,
                      tower.code,
                      measurementConversion(metric, tower.height),
                      leg.legGrid.name,
                      asset.name,
                      `${measurementConversion(
                        metric,
                        tower.height - asset.y - asset.h
                      )} - ${measurementConversion(
                        metric,
                        tower.height - asset.y
                      )}`,
                      '',
                      '',
                    ]);
                  }
                }
              });
          }
        });

        rackIds.forEach((rackId) => {
          const rack = tower.layout.data.shelves[rackId];

          if (rack) {
            Object.keys(rack.icons)
              .filter((assetId) => rack.icons[assetId].i >= 0)
              .forEach((assetId) => {
                const asset = rack.icons[assetId];

                if (asset) {
                  if (asset.properties) {
                    Object.keys(asset.properties).forEach((propertyId) => {
                      const property = asset.properties[propertyId];

                      // if (property && property.name) {
                        headerColumn.push([
                          tower.name,
                          tower.type.site === 'Monopole' ||
                          tower.type.site === 'Rooftop'
                            ? tower.type.site
                            : `${tower.type.site} | ${tower.type.tower}`,
                          tower.code,
                          measurementConversion(metric, tower.height),
                          rack.shelfGrid.name,
                          asset.name,
                          `${rack.shelfGrid.h - asset.y - asset.h} - ${
                            rack.shelfGrid.h - asset.y
                          }`,
                          property.name,
                          property.value,
                        ]);
                      // }
                    });
                  } else {
                    headerColumn.push([
                      tower.name,
                      tower.type.site === 'Monopole' ||
                      tower.type.site === 'Rooftop'
                        ? tower.type.site
                        : `${tower.type.site} | ${tower.type.tower}`,
                      tower.code,
                      rack.shelfGrid.h,
                      rack.shelfGrid.name,
                      asset.name,
                      `${rack.shelfGrid.h - asset.y - asset.h} - ${
                        rack.shelfGrid.h - asset.y
                      }`,
                      '',
                      '',
                    ]);
                  }
                }
              });
          }
        });
      }
    });

    const csvContent = headerColumn.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    saveAs(blob, `excel-file.csv`);
  };

  return (
    <button
      className={classes.button}
      onClick={handleExportToExcel}>
      Export to Excel
    </button>
  );
};

export default ExcelExport;
