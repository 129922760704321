import { Polyline } from '@react-google-maps/api';
import { IMapContext } from 'state/iContext';
import { MapContext } from 'state/context';
import React, { useContext} from 'react';


const TowerDistanceLine = () => {
  const { measureEndPoints }  = useContext(MapContext) as IMapContext;

  // Polyline options
  const polyOptions = {
    strokeColor: '#689efe',
    fillColor: '#FF0000',
    strokeOpacity: 0.8,
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    strokeWeight: 5,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
    path: [measureEndPoints.start?.location, measureEndPoints.end?.location],
  };
  return (
    <>
      <Polyline options={polyOptions} />
    </>
  );
};

export default TowerDistanceLine;