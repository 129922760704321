import {
  IEditContext,
  IMapContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import React, { useContext, ReactElement, useMemo} from 'react';

import {
  EditContext,
  MapContext,
  SitesContext,
  UserContext,
} from 'state/context';
import { INumberProps, IStringProps } from 'iApp';
import { IUser } from 'state/iState';

const FavoritesButton = ({ towerId }: INumberProps) => {
  const { setUser } = useContext(UserContext) as IUserContext;
  const { favoritesTab, favoriteSites } = useContext(SitesContext) as ISitesContext;
  const { setInfoWindowID } = useContext(MapContext) as IMapContext;
  const { isEditPanelOpen } = useContext(EditContext) as IEditContext;

  // Component classes
  const classes: IStringProps = {
    nonFavorite:
      'fa-regular fa-star z-50 m-1 text-stone-600 hover:text-red-600 cursor-pointer',
    favorite:
      'fa-solid fa-star z-50 m-1 text-red-600 hover:text-red-300 cursor-pointer',
  };

  // Adds selected site to user favorites list
  const favoriteClickHandler = useMemo(
    () => (): void => {
      const updatedFavorites: number[] = [...favoriteSites];
      const index: number = updatedFavorites.findIndex((id) => id === towerId);
      if (!isEditPanelOpen) {
        if (favoritesTab) {
          setInfoWindowID('');
        }
        updatedFavorites.splice(index, 1);
        setUser((prev: IUser) => ({
          ...prev,
          favorites: updatedFavorites,
        }));
      }
    },
    [
      towerId,
      favoritesTab,
      setUser,
      favoriteSites,
      isEditPanelOpen,
      setInfoWindowID,
    ]
  );

  // Removes selected site from user favorites list
  const nonFavoriteClickHandler = useMemo(
    () => (): void => {
      if (!isEditPanelOpen) {
        setUser((prev: IUser) => ({
          ...prev,
          favorites: [...prev.favorites, towerId],
        }));
      }
    },
    [towerId, setUser, isEditPanelOpen]
  );

  // Renders star icon to respective favorite status
  const star: ReactElement<any, any> = useMemo(
    () =>
      favoriteSites.includes(towerId) ? (
        <i
          className={classes.favorite}
          onClick={favoriteClickHandler}
        />
      ) : (
        <i
          className={classes.nonFavorite}
          onClick={nonFavoriteClickHandler}
        />
      ),
    [
      towerId,
      favoriteSites,
      classes.favorite,
      classes.nonFavorite,
      favoriteClickHandler,
      nonFavoriteClickHandler,
    ]
  );
  return <>{star}</>;
};

export default FavoritesButton;
