import { ILibraryContext, IUserContext } from 'state/iContext';
import { LibraryContext, UserContext } from 'state/context';
import Icons from 'components/icons/Icons';
import React, { useContext } from 'react';
import { IStringProps } from 'iApp';

const IconsPanel = () => {
  const { libraries, activeLibrary, setEditLibrary } =
    useContext(LibraryContext) as ILibraryContext;
  const { user } = useContext(UserContext) as IUserContext;
  const editableLibrary: boolean = Object.keys(user.private_libraries)
    .includes(activeLibrary);

  const classes: IStringProps = {
    container:
      'h-2/3 w-full border border-stone-300 overflow-y-auto bg-slate-100 sm:w-2/3 sm:h-full',
    header: 'm-1 font-medium text-lg text-stone-600',
    icon: 'fa-solid fa-pen-to-square mx-3 text-stone-400 text-sm hover:text-red-600',
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.header}>
        {libraries[activeLibrary]?.name}
        {editableLibrary && (
          <i
            className={classes.icon}
            onClick={() => {
              setEditLibrary({
                active: true,
                name: libraries[activeLibrary]?.name,
                prevName: libraries[activeLibrary]?.name,
              });
            }}
          />
        )}
      </h1>
      {activeLibrary !== '' && (
        <Icons library={libraries[activeLibrary].assets} />
      )}
    </div>
  );
};

export default IconsPanel;
