import React, { useContext, useState, useEffect } from 'react';
import { IAppViewClasses, IAppViewIcons } from './iHeaderTabs';
import AddButton from 'components/buttons/addButton/AddButton';
import HeaderTabs from './HeaderTabs';
import {
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  IServerContext,
  ISettingsContext,
} from 'state/iContext';
import {
  UserContext,
  SitesContext,
  CanvasContext,
  HeaderContext,
  ServerContext,
  SettingsContext,
} from 'state/context';
import { ITowerList } from 'state/iState';

const ApplicationView = () => {
  const {
    screenDimensions: { width },
  } = useContext(SettingsContext) as ISettingsContext;
  const { activeTowerID } =
    useContext(CanvasContext) as ICanvasContext;
  const { towerList, setTowerList } =
    useContext(SitesContext) as ISitesContext;
  const { domain, releaseCanvasLock } =
    useContext(ServerContext) as IServerContext;
  const {
    activeHeaderButton: { map, inventory },
  } = useContext(HeaderContext) as IHeaderContext;
  const { user } = useContext(UserContext) as IUserContext;

  // Component classes
  const classes: IAppViewClasses = {
    container: 'flex justify-between w-full',
    tabs: {
      active: 'flex text-xs ml-5 font-semibold',
      inactive:
        'absolute top-[50px] right-0 flex z-50 h-[50px] bg-stone-200 border border-slate-300 sm:right-auto',
    },
    tower: {
      container: 'flex items-center',
      img: 'rounded-full w-[35px] h-[35px] object-cover self-center text-center',
      name: 'ml-2 font-semibold max-w-1/3 text-slate-500 truncate md:max-w-full',
    },
    addSite: 'flex',
  };

  const [visibleTabs, setVisibleTabs] = useState<boolean>(false);

  const smallScreen: boolean = width <= 700;

  const exitCanvasEdit = async () => {
    if (towerList[activeTowerID].edit.user === user.userId) {
      await releaseCanvasLock(activeTowerID, user.userId);
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          edit: {
            active: false,
            user: null,
            time: null,
          },
        },
      }));
    }
  };

  // Closes header tabs dropdown on window enlarge
  useEffect(() => {
    if (!smallScreen) {
      setVisibleTabs(false);
    }
  }, [width, smallScreen]);

  // Small screen tab icons & behaviour
  const icons: IAppViewIcons = {
    true: (
      <i
        onClick={() => setVisibleTabs(false)}
        className='fa-solid fa-arrow-down-short-wide text-2xl self-center mx-3'
      />
    ),
    false: (
      <i
        onClick={() => setVisibleTabs(true)}
        className='fa-solid fa-bars text-2xl self-center mx-3'
      />
    ),
  };

  return (
    <div className={classes.container}>
      {smallScreen ? (
        icons[visibleTabs.toString() as keyof typeof icons]
      ) : (
        <div className={classes.tabs.active}>
          <HeaderTabs />
        </div>
      )}
      {!map ||
        (!inventory && (
          <div className={classes.tower.container}>
            <img
              src={`${domain}${towerList[activeTowerID].img}`}
              alt='tower thumbnail'
              className={classes.tower.img}
            />
            {width >= 420 && (
              <div className={classes.tower.name}>
                {towerList[activeTowerID].name}
              </div>
            )}
          </div>
        ))}
      <div
        className={classes.addSite}
        onClick={exitCanvasEdit}>
        {user.admin && <AddButton name='SITE' />}
      </div>
      {visibleTabs && (
        <div className={classes.tabs.inactive}>
          <HeaderTabs />
        </div>
      )}
    </div>
  );
};

export default ApplicationView;
