import { highlightSearch } from 'helpers/helperFunctions';
import { IStringProps } from 'iApp';
import React, { useContext } from 'react';
import { SitesContext, UserContext } from 'state/context';
import { ISitesContext, IUserContext } from 'state/iContext';

const PropertyDetails = ({ properties }) => {
  const { searchBar } =
    useContext(UserContext) as IUserContext;
  const { inventoryFilters, activeInventoryFilterCount } =
    useContext(SitesContext) as ISitesContext;
  const classes: IStringProps = {
    blank: 'p-0.5 border border-stone-300 bg-stone-100',
    column: 'p-0.5 border border-stone-300 text-center text-sm',
    semibold: ' font-semibold text-stone-400 underline',
  };

  const propertyKeys = Object.keys(properties).filter(
    (property) => !!properties[property].name
  );

  const propertyLines = propertyKeys.map((property) => (
    <tr key={property}>
      <td
        className={classes.blank}
        colSpan={activeInventoryFilterCount}
      />
      <td
        className={classes.column}
        colSpan={1}>
        {highlightSearch(properties[property].name, searchBar.trim())}
      </td>
      <td
        className={classes.column}
        colSpan={1}>
        {highlightSearch(properties[property].value, searchBar.trim())}
      </td>
    </tr>
  ));

  return (
    <>
      <tr className={classes.column + classes.semibold}>
        {propertyKeys.length > 0 ? (
          <>
            <td
              className={classes.blank}
              colSpan={activeInventoryFilterCount}
            />
            <td
              className={classes.column}
              colSpan={1}>
              Property
            </td>
            <td
              className={classes.column}
              colSpan={1}>
              Details
            </td>
          </>
        ) : (
          <>
            <td
              className={classes.blank}
              colSpan={activeInventoryFilterCount}
            />
            <td
              className={classes.column + ' text-sm'}
              colSpan={2}>
              No Properties
            </td>
          </>
        )}
      </tr>
      {propertyLines}
    </>
  );
};

export default PropertyDetails;
