import { ILibraryIcon, ITowerList } from 'state/iState';
import React, { useContext, useEffect } from 'react';
import {
  SettingsContext,
  CanvasContext,
  ServerContext,
  SitesContext,
  UserContext,
} from 'state/context';
import {
  ISettingsContext,
  ICanvasContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';

const EditCanvasButton = () => {
  const { towerList, setTowerList, fetchSiteData } = useContext(
    SitesContext
  ) as ISitesContext;
  const { activeTowerID, setDragProps } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const { setMinimize } = useContext(SettingsContext) as ISettingsContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { acquireCanvasLock, releaseCanvasLock, checkCanvasLock } = useContext(
    ServerContext
  ) as IServerContext;

  const editActive: boolean =
    towerList[activeTowerID].edit.active &&
    towerList[activeTowerID].edit.user === user.userId;

  const handlers = {
    enter: async () => {
      setMinimize(false);
      const locked: boolean = await checkCanvasLock(activeTowerID);

      if (!locked) {
        const acquired = await acquireCanvasLock(activeTowerID, user.userId);
        if (acquired) {
          setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              edit: {
                active: true,
                user: user.userId,
                time: Date.now(),
              },
            },
          }));
        } else {
          // Handle case where lock acquisition fails
          const fetchedSiteData = await fetchSiteData(activeTowerID);
          setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: fetchedSiteData,
          }));
        }
      } else {
        const fetchedSiteData = await fetchSiteData(activeTowerID);
        setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: fetchedSiteData,
        }));
      }
    },
    exit: async () => {
      setMinimize(true);
      await releaseCanvasLock(activeTowerID, user.userId);
      await setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          edit: {
            active: false,
            user: null,
            time: null,
          },
        },
      }));
    },
  };

  useEffect(
    () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
        content: '',
        id: '',
        target: '',
        type: '',
      })),
    [editActive]
  );

  return (
    <button
      className='relative flex flex-col justify-center text-center self-center border border-stone-300 py-1 px-2 bg-blue-700 text-slate-200 cursor-pointer transition ease-in-out delay-100 hover:bg-blue-800'
      onClick={editActive ? handlers.exit : handlers.enter}>
      {editActive ? 'Exit Edit Mode' : 'Edit Canvas'}
    </button>
  );
};

export default EditCanvasButton;
