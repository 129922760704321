import { IStringProps } from 'iApp';
import React from 'react';

const SectionLine = ({
  name,
  data,
  handler,
}: {
  name: string;
  data: string;
  handler: () => void;
}) => {
  const classes: IStringProps = {
    container: 'group flex justify-between',
    name: 'flex ml-1 font-bold text-stone-500 box-content text-center items-center',
    edit: 'hidden w-contain fa-solid fa-pen ml-1 text-xs text-stone-400 hover:text-red-600 group-hover:flex',
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.name}>
        {name}:
        <i
          className={classes.edit}
          onClick={handler}
        />
      </h1>
      <span>{data}</span>
    </div>
  );
};

export default SectionLine;
