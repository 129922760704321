import {
  ICanvasContext,
  IHeaderContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  CanvasContext,
  HeaderContext,
  SitesContext,
  UserContext,
} from 'state/context';
import React, { useEffect, useContext, useMemo } from 'react';
import { IActiveToolBar } from 'state/iState';
import ToolBarRight from './ToolBarRight';
import ToolBarLeft from './ToolBarLeft';
import EditCanvasButton from './EditCanvasButton';
import UpdateBanner from '../UpdateBanner';

const DataToolBar = (props) => {
  const { setActiveToolBar, activeTowerID } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { activeHeaderButton } = useContext(HeaderContext) as IHeaderContext;

  const activeEditor: boolean = useMemo(
    () => towerList[activeTowerID].edit.user === user.userId,
    [towerList, activeTowerID, activeHeaderButton]
  );

  useEffect(() => {
    const propsGrid = props.full
      ? { Properties: true, Grid: true }
      : {
          Properties: false,
          Grid: false,
        };

    setActiveToolBar((prev: IActiveToolBar) => ({
      ...prev,
      ...propsGrid,
    }));
  }, [props.full, setActiveToolBar]);

  useEffect(() => {}, [activeHeaderButton]);

  return (
    <div
      id='data-canvas-toolbar'
      className='flex flex-col z-60 w-full bg-slate-200 border border-stone-300 items-start justify-between text-stone-400 text-sm sm:flex-row'>
      {towerList[activeTowerID].edit.active && activeEditor && (
        <ToolBarLeft {...props} />
      )}
      {user.admin && (
        <>
          {towerList[activeTowerID].edit.active && !activeEditor ? (
            <UpdateBanner />
          ) : (
            <EditCanvasButton />
          )}
        </>
      )}
      {props.full && <ToolBarRight />}
    </div>
  );
};

export default DataToolBar;
