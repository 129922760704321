import {
  ICanvasContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  CanvasContext,
  ServerContext,
  SitesContext,
  UserContext,
} from 'state/context';
import { highlightSearch } from 'helpers/helperFunctions';
import React, { useContext } from 'react';
import { IGridItem } from '../iGridItems';
import DragHandle from '../DragHandle';
import { IStringProps } from 'iApp';

const GridImages = ({ layout }: IGridItem) => {
  const { activeToolBar, activeTowerID } =
    useContext(CanvasContext) as ICanvasContext;
  const { searchBar, user } =
    useContext(UserContext) as IUserContext;
  const { domain } =
    useContext(ServerContext) as IServerContext;
  const { towerList } =
    useContext(SitesContext) as ISitesContext;

  const editable: boolean = towerList[activeTowerID].edit.active && towerList[activeTowerID].edit.user === user.userId;

  const classes: IStringProps = {
    img: 'w-full h-full object-contain',
    property:
      'absolute w-full max-w-content -bottom-4 text-center text-xs flex-nowrap whitespace-nowrap hover:font-bold',
  };

  return (
    <>
      <img
        src={`${domain}${layout.img}`}
        alt={layout.name}
        className={classes.img}
      />
      {editable && <DragHandle />}
      {activeToolBar.Properties && (
        <div className={classes.property}>
          {highlightSearch(layout.name!, searchBar.trim())}
        </div>
      )}
    </>
  );
};

export default GridImages;
