import { IActiveToolBar, IHeaderButtons, ITowerList } from 'state/iState';
import { ApplicationTabIcons } from './ApplicationTabIcons';
import React, { useContext, ReactNode } from 'react';
import { ITabClasses } from './iHeaderTabs';
import {
  IHeaderContext,
  ICanvasContext,
  IUserContext,
  IDocumentContext,
  IEditContext,
  ISitesContext,
  IServerContext,
  ICompanyContext,
} from 'state/iContext';
import {
  HeaderContext,
  CanvasContext,
  UserContext,
  DocumentContext,
  EditContext,
  SitesContext,
  ServerContext,
  CompanyContext,
} from 'state/context';

// Component classes
const classes: ITabClasses = {
  container: {
    active:
      'flex flex-col justify-end px-4 text-slate-200 bg-red-700 transition ease-in-out delay-100 hover:bg-red-800',
    inactive:
      'flex flex-col justify-end px-4 text-slate-600 transition ease-in-out delay-100 hover:bg-red-700 hover:text-slate-200',
  },
  icon: 'flex flex-col w-auto max-h-[20px] justify-center items-center text-center',
};

const HeaderTabs = () => {
  const { activeHeaderButton, setActiveHeaderButton, homePage } = useContext(
    HeaderContext
  ) as IHeaderContext;
  const { setDragProps, clearDragProps, scale, setScale, setActiveToolBar } =
    useContext(CanvasContext) as ICanvasContext;
  const { user, setSearchBar, setSearchDate, setRangeSearchActive } =
    useContext(UserContext) as IUserContext;
  const {
    setUpdateFolders,
    setActiveFile,
    setActiveReport,
    setReportFormData,
    setReportImageData,
  } = useContext(DocumentContext) as IDocumentContext;
  const { isEditPanelOpen } = useContext(EditContext) as IEditContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { towerList, setTowerList } = useContext(SitesContext) as ISitesContext;
  const { releaseCanvasLock } = useContext(ServerContext) as IServerContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;

  const tabs: string[] =
    homePage || !activeTowerID
      ? ['map', 'inventory', 'work orders', 'reports']
      : [
          'tower',
          'site',
          'data',
          'drawings',
          'files',
          'work orders',
          'reports',
        ];

  const homeClickHandlers = async (tab: string) => {
    if (!activeHeaderButton[tab as keyof typeof activeHeaderButton]) {
      setActiveHeaderButton((prev: IHeaderButtons) => ({
        ...prev,
        map: false,
        inventory: false,
        tower: false,
        site: false,
        data: false,
        drawings: false,
        files: false,
        'work orders': false,
        reports: false,
        [tab]: true,
      }));
      setSearchBar('');
    }

    if (activeTowerID && towerList[activeTowerID]?.edit.user === user.userId) {
      await releaseCanvasLock(activeTowerID, user.userId);
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          edit: {
            active: false,
            user: null,
            time: null,
          },
        },
      }));
    }
  };

  // Toggles between each header tab in the application view
  const applicationClickHandlers = async (tab: string) => {
    if (tab === 'site' && towerList[activeTowerID].layout.site.image) {
      await setScale(0.55);
    } else {
      await setScale(1);
    }

    if (!activeHeaderButton[tab as keyof typeof activeHeaderButton]) {
      setActiveToolBar((prev: IActiveToolBar) => ({
        ...prev,
        select: true,
        image: false,
        text: false,
        drawings: false,
        sections: false,
        edit: false,
      }));
      setActiveHeaderButton((prev: IHeaderButtons) => ({
        ...prev,
        map: false,
        inventory: false,
        tower: false,
        site: false,
        data: false,
        drawings: false,
        files: false,
        contacts: false,
        'work orders': false,
        reports: false,
        [tab]: true,
      }));
      setActiveFile('add');
      setActiveReport(null);
      setReportFormData({});
      setReportImageData({});
      setDragProps(clearDragProps);
      setSearchBar('');
      setSearchDate('');
      setRangeSearchActive(false);
      setUpdateFolders({ active: false, parent: '', folder: '', name: '' });
    }

    if (activeTowerID && towerList[activeTowerID]?.edit.user === user.userId) {
      await releaseCanvasLock(activeTowerID, user.userId);
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          edit: {
            active: false,
            user: null,
            time: null,
          },
        },
      }));
    }
  };

  // Renders each tab to the header
  const headerTabs: ReactNode[] = tabs
    .filter((tab) => {
      if (tab === 'work orders') {
        return company.subscription.id >= 3 || company.add_ons.includes(3);
      }

      if (tab === 'reports') {
        return company.subscription.id >= 4 || company.add_ons.includes(4);
      }

      return (
        (!activeTowerID &&
          (tab === 'work orders' ||
            tab === 'inventory' ||
            tab === 'map' ||
            tab === 'reports')) ||
        (tab === 'map' && isEditPanelOpen) ||
        activeTowerID ||
        homePage
      );
    })
    .map((tab): ReactNode => {
      const key: string = `${tab}-tab`;
      const activeClass: string = activeHeaderButton[
        tab as keyof typeof activeHeaderButton
      ]
        ? classes.container.active
        : classes.container.inactive;

      const handleClick = homePage
        ? homeClickHandlers
        : applicationClickHandlers;

      return (
        <div
          key={key}
          className={activeClass}
          onClick={() => handleClick(tab)}>
          <div className={classes.icon}>{ApplicationTabIcons[tab]}</div>
          {tab.toUpperCase()}
        </div>
      );
    });

  return <>{headerTabs}</>;
};

export default HeaderTabs;
