import { kebabString } from 'helpers/helperFunctions';
import React, { useContext, useMemo } from 'react';
import { ILibraryTabClasses } from './iLibrary';
import Icons from '../../../../icons/Icons';
import {
  ICompanyContext,
  ILibraryContext,
  IModalContext,
  IUserContext,
} from 'state/iContext';
import {
  CompanyContext,
  LibraryContext,
  ModalContext,
  UserContext,
} from 'state/context';

// Component classes
const classes: ILibraryTabClasses = {
  tab: {
    active:
      'sticky top-0 z-20 flex justify-between items-center bg-red-700 text-slate-200 border border-stone-500 p-1',
    inactive:
      'flex z-20 justify-between items-center p-1 odd:bg-slate-100 transition ease-in-out delay-100 hover:bg-stone-300',
  },
  left: 'flex',
  search: {
    active: 'ml-1',
    inactive: 'ml-1 text-red-600',
  },
  arrow: {
    active: 'fa-solid fa-angle-down text-center mr-1',
    inactive: 'fa-solid fa-angle-right text-center mr-1',
  },
};

const LibraryTab = ({ id }: { id: string }) => {
  const {
    libraries,
    activeLibrary,
    setActiveIcon,
    toggleLibrary,
    searchFiltered,
  } = useContext(LibraryContext) as ILibraryContext;
  const {
    isModalActive,
    activeModal: { manageLibraries },
  } = useContext(ModalContext) as IModalContext;
  const { searchBar } = useContext(UserContext) as IUserContext;
  const { icons } = useContext(CompanyContext) as ICompanyContext;

  const { tab, search, arrow } = classes;
  // Toggles classes
  const activeName: boolean = activeLibrary === id;
  const activeTab: boolean = activeName && !manageLibraries;
  const activeTabClass: string = activeTab ? tab.active : tab.inactive;
  const activeArrow: string = activeTab ? arrow.active : arrow.inactive;
  const activeSearch: string = activeTab ? search.active : search.inactive;
  // Returns total number of icons that match search bar
  const iconTotal: number = useMemo(() => {
    const library = libraries[id] || {};
    return library.assets.filter((icon: string) =>
      searchFiltered.includes(icons[icon].name)
    ).length;
  }, [libraries, searchFiltered, id, icons]);

  // Toggles library visibility
  const clickHandler = (name: string): void => {
    toggleLibrary(name);
    setActiveIcon('');
  };

  return (
    <>
      <div
        id={`${kebabString(id)}-library`}
        onClick={() => clickHandler(id)}
        className={activeTabClass}>
        <div className={classes.left}>
          {libraries[id].name}
          {searchBar && !isModalActive && (
            <div className={activeSearch}>({iconTotal})</div>
          )}
        </div>
        <i className={activeArrow} />
      </div>
      {activeTab && <Icons library={libraries[id].assets} />}
    </>
  );
};

export default LibraryTab;
