import { IStringProps } from 'iApp';
import React, { useContext, useEffect, useMemo } from 'react';
import {
  CompanyContext,
  DocumentContext,
  HistoryContext,
  ServerContext,
  SitesContext,
  UserContext,
  WorkOrderContext,
} from 'state/context';
import {
  ICompanyContext,
  IDocumentContext,
  IHistoryContext,
  IServerContext,
  ISitesContext,
  IUserContext,
  IWorkOrderContext,
} from 'state/iContext';
import { deepEqual, nextAvailableKey } from 'helpers/helperFunctions';
import { IChecklistItem, IWorkOrder } from '../iWorkOrders';
import WorkOrderFormHeader from './WorkOrderFormHeader';
import FormInputSection from './FormInputSection';
import UploadedFiles from './UploadedFiles';
import { ITowerDocument } from 'state/iState';
import { clearWorkOrder } from 'state/state';
import axios from 'axios';

//TODO: Link/reference to other work orders

const WorkOrderForm = ({
  toggleWorkOrder,
  workOrder,
}: {
  toggleWorkOrder: () => void;
  workOrder?: number;
}) => {
  const {
    domain,
    updateWorkOrdersDB,
    addHistoryToDB,
    uploadFilesToDB,
    updateDocumentsDB,
    deleteFileFromDB,
    deleteDocumentFromDB,
  } = useContext(ServerContext) as IServerContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { workOrders, setWorkOrders, workOrderStates, setWorkOrderStates } =
    useContext(WorkOrderContext) as IWorkOrderContext;
  const { user, userList } = useContext(UserContext) as IUserContext;
  const { icons: assets, company } = useContext(
    CompanyContext
  ) as ICompanyContext;
  const { history, setHistory, fetchHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { documentList, newDocKey, setNewDocKey, fetchDocuments } = useContext(
    DocumentContext
  ) as IDocumentContext;

  const placeholderHistoryKey: number = useMemo(
    () => nextAvailableKey(history, 0)!,
    [history]
  );
  const placeholderWorkOrderKey: number = useMemo(
    () => nextAvailableKey(workOrders, 0)!,
    [workOrders]
  );

  useEffect(() => {
    if (workOrder) {
      setWorkOrderStates((prev) => ({
        ...prev,
        startDate: new Date(workOrders[workOrder].startDate!),
        endDate: new Date(workOrders[workOrder].endDate!),
        description: workOrders[workOrder].description,
        locations: workOrders[workOrder].locations.map((location) => ({
          value: +location,
          label: towerList[+location].name,
        })),
        project: {
          value: workOrders[workOrder].project,
          label: workOrders[workOrder].project,
        },
        assigned: workOrders[workOrder].assigned.map((userID) => ({
          value: userID,
          label: userList[userID].name,
        })),
        confirmed: workOrders[workOrder].confirmed,
        partsAssets: workOrders[workOrder].partsAssets.map((assetID) => {
          const asset = assets[assetID];
          let path = '';
          asset.properties.forEach((property) => {
            if ('path' in property) {
              path = property.path!;
            }
          });
          return {
            value: assetID,
            label: asset.name,
            image: path,
          };
        }),
        progress: workOrders[workOrder].progress,
        priority: workOrders[workOrder].priority,
        classification: workOrders[workOrder].classification,
        fileList: workOrders[workOrder].files,
        checklist: workOrders[workOrder].checklist,
        notes: workOrders[workOrder].notes,
        additionalUsers: workOrders[workOrder].additionalUsers.map(
          (userID) => ({
            value: userID,
            label: userList[userID].name,
          })
        ),
        lastModifiedBy: user.name,
        last_modified: Date.now(),
        history: workOrders[workOrder].history,
      }));
    } else {
      setWorkOrderStates((prev) => ({
        ...prev,
        history: [placeholderHistoryKey],
      }));
    }
  }, []);

  const disabledButton: boolean =
    !workOrderStates.classification ||
    !workOrderStates.priority ||
    !workOrderStates.startDate ||
    !workOrderStates.endDate ||
    !workOrderStates.project ||
    workOrderStates.partsAssets.length === 0 ||
    workOrderStates.locations.length === 0 ||
    !workOrderStates.description;

  const createNewForm = async (): Promise<IWorkOrder> => {
    const transformedFiles = await Promise.all(
      workOrderStates.fileList.map(transformFile)
    );

    // Check if any transformation failed (returned 0)
    if (transformedFiles.some((id) => id === 0)) {
      // Handle the case where file transformation failed
      console.error('File transformation failed for some files');
      return Promise.reject('File transformation failed');
    }

    const newForm: IWorkOrder = {
      id: workOrder ? workOrder : placeholderWorkOrderKey,
      startDate: workOrderStates.startDate!.getTime(),
      endDate: workOrderStates.endDate!.getTime(),
      locations:
        workOrderStates.locations && workOrderStates.locations.length > 0
          ? workOrderStates.locations.map((loc) => loc.value)
          : [],
      assigned:
        workOrderStates.assigned && workOrderStates.assigned.length > 0
          ? workOrderStates.assigned.map((assignee) => assignee.value)
          : [],
      confirmed: workOrderStates.confirmed,
      partsAssets:
        workOrderStates.partsAssets && workOrderStates.partsAssets.length > 0
          ? workOrderStates.partsAssets.map((asset) => asset.value)
          : [],
      project: workOrderStates.project ? workOrderStates.project.value : '',
      priority: workOrderStates.priority || '',
      classification: workOrderStates.classification || '',
      description: workOrderStates.description.trim(),
      files: transformedFiles as number[],
      checklist:
        workOrderStates.checklist && workOrderStates.checklist.length > 0
          ? workOrderStates.checklist.map(transformChecklistItem)
          : [],
      notes: workOrderStates.notes.trim(),
      additionalUsers:
        workOrderStates.additionalUsers &&
        workOrderStates.additionalUsers.length > 0
          ? workOrderStates.additionalUsers.map((user) => user.value)
          : [],
      progress: workOrder ? workOrders[workOrder].progress : 'Assigned',
      createdBy: workOrder ? workOrders[workOrder].createdBy : user.userId,
      lastModifiedBy: user.userId,
      createdDate: workOrder ? workOrders[workOrder].createdDate! : Date.now(),
      lastModified: Date.now(),
      history: workOrderStates.history,
    };
    setWorkOrderStates((prevState) => ({
      ...prevState,
      ...newForm,
    }));

    return newForm;
  };

  const transformFile = async (file: File | number): Promise<number> => {
    if (!file) {
      return 0;
    }

    if (typeof file === 'number') return file;

    const newFileName = `workOrder-${
      workOrder ? workOrder : placeholderWorkOrderKey
    }-${file.name}`;

    // Upload the file with the new name
    const convertedFile = new File([file], newFileName);

    // Upload the file to the database
    await uploadFilesToDB([convertedFile]);

    // Update document list and save document to the database
    const docData: ITowerDocument = {
      name: file.name,
      folder: 5,
      upload: Date.now(),
      updated: Date.now(),
      user: user.userId,
      isPDF: true,
      img: `files/media/client/${company.name}/${newFileName}`,
    };

    const docId = await updateDocumentsDB(newDocKey!, 'POST', false, docData);

    if (docId === undefined || docId === null) {
      console.error('Error transforming file: DocumentID error');
      return 0;
    }
    if (docId !== newDocKey) {
      await setNewDocKey(docId);
    }

    // Call fetchDocuments to ensure the document list is updated
    await fetchDocuments();
    return docId!;
  };

  const transformChecklistItem = (
    item: IChecklistItem,
    index: number
  ): IChecklistItem => ({
    id: item.id ? item.id : index,
    text: item.text || item.value!,
  });

  const hasListChanges = (detail: any): boolean => {
    if (detail.field === 'Files' || detail.field === 'Checklist') {
      return (
        (detail.added && detail.added.length > 0) ||
        (detail.removed && detail.removed.length > 0) ||
        (detail.updated && detail.updated.length > 0)
      );
    }

    return detail.before !== detail.after;
  };

  const checklistChangesDetails = (
    workOrder: number | undefined,
    newForm: IWorkOrder
  ) => {
    if (!workOrder) {
      return [];
    }

    const oldForm = workOrders[workOrder];
    const changesDetails: Array<{
      field: string;
      before?: any;
      after?: any;
      added?: IChecklistItem[];
      removed?: IChecklistItem[];
      updated?: IChecklistItem[];
    }> = [];

    const addedItems = newForm.checklist.filter(
      (item) => !oldForm.checklist.some((oldItem) => oldItem.id === item.id)
    );
    if (addedItems.length > 0) {
      changesDetails.push({ field: 'Checklist', added: addedItems });
    }

    const removedItems = oldForm.checklist.filter(
      (item) => !newForm.checklist.some((newItem) => newItem.id === item.id)
    );
    if (removedItems.length > 0) {
      changesDetails.push({ field: 'Checklist', removed: removedItems });
    }

    const updatedItems = newForm.checklist.filter((newItem) => {
      const oldItem = oldForm.checklist.find((item) => item.id === newItem.id);
      return oldItem && !deepEqual(oldItem, newItem);
    });
    if (updatedItems.length > 0) {
      changesDetails.push({ field: 'Checklist', updated: updatedItems });
    }

    return changesDetails;
  };

  const generateChangesDetails = (newForm: IWorkOrder): any[] => {
    const checklistDetails = checklistChangesDetails(workOrder, newForm);
    const fileDetails = generateFileChangesDetails(newForm);

    const fieldsToCheck = [
      'classification',
      'priority',
      'startDate',
      'endDate',
      'project',
      'assigned',
      'locations',
      'partsAssets',
      'description',
      'notes',
      'additionalUsers',
    ];

    const otherDetails = fieldsToCheck
      .map((field) => getFieldChangeDetail(field, workOrder, newForm))
      .filter((detail) => detail !== null)
      .map(filterChecklistChanges);

    return [...checklistDetails, ...fileDetails, ...otherDetails].filter(
      (detail) => hasListChanges(detail)
    );
  };

  const getFieldChangeDetail = (
    field: string,
    workOrder: number | undefined,
    newForm: IWorkOrder
  ): any | null => {
    const oldValue = workOrder ? workOrders[workOrder][field] : null;
    const newValue = newForm[field];

    if (!deepEqual(oldValue, newValue)) {
      return {
        field,
        before: oldValue,
        after: newValue,
      };
    }

    return null;
  };

  const getFileChangesDetails = (
    workOrder: number | undefined,
    newForm: IWorkOrder
  ): any[] => {
    if (!workOrder) {
      return [];
    }

    const oldForm = workOrders[workOrder];
    const changesDetails: any[] = [];

    const addedFiles = newForm.files.filter(
      (file) => !oldForm.files.some((oldFile) => deepEqual(oldFile, file))
    );
    if (addedFiles.length > 0) {
      changesDetails.push({ field: 'Files', added: addedFiles });
    }

    const removedFiles = oldForm.files.filter(
      (file) => !newForm.files.some((newFile) => deepEqual(newFile, file))
    );
    if (removedFiles.length > 0) {
      changesDetails.push({ field: 'Files', removed: removedFiles });
    }

    const updatedFiles = newForm.files.filter((newFile) => {
      const oldFile = oldForm.files.find((file) => deepEqual(file, newFile));
      return oldFile && !deepEqual(oldFile, newFile);
    });
    if (updatedFiles.length > 0) {
      changesDetails.push({ field: 'Files', updated: updatedFiles });
    }

    return changesDetails;
  };

  const filterFileChanges = (detail: any): any => {
    if (detail.field === 'Files') {
      const filteredDetail = { ...detail };

      if (filteredDetail.added) {
        filteredDetail.added = filteredDetail.added.map(transformFile);
      }

      if (filteredDetail.removed) {
        filteredDetail.removed = filteredDetail.removed.map(transformFile);
      }

      if (filteredDetail.updated) {
        filteredDetail.updated = filteredDetail.updated.map(transformFile);
      }

      return filteredDetail;
    }

    return detail;
  };

  const generateFileChangesDetails = (newForm: IWorkOrder): any[] => {
    const fileChangesDetails = getFileChangesDetails(workOrder, newForm);
    return fileChangesDetails.map(filterFileChanges);
  };

  const filterChecklistChanges = (detail: any): any => {
    if (detail.field === 'Checklist') {
      const filteredDetail = { ...detail };

      if (filteredDetail.added) {
        filteredDetail.added = filteredDetail.added.filter(
          (item) => item !== null
        );
      }

      if (filteredDetail.removed) {
        filteredDetail.removed = filteredDetail.removed.filter(
          (item) => item !== null
        );
      }

      return filteredDetail;
    }

    return detail;
  };

  const submitNewForm = async () => {
    try {
      const fetchedHistory = await fetchHistory();
      const workOrderForm = await createNewForm();
      const changesDetails = generateChangesDetails(workOrderForm);
      const method = workOrder ? 'PUT' : 'POST';
      const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);
      const nextWorkOrderKey = await axios.get(
        `${domain}get-next-pk/workorders/`
      );

      if (changesDetails.length === 0) {
        toggleWorkOrder();
        return;
      }

      const filesToDelete = workOrders[workOrder]?.files.filter(
        (existingFileID) =>
          !workOrderForm.files.some((fileID) => fileID === existingFileID)
      );

      const historyEntry = {
        id: nextHistoryKey.data.next_pk,
        timestamp: Date.now(),
        type: 'WorkOrder',
        createdBy: user.userId,
        changes: {
          action: workOrder ? 'Updated' : 'Created',
          details: changesDetails,
        },
      };

      const workOrderEntry = {
        ...workOrderForm,
        id: workOrder ? workOrderForm.id : nextWorkOrderKey.data.next_pk,
        history: [...workOrderStates.history, nextHistoryKey.data.next_pk],
      };

      setHistory({
        ...fetchedHistory,
        [nextHistoryKey.data.next_pk]: historyEntry,
      });
      addHistoryToDB(historyEntry);
      updateWorkOrdersDB(method, workOrderEntry);

      setWorkOrders((prev) => ({
        ...prev,
        [workOrder ? workOrders[workOrder].id : nextWorkOrderKey.data.next_pk]:
          workOrderEntry,
      }));
      toggleWorkOrder();

      if (filesToDelete?.length > 0) {
        await Promise.all(
          filesToDelete.map(async (file) => {
            await deleteDocumentFromDB(file);
            await deleteFileFromDB(documentList[file].img!);
          })
        );
      }

      const fromEmail = 'alvin.tolentino@rigstar.ca';
      const subject = workOrder
        ? `Work Order #${workOrderEntry.id} Updated`
        : `New Work Order #${nextWorkOrderKey.data.next_pk} Created`;
      const content = [
        {
          type: 'text/html',
          value: `<!DOCTYPE html>
              <html>
              <body>
                <p>Dear Team,</p>
                <p>A new work order has been created:</p>
                <p>
                  <strong>Project:</strong> ${workOrderForm.project}<br>
                  <strong>Description:</strong> ${workOrderForm.description}<br>
                  <strong>Locations:</strong> ${workOrderForm.locations
                    .map((loc) => towerList[loc].name)
                    .join(', ')}
                </p>
                <p>Please log in to the system to view more details and take the necessary actions.</p>
                <p><a href="https://${
                  company.name
                }.tms.rigstar.ca">TMS: Tower Management Software</a></p>
                <p>Best regards,<br>Your Work Order System</p>
              </body>
              </html>`,
        },
      ];

      interface Personalization {
        to: { email: string }[];
      }

      if (workOrder) {
        const existingAssignees = workOrders[workOrder].assigned;
        const existingAdditionalUsers = workOrders[workOrder].additionalUsers;

        const newAssignees = workOrderForm.assigned.filter(
          (assignee) => !existingAssignees.includes(assignee)
        );

        const newAdditionalUsers = workOrderStates.additionalUsers.filter(
          (user) => !existingAdditionalUsers.includes(user)
        );
        const personalizations: Personalization[] = [];

        newAssignees.forEach((assignee) => {
          if (userList[assignee as unknown as string]) {
            personalizations.push({
              to: [{ email: userList[assignee as unknown as string].email }],
            });
          } else {
            console.warn(`Assignee ID ${assignee} not found in userList`);
          }
        });

        newAdditionalUsers.forEach((additionalUser) => {
          if (userList[additionalUser.value]) {
            personalizations.push({
              to: [{ email: userList[additionalUser.value].email }],
            });
          } else {
            console.warn(
              `Additional User ID ${additionalUser} not found in userList`
            );
          }
        });

        if (personalizations.length > 0) {
          const emailData = {
            from: { email: fromEmail },
            subject,
            content,
            personalizations,
          };

          await axios.post(`${domain}send_email/`, emailData);
        }
      } else {
        const allRecipients = [
          ...workOrderForm.assigned,
          ...workOrderStates.additionalUsers,
        ];

        const personalizations: Personalization[] = allRecipients
          .map((recipient) => {
            const user = userList[recipient as unknown as string];
            if (user) {
              return { to: [{ email: user.email }] };
            } else {
              console.warn(`Recipient ID ${recipient} not found in userList`);
              return null;
            }
          })
          .filter((item): item is Personalization => item !== null);

        if (personalizations.length > 0) {
          const emailData = {
            from: { email: fromEmail },
            subject,
            content,
            personalizations,
          };

          await axios.post(`${domain}send_email/`, emailData);
        }
      }
    } catch (error) {
      console.error('Error submitting new form:', error);
    }
  };

  const classes: IStringProps = {
    dropdowns: 'flex w-full justify-around mb-4 ',
    buttons: 'flex w-full justify-center',
    button: 'text-white px-4 py-2 mx-1 rounded ',
  };

  useEffect(() => {
    if (!workOrder) {
      setWorkOrderStates(clearWorkOrder);
    }
  }, []);

  return (
    <div className='p-4'>
      <WorkOrderFormHeader />
      <div>
        <div className={classes.dropdowns}>
          <FormInputSection label='Classification' />
          <FormInputSection label='Priority' />
          <FormInputSection label='Start Date' />
          <FormInputSection label='End Date' />
        </div>
        <FormInputSection label='Project' />
        <FormInputSection label='Assigned' />
        <FormInputSection label='Parts Assets' />
        <FormInputSection label='Locations' />
        <FormInputSection label='Description' />
        <FormInputSection label='Files' />
        <UploadedFiles />
        <FormInputSection label='Checklist' />
        <FormInputSection label='Notes' />
        <FormInputSection label='Additional Users' />
        <div className={classes.buttons}>
          <button
            type='submit'
            disabled={disabledButton}
            className={classes.button + ' bg-blue-500 disabled:bg-stone-400'}
            onClick={submitNewForm}>
            {workOrder ? 'Update' : 'Submit'}
          </button>
          <button
            type='button'
            className={classes.button + ' bg-red-600'}
            onClick={toggleWorkOrder}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderForm;
