import { highlightSearch } from 'helpers/helperFunctions';
import { IIconClasses } from './iIcons';
import { IHandlers } from 'iApp';
import {
  IUserContext,
  IModalContext,
  ICanvasContext,
  IServerContext,
  ICompanyContext,
  ILibraryContext,
} from 'state/iContext';
import {
  UserContext,
  ModalContext,
  CanvasContext,
  ServerContext,
  CompanyContext,
  LibraryContext,
} from 'state/context';
import React, {
  useMemo,
  useState,
  ReactNode,
  useContext,
  ReactElement,
} from 'react';
import { IAddItemProps } from 'state/iState';

// Component classes
const classes: IIconClasses = {
  container:
    'flex flex-wrap w-full h-fit bg-slate-100 justify-center items-start',
  image: 'w-full h-[70px] object-scale-down',
  icon: {
    active:
      'relative flex flex-col bg-white min-w-[85px] max-w-[85px] min-h-[100px] max-h-[100px] rounded rounded-sm my-1 mx-2 p-0.5 text-center border border-4 border-red-600 cursor-pointer hover:border-red-700',
    inactive:
      'relative flex flex-col bg-white min-w-[85px] max-w-[85px] min-h-[100px] max-h-[100px] rounded rounded-sm my-1 mx-2 p-0.5 text-center border border-stone-200 cursor-pointer hover:border-red-700 hover:border-2',
  },
  hover: 'absolute bottom-2 flex w-90 p-1 justify-center z-10 text-xs bg-white',
  displayOnly:
    'relative flex flex-col bg-white min-w-[85px] max-w-[85px] min-h-[100px] max-h-[100px] rounded rounded-sm m-0.5 p-0.5 text-center border border-stone-200',
  label: 'text-xs truncate',
};

// TODO: Rename to assets///////////---------
const Icons = ({ library }) => {
  const { toggleIcon, activeIcon, searchFiltered } = useContext(
    LibraryContext
  ) as ILibraryContext;
  const { setDragProps, setAddItemProps, clearAddProps, clearDragProps } =
    useContext(CanvasContext) as ICanvasContext;
  const { searchBar } = useContext(UserContext) as IUserContext;
  const { icons } = useContext(CompanyContext) as ICompanyContext;
  const {
    activeModal: { manageLibraries },
  } = useContext(ModalContext) as IModalContext;
  const { domain } = useContext(ServerContext) as IServerContext;

  const [hoverData, setHoverData] = useState<string>('');
  const [hover, setHover] = useState<boolean>(false);

  const { active, inactive } = classes.icon;

  const iconIds: string[] = useMemo(
    () =>
      library.filter((icon: string) =>
        searchFiltered.includes(icons[icon].name)
      ),
    [searchFiltered, library, icons]
  );

  const assets: ReactElement[] | ReactNode = useMemo(
    () =>
      iconIds.length > 0 ? (
        iconIds.map((icon): ReactElement => {
          const iconName: string = icons[icon].name;
          const activeClass: string = activeIcon === icon ? active : inactive;
          const currentClass: string = manageLibraries
            ? classes.displayOnly
            : activeClass;

          const handlers: IHandlers = {
            click: (): void => {
              if (!manageLibraries) {
                setAddItemProps(clearAddProps);
                toggleIcon(icon);
              }
            },
            mouseOver: (): void => {
              setHoverData(iconName);
              setHover(true);
            },
            mouseOut: (): void => {
              setHoverData('');
              setHover(false);
            },
          };
          const pathObject = icons[icon].properties.find((obj) => {
            return obj.path;
          });
          return (
            <div
              draggable
              key={icon}
              id={iconName}
              onClick={handlers.click}
              className={currentClass}
              onMouseOut={handlers.mouseOut}
              onMouseOver={handlers.mouseOver}
              onDragStart={(_) => {
                setDragProps({
                  id: icons[icon].name,
                  content: icons[icon].content,
                  properties: icons[icon].properties,
                  target: icon,
                  type: 'new',
                });
              }}>
              <img
                alt='library icon'
                src={`${domain}${pathObject!.path}`}
                className={classes.image}
              />
              <label className={classes.label}>
                {highlightSearch(iconName, searchBar.trim())}
              </label>
              {hover && hoverData === iconName && iconName.length >= 12 && (
                <div className={classes.hover}>{iconName}</div>
              )}
            </div>
          );
        })
      ) : (
        <div className='flex w-full justify-center bg-slate-300 text-stone-500'>
          No Icons Saved
        </div>
      ),
    [
      icons,
      hover,
      active,
      inactive,
      iconIds,
      hoverData,
      searchBar,
      activeIcon,
      toggleIcon,
      setDragProps,
      classes.image,
      classes.hover,
      classes.label,
      manageLibraries,
      classes.displayOnly,
    ]
  );

  return <div className={classes.container}>{assets}</div>;
};

export default Icons;
