import { IActiveTab, ISettingsCardClasses } from './iSettings';
import SystemSettings from './edit/system/SystemSettings';
import { IHeaderContext } from 'state/iContext';
import { HeaderContext } from 'state/context';
import Close from 'components/buttons/Close';
import EditUser from './edit/user/EditUser';
import React, { useContext } from 'react';
import SupportPage from './SupportPage';

const SettingsCard = ({ account, settings, support }: IActiveTab) => {
  const { setActiveHeaderButton, lastActiveView } =
    useContext(HeaderContext) as IHeaderContext;

  // Component classes
  const classes: ISettingsCardClasses = {
    container:
      'flex flex-col relative border border-stone-200 p-3 w-full h-full',
    titleClass: 'font-bold text-stone-500 mb-4',
    border:
      'relative border border-t-4 border-stone-300 p-5 w-full h-full overflow-y-auto',
  };

  const title: string = account ? 'User' : settings ? 'Settings' : 'Support';

  return (
    <div className={classes.container}>
      <Close handler={(): void => setActiveHeaderButton(lastActiveView)} />
      <div className={classes.titleClass}>{title}</div>
      <div className={classes.border}>
        {account && <EditUser />}
        {settings && <SystemSettings />}
        {support && <SupportPage />}
      </div>
    </div>
  );
};

export default SettingsCard;