import { IActiveToolBar, ITowerList } from 'state/iState';
import { formatFieldName } from 'helpers/helperFunctions';
import { ICallbackProps, IStringProps } from 'iApp';
import React, { useContext } from 'react';
import axios from 'axios';
import {
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  IServerContext,
  ISettingsContext,
  IHistoryContext,
  IUserContext,
} from 'state/iContext';
import {
  SitesContext,
  CanvasContext,
  HeaderContext,
  ServerContext,
  SettingsContext,
  HistoryContext,
  UserContext,
} from 'state/context';

const activeGrid: IStringProps = {
  data: 'shelves',
  tower: 'legs',
};

const key: IStringProps = {
  drawings: 'drawings',
  images: 'image',
  textBoxes: 'text',
  sections: 'sections',
};

const content: IStringProps = {
  drawings: 'refID',
  images: 'img',
  textBoxes: 'text',
};

const GridItemOptions = () => {
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { towerList, setTowerList } = useContext(SitesContext) as ISitesContext;
  const { activeCanvasTab } = useContext(HeaderContext) as IHeaderContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { domain, deleteFileFromDB, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { minimize, setMinimize } = useContext(
    SettingsContext
  ) as ISettingsContext;
  const {
    dragProps,
    clearPanel,
    setDragProps,
    activeTowerID,
    clearDragProps,
    setAddItemProps,
    setActiveToolBar,
  } = useContext(CanvasContext) as ICanvasContext;

  const classes: IStringProps = {
    label: 'w-full bg-slate-400 text-center',
    container: minimize
      ? 'flex flex-col h-full w-full bg-red-600 self-center justify-center'
      : 'flex h-[32px] w-full bg-red-600',
    button: minimize
      ? 'flex h-full w-full justify-center items-center text-slate-200 transition ease-in-out delay-100 hover:bg-red-700'
      : 'flex w-full justify-center items-center text-slate-200 transition ease-in-out delay-100 hover:bg-red-700',
    edit: 'fa-solid fa-pen-to-square',
    remove: 'fa-solid fa-trash-can',
  };

  const towerLayout = towerList[activeTowerID].layout.tower;
  const siteLayout = towerList[activeTowerID].layout.site;
  const dataLayout = towerList[activeTowerID].layout.data;

  const target = {
    tower: {
      asset: towerLayout.legs[+dragProps.target!]?.icons,
      drawings: towerLayout.drawings,
      images: towerLayout.images,
      textBoxes: towerLayout.textBoxes,
      sections: towerLayout.legs[+dragProps.target!]?.sections,
    },
    site: {
      asset: siteLayout.assets,
      drawings: siteLayout.drawings,
      images: siteLayout.images,
      textBoxes: siteLayout.textBoxes,
    },
    data: {
      asset: dataLayout.shelves[dragProps.target!]?.icons,
      drawings: dataLayout.drawings,
      images: dataLayout.images,
      textBoxes: dataLayout.textBoxes,
      sections: dataLayout.shelves[dragProps.target!]?.sections,
    },
  };

  if (!!!target[activeCanvasTab]?.[dragProps?.type!]?.[dragProps.id]) {
    return <></>;
  }

  const activeTarget = target[activeCanvasTab][dragProps.type!][dragProps.id];

  const { [+dragProps.id!]: toBeRemoved, ...rest } =
    target[activeCanvasTab][dragProps.type!]!;

  const updateHistory = async () => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    // Determine the type of action being recorded
    const isAssetOrSection = ['assets', 'sections'].includes(dragProps?.type!);

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: `${formatFieldName(activeCanvasTab)} Canvas`,
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: isAssetOrSection
          ? [
              {
                updated: [
                  {
                    text: `Removed on ${
                      towerList[activeTowerID].layout[activeCanvasTab][
                        activeGrid[activeCanvasTab]
                      ][+dragProps.target!][
                        activeCanvasTab === 'tower' ? 'legGrid' : 'shelfGrid'
                      ].name
                    }`,
                  },
                ],
                removed: [
                  {
                    text: dragProps.content,
                  },
                ],
                field: `${activeCanvasTab === 'tower' ? 'Tower' : 'Data'} ${
                  dragProps.type === 'sections'
                    ? 'Lease Section'
                    : formatFieldName(dragProps.type)
                } Removed`,
              },
            ]
          : [
              {
                removed: [
                  {
                    text: dragProps.content,
                  },
                ],
                field: `${activeCanvasTab === 'tower' ? 'Tower' : 'Data'} ${
                  dragProps.type === 'drawings'
                    ? 'Reference Link'
                    : formatFieldName(dragProps.type)
                } Removed`,
              },
            ],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    await addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const asset = {
    tower: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            tower: {
              ...prev[activeTowerID].layout.tower,
              legs: {
                ...prev[activeTowerID].layout.tower.legs,
                [dragProps.target!]: {
                  ...prev[activeTowerID].layout.tower.legs[dragProps.target!],
                  icons: rest,
                },
              },
            },
          },
        },
      }));
    },
    site: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            site: {
              ...prev[activeTowerID].layout.site,
              assets: rest,
            },
          },
        },
      }));
    },
    data: () =>
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            data: {
              ...prev[activeTowerID].layout.data,
              shelves: {
                ...prev[activeTowerID].layout.data.shelves,
                [dragProps.target!]: {
                  ...prev[activeTowerID].layout.data.shelves[dragProps.target!],
                  icons: rest,
                },
              },
            },
          },
        },
      })),
  };

  const handlers = {
    asset: asset[activeCanvasTab],
    drawings: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [activeCanvasTab]: {
              ...prev[activeTowerID].layout[activeCanvasTab],
              drawings: rest,
            },
          },
        },
      }));
    },
    images: async () => {
      await deleteFileFromDB(activeTarget.img);
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [activeCanvasTab]: {
              ...prev[activeTowerID].layout[activeCanvasTab],
              images: rest,
            },
          },
        },
      }));
    },
    textBoxes: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [activeCanvasTab]: {
              ...prev[activeTowerID].layout[activeCanvasTab],
              textBoxes: rest,
            },
          },
        },
      }));
    },
    sections: () => {
      setTowerList((prev: ITowerList) => {
        const { [+dragProps.id!]: toBeRemoved, ...sectionsLeft } =
          prev[activeTowerID].layout[activeCanvasTab][
            activeGrid[activeCanvasTab]
          ][dragProps.target!].sections;
        return {
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: {
              ...prev[activeTowerID].layout,
              [activeCanvasTab]: {
                ...prev[activeTowerID].layout[activeCanvasTab],
                [activeGrid[activeCanvasTab]]: {
                  ...prev[activeTowerID].layout[activeCanvasTab][
                    activeGrid[activeCanvasTab]
                  ],
                  [dragProps.target!]: {
                    ...prev[activeTowerID].layout[activeCanvasTab][
                      activeGrid[activeCanvasTab]
                    ][dragProps.target!],
                    sections: sectionsLeft,
                  },
                },
              },
            },
          },
        };
      });
    },
  };

  const optionHandler: ICallbackProps = {
    edit: () => {
      clearPanel();
      setMinimize(false);
      setAddItemProps({
        label: activeTarget.name,
        content:
          dragProps.type === 'sections'
            ? dragProps.id
            : activeTarget[content[dragProps.type!]],
        index:
          dragProps.type === 'sections' || dragProps.type === 'asset'
            ? +dragProps.target!
            : dragProps.id,
        edit: true,
      });
      if (dragProps.type !== 'asset') {
        setActiveToolBar((prev: IActiveToolBar) => ({
          ...prev,
          select: false,
          [key[dragProps.type!]]: true,
        }));
        setDragProps(clearDragProps);
      }
    },
    remove: async () => {
      await updateHistory();
      await handlers[dragProps.type!]();
      await setDragProps(clearDragProps);
    },
  };

  return (
    <>
      {!minimize && (
        <div className={classes.label}>
          {(dragProps.type === 'sections' || dragProps.target!) && (
            <>
              {towerList[activeTowerID].layout[activeCanvasTab][
                activeGrid[activeCanvasTab]
              ][+dragProps.target!][
                activeCanvasTab === 'tower' ? 'legGrid' : 'shelfGrid'
              ]?.name + ': '}
            </>
          )}
          {dragProps.type === 'sections'
            ? activeTarget.name
            : dragProps.content}
        </div>
      )}
      <div className={classes.container}>
        {/* {dragProps.type !== 'asset' && ( */}
        <div
          key='edit'
          className={classes.button}
          onClick={optionHandler.edit}
          onTouchStart={optionHandler.edit}>
          <i className={classes.edit} />
        </div>
        {/* )}{' '} */}
        <div
          key='delete'
          className={classes.button}
          onClick={optionHandler.remove}
          onTouchStart={optionHandler.remove}>
          <i className={classes.remove} />
        </div>
      </div>
    </>
  );
};

export default GridItemOptions;
