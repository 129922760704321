import { ILibrariesList } from 'components/application/canvas/panel/manageLibraries/iManageLibraries';
import { IAsset, ILibrary, ILibraryIcon, ITowerList } from 'state/iState';
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { ICustomIconClasses, ICustomIconProps } from './iCustomIcon';
import PropertiesStage from './stages/PropertiesStage';
import CustomIconButtons from './CustomIconButtons';
import SaveToLibrary from './stages/SaveToLibrary';
import StageProgress from './stages/StageProgress';
import ImageStage from './stages/ImageStage';
import ModalHeader from '../ModalHeader';
import { IInputEvent } from 'iApp';
import axios from 'axios';
import {
  ModalContext,
  ServerContext,
  CompanyContext,
  LibraryContext,
  UserContext,
  SitesContext,
  CanvasContext,
  HistoryContext,
} from 'state/context';
import {
  IModalContext,
  IServerContext,
  ICompanyContext,
  ILibraryContext,
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IHistoryContext,
} from 'state/iContext';

const CustomIcon = () => {
  const {
    editIcon,
    clearModal,
    setEditIcon,
    clearEditIcon,
    setActiveModal,
    customIconStage: stage,
    setCustomIconStage: setStage,
  } = useContext(ModalContext) as IModalContext;
  const {
    libraries,
    activeIcon,
    setLibraries,
    setActiveIcon,
    assetNameInvalid,
  } = useContext(LibraryContext) as ILibraryContext;
  const { company, icons, setIcons, assetKeys } = useContext(
    CompanyContext
  ) as ICompanyContext;
  const { domain, updateAssetsDB, updateLibrariesDB, addHistoryToDB } =
    useContext(ServerContext) as IServerContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { setTowerList } = useContext(SitesContext) as ISitesContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { setHistory, fetchHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const [saveToLibrary, setSaveToLibrary] = useState<string>('');

  const classes: ICustomIconClasses = {
    container:
      'relative flex flex-col w-[90%] h-[80%] z-50 bg-white border border-stone-300 rounded-md md:w-[60%] items-center p-3',
    iconName: {
      container:
        'flex w-full mt-3 p-2 bg-stone-200 border border-stone-300 rounded rounded-md justify-center items-center',
      input:
        'w-2/3 h-2/3 ml-4 required:border-red-500 valid:border-stone-300 invalid: border-red-500',
    },
  };

  const header: string =
    editIcon.type === 'save' ? 'SAVE TO CUSTOM LIBRARY' : 'CREATE CUSTOM ICON';

  const nextAssetKey = useMemo(
    () => `${company.libraryID}-${Math.max(...assetKeys, 0) + 1}`,
    [assetKeys]
  );
  const updateHistory = async () => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    // Extract relevant properties from editIcon and filter out objects with 'path' key
    const newProperties = editIcon.properties
      .filter((property: any) => !property.path)
      .map((property: any) => ({ name: property.name, value: property.value }));

    const historyDetail = [
      {
        added: [
          { text: `Asset - ${editIcon.id} Added` },
          ...newProperties.map((prop) => ({
            text: `Property - ${prop.name}: ${prop.value}`,
          })),
        ],
        field: `${
          editIcon.type && editIcon.type === 'copy' ? 'Copied' : 'Created'
        } Icon`,
      },
    ];

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'Icons',
      createdBy: user.userId,
      changes: {
        action: 'Updated',
        details: historyDetail,
      },
    };
    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    await addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const assetId = useMemo(() => {
    const assetIdPart = nextAssetKey.split('-').pop();
    return parseInt(assetIdPart!, 10);
  }, [nextAssetKey]);

  useEffect(() => {
    if (!activeIcon) {
      setEditIcon((prev: ILibraryIcon) => ({
        ...prev,
        content: '',
      }));
    }
  }, [setEditIcon, activeIcon]);

  const props: ICustomIconProps = {
    input: {
      type: 'text',
      name: 'name',
      id: 'create-icon-name',
      defaultValue: editIcon.id,
      className:
        editIcon.type === 'save'
          ? `${classes.iconName.input} bg-stone-200`
          : classes.iconName.input,
      required: true,
      onChange: (e: IInputEvent) =>
        setEditIcon((prev: ILibraryIcon) => ({
          ...prev,
          id: e.target.value,
        })),
    },
    image: {
      editIcon,
      setEditIcon,
      setStage,
    },
    portProperties: {
      editIcon,
      setEditIcon,
    },
    save: {
      handlers: {
        setSaveToLibrary,
        saveBox: {
          save: async () => {
            if (saveToLibrary) {
              console.log({ activeIcon, editIcon });
              const assetKey =
                activeIcon && editIcon.type === 'save'
                  ? activeIcon
                  : nextAssetKey;
              const assetProps: IAsset = {
                companyId: company.id,
                id: +assetId,
                name: editIcon.id,
                content: editIcon.content as File,
                properties: editIcon.properties,
                type: 'client',
              };

              if (editIcon.type !== 'save') {
                setIcons((prev) => ({
                  ...prev,
                  [nextAssetKey]: assetProps,
                }));
              }

              setLibraries((prev: ILibrary) => ({
                ...prev,
                [saveToLibrary]: {
                  ...prev[saveToLibrary],
                  assets: [...prev[saveToLibrary].assets, assetKey],
                },
              }));

              const libraryProps: ILibrary = {
                ...libraries[saveToLibrary],
                assets: [...libraries[saveToLibrary].assets, assetKey],
              };

              const companyLibraryProps: ILibrary = {
                ...libraries[company.libraryID],
                assets: [...libraries[company.libraryID].assets, assetKey],
              };

              try {
                if (editIcon.type !== 'save') {
                  updateAssetsDB('POST', assetProps);
                  await updateHistory();
                }
                updateLibrariesDB('PUT', libraryProps);

                if (
                  company.libraryID !== +saveToLibrary &&
                  editIcon.type !== 'save'
                ) {
                  updateLibrariesDB('PUT', companyLibraryProps);
                  setLibraries((prev: ILibrariesList) => ({
                    ...prev,
                    [company.libraryID]: {
                      ...prev[company.libraryID],
                      assets: [...prev[company.libraryID].assets, assetKey],
                    },
                  }));
                }

                setSaveToLibrary('');
                setStage(1);
                setEditIcon(clearEditIcon);
                setActiveIcon('');
                setActiveModal(clearModal);
              } catch (error: any) {
                window.alert('Data update failed: ' + error.message);
                console.log({ ERROR: error });
              }
            }
          },
          cancel: () => {
            setSaveToLibrary('');
            setStage(2);
          },
        },
      },
      saveToLibrary,
    },
    buttons: {
      stage,
      setStage,
      editIcon,
    },
  };

  return (
    <div className={classes.container}>
      <ModalHeader name={header} />
      <div className={classes.iconName.container}>
        <label htmlFor='name'>Icon Name:</label>
        <input
          {...props.input}
          disabled={editIcon.type === 'save'}
        />
      </div>
      {assetNameInvalid && editIcon.type !== 'save' && (
        <span className='font-bold text-red-500'>
          Please choose a Unique icon name
        </span>
      )}
      <StageProgress stage={stage} />
      {stage === 1 && <ImageStage {...props.image} />}
      {stage >= 2 && <PropertiesStage {...props.portProperties} />}
      {stage === 3 && <SaveToLibrary {...props.save} />}
      <CustomIconButtons {...props.buttons} />
    </div>
  );
};

export default CustomIcon;
