import { IActiveDrawingProps } from './iDrawings';
import { IInputEvent, IStringProps } from 'iApp';
import SaveBox from 'components/buttons/SaveBox';
import DisplayFile from '../files/DisplayFile';
import React, { useContext } from 'react';
import DrawingButtons from './DrawingButtons';
import {
  ITowerDocument,
  IAddItemProps,
  IDocumentList,
  ITowerList,
} from 'state/iState';
import {
  IDocumentContext,
  ICanvasContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  DocumentContext,
  CanvasContext,
  ServerContext,
  SitesContext,
  UserContext,
} from 'state/context';

const ActiveDrawing = ({ drawing }: { drawing: ITowerDocument }) => {
  const { addItemProps, activeTowerID, clearAddProps, setAddItemProps } =
    useContext(CanvasContext) as ICanvasContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { setTowerList } = useContext(SitesContext) as ISitesContext;
  const { updateDocumentsDB } = useContext(ServerContext) as IServerContext;
  const { activeFile, fetchDocuments, documentList, setDocumentList } =
    useContext(DocumentContext) as IDocumentContext;

  const classes: IStringProps = {
    container: 'relative w-full h-full flex flex-col items-center',
    heading: 'font-bold text-xl text-stone-400 text-center',
    img: 'h-2/3 w-fit self-center object-contain border border-2 border-stone-400',
    pdf: 'h-full self-center border border-2 border-stone-400 object-stretch',
    saveBox: 'absolute flex top-0 right-0 w-[150px] justify-around my-1 mx-5',
  };

  const props: IActiveDrawingProps = {
    input: {
      type: 'text',
      name: 'drawing-label',
      id: 'drawing-label',
      placeholder: 'Drawing Label',
      defaultValue: addItemProps.label,
      onChange: (e: IInputEvent) =>
        setAddItemProps((prev: IAddItemProps) => ({
          ...prev,
          label: e.target.value,
        })),
    },
    saveBox: {
      classes: classes.saveBox,
      clickHandlers: {
        save: async () => {
          await fetchDocuments();
          const docData: ITowerDocument = {
            ...documentList[activeFile!],
            name: addItemProps.label,
            updated: Date.now(),
            user: user.userId,
          };

          await setDocumentList((prev: IDocumentList) => ({
            ...prev,
            [activeFile!]: docData,
          }));
          await updateDocumentsDB(
            +activeFile!,
            'PUT',
            false,
            docData
          );

          setTowerList((prev: ITowerList) => {
            const files = prev[activeTowerID].documents.folders[1].files;
            const newFiles = files.includes(addItemProps.index!)
              ? files
              : [...files, addItemProps.index!];

            return {
              ...prev,
              [activeTowerID]: {
                ...prev[activeTowerID],
                documents: {
                  ...prev[activeTowerID].documents,
                  folders: {
                    ...prev[activeTowerID].documents.folders,
                    1: {
                      ...prev[activeTowerID].documents.folders[1],
                      files: newFiles,
                    },
                  },
                },
              },
            };
          });

          setAddItemProps(clearAddProps);
        },
        cancel: () => setAddItemProps(clearAddProps),
      },
      disabled: !addItemProps.label,
    },
  };

  return (
    <div className={classes.container}>
      {addItemProps.edit ? (
        <>
          <input {...props.input} />
          <SaveBox {...props.saveBox} />
        </>
      ) : (
        <>
          <h1 className={classes.heading}>{drawing.name}</h1>
          <DrawingButtons />
        </>
      )}
      <DisplayFile />
    </div>
  );
};

export default ActiveDrawing;
