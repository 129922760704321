import { IEditUserImageClasses, IEditUserImage } from './iEditUser';
import { IUpdateImageProps } from 'components/home/editPanel/iEdit';
import { convertImageToWebP } from 'helpers/helperFunctions';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import React, { useContext, useEffect } from 'react';
import SaveBox from 'components/buttons/SaveBox';
import ReactCrop from 'react-image-crop';
import { IUser } from 'state/iState';
import { IInputEvent } from 'iApp';
import {
  ICropContext,
  IUserContext,
  IServerContext,
  ISettingsContext,
} from 'state/iContext';
import {
  CropContext,
  UserContext,
  ServerContext,
  SettingsContext,
} from 'state/context';
import {
  ICropSaveMethods,
  IChangeImageStates,
  IFileSelectComplete,
} from 'helpers/iHelpers';

const EditUserImage = ({ image }: IEditUserImage) => {
  const {
    fileTypes: { acceptedImageTypes },
  } = useContext(SettingsContext) as ISettingsContext;
  const { isCropActive, setIsCropActive, setImage } =
    useContext(CropContext) as ICropContext;
  const { user, setUser } =
    useContext(UserContext) as IUserContext;
  const { domain } =
    useContext(ServerContext) as IServerContext;

  const {
    crop,
    imgSrc,
    setImgSrc,
    setImgSrcExt,
    fileInputRef,
    onFileSelect,
    setFileReader,
    onCropSave,
    onCropChange,
    onCropComplete,
    clearToDefault,
    imagePreviewCanvasRef,
    getImageFileExtension,
    setScaleDimensions,
  } = ImageCropHelpers(image);

  // Component classes
  const classes: IEditUserImageClasses = {
    container: 'relative flex flex-col',
    imgInputBox: 'flex',
    cropBox: 'min-w-[208px] min-h-[208px] max-w-[208px] max-h-[208px]',
    canvasClass:
      'rounded-full min-w-[208px] min-h-[208px] max-w-[208px] max-h-[208px] p-5 m-3 object-fill self-center text-center',
    imageClass:
      'rounded-full min-w-[208px] min-h-[208px] max-w-[208px] max-h-[208px] m-3 object-fill text-center border border-stone-200',
    inputClass: 'ml-2 mt-3 text-sm text-blue-500',
    saveBoxClass:
      'absolute flex -right-2 sm:right-0 bottom-5 w-[150px] justify-around sm:mx-5',
  };

  // Changes/saves the state of the image and file extension
  const changeImageStates = async ({ image, save }: IChangeImageStates) => {
    setImgSrc(image);
    setImgSrcExt(getImageFileExtension(image));
    const fileName: string = `user-image-${user.userId}`;
    const imageFile: File = await convertImageToWebP(image, fileName);

    if (save) {
      setImage(image);
      setUser((prev: IUser) => ({
        ...prev,
        image: `files/media/clients/${user.company}/${fileName}`,
        file: imageFile,
      }));
    }
  };

  // Additional methods to envoke once a file is selected
  const onFileSelectComplete = ({ e, file }: IFileSelectComplete) => {
    setIsCropActive(true);
    setFileReader({
      file,
      changeImageStates,
    });
    clearToDefault(e);
  };

  // Methods to envoke while saving the cropped image
  const cropSaveMethods: ICropSaveMethods = {
    changeImageStates,
    onComplete: () => setIsCropActive(false),
  };

  // Props to pass to their respective elements
  const props: IUpdateImageProps = {
    reactCrop: {
      crop,
      aspect: 1,
      src: imgSrc,
      circularCrop: true,
      ruleOfThirds: true,
      onChange: onCropChange,
      crossorigin: 'anonymous',
      className: classes.cropBox,
      onComplete: onCropComplete,
    },
    canvas: {
      ref: imagePreviewCanvasRef,
      className: classes.canvasClass,
    },
    img: {
      crop: {
        id: 'crop-user-image',
        src: imgSrc,
      },
      displayed: {
        id: 'displayed-user-thumbnail',
        className: classes.imageClass,
        src: image ? image : `${domain}${user.image}`,
      },
    },
    input: {
      type: 'file',
      multiple: false,
      ref: fileInputRef!,
      name: 'photo-input',
      id: 'user-photo-input',
      accept: acceptedImageTypes,
      className: classes.inputClass,
      onChange: (e: IInputEvent) => onFileSelect({ e, onFileSelectComplete }),
    },
    saveBox: {
      classes: classes.saveBoxClass,
      clickHandlers: {
        save: (e: IInputEvent) => onCropSave({ e, cropSaveMethods }),
        cancel: () => {
          setImgSrc('');
          setIsCropActive(false);
        },
      },
    },
  };

  const { input, reactCrop, canvas, img, saveBox } = props;

  // Displays a static/croppable image
  const imageElementToDisplay = isCropActive ? (
    <>
      <ReactCrop {...reactCrop}>
        <img
          alt='user thumbail'
          {...img.crop}
        />
      </ReactCrop>
      <SaveBox {...saveBox} />
    </>
  ) : (
    <img
      alt='user thumbail'
      {...img.displayed}
    />
  );

  useEffect(
    () =>
      setScaleDimensions({
        w: 210,
        h: 210,
      }),
    [setScaleDimensions]
  );

  return (
    <>
      <div className={classes.container}>
        <div className={classes.imgInputBox}>
          {imageElementToDisplay}
          {imgSrc && isCropActive && <canvas {...canvas} />}
        </div>
        <input {...input} />
      </div>
    </>
  );
};

export default EditUserImage;
