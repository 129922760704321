import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import SiteHistoryPanel from './toolbar/history/SiteHistoryPanel';
import React, { useContext, useEffect, useMemo } from 'react';
import AddCanvasButton from './toolbar/AddCanvasButton';
import CanvasGridLines from './CanvasGridLines';
import DataToolBar from './toolbar/DataToolBar';
import CanvasPanel from './panel/CanvasPanel';
import { IStringProps } from 'iApp';
import Footer from 'Footer';
import {
  IHeaderContext,
  ICanvasContext,
  ISitesContext,
  IUserContext,
  IServerContext,
} from 'state/iContext';
import {
  HeaderContext,
  CanvasContext,
  SitesContext,
  UserContext,
  ServerContext,
} from 'state/context';
import PropertiesPanel from './panel/PropertiesPanel';

const classes: IStringProps = {
  container: 'flex flex-col h-full md:flex-row-reverse md:w-full',
  zoomable: 'relative flex flex-col h-[94%] md:h-full w-full',
  canvas: 'relative w-full h-full bg-stone-200',
  transform: 'w-full h-full',
  placeholder: 'h-full w-full opacity-0',
  width: '100%',
};

const canvasContainers = {
  tower: ['legs', 'legGrid'],
  data: ['shelves', 'shelfGrid'],
};

const findSmallestX = (targetObject: any, container: string): number => {
  if (!targetObject) return 0;

  const smallestX = Object.values(targetObject).reduce(
    (smallestX: number, grid: any) => {
      const x = grid[container]?.x;
      if (isFinite(x) && x < smallestX) {
        return x;
      }
      return smallestX;
    },
    Infinity
  );

  // Return 0 if all values were invalid or if smallestX remains Infinity
  return isFinite(smallestX) ? smallestX : 0;
};

const Canvas = () => {
  const {
    scale,
    setScale,
    dragProps,
    setDragProps,
    activeToolBar,
    clearDragProps,
    activeTowerID,
  } = useContext(CanvasContext) as ICanvasContext;
  const {
    activeHeaderButton: { tower, site, data },
    activeCanvasTab,
  } = useContext(HeaderContext) as IHeaderContext;
  const { updateSitesDB, towerList } = useContext(
    SitesContext
  ) as ISitesContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { releaseCanvasLock } = useContext(ServerContext) as IServerContext;

  const editable: boolean = useMemo(
    () =>
      towerList[activeTowerID].edit.active &&
      towerList[activeTowerID].edit.user === user.userId,
    [towerList[activeTowerID].edit]
  );

  const leftTarget = findSmallestX(
    towerList[activeTowerID]?.layout?.[activeCanvasTab]?.[
      canvasContainers?.[activeCanvasTab]?.[0]
    ],
    canvasContainers?.[activeCanvasTab]?.[1]
  );

  const towerAdjustment = (leftTarget === 0 ? 0 : 1075 - leftTarget) * 7.55;
  const dataAdjustment = (leftTarget === 0 ? 0 : 1135 - leftTarget) * 7.55;
  const canvasAdjustment = data ? dataAdjustment : towerAdjustment;
  const activeSiteImage = towerList[activeTowerID]?.layout?.site?.image;
  const standardWidth = data ? 2000 : site ? 2000 : 2400;
  const windowWidth = window.innerWidth;
  const initialX = standardWidth - windowWidth + canvasAdjustment;

  const options = useMemo(
    () => ({
      x: site && activeSiteImage ? -5365 : -10000 + initialX,
      y: site && activeSiteImage ? -1991 : -3600,
    }),
    [data, tower, site, initialX, activeSiteImage]
  );

  const handlers = {
    dragStop: () => setDragProps((prev) => ({ ...prev, dragging: false })),
    onTransformed: async (_, state) => setScale(state.scale),
    onZoom: () => setDragProps((prev) => ({ ...prev, dragging: false })),
    onZoomStop: async (e) => {
      await setScale(e.state.scale);
      setDragProps(clearDragProps);
    },
    clear: () => setDragProps(clearDragProps),
  };

  useEffect(() => {}, [
    towerList[activeTowerID].edit.active,
    towerList[activeTowerID].edit.user === user.userId,
  ]);

  useEffect(() => {
    setDragProps(clearDragProps);
  }, []);

  useEffect(() => {
    if (editable) {
      updateSitesDB(activeTowerID);
    }
  }, [updateSitesDB, activeTowerID, towerList, releaseCanvasLock]);

  return (
    <div
      key={activeCanvasTab}
      className={classes.container}>
      {activeToolBar.history && <SiteHistoryPanel />}
      <div className={classes.zoomable}>
        <TransformWrapper
          maxScale={5}
          minScale={0.2}
          initialScale={scale}
          limitToBounds={false}
          initialPositionX={options.x}
          initialPositionY={options.y}
          panning={{
            disabled: dragProps.dragging,
            excluded: ['input', 'textarea'],
          }}
          onPanningStop={handlers.dragStop}
          onWheelStop={handlers.dragStop}
          onZoomStop={handlers.onZoomStop}
          onTransformed={handlers.onTransformed}
          onZoom={handlers.onZoom}>
          {(util) => (
            <>
              <DataToolBar
                {...util}
                full={true}
              />
              <div
                className={classes.canvas}
                onDoubleClick={handlers.clear}>
                <TransformComponent
                  wrapperStyle={{ width: '100%' }}
                  contentClass={classes.transform}
                  wrapperClass={classes.transform}>
                  <img
                    src=''
                    alt=''
                    key='prod grid placeholder'
                    className={classes.placeholder}
                  />
                  <CanvasGridLines />
                </TransformComponent>
              </div>
            </>
          )}
        </TransformWrapper>
        {(tower || data) && editable && <AddCanvasButton />}
        <Footer />
      </div>

      {editable ? <CanvasPanel /> : <PropertiesPanel />}
    </div>
  );
};

export default Canvas;
