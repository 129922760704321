import { IEditUserInputProps } from './iEditUser';
import { ICompanyContext } from 'state/iContext';
import { CompanyContext } from 'state/context';
import React, { useContext } from 'react';

const EditUserSelect = ({
  name,
  value,
  classes,
  onChange,
}: IEditUserInputProps) => {
  const { company } =
    useContext(CompanyContext) as ICompanyContext;

  // Renders all available options
  const options: JSX.Element[] = company.job_titles.map(
    (title): JSX.Element => (
      <option
        value={title}
        key={title}>
        {title}
      </option>
    )
  );

  return (
    <div className={classes.container}>
      <label
        className={classes.label}
        htmlFor={name}>
        {name}
      </label>
      <select
        name={name}
        onChange={onChange}
        defaultValue={value}
        className={classes.box}
        id={`edit-user-${name.toLowerCase()}`}>
        {options}
      </select>
    </div>
  );
};

export default EditUserSelect;
