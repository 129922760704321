import { formatDateTime, formatFieldName } from 'helpers/helperFunctions';
import React, { useContext, useMemo, useState } from 'react';
import {
  CompanyContext,
  DocumentContext,
  HistoryContext,
  SitesContext,
  UserContext,
} from 'state/context';
import {
  ICompanyContext,
  IDocumentContext,
  IHistoryContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import HistoryDetails from './HistoryDetails';

const WorkOrderHistory = ({ history }) => {
  const { history: allHistory } = useContext(HistoryContext) as IHistoryContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { userList } = useContext(UserContext) as IUserContext;
  const { icons: assets } = useContext(CompanyContext) as ICompanyContext;
  const { documentList } = useContext(DocumentContext) as IDocumentContext;

  const [selectedHistory, setSelectedHistory] = useState<number | null>(null);

  const sortedHistoryList = useMemo(
    () =>
      history
        .map((historyId) => allHistory[historyId])
        .filter(Boolean)
        .sort((a, b) => b.timestamp - a.timestamp),
    [allHistory, history]
  );

  const handleHistoryItemClick = (entry) =>
    setSelectedHistory(selectedHistory ? null : entry.id);

  return (
    <div className='w-1/6 h-full work-order-history border border-stone-200 p-1 text-[10px] overflow-y-auto'>
      <ul className='list-inside'>
        {selectedHistory ? (
          <HistoryDetails
            onClick={handleHistoryItemClick}
            historyId={selectedHistory}
          />
        ) : (
          sortedHistoryList.map((entry) => (
            <li
              key={entry.id}
              className='history-entry flex flex-col'
              onClick={() => handleHistoryItemClick(entry)}>
              <div className='timestamp pr-2'>
                <strong>{formatDateTime(entry.timestamp)}</strong>
              </div>
              <div className='flex w-full'>
                <div className='timestamp pr-2 flex flex-col w-1/2 font-semibold'>
                  <span>{entry.changes.action} by:</span>
                </div>
                <div className='details w-1/2 text-end'>
                  {userList[entry.createdBy].name}
                </div>
              </div>
              {entry.changes.action === 'Updated' && (
                <div className='flex flex-col w-full'>
                  {entry.changes.details.map((detail, index) => (
                    <div key={index}>
                      {detail.field === 'Checklist' ||
                      detail.field === 'Files' ? (
                        <div className='flex flex-col w-full font-semibold'>
                          {detail?.added &&
                            !detail?.updated &&
                            detail.added.length > 0 && (
                              <span>
                                Added{' '}
                                {detail.field === 'Checklist' ? 'Data to ' : ''}
                                {detail.field}
                              </span>
                            )}
                          {detail?.removed &&
                            !detail?.updated &&
                            detail.removed.length > 0 && (
                              <span>
                                Removed{' '}
                                {detail.field === 'Checklist' ? 'Data to ' : ''}
                                {detail.field}
                              </span>
                            )}
                          {detail?.updated && (
                            <span>
                              {detail.updated.length > 1
                                ? `Updated ${detail.field} Data`
                                : detail.updated[0].text}
                            </span>
                          )}
                          {detail.field === 'Files' && detail?.before && (
                            <span>File Updated</span>
                          )}
                        </div>
                      ) : (
                        <>
                          <div>
                            <span className='font-semibold'>Field:</span>{' '}
                            <span className='italic'>
                              {formatFieldName(detail.field)}
                            </span>
                          </div>
                          {Array.isArray(detail.after) ? (
                            detail.after.length > 0 && (
                              <div>
                                <span className='font-semibold'>
                                  Updated to:
                                </span>{' '}
                                <span className='italic'>
                                  {detail.after
                                    .map((item) => {
                                      if (detail.field === 'locations') {
                                        return towerList[item]?.name;
                                      }
                                      if (detail.field === 'files') {
                                        return documentList[item]?.name;
                                      }
                                      if (
                                        detail.field === 'assigned' ||
                                        detail.field === 'additionalUsers'
                                      ) {
                                        return userList[item]?.name;
                                      }
                                      return typeof item === 'string'
                                        ? assets[item]?.name || item
                                        : item.label;
                                    })
                                    .join(', ')}
                                </span>
                              </div>
                            )
                          ) : (
                            <div>
                              {detail.field !== 'Confirmed' && (
                                <>
                                  {detail.after && (
                                    <span className='font-semibold'>
                                      Updated to:
                                    </span>
                                  )}{' '}
                                  <span className='italic'>
                                    {typeof detail.after === 'number'
                                      ? new Date(detail.after).toDateString()
                                      : detail.after}
                                  </span>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <hr />
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default WorkOrderHistory;
