import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { HeaderContext, DocumentContext, ServerContext } from 'state/context';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import ViewPDFNav from './ViewPDFNav';
import { IStringProps } from 'iApp';
import axios from 'axios';
import {
  IHeaderContext,
  IDocumentContext,
  IServerContext,
} from 'state/iContext';

const DisplayFile = () => {
  const { activeFile, documentList } = useContext(
    DocumentContext
  ) as IDocumentContext;
  const { activeCanvasTab } = useContext(HeaderContext) as IHeaderContext;
  const { domain } = useContext(ServerContext) as IServerContext;

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rotationAngle, setRotationAngle] = useState<number>(0);
  const [pdfData, setPdfData] = useState<string | null>(null);
  const fileInfo = documentList[activeFile!]!;

  const onDocumentLoadSuccess = ({ numPages }): void => {
    setNumPages(numPages);
  };

  const classes: IStringProps = {
    container: 'relative flex flex-col w-full h-full',
    zoom: 'w-full h-full',
    viewer: 'relative flex flex-col w-full h-full object-contain items-center',
    document:
      'flex flex-col w-fit h-contain justify-center items-center self-center items-center',
    page: 'flex w-full m-1 items-center border border-stone-300 shadow-xl shadow-slate-300',
    img: `object-contain max-w-full max-h-full rotate-${rotationAngle}`,
  };

  const isPDF: boolean = fileInfo?.img?.toLowerCase().endsWith('.pdf')!;

  const fileSrc: string = `${domain}${fileInfo?.img}`;

  const fetchPdfFile = () => {
    axios
      .get(fileSrc, { responseType: 'arraybuffer' })
      .then((response) => {
        // Create a Blob object from the array buffer
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(blob);

        setPdfData(pdfUrl);
      })
      .catch((error) => {
        console.error('Error fetching PDF:', error);
      });
  };

  const fileViewer = useMemo(() => {
    const fileExtension = fileInfo.img
      ? fileInfo.img.split('.').pop()?.toLowerCase()
      : '';
    const isPDF = fileExtension === 'pdf';

    return !isPDF ? (
      <img
        src={fileSrc}
        alt='imported-file'
        className={classes.img}
      />
    ) : pdfData ? (
      <embed
        src={pdfData}
        width='100%'
        height='100%'
        type='application/pdf'
      />
    ) : null;
  }, [fileSrc, pdfData, fileInfo.img, pageNumber, rotationAngle]);

  useEffect(() => {
    if (isPDF) fetchPdfFile();
  }, []);

  useEffect(() => {
    setNumPages(null);
    setPageNumber(1);
  }, [setNumPages, setPageNumber]);

  return (
    <div className={classes.container}>
      <TransformWrapper
        maxScale={10}
        minScale={0.5}
        initialScale={1}
        limitToBounds={true}
        initialPositionX={0}
        initialPositionY={0}>
        {({ resetTransform }) => (
          <>
            {activeCanvasTab === 'files' && (
              <ViewPDFNav
                isPDF={isPDF}
                numPages={numPages!}
                pageNumber={pageNumber}
                folder={fileInfo.folder}
                setPageNumber={setPageNumber}
                setRotationAngle={setRotationAngle}
                resetTransform={resetTransform}
              />
            )}
            <TransformComponent
              wrapperStyle={{ width: '100%', height: '100%' }}
              contentStyle={{ width: '100%', height: '100%' }}
              contentClass={classes.zoom}
              wrapperClass={classes.zoom}>
              <div
                id='file-viewer'
                key={activeFile}
                className={classes.viewer}>
                {fileViewer}
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </div>
  );
};

export default DisplayFile;
