import { ITowerDistanceClasses } from './iTowerDistance';
import { IMeasureEndPoints } from 'state/iState';
import { IMapContext } from 'state/iContext';
import { MapContext } from 'state/context';
import React, { useContext} from 'react';


const TowerDistance = () => {
  const {
    mapRef,
    measureDistance,
    setInfoWindowID,
    measureEndPoints,
    setMeasureDistance,
    setMeasureEndPoints,
  } = useContext(MapContext) as IMapContext;

  const { start, end } = measureEndPoints;

  // Component classes
  const classes: ITowerDistanceClasses = {
    inactive:
      'fa-solid fa-ruler text-lg m-2 border border-red-200 rounded-full w-[30px] h-[30px] text-center bg-slate-100 cursor-pointer transition ease-in-out delay-100 hover:bg-red-700 hover:text-slate-200 hover:border-stone-300',
    active: {
      box: 'absolute top-[40px] right-2 p-1 min-w-[75px] max-w-[300px] h-[115px] bg-slate-100 border border-4 border-slate-300 whitespace-nowrap overflow-hidden cursor-pointer',
      title: 'font-semibold',
      name: {
        active: 'ml-2 truncate text-blue-700',
        inactive: 'ml-2 truncate text-red-700',
      },
      icon: 'fa-solid fa-ruler text-lg m-2 border border-stone-400 rounded-full w-[30px] h-[30px] text-center text-slate-200 bg-red-700',
    },
  };
  const { box, title, name, icon } = classes.active;

  // Toggles measure distance tool
  const clickHandler = (): void => {
    setMeasureDistance((prev: boolean) => !prev);
    setInfoWindowID('');
  };

  // Adds/removes distance start point
  const startClickHandler = (): void => {
    const panOrClear: void = start
      ? (prev: IMeasureEndPoints) => ({ ...prev, start: null })
      : mapRef.current.panTo(start);
    setMeasureEndPoints(panOrClear);
  };

  // Adds/removes distance end point
  const endClickHandler = (): void => {
    const panOrClear: void = end
      ? (prev: IMeasureEndPoints) => ({ ...prev, end: null })
      : mapRef.current.panTo(end);
    setMeasureEndPoints(panOrClear);
  };

  // Displays measure tool details
  const startContents: string = measureEndPoints.start
    ? start!.name
    : 'Please pick a site starting point';
  const endContents: string = measureEndPoints.end
    ? end!.name
    : 'Please pick a site end point';

  // Displays active/inactive color of measure tool details
  const nameColorStart: string = measureEndPoints.start
    ? name.active
    : name.inactive;
  const nameColorEnd: string = measureEndPoints.end
    ? name.active
    : name.inactive;

  return (
    <>
      {measureDistance ? (
        <>
          <i
            className={icon}
            onClick={clickHandler}
          />
          <div className={box}>
            <div className={title}>
              Start:
              <div
                className={nameColorStart}
                onClick={startClickHandler}>
                {startContents}
              </div>
            </div>
            <div className={title}>
              End:
              <div
                className={nameColorEnd}
                onClick={endClickHandler}>
                {endContents}
              </div>
            </div>
          </div>
        </>
      ) : (
        <i
          className={classes.inactive}
          onClick={clickHandler}
        />
      )}
    </>
  );
};

export default TowerDistance;
