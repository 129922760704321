import { ICommentClasses, ICommentProps } from '../IMap';
import React from 'react';

const InfoWindowComments = ({ comments, handler }: ICommentProps) => {
  // Component classes
  const classes: ICommentClasses = {
    outerBox:
      'relative w-[160px] text-[10px] min-h-[60px] bg-zinc-100 px-1 py-2 rounded-sm mt-3 border border-slate-200 md:text-[13px] md:w-full md:p-4',
    innerBox: 'flex flex-col justify-between w-[140px] md:flex-row md:w-3/4',
    label:
      'flex font-medium md:justify-between md:min-w-[118.5px] md:max-w-[118.5px] ',
    title: 'text-gray-600 mb-1',
    colon: 'md:w-1/4',
    textBox: 'flex justify-between font-medium md:min-w-[200px]',
    text: 'font-medium truncate',
    readMore: 'absolute right-4 underline cursor-pointer',
  };

  return (
    <div
      id='info-window-comments'
      className={classes.outerBox}>
      <div className={classes.innerBox}>
        <div className={classes.label}>
          <div className={classes.title}>Comments</div>
          <div className={classes.colon}>:</div>
        </div>
        <div className={classes.textBox}>
          <div className={classes.text}>{comments}</div>
        </div>
      </div>
      {comments.length > 33 && (
        <div
          className={classes.readMore}
          onClick={handler}>
          read more
        </div>
      )}
    </div>
  );
};

export default InfoWindowComments;
