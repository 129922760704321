import { SitesContext, CanvasContext, DocumentContext } from 'state/context';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import React, { useContext, useEffect, useState } from 'react';
import PDFBuilder from './documentEditor/PDFBuilder';
import FileList from './fileList/files/FileList';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import UpdateBanner from '../../UpdateBanner';
import DisplayFile from './DisplayFile';
import { IStringProps } from 'iApp';
import Footer from 'Footer';
import {
  ISitesContext,
  ICanvasContext,
  IDocumentContext,
} from 'state/iContext';
import SiteHistoryPanel from '../../toolbar/history/SiteHistoryPanel';
import DisplayCustomFile from './DisplayCustomFile';

const classes: IStringProps = {
  container: 'w-full h-full',
  columns: 'flex w-full h-full',
  files: 'flex flex-col relative w-full h-full items-center',
  title:
    'w-[95%] text-center text-xl font-bold text-stone-500 border-b border-b-2 border-red-500',
  button:
    'p-1 px-2 m-1 border border-stone-400 rounded rounded-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
};

const TowerFiles = () => {
  const {
    activeFile,
    setActiveFile,
    editFileProps,
    setEditFileProps,
    clearEditFileProps,
  } = useContext(DocumentContext) as IDocumentContext;
  const { updateSitesDB, towerList } = useContext(
    SitesContext
  ) as ISitesContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { documentList } = useContext(DocumentContext) as IDocumentContext;

  const [historyOpen, setHistoryOpen] = useState<boolean>(false);

  useEffect(
    () => setEditFileProps(clearEditFileProps),
    [setEditFileProps, clearEditFileProps]
  );
  useEffect(() => {
    updateSitesDB(activeTowerID);
  }, [updateSitesDB, activeTowerID, towerList]);

  return (
    <div className={classes.container}>
      <div className={classes.columns}>
        <div className={classes.files}>
          {towerList[activeTowerID].edit.active && <UpdateBanner />}
          <h1 className={classes.title}>FILE LIST</h1>
          {activeFile !== 'add' && documentList[+activeFile!]?.custom ? (
            // <PDFBuilder />
            <DisplayCustomFile />
          ) : (
            <>{activeFile !== 'add' ? <DisplayFile /> : <FileList />}</>
          )}
          <div
            onClick={() => setHistoryOpen((prev) => !prev)}
            className={`absolute top-1 right-1 fa-solid fa-${
              historyOpen ? 'angles-right' : 'clock-rotate-left'
            } text-red-600 font-bold border border-stone-400 bg-slate-200 rounded p-1 transition ease-in-out delay-200 hover:bg-red-600 hover:border-red-900 hover:text-slate-100`}
          />
        </div>
        {historyOpen && <SiteHistoryPanel />}
      </div>
      <Footer />
    </div>
  );
};

export default TowerFiles;
