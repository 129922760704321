import { formatFieldName } from 'helpers/helperFunctions';
import { IAddItemProps, ITower } from 'state/iState';
import { IInputEvent, IStringProps } from 'iApp';
import React, { useContext } from 'react';
import axios from 'axios';
import {
  SitesContext,
  CanvasContext,
  HeaderContext,
  SettingsContext,
  UserContext,
  HistoryContext,
  ServerContext,
} from 'state/context';
import {
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  ISettingsContext,
  IUserContext,
  IHistoryContext,
  IServerContext,
} from 'state/iContext';

const EditSection = () => {
  const { activeCanvasTab } = useContext(HeaderContext) as IHeaderContext;
  const { towerList, setTowerList } = useContext(SitesContext) as ISitesContext;
  const { scale } = useContext(SettingsContext) as ISettingsContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { activeTowerID, addItemProps, setAddItemProps, clearAddProps } =
    useContext(CanvasContext) as ICanvasContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;

  const gridItem: { tower: string[]; data: string[] } = {
    tower: ['legs', 'legGrid', scale],
    data: ['shelves', 'shelfGrid', 'U'],
  };

  const classes: IStringProps = {
    container: 'flex w-full justify-between',
    input: 'p-0',
    edit: 'flex fa-solid fa-check text-stone-400 p-1 justify-center hover:text-green-600',
    cancel:
      'flex fa-solid fa-xmark text-stone-400 p-1 justify-center hover:text-red-600',
  };

  return (
    <div className={classes.container}>
      <input
        type='text'
        className={classes.input}
        value={addItemProps.label}
        onChange={(e: IInputEvent) =>
          setAddItemProps((prev: IAddItemProps) => ({
            ...prev,
            label: e.target.value,
          }))
        }
      />
      <i
        className={classes.edit}
        onClick={async () => {
          const fetchedHistory = await fetchHistory();
          const nextHistoryKey = await axios.get(
            `${domain}get-next-pk/history/`
          );
          const historyEntry = {
            id: nextHistoryKey.data.next_pk,
            timestamp: Date.now(),
            type: `${formatFieldName(activeCanvasTab)} Canvas`,
            createdBy: user.userId,
            siteID: activeTowerID,
            changes: {
              action: 'Updated',
              details: [
                {
                  updated: [
                    {
                      text: `Lease Section on ${
                        towerList[activeTowerID].layout[activeCanvasTab][
                          gridItem[activeCanvasTab][0]
                        ][addItemProps.index][gridItem[activeCanvasTab][1]].name
                      }`,
                    },
                  ],
                  before: [
                    {
                      text: towerList[activeTowerID].layout[activeCanvasTab][
                        gridItem[activeCanvasTab][0]
                      ][addItemProps.index].sections[addItemProps.content].name,
                    },
                  ],
                  after: [
                    {
                      text: addItemProps.label,
                    },
                  ],
                  field: `${
                    activeCanvasTab === 'tower' ? 'Tower' : 'Data'
                  } Lease Section Renamed`,
                },
              ],
            },
          };
          setHistory({
            ...fetchedHistory,
            [nextHistoryKey.data.next_pk]: historyEntry,
          });

          await addHistoryToDB(historyEntry);
          setTowerList((prev: ITower) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              layout: {
                ...prev[activeTowerID].layout,
                [activeCanvasTab]: {
                  ...prev[activeTowerID].layout[activeCanvasTab],
                  [gridItem[activeCanvasTab][0]]: {
                    ...prev[activeTowerID].layout[activeCanvasTab][
                      gridItem[activeCanvasTab][0]
                    ],
                    [+addItemProps.index!]: {
                      ...prev[activeTowerID].layout[activeCanvasTab][
                        gridItem[activeCanvasTab][0]
                      ][addItemProps.index],
                      sections: {
                        ...prev[activeTowerID].layout[activeCanvasTab][
                          gridItem[activeCanvasTab][0]
                        ][addItemProps.index].sections,
                        [addItemProps.content]: {
                          ...prev[activeTowerID].layout[activeCanvasTab][
                            gridItem[activeCanvasTab][0]
                          ][addItemProps.index].sections[addItemProps.content],
                          name: addItemProps.label,
                        },
                      },
                    },
                  },
                },
              },
              history: [
                ...prev[activeTowerID].history,
                nextHistoryKey.data.next_pk,
              ],
            },
          }));
          setAddItemProps(clearAddProps);
        }}
      />
      <i
        className={classes.cancel}
        onClick={() => setAddItemProps(clearAddProps)}
      />
    </div>
  );
};

export default EditSection;
