import React from 'react';
import { IUserDropdownIcons } from "./iHeader";

// Account Menu Icons
export const userDropdownMenuIcons: IUserDropdownIcons = {
  'My Account': <i className='fa-solid fa-address-card' />,
  Settings: <i className='fa-solid fa-gear' />,
  Reset: <i className="fa-solid fa-pen-to-square"/>,
  Support: <i className="fa-regular fa-circle-question"/>,
  Logout: <i className="fa-solid fa-right-from-bracket"/>
};