import logo from '../src/assets/RSG_SecondaryLogo.png';
import towerBG from '../src/assets/towers/cell-tower.jpeg';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className='relative flex w-full h-full justify-center items-center'>
      <img
        src={towerBG}
        alt='cell-tower'
        className='absolute w-screen h-screen left-0 top-0 opacity-50 -z-40'
      />
      <div
        onClick={() => {
          loginWithRedirect();
        }}
        className='flex flex-col m-5 p-5 bg-slate-100 justify-between text-center text-stone-500 text-lg font-bold cursor-pointer border border-stone-300 rounded rounded-lg shadow-xl hover:shadow-red-500/40 hover:text-red-600 hover:underline'>
        <img
          src={logo}
          className='max-w-[200px] max-h-[200px]'
          alt='Rigstar'
        />
        <span>Log In</span>
      </div>
    </div>
  );
};

export default Login;
