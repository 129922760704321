import React from 'react';
import { IButton, IStringProps } from 'iApp';

const Button = ({ name, handler, disabled, type }: IButton) => {
  const classes: IStringProps = {
    CANCEL:
      'border rounded-md px-3 mr-2 border-blue-600 text-blue-500 bg-stone-100 transition ease-in-out delay-100 hover:bg-stone-200',
    SAVE: 'flex rounded-md px-3 border border-red-700 text-red-700 justify-center transition ease-in-out delay-100 hover:bg-red-100 disabled:text-gray-400 disabled:border-gray-400 disabled:bg-stone-200',
    FINISH:
      'p-3 text-xl font-medium text-base text-blue-600 hover:text-blue-800',
  };

  const activeClass: string = classes[name] ? classes[name] : classes.SAVE;
  return (
    <button
      type={type}
      className={activeClass}
      disabled={disabled}
      onClick={handler}>
      {name}{' '}
      {name === 'SAVE TO' && <i className='fa-solid fa-sort-down ml-2' />}
    </button>
  );
};

export default Button;
