import React, { useContext } from 'react';
import { IStringProps } from 'iApp';
import { UserContext } from 'state/context';
import { IUserContext } from 'state/iContext';

const WorkOrdersPanel = ({
  activeTab,
  handleTabChange,
  handleCreateNewClick,
}) => {
  const { user } = useContext(UserContext) as IUserContext;

  const tabTitles = [
    'Assigned',
    'In Progress',
    'On Hold',
    'Completed',
    'Cancelled',
  ];

  const classes: IStringProps = {
    leftPanel: 'flex flex-col w-full h-full bg-gray-200',
    button: 'bg-blue-500 text-white p-4 py-2 m-4 rounded disabled:bg-stone-400',
    tab: 'cursor-pointer p-2 overflow-hidden border hover:bg-red-200',
    activeTab: 'bg-red-500 font-bold text-slate-200 hover:bg-red-500',
    inactiveTab: 'bg-stone-100',
  };

  return (
    <div className={classes.leftPanel}>
      {user.admin && (
        <button
          disabled={!activeTab}
          className={classes.button}
          onClick={handleCreateNewClick}>
          Create New Work Order
        </button>
      )}
      {tabTitles.map((tab) => (
        <div
          key={tab}
          className={`${classes.tab} ${
            activeTab === tab ? classes.activeTab : classes.inactiveTab
          }`}
          onClick={() => handleTabChange(tab)}>
          {tab}
        </div>
      ))}
    </div>
  );
};

export default WorkOrdersPanel;
