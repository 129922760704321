import React, { useContext } from 'react';
import { HeaderContext } from 'state/context';
import { IHeaderContext } from 'state/iContext';

const crmDirectory = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@example.com',
    phone: '123-456-7890',
    company: 'ABC Inc',
    role: 'Admin',
  },
  {
    id: 2,
    firstName: 'Jane',
    lastName: 'Smith',
    email: 'jane.smith@example.com',
    phone: '987-654-3210',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 3,
    firstName: 'Robert',
    lastName: 'Johnson',
    email: 'robert.johnson@example.com',
    phone: '555-555-5555',
    company: 'ABC Inc',
    role: 'Manager',
  },
  {
    id: 4,
    firstName: 'Emily',
    lastName: 'Davis',
    email: 'emily.davis@example.com',
    phone: '111-222-3333',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 5,
    firstName: 'Michael',
    lastName: 'Lee',
    email: 'michael.lee@example.com',
    phone: '777-888-9999',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 6,
    firstName: 'Sophia',
    lastName: 'Martin',
    email: 'sophia.martin@example.com',
    phone: '444-444-4444',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 7,
    firstName: 'William',
    lastName: 'Brown',
    email: 'william.brown@example.com',
    phone: '666-666-6666',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 8,
    firstName: 'Olivia',
    lastName: 'Taylor',
    email: 'olivia.taylor@example.com',
    phone: '999-999-9999',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 9,
    firstName: 'James',
    lastName: 'Anderson',
    email: 'james.anderson@example.com',
    phone: '333-333-3333',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 10,
    firstName: 'Ava',
    lastName: 'Wilson',
    email: 'ava.wilson@example.com',
    phone: '222-222-2222',
    company: 'ABC Inc',
    role: 'Manager',
  },
  {
    id: 11,
    firstName: 'Ethan',
    lastName: 'Moore',
    email: 'ethan.moore@example.com',
    phone: '888-888-8888',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 12,
    firstName: 'Mia',
    lastName: 'White',
    email: 'mia.white@example.com',
    phone: '777-777-7777',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 13,
    firstName: 'Alexander',
    lastName: 'Hill',
    email: 'alexander.hill@example.com',
    phone: '666-666-6666',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 14,
    firstName: 'Abigail',
    lastName: 'Cooper',
    email: 'abigail.cooper@example.com',
    phone: '555-555-5555',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 15,
    firstName: 'Daniel',
    lastName: 'Ward',
    email: 'daniel.ward@example.com',
    phone: '444-444-4444',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 16,
    firstName: 'Sofia',
    lastName: 'Evans',
    email: 'sofia.evans@example.com',
    phone: '333-333-3333',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 17,
    firstName: 'Matthew',
    lastName: 'Murphy',
    email: 'matthew.murphy@example.com',
    phone: '111-111-1111',
    company: 'XYZ Ltd',
    role: 'Manager',
  },
  {
    id: 18,
    firstName: 'Chloe',
    lastName: 'Baker',
    email: 'chloe.baker@example.com',
    phone: '999-999-9999',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 19,
    firstName: 'Joseph',
    lastName: 'Fisher',
    email: 'joseph.fisher@example.com',
    phone: '888-888-8888',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 20,
    firstName: 'Grace',
    lastName: 'Perry',
    email: 'grace.perry@example.com',
    phone: '777-777-7777',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 21,
    firstName: 'Grace',
    lastName: 'Doe',
    email: 'grace.doe@example.com',
    phone: '123-456-7890',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 22,
    firstName: 'John',
    lastName: 'Smith',
    email: 'john.smith@example.com',
    phone: '987-654-3210',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 23,
    firstName: 'Jane',
    lastName: 'Johnson',
    email: 'jane.johnson@example.com',
    phone: '555-555-5555',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 24,
    firstName: 'Robert',
    lastName: 'Davis',
    email: 'robert.davis@example.com',
    phone: '111-222-3333',
    company: 'ABC Inc',
    role: 'Manager',
  },
  {
    id: 25,
    firstName: 'Emily',
    lastName: 'Lee',
    email: 'emily.lee@example.com',
    phone: '777-888-9999',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 26,
    firstName: 'Michael',
    lastName: 'Martin',
    email: 'michael.martin@example.com',
    phone: '444-444-4444',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 27,
    firstName: 'Sophia',
    lastName: 'Brown',
    email: 'sophia.brown@example.com',
    phone: '666-666-6666',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 28,
    firstName: 'William',
    lastName: 'Taylor',
    email: 'william.taylor@example.com',
    phone: '999-999-9999',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 29,
    firstName: 'Olivia',
    lastName: 'Anderson',
    email: 'olivia.anderson@example.com',
    phone: '333-333-3333',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 30,
    firstName: 'James',
    lastName: 'Wilson',
    email: 'james.wilson@example.com',
    phone: '222-222-2222',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 31,
    firstName: 'Ava',
    lastName: 'Moore',
    email: 'ava.moore@example.com',
    phone: '888-888-8888',
    company: 'XYZ Ltd',
    role: 'Manager',
  },
  {
    id: 32,
    firstName: 'Ethan',
    lastName: 'White',
    email: 'ethan.white@example.com',
    phone: '777-777-7777',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 33,
    firstName: 'Mia',
    lastName: 'Hill',
    email: 'mia.hill@example.com',
    phone: '666-666-6666',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 34,
    firstName: 'Alexander',
    lastName: 'Cooper',
    email: 'alexander.cooper@example.com',
    phone: '555-555-5555',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 35,
    firstName: 'Abigail',
    lastName: 'Ward',
    email: 'abigail.ward@example.com',
    phone: '444-444-4444',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 36,
    firstName: 'Daniel',
    lastName: 'Evans',
    email: 'daniel.evans@example.com',
    phone: '333-333-3333',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 37,
    firstName: 'Sofia',
    lastName: 'Murphy',
    email: 'sofia.murphy@example.com',
    phone: '111-111-1111',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 38,
    firstName: 'Matthew',
    lastName: 'Baker',
    email: 'matthew.baker@example.com',
    phone: '999-999-9999',
    company: 'ABC Inc',
    role: 'User',
  },
  {
    id: 39,
    firstName: 'Chloe',
    lastName: 'Fisher',
    email: 'chloe.fisher@example.com',
    phone: '888-888-8888',
    company: 'XYZ Ltd',
    role: 'User',
  },
  {
    id: 40,
    firstName: 'Joseph',
    lastName: 'Perry',
    email: 'joseph.perry@example.com',
    phone: '777-777-7777',
    company: 'ABC Inc',
    role: 'User',
  },
];

const DirectoryView = () => {
  const { setActiveHeaderButton, lastActiveView } =
    useContext(HeaderContext) as IHeaderContext;

  const handleEdit = (id) => {
    // Implement edit logic
    console.log(`Edit clicked for user with ID ${id}`);
  };

  const handleDelete = (id) => {
    // Implement delete logic
    console.log(`Delete clicked for user with ID ${id}`);
  };

  const handleBack = (): void => setActiveHeaderButton(lastActiveView);

  const bgColour = (index) => (index % 2 === 0 ? 'bg-gray-100' : 'bg-white');

  return (
    <div className='p-4'>
      <div className='mb-4'>
        <button
          className='text-blue-600 hover:underline'
          onClick={handleBack}>
          Back
        </button>
      </div>
      <h2 className='text-2xl font-bold mb-4'>Directory</h2>
      <table className='min-w-full divide-y divide-gray-200'>
        <thead className='bg-gray-50'>
          <tr>
            <th
              scope='col'
              className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              Name
            </th>
            <th
              scope='col'
              className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              Email
            </th>
            <th
              scope='col'
              className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              Phone
            </th>
            <th
              scope='col'
              className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              Company
            </th>
            <th
              scope='col'
              className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              Role
            </th>
            <th
              scope='col'
              className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody className='bg-white divide-y divide-gray-200'>
          {crmDirectory.map((contact, index) => (
            <tr
              key={contact.id}
              className={
                bgColour(index) +
                ' hover:bg-red-200 transition-all duration-100 ease-in-out'
              }>
              <td className='px-6 py-4 whitespace-nowrap'>
                {contact.firstName} {contact.lastName}
              </td>
              <td className='px-6 py-4 whitespace-nowrap'>{contact.email}</td>
              <td className='px-6 py-4 whitespace-nowrap'>{contact.phone}</td>
              <td className='px-6 py-4 whitespace-nowrap'>{contact.company}</td>
              <td className='px-6 py-4 whitespace-nowrap'>{contact.role}</td>
              <td className='px-6 py-4 whitespace-nowrap'>
                <button
                  className='text-blue-600 hover:underline'
                  onClick={() => handleEdit(contact.id)}>
                  Edit
                </button>
                <button
                  className='text-red-600 hover:underline ml-2'
                  onClick={() => handleDelete(contact.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DirectoryView;
