import { searchMatch, measurementConversion } from 'helpers/helperFunctions';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { IEditProps } from '../components/home/editPanel/iEdit';
import { useAuth0 } from '@auth0/auth0-react';
import { IBooleanProps, IStringProps } from 'iApp';
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import {
  MapContext,
  EditContext,
  UserContext,
  SitesContext,
  HeaderContext,
  SettingsContext,
  CompanyContext,
  ServerContext,
  HistoryContext,
} from './context';
import {
  IGoogleKey,
  ITowerList,
  IAppChildren,
  LatLngLiteral,
  IMeasureEndPoints,
  ITower,
} from './iState';
import {
  IUserContext,
  IHeaderContext,
  ICompanyContext,
  ISettingsContext,
  IHomeProviderProps,
  IServerContext,
  IHistoryContext,
} from './iContext';
import {
  mapCenter,
  siteTypes,
  towerTypes,
  stockImages,
  towerColors,
  editPanelProps,
  measureDistanceEndPoints,
} from './state';
import axios from 'axios';

const HomeStateProvider = ({ children }: IAppChildren) => {
  const { user: authUser } = useAuth0();
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { setHistory, fetchHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { addHistoryToDB } = useContext(ServerContext) as IServerContext;
  const { domain, uploadFilesToDB, releaseCanvasLock } = useContext(
    ServerContext
  ) as IServerContext;
  const {
    activeHeaderButton: { inventory },
  } = useContext(HeaderContext) as IHeaderContext;

  // Site States
  const [towerList, setTowerList] = useState<ITowerList>({});
  const [newSite, setNewSite] = useState<boolean>(false);
  const [favoritesTab, setFavoritesTab] = useState<boolean>(false);
  const [warningClicked, setWarningClicked] = useState<boolean>(false);
  const [inventoryFilters, setInventoryFilters] = useState<IBooleanProps>({
    type: true,
    code: true,
    height: true,
    assets: true,
  });

  const towerIds: string[] = Object.keys(towerList);

  // Edit States
  const [isEditPanelOpen, setIsEditPanelOpen] = useState<boolean>(false);
  const [imagePanel, setImagePanel] = useState<boolean>(false);
  const [editProps, setEditProps] = useState<IEditProps>(editPanelProps);

  // Map States
  const [center, setCenter] = useState<LatLngLiteral>(mapCenter);
  const [infoWindowID, setInfoWindowID] = useState<number>(-1);
  const [zoom, setZoom] = useState<number>(10);
  const [measureDistance, setMeasureDistance] = useState<boolean>(false);
  const [measureEndPoints, setMeasureEndPoints] = useState<IMeasureEndPoints>(
    measureDistanceEndPoints
  );
  const [stringLocation, setStringLocation] = useState<IStringProps>({
    locality: '',
    political: '',
  });

  const { user, lowCaseSearch } = useContext(UserContext) as IUserContext;
  const { menuFilters } = useContext(HeaderContext) as IHeaderContext;
  const { metric } = useContext(SettingsContext) as ISettingsContext;

  const googleKey: IGoogleKey = {
    id: 'google-map-api',
    googleMapsApiKey: process.env.REACT_APP_API_KEY!,
  };
  const { isLoaded } = useJsApiLoader(googleKey);
  const mapRef = useRef<GoogleMap>();

  const onLoad = useCallback((map: GoogleMap): void => {
    console.log('Map Reloaded');
    mapRef.current = map;
  }, []);

  // Number of active Inventory filters active
  const activeInventoryFilterCount = Object.values(inventoryFilters).filter(
    (value) => value
  ).length;

  // Search bar filtering through site/tower details
  const searchFiltered = useMemo(
    () =>
      towerIds
        .filter((tower) => {
          const towerData = towerList[tower];
          if (!towerData) return false;

          const { name, code, company, geoCode, contact, comments, layout } =
            towerData;

          const towerLegLayout = layout?.tower?.legs || {};
          const dataShelfLayout = layout?.data?.shelves || {};

          const isIconValid = (icon) => icon.i > 0;

          const towerLegNames = Object.values(towerLegLayout).map(
            (leg) => leg?.legGrid?.name || ''
          );

          const dataShelfNames = Object.values(dataShelfLayout).map(
            (shelf) => shelf?.shelfGrid?.name || ''
          );

          const towerLegIconsNames = Object.values(towerLegLayout).flatMap(
            (leg) => Object.values(leg.icons).map((icon) => icon.name)
          );

          const dataShelfIconsNames = Object.values(dataShelfLayout).flatMap(
            (shelf) => Object.values(shelf.icons).map((icon) => icon.name)
          );

          const towerLegIconsPropertiesNames = Object.values(
            towerLegLayout
          ).flatMap((leg) =>
            Object.values(leg.icons)
              .filter(isIconValid)
              .flatMap((icon) =>
                (Object.values(icon.properties) as { name: string }[]).map(
                  (property) => property?.name || ''
                )
              )
          );

          const dataShelfIconsPropertiesNames = Object.values(
            dataShelfLayout
          ).flatMap((shelf) =>
            Object.values(shelf.icons)
              .filter(isIconValid)
              .flatMap((icon) =>
                (Object.values(icon.properties) as { name: string }[]).map(
                  (property) => property?.name || ''
                )
              )
          );

          const towerLegIconsPropertiesValues = Object.values(
            towerLegLayout
          ).flatMap((leg) =>
            Object.values(leg.icons)
              .filter(isIconValid)
              .flatMap((icon) =>
                (Object.values(icon.properties) as { value: string }[]).map(
                  (property) => property?.value || ''
                )
              )
          );

          const dataShelfIconsPropertiesValues = Object.values(
            dataShelfLayout
          ).flatMap((shelf) =>
            Object.values(shelf.icons)
              .filter(isIconValid)
              .flatMap((icon) =>
                (Object.values(icon.properties) as { value: string }[]).map(
                  (property) => property?.value || ''
                )
              )
          );

          const inventorySearch = inventory
            ? [
                ...towerLegNames,
                ...dataShelfNames,
                ...towerLegIconsNames,
                ...dataShelfIconsNames,
                ...towerLegIconsPropertiesNames,
                ...dataShelfIconsPropertiesNames,
                ...towerLegIconsPropertiesValues,
                ...dataShelfIconsPropertiesValues,
              ]
            : [];

          const searchInList = [
            name,
            code,
            company,
            geoCode,
            contact?.name,
            comments,
            ...inventorySearch,
          ];

          return searchMatch({ searchInList, lowCaseSearch });
        })
        .map((tower) => Number(tower)),
    [lowCaseSearch, towerIds, towerList, inventory]
  );

  // Site type filters
  const siteFilters: string[] = useMemo(
    () =>
      Object.keys(menuFilters).filter(
        (type): boolean => menuFilters[type as keyof typeof menuFilters]
      ),
    [menuFilters]
  );

  // Filtered favorite sites
  const favoriteSites: number[] = useMemo(
    () =>
      searchFiltered.filter(
        (tower): boolean =>
          user.favorites.includes(tower) &&
          siteFilters.includes(towerList[tower].type.site.toLowerCase())
      ),
    [towerList, user.favorites, siteFilters, searchFiltered]
  );

  // Filtered list of all available sites
  const filteredSiteIds: number[] = useMemo(
    () =>
      searchFiltered.filter((tower): boolean =>
        siteFilters.includes(towerList[tower].type.site.toLowerCase())
      ),
    [searchFiltered, siteFilters, towerList]
  );

  // Toggles all sites/favorites tab
  const changeSitesTab = (name: string): void => {
    let tab: boolean = false;
    if (name === 'favorites-tab') {
      tab = true;
    }
    setFavoritesTab(tab);
  };

  // Toggles edit panel
  const openEditPanel = (props: IEditProps): void => {
    const height = metric
      ? measurementConversion(metric, props.height!)
      : props.height;
    setEditProps({
      ...props,
      height,
    });
    setImagePanel(false);
    setIsEditPanelOpen((prev) => !prev);
  };

  // Toggles edit image panel
  const openImagePanel = (): void => setImagePanel((prev) => !prev);

  const fetchSiteData = async (
    towerID: number
  ): Promise<ITower | null | undefined> => {
    try {
      if (authUser) {
        const sitesResponse = await fetch(`${domain}sites/${towerID}/`);

        if (sitesResponse.ok) {
          const towerData = await sitesResponse.json();

          // Transform tower data
          const fetchedData = {
            towerId: +towerData.id!,
            code: towerData.data.code,
            comments: towerData.data.comments,
            company: towerData.data.company,
            contact: {
              name: towerData.data.contact.name,
              phone: +towerData.data.contact.phone,
              email: towerData.data.contact.email,
            },
            geoCode: towerData.data.geoCode,
            height: towerData.data.height,
            img: towerData.data.img,
            location: towerData.data.location,
            name: towerData.data.name,
            type: towerData.data.type,
            dates: towerData.data.dates,
            documents: towerData.data.documents,
            history: towerData.data.history,
            layout: towerData.data.layout,
            edit: towerData.edit,
          };

          return fetchedData;
        } else {
          console.error('Error with loading tower data');
          return null;
        }
      }
    } catch (error: any) {
      console.error(error.message);
      return null;
    }
  };

  const updateSitesDB = async (id: number) => {
    const fetchedSiteData = await fetchSiteData(id);
    const mutable =
      (user.admin && !fetchedSiteData!.edit.active) ||
      fetchedSiteData!.edit.user === user.userId;

    if (mutable) {
      try {
        const postData = {
          companyId: company!.id,
          name: id,
          edit: {
            ...towerList[id].edit,
            time: towerList[id].edit.active ? Date.now() : null,
          },
          data: towerList[id],
        };

        const response = await fetch(`${domain}sites/${id}/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-User-ID': user.userId,
          },
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          console.error('Failed to save data');
        }
      } catch (error: any) {
        console.error(error.message);
      }
    }
  };

  // Updates details and pans to tower
  const editTower = async (props: IEditProps) => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    const towerExists = !!towerList[props.towerId!];
    const filePath: string = props.file
      ? `files/media/client/${company.name}/${props.file!.name}`
      : props.img!;

    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'Site',
      createdBy: user.userId,
      siteID: props.towerId,
      changes: {
        action: towerExists ? 'Updated' : 'Created',
        details: [] as { field: string; before: any; after: any }[],
      },
    };
    if (towerExists) {
      const existingTower = towerList[props.towerId!];

      // Compare properties and add to historyEntry.details if different
      const propertiesToCompare = [
        'img',
        'name',
        'code',
        'type',
        'location',
        'height',
        'contact',
        'comments',
        'dates',
      ];

      propertiesToCompare.forEach((property) => {
        if (
          JSON.stringify(existingTower[property]) !==
          JSON.stringify(props[property])
        ) {
          historyEntry.changes.details.push({
            field: property,
            before: existingTower[property],
            after: props[property],
          });
        }
      });
    }

    if (!inventory) {
      mapRef.current!.panTo(props.location! as LatLngLiteral);
    }
    try {
      const postData = {
        companyId: company!.id,
        name: +props.towerId,
        edit: {
          time: null,
          user: null,
          active: false,
        },
        data: {
          ...props,
          img: filePath,
          history: towerExists
            ? [...towerList[props.towerId].history, nextHistoryKey.data.next_pk]
            : [nextHistoryKey.data.next_pk],
        },
      };

      const method: string = towerExists ? 'PUT' : 'POST';
      const url: string = towerExists
        ? `${domain}sites/${props.towerId}/`
        : `${domain}sites/`;

      if (props.file) {
        await uploadFilesToDB([props.file!]);
      }
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-User-ID': user.userId,
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        window.alert('Failed to save data');
      }
    } catch (error: any) {
      console.error(error.message);
    }
    setTowerList((prev) => ({
      ...prev,
      [props.towerId!]: {
        ...prev[props.towerId!],
        ...props,
        img: filePath,
        history: towerExists
          ? [...prev[props.towerId].history, nextHistoryKey.data.next_pk]
          : [nextHistoryKey.data.next_pk],
        edit: {
          time: null,
          user: null,
          active: false,
        },
      },
    }));
    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });
    addHistoryToDB(historyEntry);
  };

  // Changes lat/long coordinates in edit panel
  const updateCoordinates = (location: LatLngLiteral) =>
    setEditProps((prev) => ({ ...prev, location }));

  // Saves new geolocation of provided lat/long
  const getGeoLocation = (location: LatLngLiteral): void => {
    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location })
      .then(async (res) => {
        const filteredResults: google.maps.GeocoderResult[] =
          res.results.filter((item) => item.types.includes('postal_code'));
        const result: string =
          filteredResults.length > 0
            ? filteredResults[0].formatted_address
            : res.results[0].formatted_address;

        setEditProps((prev) => ({
          ...prev,
          location: location,
          geoCode: result,
        }));
      })
      .catch((e) => console.error('Geocoder failed due to: ' + e));
  };

  // Sets location strings of provided lat/long
  const setGeoLocationStrings = useCallback((location: LatLngLiteral): void => {
    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location })
      .then(async (res) => {
        const localityFilter: google.maps.GeocoderResult[] = res.results.filter(
          (item) => item.types.includes('locality')
        );
        const politialFilter: google.maps.GeocoderResult[] = res.results.filter(
          (item) => item.types.includes('administrative_area_level_1')
        );

        const politial: string =
          politialFilter.length > 0
            ? politialFilter[0].formatted_address
            : res.results[0].formatted_address;
        const locality: string =
          localityFilter.length > 0
            ? localityFilter[0].formatted_address
            : res.results[0].formatted_address;
        setStringLocation((prev: IStringProps) => ({
          ...prev,
          locality: locality.slice(0, locality.indexOf(',')),
          political: politial.slice(0, politial.indexOf(',')),
        }));
      })
      .catch((e) => console.error('Geocoder failed due to: ' + e));
  }, []);

  // Retrieves device location
  const getUserLocation = () => {
    const getCurrentCoords = (position: GeolocationPosition) => {
      const lat: number = position.coords.latitude;
      const lng: number = position.coords.longitude;

      const currentLocation: LatLngLiteral = { lat, lng };

      mapRef.current !== undefined
        ? mapRef.current!.panTo(currentLocation)
        : setCenter(currentLocation);
    };
    navigator.geolocation.getCurrentPosition(getCurrentCoords);
  };

  // Adds/Removes measure tool end points
  const changeMeasureEndPoints = (id: number) => {
    const { start, end } = measureEndPoints;
    let changeEndPoints = (prev: IMeasureEndPoints) => prev;

    if (start === null && end?.towerId !== id) {
      changeEndPoints = (prev: IMeasureEndPoints) => ({
        ...prev,
        start: towerList[id],
      });
    }
    if (start?.towerId === id && end) {
      changeEndPoints = (prev: IMeasureEndPoints) => ({
        ...prev,
        start: null,
      });
    }
    if (end?.towerId === id) {
      changeEndPoints = (prev: IMeasureEndPoints) => ({
        ...prev,
        end: null,
      });
    }
    if (start && end === null) {
      changeEndPoints = (prev: IMeasureEndPoints) => ({
        ...prev,
        end: towerList[id],
      });
    }

    setMeasureEndPoints(changeEndPoints);
  };

  // Clears measure tool endpoints on close
  useEffect((): void => {
    if (!measureDistance) {
      setMeasureEndPoints({ start: null, end: null });
    }
  }, [measureDistance]);

  // Retieves device location on load
  useEffect((): void => {
    getUserLocation();
  }, []);

  // Converts all data to respective measurements units
  useEffect((): void => {
    const heightProp = (height: number) =>
      metric ? measurementConversion(metric, height) : height;
    setEditProps((prev: IEditProps) => ({
      ...prev,
      height: heightProp(prev.height!),
    }));
  }, [metric]);

  // Initial fetch request that populates db data
  useEffect(() => {
    const fetchSitesData = async () => {
      try {
        if (authUser) {
          const userId: string = authUser.sub!.split('|')[1];
          const sitesResponse = await fetch(`${domain}sites/`);

          if (sitesResponse.ok) {
            const sitesData = await sitesResponse.json();

            const transformedData = {};
            await sitesData.sites
              .filter(
                (site) =>
                  user.access.includes('all') ||
                  user.access.includes(site.id.toString())
              )
              .forEach(async (tower) => {
                const activeInCanvas: boolean = userId === tower.edit.user;

                if (activeInCanvas) {
                  await releaseCanvasLock(+tower.id, userId);
                }

                transformedData[+tower.id!] = {
                  towerId: +tower.id!,
                  code: tower.data.code,
                  comments: tower.data.comments,
                  company: tower.data.company,
                  contact: {
                    name: tower.data.contact.name,
                    phone: +tower.data.contact.phone,
                    email: tower.data.contact.email,
                  },
                  geoCode: tower.data.geoCode,
                  height: tower.data.height,
                  img: tower.data.img,
                  location: tower.data.location,
                  name: tower.data.name,
                  type: tower.data.type,
                  dates: tower.data.dates,
                  documents: tower.data.documents,
                  layout: tower.data.layout,
                  history: tower.data.history,
                  edit: activeInCanvas
                    ? {
                        active: false,
                        user: null,
                        time: null,
                      }
                    : tower.edit,
                };
              });
            setTowerList(transformedData);
            console.log('Sites Loaded');
          } else {
            window.alert('Error with loading sites');
          }
        }
      } catch (error: any) {
        console.log(error.message);
      }
    };

    fetchSitesData();
  }, [authUser, user]);

  // Props to pass to their respective elements
  const providerProps: IHomeProviderProps = {
    sites: {
      siteTypes,
      towerTypes,
      towerColors,
      towerList,
      setTowerList,
      favoritesTab,
      favoriteSites,
      changeSitesTab,
      filteredSiteIds,
      warningClicked,
      setWarningClicked,
      updateSitesDB,
      inventoryFilters,
      setInventoryFilters,
      activeInventoryFilterCount,
      fetchSiteData,
    },
    map: {
      zoom,
      mapRef,
      onLoad,
      setZoom,
      center,
      setCenter,
      infoWindowID,
      setInfoWindowID,
      getGeoLocation,
      setGeoLocationStrings,
      getUserLocation,
      stringLocation,
      setStringLocation,
      updateCoordinates,
      measureEndPoints,
      measureDistance,
      setMeasureDistance,
      setMeasureEndPoints,
      changeMeasureEndPoints,
    },
    edit: {
      newSite,
      setNewSite,
      imagePanel,
      stockImages,
      editTower,
      editProps,
      setEditProps,
      openEditPanel,
      openImagePanel,
      isEditPanelOpen,
    },
  };

  if (!isLoaded) return <div>Map Loading...</div>;

  return (
    <SitesContext.Provider value={providerProps.sites}>
      <EditContext.Provider value={providerProps.edit}>
        <MapContext.Provider value={providerProps.map}>
          {children}
        </MapContext.Provider>
      </EditContext.Provider>
    </SitesContext.Provider>
  );
};

export default HomeStateProvider;
