import { ILibraryCheckBox, ILibraryCheckBoxClasses } from '../iManageLibraries';
import { ILibraryContext, IUserContext } from 'state/iContext';
import { LibraryContext, UserContext } from 'state/context';
import React, { useContext } from 'react';

const LibraryCheckBox = ({ id, type, handler }: ILibraryCheckBox) => {
  const { libraries, toggleLibrary, activeLibrary, setActiveLibrary } =
    useContext(LibraryContext) as ILibraryContext;
  const { user } = useContext(UserContext) as IUserContext;

  // Component classes
  const classes: ILibraryCheckBoxClasses = {
    container: {
      active: 'w-full py-1 pl-3 bg-stone-200 border-b border-b-stone-300',
      inactive:
        'w-full py-1 pl-3 border-b border-b-stone-300 odd:bg-slate-100 transition ease-in-out delay-100 hover:bg-stone-200',
    },
    input:
      'mr-2 enabled:transition ease-in-out delay-100 hover:bg-red-600 checked:bg-stone-500 rounded-sm',
    label: 'text-stone-500',
  };

  const { active, inactive } = classes.container;
  const activeClass: string = activeLibrary === id ? active : inactive;
  const checked: boolean =
    type === 'private'
      ? user.private_libraries[id]
      : user.standard_libraries.includes(id);

  // Controls what library of icons are displayed
  const clickHandler = (): void => {
    toggleLibrary(id);
  };

  // Toggles visible status of selected library
  const changeHandler = (): void => {
    setActiveLibrary(id);
    handler();
  };

  return (
    <div
      onClick={clickHandler}
      className={activeClass}>
      <input
        name={id}
        type='checkbox'
        checked={checked}
        onChange={changeHandler}
        className={classes.input}
        id={`manage-library-${id}`}
      />
      <label
        className={classes.label}
        htmlFor={id}>
        {libraries[id].name}
      </label>
    </div>
  );
};

export default LibraryCheckBox;
