import React, { useContext, useState } from 'react';
import FileViewer from './FileViewer';
import {
  ICompanyContext,
  IDocumentContext,
  IHistoryContext,
  IServerContext,
  IUserContext,
  IWorkOrderContext,
} from 'state/iContext';
import {
  CompanyContext,
  DocumentContext,
  HistoryContext,
  ServerContext,
  UserContext,
  WorkOrderContext,
} from 'state/context';
import { ITowerDocument } from 'state/iState';
import { IWorkOrderList } from '../iWorkOrders';
import axios from 'axios';

const FilesTab = ({ files }) => {
  const { documentList, activeFile, newDocKey, setNewDocKey, fetchDocuments } =
    useContext(DocumentContext) as IDocumentContext;
  const {
    domain,
    uploadFilesToDB,
    updateDocumentsDB,
    updateWorkOrdersDB,
    addHistoryToDB,
  } = useContext(ServerContext) as IServerContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { setHistory, fetchHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { workOrders, setWorkOrders, fetchWorkOrder } = useContext(
    WorkOrderContext
  ) as IWorkOrderContext;

  const classes = {
    tableContainer: 'mb-4',
    table: 'w-full border border-stone-300 bg-stone-200 text-stone-500',
    headerCell:
      'p-0.5 border border-stone-300 bg-stone-200 text-stone-500 text-xs text-left font-bold',
    fileCell:
      'p-0.5 border border-stone-300 bg-stone-200 text-stone-500 text-xs text-left cursor-pointer hover:bg-gray-100',
    button: 'text-white px-2 py-0.5 mx-1 my-4 rounded bg-red-600',
    hiddenInput: 'hidden',
  };

  const [selectedFile, setSelectedFile] = useState<number | null>(null);

  if (typeof activeFile !== 'number') {
    return null;
  }

  const handleFileClick = (file: number) => setSelectedFile(file);
  const handleCloseFile = () => setSelectedFile(null);

  const handleImportClick = () => {
    const fileInput = document.getElementById('work-order-add-file');
    fileInput?.click();
  };

  const transformFile = async (file: File | number): Promise<number> => {
    if (!file) {
      return 0;
    }

    if (typeof file === 'number') return file;

    const newFileName = `workOrder-${activeFile}-${file.name}`;

    // Upload the file with the new name
    const convertedFile = new File([file], newFileName);

    // Upload the file to the database
    await uploadFilesToDB([convertedFile]);

    // Update document list and save document to the database
    const docData: ITowerDocument = {
      name: file.name,
      folder: 5,
      upload: Date.now(),
      updated: Date.now(),
      user: user.userId,
      isPDF: true,
      img: `files/media/client/${company.name}/${newFileName}`,
    };

    const docId = await updateDocumentsDB(newDocKey!, 'POST', false, docData);

    if (docId === undefined || docId === null) {
      console.error('Error transforming file: DocumentID error');
      return 0;
    }
    if (docId !== newDocKey) {
      await setNewDocKey(docId);
    }

    await fetchDocuments();
    return docId!;
  };

  const handleFileChange = async (event) => {
    const fetchedHistory = await fetchHistory();
    const latestWorkOrder = await fetchWorkOrder(activeFile);
    const file = event.target.files[0];

    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    const newFileID: number = await transformFile(file);

    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'WorkOrder',
      createdBy: user.userId,
      changes: {
        action: 'Updated',
        details: [
          {
            field: 'Files',
            added: [newFileID],
          },
        ],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });
    addHistoryToDB(historyEntry);

    setWorkOrders((prev: IWorkOrderList) => {
      const updatedWorkOrder = {
        ...latestWorkOrder,
        files: [...latestWorkOrder.files, newFileID],
        history: [...latestWorkOrder.history, nextHistoryKey.data.next_pk],
        lastModified: Date.now(),
        lastModifiedBy: user.userId,
      };
      updateWorkOrdersDB('PUT', updatedWorkOrder);

      return {
        ...prev,
        [activeFile!]: updatedWorkOrder,
      };
    });
  };

  return (
    <div className={classes.tableContainer}>
      <input
        type='file'
        name='Add File'
        id='work-order-add-file'
        className={classes.hiddenInput}
        onChange={handleFileChange}
      />
      {workOrders[activeFile].progress === 'In Progress' && (
        <button
          className={classes.button}
          onClick={handleImportClick}>
          Import New File
        </button>
      )}
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.headerCell}>File Name</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr
              key={index}
              className={classes.fileCell}
              onClick={() => handleFileClick(file)}>
              <td>{documentList[file].name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedFile && (
        <FileViewer
          file={selectedFile}
          onClose={handleCloseFile}
        />
      )}
    </div>
  );
};

export default FilesTab;
