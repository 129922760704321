import React, { useCallback } from 'react';

const GridSiteRuler = React.memo(() => {
  const classes = {
    scale: {
      container: 'absolute',
      yAxis: (position: string) => `absolute bottom-0.5 ${position} h-full`,
      xAxis: (position: string) => `absolute flex ${position} left-0 w-full`,
      short: 'text-[0px]',
      long: (orientation: string) =>
        `flex border border-stone-400 text-[9px] justify-${orientation} items-start`,
    },
    box: {
      top: 'absolute z-10 -top-[23px] border border-stone-700 bg-slate-200 w-[23px] h-[24px]',
      left: 'absolute z-10 -left-[23px] border border-stone-700 bg-slate-200 w-[23px] h-[24px]',
      right:
        'absolute z-10 right-[-22px] border border-stone-700 bg-slate-200 w-[23px] h-[24px]',
    },
  };

  // Generic function to render scale marks
  const renderScale = useCallback(
    (isHorizontal: boolean) => {
      const scales: JSX.Element[] = [];
      const max = isHorizontal ? 300 : 0;
      const direction = isHorizontal ? 1 : -1;
      let line = isHorizontal ? 0 : 300;

      while ((isHorizontal && line <= max) || (!isHorizontal && line >= max)) {
        const hiddenClass = line < (isHorizontal ? 5 : 10) ? 'hidden' : '';
        const longClass = `${classes.scale.long(
          isHorizontal ? 'end' : 'center'
        )} ${
          line % 20
            ? `bg-stone-400 ${
                isHorizontal ? 'w-[75.45px] h-[24px]' : 'w-[23px] h-[75.49px]'
              }`
            : `bg-slate-200 ${
                isHorizontal ? 'w-[75.45px] h-[24px]' : 'w-[23px] h-[75.49px]'
              }`
        }`;
        const activeClass = line % 10 ? classes.scale.short : longClass;

        scales.push(
          <div
            key={`${isHorizontal ? 'x' : 'y'}-${line}`}
            className={`${hiddenClass} ${activeClass}`}>
            {line}
          </div>
        );

        line += direction;
      }

      return scales;
    },
    [classes.scale]
  );

  return (
    <div>
      <div className={classes.scale.yAxis('left-[-23px]')}>
        <div className={classes.box.top} />
        {renderScale(false)}
      </div>
      <div className={classes.scale.yAxis('right-[-21px]')}>
        <div className={classes.box.top} />
        {renderScale(false)}
      </div>
      <div className={classes.scale.xAxis('-top-[25px]')}>
        {renderScale(true)}
      </div>
      <div className={classes.scale.xAxis('bottom-[-22px]')}>
        <div className={classes.box.left} />
        {renderScale(true)}
        <div className={classes.box.right} />
      </div>
    </div>
  );
});

export default GridSiteRuler;
