import { ICanvasContext, ISitesContext } from 'state/iContext';
import { CanvasContext, SitesContext } from 'state/context';
import React, { useContext, useMemo } from 'react';
import Folder from './Folder';

const FolderContainer = () => {
  const { activeTowerID } =
    useContext(CanvasContext) as ICanvasContext;
  const { towerList } =
    useContext(SitesContext) as ISitesContext;
  const folders = useMemo(
    () => towerList[activeTowerID].documents.folders[0],
    [towerList, activeTowerID]
  );

  return (
    <Folder
      name={folders.name}
      files={folders.files}
      folders={folders.folders}
      onFolderClick={() => {}}
      subIndex={0}
      folderID={0}
      parentFolderID={-1}
    />
  );
};

export default FolderContainer;
