import ModalHeader from 'components/application/modals/ModalHeader';
import AddButton from 'components/buttons/addButton/AddButton';
import { IManageLibraryClasses } from './iManageLibraries';
import LibrariesPanel from './panels/LibrariesPanel';
import { ILibraryContext } from 'state/iContext';
import { LibraryContext } from 'state/context';
import EditLibrary from './panels/EditLibrary';
import IconsPanel from './panels/IconsPanel';
import React, { useContext } from 'react';

const ManageLibraries = () => {
  const { editLibrary } =
    useContext(LibraryContext) as ILibraryContext;

  // Component classes
  const classes: IManageLibraryClasses = {
    container:
      'relative flex flex-col w-[90%] h-[80%] z-50 bg-white border border-stone-300 rounded-md md:w-[60%] items-center p-3',
    innerBox:
      'flex flex-col w-[90%] h-[85%] m-5 border border-stone-300 rounded rounded-md sm:flex-row',
    libraries:
      'flex flex-col h-1/3 w-full border border-stone-300 bg-stone-100 sm:w-1/3 sm:h-full',
  };

  return (
    <div className={classes.container}>
      <ModalHeader name={'MANAGE LIBRARIES'} />
      <div className={classes.innerBox}>
        <div className={classes.libraries}>
          <LibrariesPanel />
          {!editLibrary.active && <AddButton name='LIBRARY' />}
        </div>
        {editLibrary.active ? <EditLibrary /> : <IconsPanel />}
      </div>
    </div>
  );
};

export default ManageLibraries;
