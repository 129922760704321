import React, { ReactNode, useCallback, useContext, useMemo } from 'react';
import { IGridLayoutProps, ILibraryIcon, ITowerList } from 'state/iState';
import { IImageGridHandlers } from '../../views/grids/iSiteView';
import { nextAvailableKey } from 'helpers/helperFunctions';
import GridLayout, { Layout } from 'react-grid-layout';
import { IStringProps } from 'iApp';
import GridAsset from './GridAsset';
import axios from 'axios';
import {
  ICanvasContext,
  ICompanyContext,
  IHistoryContext,
  ILibraryContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  CanvasContext,
  CompanyContext,
  HistoryContext,
  ServerContext,
  SitesContext,
  UserContext,
} from 'state/context';
import GridSiteRuler from '../gridContainers/GridSiteRuler';

const classes: IStringProps = {
  img: 'absolute w-full h-full z-10 opacity-50',
  grid: 'relative absolute left-0 top-0 z-20 w-full h-full',
  asset:
    'relative flex flex-nowrap w-full border z-30 border-slate-200 bg-stone-300 object-fit justify-center cursor-all-scroll',
};

const GridRooftop = ({ image }: IStringProps) => {
  const { setTowerList, towerList } = useContext(SitesContext) as ISitesContext;
  const { icons } = useContext(CompanyContext) as ICompanyContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const {
    scale,
    dragProps,
    setDragProps,
    activeToolBar,
    activeTowerID,
    openGridItemOptions,
  } = useContext(CanvasContext) as ICanvasContext;

  const editable: boolean =
    towerList[activeTowerID].edit.active &&
    towerList[activeTowerID].edit.user === user.userId;

  const rooftopObject = towerList[activeTowerID].layout.site;
  const rooftopAssets: IGridLayoutProps = rooftopObject.assets;

  const assetList: string[] = useMemo(
    () => Object.keys(rooftopAssets),
    [rooftopAssets]
  );
  const assets = assetList.map((asset) => rooftopAssets[asset]);

  const nextAssetKey: number = useMemo(
    () => nextAvailableKey(rooftopAssets, 0)!,
    [rooftopAssets]
  );
  const layoutOnLoad = useMemo(
    () => [
      {
        i: '-1',
        name: '',
        x: 400,
        y: 300,
        w: 0,
        minW: 0,
        minH: 0,
        h: 0,
        static: true,
        isResizable: false,
      },
      ...assets,
    ],
    [assets]
  );

  const canvasAssets: ReactNode[] = layoutOnLoad.map(
    (asset): ReactNode => (
      <div
        key={asset.i}
        data-grid={asset}
        className={classes.asset}
        onClick={() => openGridItemOptions(rooftopAssets[asset.i], 'asset')}
        onTouchStart={() =>
          openGridItemOptions(rooftopAssets[asset.i], 'asset')
        }>
        {asset.i !== '-1' && <GridAsset layout={asset} />}
      </div>
    )
  );

  const updateAssets = useCallback(
    (layout: Layout[]) => {
      let updatedLayout = {};
      layout
        .filter((item, i) => i > 0 && item.i !== '__dropping-elem__')
        .forEach((asset, i) => {
          updatedLayout[i] = {
            ...asset,
            i,
            w: asset.w,
            h: asset.h,
            minH: 5,
            minW: 5,
            name: rooftopAssets[asset.i].name,
            img: rooftopAssets[asset.i].img,
            properties: rooftopAssets[asset.i].properties,
          };
        });
      return updatedLayout;
    },
    [rooftopAssets]
  );

  const updateHistory = async (
    action: string,
    prevData?: {
      top?: number;
      bot?: number;
      height?: number;
      width?: number;
      x?: number;
      y?: number;
    },
    newData?: {
      top?: number;
      bot?: number;
      height?: number;
      width?: number;
      x?: number;
      y?: number;
    }
  ) => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    const historyDetail = {
      drop: [
        {
          added: [
            {
              text: `${icons[dragProps.target!]?.name} Added `,
            },
          ],
          field: `Site Asset Added`,
        },
      ],
      move: [
        {
          updated: [
            {
              text: `${
                towerList[activeTowerID].layout.site.assets[dragProps.id]?.name
              } Moved`,
            },
          ],
          before: [
            {
              text: `X: ${prevData?.x} | Y: ${300 - prevData?.y!}`,
            },
          ],
          after: [
            {
              text: `X: ${newData?.x} | Y: ${300 - newData?.y!}`,
            },
          ],
          field: `Site Asset Moved`,
        },
      ],
      resize: [
        {
          updated: [
            {
              text: `${
                towerList[activeTowerID].layout.site.assets[dragProps.id]?.name
              } Resized`,
            },
          ],
          before: [{ height: prevData?.height, width: prevData?.width }],
          after: [{ height: newData?.height, width: newData?.width }],
          field: `Site Asset Resized`,
        },
      ],
    };

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'Asset',
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: historyDetail[action],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    await addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const handler: IImageGridHandlers = {
    onLayoutChange: (layout: Layout[]) =>
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            site: {
              ...prev[activeTowerID].layout.site,
              assets: updateAssets(layout),
            },
          },
        },
      })),
    onDragStop: async (_, prevLayout: Layout, newLayout: Layout) => {
      const hasChanged =
        prevLayout.y !== newLayout.y || prevLayout.x !== newLayout.x;
      if (hasChanged) {
        await updateHistory(
          'move',
          { x: prevLayout.x, y: prevLayout.y },
          { x: newLayout.x, y: newLayout.y }
        );
      }
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
      }));
    },
    onResizeStop: async (_, prevLayout: Layout, newLayout: Layout) => {
      const hasChanged =
        prevLayout.h !== newLayout.h || prevLayout.w !== newLayout.w;
      if (hasChanged) {
        await updateHistory(
          'resize',
          { height: prevLayout.h, width: prevLayout.w },
          { height: newLayout.h, width: newLayout.w }
        );
      }
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
      }));
    },
    onDrop: async (_, layout: Layout) => {
      if (dragProps.id) {
        await setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: {
              ...prev[activeTowerID].layout,
              site: {
                ...prev[activeTowerID].layout.site,
                assets: {
                  ...prev[activeTowerID].layout.site.assets,
                  [nextAssetKey]: {
                    i: nextAssetKey,
                    name: dragProps.id,
                    img: dragProps.content,
                    x: layout.x - 2,
                    y: layout.y - 2,
                    w: 5,
                    h: 5,
                    properties: dragProps.properties,
                    uniqueProperties: [],
                  },
                },
              },
            },
          },
        }));

        updateHistory('drop', { top: layout.y, bot: layout.y + 4 });
      }

      setDragProps({
        id: '',
        img: '',
        properties: [],
      });
    },
    onDragResizeStart: (_, layout) => {
      if (layout.i !== '__dropping-elem__') {
        openGridItemOptions(
          { ...layout, name: rooftopAssets[layout.i].name },
          'asset'
        );
      }
    },
  };

  return (
    <>
      <img
        src={`${domain}${image}`}
        className={classes.img}
        alt='aerial view'
      />
      <GridLayout
        cols={300}
        width={2265}
        margin={[0, 0]}
        autoSize={true}
        rowHeight={7.55}
        isBounded={true}
        compactType={null}
        isDraggable={editable}
        isDroppable={editable}
        isResizable={editable}
        allowOverlap={false}
        layout={layoutOnLoad}
        transformScale={scale}
        className={classes.grid}
        key='Site-grid-layout'
        onLayoutChange={handler.onLayoutChange}
        onDragStop={handler.onDragStop}
        onDragStart={handler.onDragResizeStart}
        onResizeStart={handler.onDragResizeStart}
        onResizeStop={handler.onResizeStop}
        onDrop={handler.onDrop}>
        {canvasAssets}
      </GridLayout>
      {activeToolBar.Grid && <GridSiteRuler />}
    </>
  );
};

export default GridRooftop;
