import { IMapContext, ISitesContext } from 'state/iContext';
import { MapContext, SitesContext } from 'state/context';
import { isAllType } from 'helpers/helperFunctions';
import { ITabClasses, ITabProps } from './iTab';
import React, { useContext} from 'react';


const Tab = ({ id, name, total, onScreen }: ITabProps) => {
  const { favoritesTab, changeSitesTab } = useContext(SitesContext) as ISitesContext;
  const { setInfoWindowID, setMeasureDistance } = useContext(MapContext) as IMapContext;

  // Component classes
  const classes: ITabClasses = {
    unselected: 'w-1/2 text-[10px] self-center text-center p-2',
    selected:
      'w-1/2 text-[10px] self-center text-center font-bold p-2 border-b border-b-[3px] border-b-red-600',
    icon: 'fa-solid fa-tower-cell',
    color: isAllType(id),
  };

  const favorites: string = favoritesTab
    ? classes.selected
    : classes.unselected;
  const allSites: string = !favoritesTab
    ? classes.selected
    : classes.unselected;
  const isSelected: string = id === 'favorites-tab' ? favorites : allSites;

  // Changes site tab focus, closes info windows & measuring tool
  const clickHandler = (): void => {
    setInfoWindowID('');
    setMeasureDistance(false);
    changeSitesTab(id);
  };

  return (
    <div
      id={id}
      className={isSelected}
      onClick={clickHandler}>
      {name} <i className={classes.icon} />
      {/* {total} */} <span className={classes.color}>({onScreen})</span>
    </div>
  );
};

export default Tab;
