import { IChangeImageStates, IFileSelectComplete } from 'helpers/iHelpers';
import { convertImageToWebP } from 'helpers/helperFunctions';
import { IAddItemProps, ITowerList } from 'state/iState';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import { IInputEvent, IStringProps } from 'iApp';
import SaveBox from 'components/buttons/SaveBox';
import React, { useContext } from 'react';
import {
  SitesContext,
  CanvasContext,
  ServerContext,
  CompanyContext,
  SettingsContext,
  UserContext,
  HistoryContext,
} from 'state/context';
import {
  ISitesContext,
  ICanvasContext,
  IServerContext,
  ICompanyContext,
  ISettingsContext,
  IUserContext,
  IHistoryContext,
} from 'state/iContext';
import axios from 'axios';

const RooftopImageInput = () => {
  const { addItemProps, activeTowerID, clearAddProps, setAddItemProps } =
    useContext(CanvasContext) as ICanvasContext;
  const { towerList, setTowerList } = useContext(SitesContext) as ISitesContext;
  const { domain, uploadFilesToDB, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { user } = useContext(UserContext) as IUserContext;
  const {
    fileTypes: { acceptedImageTypes },
  } = useContext(SettingsContext) as ISettingsContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;

  const editable =
    towerList[activeTowerID].edit.active &&
    towerList[activeTowerID].edit.user === user.userId;

  const {
    imgSrc,
    setImgSrc,
    fileInputRef,
    onFileSelect,
    setImgSrcExt,
    setFileReader,
    clearToDefault,
    getImageFileExtension,
  } = ImageCropHelpers(addItemProps.content);

  // Changes/saves the state of the image and file extension
  const changeImageStates = ({ image }: IChangeImageStates): void => {
    setImgSrc(image);
    setAddItemProps((prev: IAddItemProps) => ({
      ...prev,
      content: image,
    }));
    setImgSrcExt(getImageFileExtension(image));
  };

  // Additional methods to envoke once a file is selected
  const onFileSelectComplete = ({ e, file }: IFileSelectComplete): void => {
    setFileReader({
      file,
      changeImageStates,
    });
    clearToDefault(e);
  };

  const classes: IStringProps = {
    container: 'flex flex-col w-full h-full justify-center items-center',
    heading: 'font-bold text-xl text-stone-500',
    imgImported:
      'relative w-5/6 h-5/6 flex flex-col justify-around items-center border border-stone-300 rounded rounded-lg bg-slate-100',
    img: 'h-2/3 w-fit self-center object-contain border border-2 border-stone-400',
    saveBox:
      'absolute flex -right-2 sm:right-0 bottom-5 w-[150px] justify-around sm:mx-5',
  };

  const props = {
    file: {
      type: 'file',
      multiple: false,
      className: 'm-2',
      ref: fileInputRef!,
      id: 'imported-aerial-view',
      name: 'imported-aerial-view',
      accept: acceptedImageTypes,
      onChange: (e: IInputEvent) => onFileSelect({ e, onFileSelectComplete }),
    },
    img: {
      id: 'displayed-user-image',
      src: addItemProps.content,
      className: classes.img,
    },
    saveBox: {
      classes: classes.saveBox,
      clickHandlers: {
        save: async () => {
          const fetchedHistory = await fetchHistory();
          const nextHistoryKey = await axios.get(
            `${domain}get-next-pk/history/`
          );
          const rooftopImageName: string = `rooftop-image-${towerList[activeTowerID].name}`;
          const webPFile: File = await convertImageToWebP(
            addItemProps.content,
            rooftopImageName,
            true
          );
          await uploadFilesToDB([webPFile]);
          const historyEntry = {
            id: nextHistoryKey.data.next_pk,
            timestamp: Date.now(),
            type: 'Rooftop',
            createdBy: user.userId,
            siteID: activeTowerID,
            changes: {
              action: 'Updated',
              details: [
                {
                  field: `Site Aerial Image Added`,
                },
              ],
            },
          };

          await setHistory({
            ...fetchedHistory,
            [nextHistoryKey.data.next_pk]: historyEntry,
          });
          addHistoryToDB(historyEntry);

          await setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              layout: {
                ...prev[activeTowerID].layout,
                site: {
                  ...prev[activeTowerID].layout.site,
                  image: `files/media/client/${company.name}/${webPFile.name}`,
                  imageGrid: {
                    i: 0,
                    name: addItemProps.label,
                    x: 1125,
                    y: 480,
                    w: 300,
                    h: 300,
                  },
                },
              },
              history: [
                ...prev[activeTowerID].history,
                nextHistoryKey.data.next_pk,
              ],
            },
          }));
          setImgSrc('');
          setAddItemProps(clearAddProps);
        },
        cancel: () => {
          setImgSrc('');
          setAddItemProps(clearAddProps);
        },
      },
      disabled: !addItemProps.content,
    },
  };

  return (
    <>
      <h1 className='text-xl mb-10'>No Aerial Image Uploaded</h1>
      {imgSrc ? (
        <div className={classes.imgImported}>
          <img
            alt='imported thumbail'
            {...props.img}
          />
          <SaveBox {...props.saveBox} />
        </div>
      ) : (
        <>{editable && <input {...props.file} />}</>
      )}
    </>
  );
};

export default RooftopImageInput;
