import React, { useContext, useEffect, useMemo } from 'react';
import Resize from 'components/buttons/resizeButton/Resize';
import { IActiveClasses } from 'iApp';
import {
  ICanvasContext,
  ISettingsContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  CanvasContext,
  SettingsContext,
  SitesContext,
  UserContext,
} from 'state/context';

const classes: IActiveClasses = {
  active:
    'absolute bottom-0 relative flex flex-col w-full overflow-y-auto border border-slate-300 md:h-full bg-white ',
  inactive:
    'relative flex h-[50px] w-full md:h-full md:w-[50px] border border-slate-300 bg-gray-100 p-2',
};

const PropertiesPanel = () => {
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { minimize, setMinimize } = useContext(
    SettingsContext
  ) as ISettingsContext;
  const { dragProps, setDragProps, clearDragProps, activeTowerID } = useContext(
    CanvasContext
  ) as ICanvasContext;

  useEffect(() => setMinimize(!dragProps.id), [dragProps]);

  if (!dragProps.id) {
    return <></>;
  }
  const editable: boolean =
    towerList[activeTowerID].edit.active &&
    towerList[activeTowerID].edit.user === user.userId;
  const activeClass: string = minimize ? classes.inactive : classes.active;

  const properties = [
    ...(dragProps?.properties || []),
    ...(dragProps?.uniqueProperties || []),
  ]
    .filter((property) => !property?.path)
    .map((property, index) => (
      <div
        key={index}
        className='flex flex-col p-2 border-b border-slate-200'>
        <div className='w-full font-semibold'>{property.name}:</div>
        <div className='w-full text-center'>{property.value}</div>
      </div>
    ));

  const propertiesToDisplay =
    properties.length > 0 ? (
      properties
    ) : (
      <div className='font-semibold p-2'>No Properties to Display</div>
    );

  return (
    <div
      id='properties-panel'
      className={activeClass + (!editable && 'md:w-1/3 md:max-w-[400px]')}>
      <div
        className='fa-solid fa-xmark self-end text-stone-400 m-2 hover:text-red-600 transition ease-in-out delay-100'
        onClick={() => setDragProps(clearDragProps)}
      />
      {!editable && <Resize />}
      {!minimize && (
        <div className='h-full flex flex-col'>{propertiesToDisplay}</div>
      )}
    </div>
  );
};

export default PropertiesPanel;
