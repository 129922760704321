import { compareIconLayouts, nextAvailableKey } from 'helpers/helperFunctions';
import {
  ILibraryIcon,
  ITowerList,
  IGridLayoutProps,
  IAddItemProps,
} from 'state/iState';
import { ITowerLegGridHandlers } from '../../views/grids/iCanvasGrid';
import GridLayout, { Layout } from 'react-grid-layout';
import { IStringProps } from 'iApp';
import GridAsset from './GridAsset';
import axios from 'axios';
import {
  ICanvasContext,
  ICompanyContext,
  IHistoryContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  CanvasContext,
  CompanyContext,
  HistoryContext,
  ServerContext,
  SitesContext,
  UserContext,
} from 'state/context';
import React, {
  useContext,
  ReactNode,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { clearDragProps } from 'state/state';

const gridItem: IStringProps = { tower: 'legs', data: 'shelves' };

const GridTowerData = ({ id, layout, type }) => {
  const { activeTowerID, dragProps, setDragProps, scale, setAddItemProps } =
    useContext(CanvasContext) as ICanvasContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { setTowerList, towerList } = useContext(SitesContext) as ISitesContext;
  const { icons } = useContext(CompanyContext) as ICompanyContext;
  const { user } = useContext(UserContext) as IUserContext;

  const editable: boolean =
    towerList[activeTowerID].edit.active &&
    towerList[activeTowerID].edit.user === user.userId;

  const gridIcons: IGridLayoutProps =
    towerList[activeTowerID].layout[type][gridItem[type]][id].icons;

  const nextKey: number = useMemo(
    () => nextAvailableKey(gridIcons, 0)!,
    [gridIcons]
  );

  const height: number =
    type === 'tower'
      ? towerList[activeTowerID].height
      : towerList[activeTowerID].layout.data.shelves[layout[0].i].shelfGrid.h;

  const updateIcons = (layout: Layout[]) => {
    const updatedIcons = {
      ...towerList[activeTowerID].layout[type][gridItem[type]][id].icons,
    };

    layout
      .filter((icon) => icon.i !== '__dropping-elem__')
      .forEach((icon, i) => {
        const resizeInBounds =
          +height - +icon.y < +icon.h ? +height - +icon.y : icon.h;

        i === layout.length - 1
          ? (updatedIcons[-1] = {
              ...towerList[activeTowerID].layout[type][gridItem[type]][id]
                .icons[-1],
              y: height,
            })
          : (updatedIcons[icon.i] = {
              ...towerList[activeTowerID].layout[type][gridItem[type]][id]
                .icons[icon.i],
              i: icon.i,
              h: +resizeInBounds,
              y: icon.y,
            });
      });

    return updatedIcons;
  };

  const updateHistory = async (
    action: string,
    prevData?: { top: number; bot: number; height?: number },
    newData?: { top: number; bot: number; height?: number }
  ) => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);
    const height: number = layout[0].h - (!!newData ? newData?.top : 0);

    const historyDetail = {
      drop: [
        {
          added: [
            {
              text: `${icons[dragProps.target!]?.name} Added to ${
                towerList[activeTowerID].layout[type][gridItem[type]][id][
                  type === 'tower' ? 'legGrid' : 'shelfGrid'
                ]?.name
              }`,
            },
            {
              topHeight: height,
              botHeight: height + 4,
            },
          ],
          field: `${type === 'tower' ? 'Tower' : 'Data'} Asset Added`,
        },
      ],
      move: [
        {
          updated: [
            {
              text: `${
                towerList[activeTowerID].layout[type][gridItem[type]][id].icons[
                  dragProps.id
                ]?.name
              } Moved on ${
                towerList[activeTowerID].layout[type][gridItem[type]][id][
                  type === 'tower' ? 'legGrid' : 'shelfGrid'
                ].name
              }`,
            },
          ],
          before: [
            {
              topHeight: layout[0].h - prevData?.top!,
              botHeight: layout[0].h - prevData?.bot!,
            },
          ],
          after: [
            {
              topHeight: layout[0].h - newData?.top!,
              botHeight: layout[0].h - newData?.bot!,
            },
          ],
          field: `${type === 'tower' ? 'Tower' : 'Data'} Asset Moved`,
        },
      ],
      resize: [
        {
          updated: [
            {
              text: `${
                towerList[activeTowerID].layout[type][gridItem[type]][id].icons[
                  dragProps.id
                ]?.name
              } Resized on ${
                towerList[activeTowerID].layout[type][gridItem[type]][id][
                  type === 'tower' ? 'legGrid' : 'shelfGrid'
                ].name
              }`,
            },
          ],
          before: [
            {
              topHeight: layout[0].h - prevData?.top!,
              botHeight: layout[0].h - prevData?.bot!,
              height: prevData?.height,
            },
          ],
          after: [
            {
              topHeight: layout[0].h - newData?.top!,
              botHeight: layout[0].h - newData?.bot!,
              height: newData?.height,
            },
          ],
          field: `${type === 'tower' ? 'Tower' : 'Data'} Asset Resized`,
        },
      ],
    };

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'Asset',
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: historyDetail[action],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    await addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const handler: ITowerLegGridHandlers = {
    onLayoutChange: async (layout: Layout[]) => {
      const updatedIcons = await updateIcons(layout);
      const currentIconLayout =
        towerList[activeTowerID].layout[type][gridItem[type]][id].icons;

      const hasChanges = compareIconLayouts(updatedIcons, currentIconLayout);

      if (hasChanges) {
        setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: {
              ...prev[activeTowerID].layout,
              [type]: {
                ...prev[activeTowerID].layout[type],
                [gridItem[type]]: {
                  ...prev[activeTowerID].layout[type][gridItem[type]],
                  [id]: {
                    ...prev[activeTowerID].layout[type][gridItem[type]][id],
                    icons: updatedIcons,
                  },
                },
              },
            },
          },
        }));
      }
    },
    onDragStop: async (_, prevLayout, newLayout) => {
      const hasChanged =
        prevLayout.y !== newLayout.y || prevLayout.h !== newLayout.h;
      if (hasChanged) {
        await updateHistory(
          'move',
          { top: prevLayout.y, bot: prevLayout.y + prevLayout.h },
          { top: newLayout.y, bot: newLayout.y + newLayout.h }
        );
      }
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        target: id,
        dragging: false,
      }));
    },
    onDrop: async (_, item: Layout) => {
      if (dragProps.id) {
        await setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: {
              ...prev[activeTowerID].layout,
              [type]: {
                ...prev[activeTowerID].layout[type],
                [gridItem[type]]: {
                  ...prev[activeTowerID].layout[type][gridItem[type]],
                  [id]: {
                    ...prev[activeTowerID].layout[type][gridItem[type]][id],
                    icons: {
                      ...prev[activeTowerID].layout[type][gridItem[type]][id]
                        .icons!,
                      [nextKey]: {
                        i: nextKey,
                        name: dragProps.id,
                        img: dragProps.content,
                        x: 0,
                        y: item.y,
                        w: 10,
                        h: 4,
                        minW: 10,
                        properties: dragProps.properties,
                        uniqueProperties: [],
                      },
                    },
                  },
                },
              },
            },
          },
        }));
        await updateHistory('drop', { top: item.y, bot: item.y + 4 });
      }
      setDragProps(clearDragProps);
    },
    onMouseUp: () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
      })),
  };

  const openAssetOptions = useCallback(
    (iconID: string) => {
      if (iconID === '__dropping-elem__') {
        return;
      }
      setAddItemProps((prev: IAddItemProps) => ({ ...prev, edit: false }));
      setDragProps((_: ILibraryIcon) => ({
        // ...prev,
        id: +iconID,
        content:
          towerList[activeTowerID].layout[type][gridItem[type]][id].icons[
            +iconID
          ].name,
        dragging: true,
        target: id.toString(),
        type: 'asset',
        properties:
          towerList[activeTowerID].layout[type][gridItem[type]][id].icons[
            +iconID
          ].properties,
        uniqueProperties:
          towerList[activeTowerID].layout[type][gridItem[type]][id].icons[
            +iconID
          ]?.uniqueProperties,
      }));
    },
    [setDragProps, towerList, activeTowerID, gridItem, type, id]
  );

  const iconsToDisplay: ReactNode[] = useMemo(
    () =>
      Object.keys(gridIcons).map(
        (icon: string): ReactNode => (
          <div
            key={+gridIcons[icon].i}
            data-grid={gridIcons[icon]}
            onMouseUp={handler.onMouseUp}
            // onTouchStart={() => (editable ? openAssetOptions(icon) : {})}
            // onClick={() => (editable ? openAssetOptions(icon) : {})}
            onClick={() => openAssetOptions(icon)}
            className='relative flex flex-nowrap w-full border z-30 border-stone-400 bg-stone-300 justify-center cursor-row-resize pr-0.5'>
            <GridAsset layout={gridIcons[icon]} />
          </div>
        )
      ),
    [gridIcons, handler.onMouseUp, openAssetOptions]
  );

  useEffect(
    () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
        content: '',
        id: '',
        target: '',
        type: '',
      })),
    []
  );

  return (
    <GridLayout
      cols={10}
      width={74}
      layout={layout}
      margin={[0, 0]}
      rowHeight={7.55}
      isBounded={true}
      compactType={null}
      isDraggable={editable}
      isDroppable={editable}
      isResizable={editable}
      allowOverlap={false}
      transformScale={scale}
      key={id + '-grid-layout'}
      className='relative absolute z-40 h-full w-full'
      onLayoutChange={handler.onLayoutChange}
      onDragStart={(_, layout) => {
        const iconID = layout.i;
        openAssetOptions(iconID);
      }}
      onResizeStart={(_, layout) => {
        const iconID = layout.i;
        openAssetOptions(iconID);
      }}
      onDragStop={handler.onDragStop}
      onDrop={handler.onDrop}>
      {iconsToDisplay}
    </GridLayout>
  );
};

export default GridTowerData;
