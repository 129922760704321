import { IWorkOrder, IWorkOrderList } from 'components/workOrders/iWorkOrders';
import { ISettingsProviderProps, IWorkOrderContext } from './iContext';
import React, { useState, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { userJohn } from './developmentData';
import axios from 'axios';
import {
  UserContext,
  CropContext,
  HeaderContext,
  ServerContext,
  CompanyContext,
  SettingsContext,
  WorkOrderContext,
  HistoryContext,
} from './context';
import {
  version,
  fileTypes,
  measurements,
  userDropdown,
  headerButtons,
  defaultCropConfig,
  defaultMenuFilters,
  clearWorkOrder,
} from './state';
import {
  IUser,
  IAsset,
  ICompany,
  ILibrary,
  IAssetList,
  IAppChildren,
  IMenuFilters,
  IHeaderButtons,
  IScreenDimensions,
  ITowerDocument,
  IUserList,
  IHistoryList,
  IHistoryEntry,
} from './iState';
import { IReportFormData } from 'components/reports/iReports';

const TopStateProvider = ({ children }: IAppChildren) => {
  const { user: auth0UserData } = useAuth0();
  const authUser = useMemo(() => auth0UserData, [auth0UserData]);

  const [domain, setDomain] = useState<string>(process.env.REACT_APP_DOMAIN!);

  // User State
  const [user, setUser] = useState<IUser>(userJohn);
  const [userList, setUserList] = useState<IUserList>({});
  // const [favorites, setFavorites] = useState<number[]>(user.favorites);
  const [searchBar, setSearchBar] = useState<string>('');
  const [prevSearchBar, setPrevSearchBar] = useState('');
  const [searchDate, setSearchDate] = useState<string>('');
  const [searchRange, setSearchRange] = useState<string>('');
  const [rangeSearchActive, setRangeSearchActive] = useState<boolean>(false);
  const lowCaseSearch: string = searchBar.toLowerCase().trim();

  // Header States
  const [activeHeaderButton, setActiveHeaderButton] =
    useState<IHeaderButtons>(headerButtons);
  const [menuFilters, setMenuFilters] =
    useState<IMenuFilters>(defaultMenuFilters);
  const [lastActiveView, setLastActiveView] =
    useState<IHeaderButtons>(activeHeaderButton);
  const [activeCanvasTab, setActiveCanvasTab] = useState<string>('');
  const {
    tower,
    site,
    data,
    drawings,
    files,
    contacts,
    'work orders': wo,
    reports,
    settings,
    support,
  } = activeHeaderButton;

  // Determines if user is currently on the home page
  const homePage: boolean =
    !tower &&
    !drawings &&
    !support &&
    !site &&
    !data &&
    !files &&
    !reports &&
    !wo &&
    !contacts &&
    !settings;

  // Settings States
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [minimize, setMinimize] = useState<boolean>(false);
  const [metric, setMetric] = useState<boolean>(false);
  const [screenDimensions, setScreenDimensions] = useState<IScreenDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const scale: string = metric ? 'm' : 'ft';

  // Company States
  const [company, setCompany] = useState<ICompany | null>();
  const [icons, setIcons] = useState<IAssetList>({});
  const [assetKeys, setAssetKeys] = useState<number[]>([]);

  // Crop Image States
  const [isCropActive, setIsCropActive] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');

  // // Work Order States
  const [workOrders, setWorkOrders] = useState<IWorkOrderList>({});
  const [workOrderStates, setWorkOrderStates] =
    useState<IWorkOrderContext['workOrderStates']>(clearWorkOrder);

  // History State
  const [history, setHistory] = useState<IHistoryList>({});

  // Closes header dropdown windows
  const clearActiveButtons = (): void => {
    setActiveHeaderButton((prev: IHeaderButtons) => ({
      ...prev,
      siteTypes: false,
      account: false,
    }));
  };

  // Toggles min/max site panel size
  const changePanelSize = (search: boolean = false): void =>
    search ? setMinimize(false) : setMinimize((prev: boolean) => !prev);

  const deleteFileFromDB = async (filePath: string) => {
    try {
      const response = await axios.delete(`${domain}${filePath}/`);

      if (response.status === 204) {
        console.log('File deleted successfully');
      } else {
        const data = response.data;
        window.alert(`Error deleting file: ${data.message}`);
        console.error(`Error deleting file: ${data.message}`);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const fetchCsrfToken = async () => {
    try {
      const response = await axios.get(`${domain}get_csrf_token/`);
      const csrfToken = response.data.csrf_token;
      return csrfToken;
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      throw error;
    }
  };

  const acquireCanvasLock = async (siteId, userId) => {
    try {
      const response = await fetch(`${domain}sites/${siteId}/acquire_lock/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-id': userId,
        },
        body: JSON.stringify({}),
      });
      const data = await response.json();
      return data.locked;
    } catch (error) {
      console.error('Error acquiring lock:', error);
      return false;
    }
  };

  const releaseCanvasLock = async (
    siteId: number,
    userId: string
  ): Promise<boolean> => {
    try {
      const response = await axios.post(
        `${domain}sites/${siteId}/release_lock/`,
        { user: userId }
      );
      return response.status === 200;
    } catch (error) {
      return false;
    }
  };

  const checkCanvasLock = async (siteId: number): Promise<boolean> => {
    try {
      const response = await axios.get(
        `${domain}sites/${siteId}/check_site_lock/`
      );
      const { locked, time: lockTime } = response.data;

      if (!locked) {
        return false;
      }

      const currentTime = Math.floor(Date.now() / 1000);

      return currentTime - lockTime <= 300;
    } catch (error) {
      console.error('Error checking lock status:', error);
      return false;
    }
  };

  const deleteDocumentFromDB = async (id: string) => {
    try {
      const response = await fetch(`${domain}documents/${id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        console.log('Document deleted successfully');
      } else {
        console.error('Error deleting document:', response.statusText);
      }
    } catch (error: any) {
      console.error('Error:', error.message);
    }
  };

  const deleteLibraryFromDB = async (libraryId: string) => {
    try {
      const response = await axios.delete(`${domain}libraries/${libraryId}/`);

      if (response.status === 204) {
        console.log('Library deleted successfully');
      } else {
        const data = response.data;
        window.alert(`Error deleting library: ${data.message}`);
        console.error(`Error deleting library: ${data.message}`);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const uploadFilesToDB = async (files: File[]) => {
    try {
      const csrfToken = await fetchCsrfToken();
      const fileUrl = `${domain}files/media/client/${company?.name}/`;

      const formData = new FormData();
      files.forEach((file) => {
        formData.append('file', file);
      });

      const response = await axios.post(fileUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': csrfToken,
        },
      });

      if (response.status === 200 || response.status === 201) {
        console.log('Files uploaded successfully');
      } else if (response.status === 207) {
        console.warn('Partial success: Some files failed to upload');
      } else {
        window.alert(
          `Failed to upload files to the database. Error: ${response.status}`
        );
      }
    } catch (error) {
      window.alert('Error Uploading Files');
      console.error('Error uploading files:', error);
    }
  };

  const updateAssetsDB = async (method: string, assetData: IAsset) => {
    console.log({ assetData });
    try {
      const databaseUrl =
        method === 'PUT'
          ? `${domain}assets/${assetData.id}/`
          : `${domain}assets/`;

      const assetPayload = {
        ...assetData,
        companyId: +company!.id,
        type: 'client',
        content: '',
      };
      console.log({ assetPayload });

      if (!(assetData.content instanceof File)) {
        assetPayload.content = assetData.content;
      }

      const databaseResponse = await fetch(databaseUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(assetPayload),
      });

      if (!databaseResponse.ok) {
        const errorData = await databaseResponse.json();
        console.error('Failed to save asset data to the database:', errorData);
        window.alert('Failed to save asset data to the database');
        return;
      }
      if (assetData.content) {
        await uploadFilesToDB([assetData.content as File]);
      }
      setAssetKeys((prev) => [...prev, assetData.id]);
      console.log('Asset data updated successfully');
    } catch (error: any) {
      console.error('Asset update failed:', error.message);
      window.alert('Failed to Update Asset');
    }
  };

  const updateDocumentsDB = async (
    id: number,
    method: string,
    custom: boolean,
    docData: ITowerDocument | IReportFormData
  ): Promise<number> => {
    if (!authUser) return 0;

    const url =
      method === 'PUT' ? `${domain}documents/${id}/` : `${domain}documents/`;

    try {
      const documentResponse = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          custom: !!custom,
          document_data: docData,
        }),
      });

      if (!documentResponse.ok) {
        window.alert('Failed to save document data');
        return 0;
      }

      const responseJson = await documentResponse.json();
      const docId: number = responseJson.id;
      return docId;
    } catch (error: any) {
      window.alert('Documents Update Failed');
      console.error('Documents update failed:', error.message);
      return 0;
    }
  };

  const updateLibrariesDB = async (method: string, libraryData: ILibrary) => {
    if (!authUser) return;

    const url =
      method === 'PUT'
        ? `${domain}libraries/${libraryData.libraryId}/`
        : `${domain}libraries/`;

    try {
      const libraryResponse = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyId: company ? +company!.id : authUser!.company,
          ...libraryData,
        }),
      });

      if (!libraryResponse.ok) {
        window.alert('Failed to save library data');
      }
    } catch (error: any) {
      window.alert('Library Update Failed');
      console.error('Library update failed:', error.message);
    }
  };

  const updateWorkOrdersDB = async (
    method: string,
    workOrderData: IWorkOrder
  ) => {
    try {
      const databaseUrl =
        method === 'PUT'
          ? `${domain}work_orders/${workOrderData.id}/`
          : `${domain}work_orders/`;

      const databaseResponse = await fetch(databaseUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...workOrderData,
          additional_users: workOrderData.additionalUsers,
          created_by: workOrderData.createdBy,
          created_date: Math.floor(workOrderData.createdDate / 60000),
          end_date: Math.floor(workOrderData.endDate! / 60000),
          last_modified: Math.floor(workOrderData.lastModified / 60000),
          last_modified_by: workOrderData.lastModifiedBy,
          parts_assets: workOrderData.partsAssets,
          start_date: Math.floor(workOrderData.startDate! / 60000),
        }),
      });

      if (!databaseResponse.ok) {
        const errorData = await databaseResponse.json();
        console.error('Failed to update work order data:', errorData);
        window.alert('Failed to update work order data');
        return;
      }

      console.log('Work order data updated successfully');
    } catch (error: any) {
      console.error('Work order update failed:', error.message);
      window.alert('Failed to update work order');
    }
  };

  const addHistoryToDB = async (historyData: IHistoryEntry) => {
    const { createdBy, changes, type, id, timestamp, siteID, ...rest } =
      historyData;
    try {
      const databaseUrl = `${domain}history/`;

      const databaseResponse = await fetch(databaseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          type,
          siteID,
          changes,
          created_by: createdBy,
          timestamp: Math.floor(historyData.timestamp / 60000),
        }),
      });

      if (!databaseResponse.ok) {
        const errorData = await databaseResponse.json();
        console.error('Failed to add history item:', errorData);
        window.alert('Failed to add history item');
        return;
      }

      console.log('History item added successfully');
    } catch (error: any) {
      console.error('Failed to add history item:', error.message);
      window.alert('Failed to add history item');
    }
  };

  const fetchWorkOrder = async (
    workOrderID: number
  ): Promise<IWorkOrder | null | undefined> => {
    try {
      if (authUser) {
        const workOrderResponse = await fetch(
          `${domain}work_orders/${workOrderID}/`
        );

        if (workOrderResponse.ok) {
          const workOrderData = await workOrderResponse.json();

          // Transform tower data
          const fetchedData = {
            additionalUsers: workOrderData.additional_users,
            assigned: workOrderData.assigned,
            checklist: workOrderData.checklist,
            classification: workOrderData.classification,
            confirmed: workOrderData.confirmed,
            createdBy: workOrderData.created_by,
            createdDate: workOrderData.created_date * 60000,
            description: workOrderData.description,
            endDate: workOrderData.end_date * 60000,
            files: workOrderData.files,
            history: workOrderData.history,
            id: workOrderData.id,
            lastModified: workOrderData.last_modified * 60000,
            lastModifiedBy: workOrderData.last_modified_by,
            locations: workOrderData.locations,
            notes: workOrderData.notes,
            partsAssets: workOrderData.parts_assets,
            priority: workOrderData.priority,
            project: workOrderData.project,
            progress: workOrderData.progress,
            startDate: workOrderData.start_date * 60000,
          };

          return fetchedData;
        } else {
          console.error('Error with loading tower data');
          return null;
        }
      }
    } catch (error: any) {
      console.error(error.message);
      return null;
    }
  };

  const fetchHistory = async (): Promise<IHistoryList | null | undefined> => {
    try {
      if (authUser) {
        const historyResponse = await fetch(`${domain}history/`);

        if (historyResponse.ok) {
          const historyData = await historyResponse.json();
          const transformedHistory = historyData.reduce((acc, history) => {
            acc[history.id] = {
              changes: history.changes,
              createdBy: history.created_by,
              id: history.id,
              timestamp: history.timestamp * 60000,
              type: history.type,
            };
            return acc;
          }, {});

          return transformedHistory;
        } else {
          console.error('Error with loading history data');
          return null;
        }
      }
    } catch (error: any) {
      console.error(error.message);
      return null;
    }
  };

  useEffect(() => {
    const subdomain: string = window.location.host.split('.')[0];
    const isDevelopment: boolean = process.env.NODE_ENV === 'development';
    // const isDevelopment: boolean = true;

    setDomain(
      isDevelopment
        ? `http://${subdomain}.localhost:8200/`
        : `https://${subdomain}${process.env.REACT_APP_DOMAIN}`
    );
  }, []);

  // Watches and sets window width into state
  useEffect(() => {
    const resizeWidth = () => {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', resizeWidth);
  }, []);

  useEffect(() => {
    const { siteTypes, account, settings, map, inventory, ...rest } =
      activeHeaderButton;
    const canvasTab: string = Object.keys(rest).filter(
      (tab) => activeHeaderButton[tab]
    )[0];
    setActiveCanvasTab(canvasTab);
  }, [activeHeaderButton]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!authUser) return;

        const userId: string = authUser.sub!.split('|')[1];
        const subdomain: string = window.location.host.split('.')[0];

        const userAccess: string[] = authUser.access;
        const isAdmin: boolean = authUser.admin;
        const hasAccessToSubdomain: boolean =
          userAccess.includes(subdomain) || userAccess.includes('all');

        if (!isAdmin && !hasAccessToSubdomain) {
          window.alert('Unauthorized access or data not found');
          // TODO: ADD logout function
          return;
        }

        const [
          companyResponse,
          assetResponse,
          userDataResponse,
          usersListResponse,
          historyResponse,
          workOrderResponse,
        ] = await Promise.all([
          fetch(`${domain}companies/${authUser.company}/`),
          fetch(`${domain}assets/`),
          fetch(`${domain}users/${userId}/`),
          fetch(`${domain}users/`),
          fetch(`${domain}history/`),
          fetch(`${domain}work_orders/`),
        ]);

        if (!companyResponse.ok)
          console.log('Attempt to load Company Data Failed');
        if (!assetResponse.ok) console.log('Attempt to load Asset Data Failed');
        if (!userDataResponse.ok)
          console.log('Attempt to load User Data Failed');
        if (!usersListResponse.ok)
          console.log('Attempt to load Users List Data Failed');
        if (!historyResponse.ok)
          console.log('Attempt to load History Data Failed');
        if (!workOrderResponse.ok)
          console.log('Attempt to load Work Orders Data Failed');

        const companyData = await companyResponse.json();
        const assetData = await assetResponse.json();
        const userData = await userDataResponse.json();
        const usersListData = await usersListResponse.json();
        const historyData = await historyResponse.json();
        const workOrdersData = await workOrderResponse.json();

        const transformedAssetData = {};
        const fetchedUserList = {};
        const fetchedHistory = {};
        const fetchedWorkOrders = {};

        assetData.assets.forEach((asset) => {
          const libraryID: number =
            asset.companyId === 2 ? asset.companyId : companyData.libraryID;

          transformedAssetData[`${libraryID}-${asset.id}`] = {
            id: asset.id,
            name: asset.name,
            content: asset.content,
            properties: asset.properties,
            type: asset.type,
          };
        });

        const assetDataKeys: number[] = assetData.assets.map(
          (asset) => asset.id
        );

        usersListData.users.forEach((user) => {
          fetchedUserList[user.userId] = {
            id: user.userId,
            company: user.company,
            phone: user.phone,
            name: user.name,
            title: user.title,
            email: user.email,
            image: user.image,
            admin: user.admin,
            access: user.access,
          };
        });

        historyData.forEach((history) => {
          fetchedHistory[history.id] = {
            changes: history.changes,
            createdBy: history.created_by,
            id: history.id,
            timestamp: history.timestamp * 60000,
            type: history.type,
            siteID: history.siteID,
          };
        });

        workOrdersData.work_orders.forEach((order) => {
          fetchedWorkOrders[order.id] = {
            additionalUsers: order.additional_users,
            assigned: order.assigned,
            checklist: order.checklist,
            classification: order.classification,
            confirmed: order.confirmed,
            createdBy: order.created_by,
            createdDate: order.created_date * 60000,
            description: order.description,
            endDate: order.end_date * 60000,
            files: order.files,
            history: order.history,
            id: order.id,
            lastModified: order.last_modified * 60000,
            lastModifiedBy: order.last_modified_by,
            locations: order.locations,
            notes: order.notes,
            partsAssets: order.parts_assets,
            priority: order.priority,
            project: order.project,
            progress: order.progress,
            startDate: order.start_date * 60000,
          };
        });

        setCompany(companyData);
        setUser((prev: IUser) => ({
          ...prev,
          userId,
          admin: userData.admin || isAdmin,
          name: userData.name || authUser.name!,
          title: userData.title,
          email: userData.email || authUser.email!,
          phone: userData.phone,
          company: companyData.id,
          image: userData.image ? userData.image : authUser.picture!,
          favorites: userData.favorites,
          private_libraries: userData.private_libraries,
          standard_libraries: userData.standard_libraries,
          access: userData.access,
        }));
        setIcons((prev: IAssetList) => ({
          ...prev,
          ...transformedAssetData,
        }));
        setAssetKeys(assetDataKeys);
        setUserList(fetchedUserList);
        setHistory(fetchedHistory);
        setWorkOrders(fetchedWorkOrders);
      } catch (error: any) {
        console.error('Error:', error.message);
      }
    };

    fetchData();
  }, [authUser]);

  useEffect(() => {
    if (authUser && user.userId !== '0') {
      const fetchData = async () => {
        try {
          const userData = {
            ...user,
            phone_primary: user.phone.primary,
            phone_mobile: user.phone.mobile,
            phone_ext: user.phone.ext,
          };
          const userResponse = await fetch(`${domain}users/${user.userId}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userData),
          });
          if (!userResponse.ok) {
            window.alert('User Data Update Failed: ' + userResponse.statusText);
            return;
          }
          console.log('User Data Updated');
        } catch (error: any) {
          window.alert('User Data Update Failed: ' + error.message);
          console.log('User Data Update Failed: ' + error.message);
        }
      };
      fetchData();
    }
  }, [user, authUser]);

  // Props to pass to their respective elements
  const providerProps: ISettingsProviderProps = {
    user: {
      user,
      setUser,
      userList,
      setUserList,
      searchBar,
      setSearchBar,
      prevSearchBar,
      setPrevSearchBar,
      searchDate,
      setSearchDate,
      searchRange,
      setSearchRange,
      rangeSearchActive,
      setRangeSearchActive,
      lowCaseSearch,
    },
    header: {
      homePage,
      menuFilters,
      userDropdown,
      lastActiveView,
      setMenuFilters,
      activeCanvasTab,
      setLastActiveView,
      activeHeaderButton,
      clearActiveButtons,
      setActiveHeaderButton,
    },
    settings: {
      metric,
      setMetric,
      scale,
      fileTypes,
      minimize,
      setMinimize,
      screenDimensions,
      measurements,
      changePanelSize,
      isSettingsOpen,
      setIsSettingsOpen,
      defaultCropConfig,
      version,
    },
    company: {
      icons,
      assetKeys,
      setIcons,
      company,
    },
    crop: {
      isCropActive,
      setIsCropActive,
      image,
      setImage,
    },
    server: {
      domain,
      updateAssetsDB,
      updateDocumentsDB,
      updateLibrariesDB,
      uploadFilesToDB,
      updateWorkOrdersDB,
      addHistoryToDB,
      deleteFileFromDB,
      deleteDocumentFromDB,
      deleteLibraryFromDB,
      acquireCanvasLock,
      releaseCanvasLock,
      checkCanvasLock,
    },
    workOrders: {
      workOrders,
      setWorkOrders,
      workOrderStates,
      setWorkOrderStates,
      fetchWorkOrder,
    },
    history: {
      history,
      setHistory,
      fetchHistory,
    },
  };

  return (
    <ServerContext.Provider value={providerProps.server}>
      <CropContext.Provider value={providerProps.crop}>
        <UserContext.Provider value={providerProps.user}>
          <SettingsContext.Provider value={providerProps.settings}>
            <CompanyContext.Provider value={providerProps.company}>
              <HeaderContext.Provider value={providerProps.header}>
                <WorkOrderContext.Provider value={providerProps.workOrders}>
                  <HistoryContext.Provider value={providerProps.history}>
                    {children}
                  </HistoryContext.Provider>
                </WorkOrderContext.Provider>
              </HeaderContext.Provider>
            </CompanyContext.Provider>
          </SettingsContext.Provider>
        </UserContext.Provider>
      </CropContext.Provider>
    </ServerContext.Provider>
  );
};

export default TopStateProvider;
