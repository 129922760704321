import { highlightSearch } from 'helpers/helperFunctions';
import React, { useContext } from 'react';
import {
  IDocumentContext,
  ICompanyContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  DocumentContext,
  CompanyContext,
  SitesContext,
  UserContext,
} from 'state/context';

const TableRow = ({ workOrder, columns, onRowClick }) => {
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { userList, searchBar } = useContext(UserContext) as IUserContext;
  const { icons: assets } = useContext(CompanyContext) as ICompanyContext;
  const { documentList } = useContext(DocumentContext) as IDocumentContext;

  const renderCells = (value, renderItem) =>
    Array.isArray(value) ? (
      <ul className='list-disc list-inside'>
        {value.map((item, index) => (
          <li key={index}>{highlightSearch(renderItem(item), searchBar)}</li>
        ))}
      </ul>
    ) : (
      highlightSearch(renderItem(value), searchBar)
    );

  const cellDetail = (item, accessor) => {
    switch (accessor) {
      case 'startDate':
      case 'endDate':
        return new Date(item).toDateString();
      case 'id':
        return String(item).padStart(8, '0');
      case 'files':
        return accessor === 'files' && Array.isArray(item)
          ? item.map((fileId) => documentList[fileId]?.name).join(', ')
          : documentList[item]?.name;
      case 'partsAssets':
      case 'checklist':
      case 'assigned':
        return accessor === 'assigned'
          ? userList[item]?.name
          : accessor === 'partsAssets'
          ? assets[item]?.name
          : item?.label || item?.name || item?.text || item;
      case 'locations':
        return Array.isArray(item)
          ? item.map((location, index) => (
              <span key={index}>
                {towerList[location]?.name}
                {index !== item.length - 1 && ', '}
              </span>
            ))
          : towerList[item]?.name;
      default:
        return item;
    }
  };

  const columnClasses =
    'p-0.5 border border-stone-300 bg-slate-100 text-stone-500 text-xs';

  return (
    <tr onClick={() => onRowClick(workOrder)}>
      {columns.map(({ accessor, maxWidthClass }) => (
        <td
          key={accessor}
          className={`${columnClasses} ${maxWidthClass} ${
            [
              'id',
              'priority',
              'progress',
              'project',
              'startDate',
              'endDate',
              'description',
              'notes',
            ].includes(accessor)
              ? 'text-center'
              : ''
          }`}>
          {renderCells(workOrder[accessor], (item) =>
            cellDetail(item, accessor)
          )}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
