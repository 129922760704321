import { IEditLibrary, ILibraries, IUser } from 'state/iState';
import { nextAvailableKey } from 'helpers/helperFunctions';
import { IEditLibraryProps } from '../iManageLibraries';
import { IInputEvent, IStringProps } from 'iApp';
import Button from 'components/buttons/Button';
import Close from 'components/buttons/Close';
import React, { useContext } from 'react';
import {
  IUserContext,
  IModalContext,
  IServerContext,
  ILibraryContext,
} from 'state/iContext';
import {
  UserContext,
  ModalContext,
  ServerContext,
  LibraryContext,
} from 'state/context';

const EditLibrary = () => {
  const {
    libraries,
    editLibrary,
    setLibraries,
    setEditLibrary,
    setLibraryList,
    clearEditLibrary,
    activeLibrary,
    setActiveLibrary,
    setActivePrivateLibraries,
  } = useContext(LibraryContext) as ILibraryContext;
  const { setCustomIconStage } =
    useContext(ModalContext) as IModalContext;
  const { user, setUser } =
    useContext(UserContext) as IUserContext;
  const { updateLibrariesDB, deleteLibraryFromDB } =
    useContext(ServerContext) as IServerContext;

  const classes: IStringProps = {
    container: 'relative flex flex-col w-full h-full md:w-2/3',
    title: 'm-3 text-stone-500 font-bold',
    inputBox: 'w-1/2 m-3 self-center',
    input: 'w-full rounded rounded-md',
    invalid: 'absolute text-red-600 font-bold',
    buttons: 'flex w-[150px] justify-between m-5 self-end',
  };

  const nextKey: string = nextAvailableKey(libraries, 0)!.toString();

  const props: IEditLibraryProps = {
    input: {
      id: 'name',
      type: 'text',
      name: 'name',
      placeholder: 'New Library Name',
      defaultValue: editLibrary.name,
      className: classes.input,
      onChange: (e: IInputEvent) =>
        setEditLibrary((prev: IEditLibrary) => ({
          ...prev,
          name: e.target.value.trim(),
        })),
    },
    delete: {
      name: 'DELETE',
      handler: () => {
        setLibraryList((prev: string[]) =>
          prev.filter((id: string) => id !== activeLibrary)
        );
        setUser((prev: IUser) => {
          const { [activeLibrary]: target, ...rest } = prev.private_libraries;
          return { ...prev, private_libraries: rest };
        });
        setLibraries((prev: ILibraries) => {
          const { [activeLibrary]: target, ...rest } = prev;
          return rest;
        });
        setActivePrivateLibraries((prev: string[]) =>
          prev.filter((id: string) => id !== activeLibrary)
        );
        deleteLibraryFromDB(activeLibrary);
        setActiveLibrary('');
        setEditLibrary(clearEditLibrary);
      },
    },
    save: {
      name: 'SAVE',
      handler: async () => {
        if (editLibrary.prevName) {
          await setUser((prev: IUser) => {
            const { [activeLibrary]: target, ...rest } = prev.private_libraries;
            return {
              ...prev,
              private_libraries: {
                ...rest,
                [activeLibrary]: prev.private_libraries[activeLibrary],
              },
            };
          });
          await setLibraries((prev: ILibraries) => ({
            ...prev,
            [activeLibrary]: {
              ...prev[activeLibrary],
              name: editLibrary.name,
            },
          }));
          updateLibrariesDB('PUT', {
            ...libraries[activeLibrary],
            name: editLibrary.name,
          });
        } else {
          await setLibraries((prev: ILibraries) => ({
            ...prev,
            [`${nextKey}`]: {
              libraryId: nextKey,
              name: editLibrary.name,
              assets: [],
            },
          }));
          await setUser((prev: IUser) => ({
            ...prev,
            private_libraries: {
              ...prev.private_libraries,
              [`${nextKey}`]: true,
            },
          }));
          updateLibrariesDB('POST', {
            libraryId: nextKey,
            name: editLibrary.name,
            assets: [],
          });
        }
        setActiveLibrary('');
        setEditLibrary(clearEditLibrary);
        setCustomIconStage(1);
      },
      disabled:
        editLibrary.name === '' ||
        Object.keys(libraries).includes(editLibrary.name),
    },
  };

  return (
    <div className={classes.container}>
      <Close handler={() => setEditLibrary(clearEditLibrary)} />
      <h1 className={classes.title}>
        {editLibrary.prevName ? 'EDIT' : 'NEW'} LIBRARY
      </h1>
      <div className={classes.inputBox}>
        <input {...props.input} />
        {editLibrary.name && libraries[editLibrary.name] && (
          <div className={classes.invalid}>Please choose unique name</div>
        )}
      </div>
      <div className={classes.buttons}>
        {editLibrary.prevName && user.userId !== activeLibrary && (
          <Button {...props.delete} />
        )}
        <Button {...props.save} />
      </div>
    </div>
  );
};

export default EditLibrary;
