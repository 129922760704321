import { ICallbackProps, IStringProps } from 'iApp';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { IUpdateFolders } from 'state/iState';
import React, { useContext } from 'react';
import { IPdfNav } from './iTowerFiles';

const ViewPDFNav = ({
  folder,
  isPDF,
  numPages,
  pageNumber,
  setPageNumber,
  resetTransform,
  setRotationAngle,
}: IPdfNav) => {
  const { setActiveFile, setUpdateFolders } =
    useContext(DocumentContext) as IDocumentContext;

  const handlers: ICallbackProps = {
    close: (): void => {
      setActiveFile('add');
      setUpdateFolders((prev: IUpdateFolders) => ({
        ...prev,
        folder,
      }));
    },
    next: (): void => {
      setPageNumber(pageNumber + 1 >= numPages! ? numPages! : pageNumber + 1);
      resetTransform();
    },
    prev: (): void => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1),
    rotate: () => {
      setRotationAngle((prev: number) => (prev + 90) % 360);
    },
  };

  const classes: IStringProps = {
    nav: 'flex flex-col w-full items-center',
    button: 'p-1 m-1 border border-stone-300 rounded rounded-xl bg-slate-300',
    rotate:
      'fa-solid fa-rotate-right p-2 m-1 border border-stone-300 rounded rounded-xl bg-slate-300',
  };

  return (
    <nav className={classes.nav}>
      {numPages > 1 && (
        <span>
          Page {pageNumber} of {numPages}
        </span>
      )}
      <div>
        {pageNumber > 1 && (
          <button
            className={classes.button}
            onClick={handlers.prev}>
            Prev
          </button>
        )}
        <button
          className={classes.button}
          onClick={handlers.close}>
          Close
        </button>
        {!isPDF && (
          <button
            className={classes.rotate}
            onClick={handlers.rotate}
          />
        )}
        {pageNumber < numPages! && (
          <button
            className={classes.button}
            onClick={handlers.next}>
            Next
          </button>
        )}
      </div>
    </nav>
  );
};
export default ViewPDFNav;
