import { isMinClass, nextAvailableKey } from 'helpers/helperFunctions';
import { IActiveModal, IEditLibrary } from 'state/iState';
import pin from 'assets/map/map-pin-red.svg';
import React, { useContext } from 'react';
import { IAddClasses } from './iAdd';
import { IStringProps } from 'iApp';
import {
  MapContext,
  EditContext,
  ModalContext,
  SitesContext,
  HeaderContext,
  LibraryContext,
  SettingsContext,
  CompanyContext,
} from 'state/context';
import {
  IMapContext,
  IEditContext,
  IModalContext,
  ISitesContext,
  IHeaderContext,
  ILibraryContext,
  ISettingsContext,
  ICompanyContext,
} from 'state/iContext';

const AddButton = ({ name }: IStringProps) => {
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { metric } = useContext(SettingsContext) as ISettingsContext;
  const { setActiveModal } = useContext(ModalContext) as IModalContext;
  const { setMeasureDistance } = useContext(MapContext) as IMapContext;
  const { openEditPanel, setNewSite } = useContext(EditContext) as IEditContext;
  const { homePage, setActiveHeaderButton } = useContext(
    HeaderContext
  ) as IHeaderContext;
  const { setEditLibrary, setActiveLibrary } = useContext(
    LibraryContext
  ) as ILibraryContext;
  const { minimize, changePanelSize } = useContext(
    SettingsContext
  ) as ISettingsContext;
  const minHeight: number = metric ? 15 : 50;

  // Component classes
  const classes: IAddClasses = {
    main: {
      min: 'h-full bg-red-600 text-white text-xs p-2 text-center border border-red-700 cursor-pointer md:h-auto md:-left-0.5',
      max: 'w-full bg-red-700 text-white text-xs p-2 pl-4 text-center border border-red-700 cursor-pointer',
    },
    container: 'flex h-full items-center justify-center',
    icon: {
      min: 'fa-solid fa-circle-plus text-xl',
      max: 'fa-solid fa-circle-plus mr-2',
    },
    label: 'truncate text-[11px]',
  };

  const buttonClasses: string = homePage
    ? isMinClass(minimize, classes.main)
    : classes.main.max;
  const iconClasses: string = homePage
    ? isMinClass(minimize, classes.icon)
    : classes.icon.max;
  const newSiteLabel: boolean = (!minimize && homePage) || !homePage;

  // Opens edit panel with empty fields and new marker in center of map
  const clickHandlers = {
    SITE: (): void => {
      setActiveHeaderButton({
        siteTypes: false,
        map: true,
        inventory: false,
        account: false,
        settings: false,
        tower: false,
        drawings: false,
        site: false,
        contacts: false,
        'work orders': false,
      });
      setMeasureDistance(false);
      openEditPanel({
        towerId: nextAvailableKey(towerList, 0),
        company: company.name,
        img: pin,
        name: '',
        code: '',
        type: {
          site: 'Monopole',
          tower: 'Monopole',
        },
        location: {
          lat: '',
          lng: '',
        },
        geoCode: '',
        height: minHeight,
        contact: {
          name: '',
          phone: '',
          email: '',
        },
        comments: '',
        dates: {
          inspection: Date.now(),
          licenses: [],
        },
        documents: {
          folders: {
            0: {
              name: 'root',
              folders: [1, 2, 3, 4, 5],
              files: [],
            },
            1: {
              name: 'Drawings',
              folders: [],
              files: [],
            },
            2: {
              name: 'Equipment',
              folders: [],
              files: [],
            },
            3: {
              name: 'Licenses',
              folders: [],
              files: [],
            },
            4: {
              name: 'Reports',
              folders: [],
              files: [],
            },
            5: {
              name: 'Images',
              folders: [],
              files: [],
            },
          },
        },
        layout: {
          tower: {
            legs: {},
            images: {},
            textBoxes: {},
            drawings: {},
          },
          data: {
            shelves: {},
            images: {},
            textBoxes: {},
            drawings: {},
          },
          site: {
            image: null,
            imageGrid: null,
            assets: {},
            images: {},
            textBoxes: {},
            drawings: {},
          },
        },
      });
      setNewSite(true);
      if (minimize) {
        changePanelSize();
      }
    },
    ICON: (): void => {
      setActiveModal((prev: IActiveModal) => ({ ...prev, customIcon: true }));
      setActiveLibrary('');
      if (minimize) {
        changePanelSize();
      }
    },
    LIBRARY: () =>
      setEditLibrary((prev: IEditLibrary) => ({ ...prev, active: true })),
  };

  return (
    <div
      className={buttonClasses}
      onClick={clickHandlers[name]}>
      <div className={classes.container}>
        <i className={iconClasses} />{' '}
        {newSiteLabel && (
          <p className={classes.label}>
            NEW <>{name}</>
          </p>
        )}
      </div>
    </div>
  );
};

export default AddButton;
