import React, { useContext } from 'react';
import { CanvasContext, HeaderContext, SettingsContext } from 'state/context';
import EditSection from './EditSection';
import SectionLine from './SectionLine';
import {
  ICanvasContext,
  IHeaderContext,
  ISettingsContext,
} from 'state/iContext';
import { IGridSection } from 'state/iState';

const SectionBlocks = ({ containers, name, i }) => {
  const { scale } = useContext(SettingsContext) as ISettingsContext;
  const { addItemProps, setAddItemProps } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const { activeCanvasTab } = useContext(HeaderContext) as IHeaderContext;

  const gridItem = {
    tower: ['legs', 'legGrid', scale],
    data: ['shelves', 'shelfGrid', 'U'],
  };

  const container = containers[i];
  if (!container) {
    return (
      <span className='mx-1 font-bold text-stone-400'>No Data Available</span>
    );
  }

  const containerGrid = container[gridItem[activeCanvasTab][1]];
  const containerSections: IGridSection = container.sections;

  if (!containerSections || typeof containerSections !== 'object') {
    return (
      <span className='mx-1 font-bold text-stone-400'>
        Invalid Data Structure
      </span>
    );
  }

  const sectionBlocks =
    Object.keys(containerSections).length > 0 ? (
      Object.entries(containerSections).map(([key, section]) => {
        if (key === '-1') return null;

        const dataFirst = containerGrid.h - section.y - section.h;
        const dataLast = containerGrid.h - section.y;
        const measurement = gridItem[activeCanvasTab][2];
        const data = `${dataFirst}${measurement} - ${dataLast}${measurement}`;

        return (
          <div key={`${name}-${section.name}`}>
            {+addItemProps.content === +section.i &&
            +addItemProps.index! === +containerGrid.i ? (
              <EditSection />
            ) : (
              <SectionLine
                name={section.name}
                data={data}
                handler={() =>
                  setAddItemProps({
                    content: section.i,
                    index: containerGrid.i,
                    label: section.name,
                  })
                }
              />
            )}
          </div>
        );
      })
    ) : (
      <span className='mx-1 font-bold text-stone-400'>
        No Lease Sections To Display
      </span>
    );

  return <>{sectionBlocks}</>;
};

export default SectionBlocks;
