import { CanvasContext, ModalContext, SettingsContext } from 'state/context';
import GridItemOptions from './highlightOptions/GridItemOptions';
import EditContainerPanel from './toolbar/EditContainerPanel';
import EditGridItemPanel from './toolbar/EditGridItemPanel';
import LeasePanel from './toolbar/leaseSections/LeasePanel';
import Resize from 'components/buttons/resizeButton/Resize';
import SearchBar from 'components/search/SearchBar';
import PropertiesPanel from './PropertiesPanel';
import Libraries from './libraries/Libraries';
import React, { useContext } from 'react';
import { IActiveClasses } from 'iApp';
import {
  IModalContext,
  ICanvasContext,
  ISettingsContext,
} from 'state/iContext';
import EditAssetPanel from './toolbar/EditAssetPanel';

const classes: IActiveClasses = {
  active:
    'absolute bottom-0 relative flex flex-col justify-between h-1/3 w-full border border-slate-300 md:h-full md:w-1/3 md:max-w-[400px]',
  inactive:
    'relative flex h-[50px] w-full h-[50px] md:h-full md:w-[50px] border border-t-red-700 border-slate-300 md:border-r-red-700 md:border-t-red-50',
};

const CanvasPanel = () => {
  const { isModalActive } = useContext(ModalContext) as IModalContext;
  const { minimize, setMinimize } = useContext(
    SettingsContext
  ) as ISettingsContext;
  const {
    dragProps,
    addItemProps,
    activeToolBar: { image, text, drawings, edit, sections },
  } = useContext(CanvasContext) as ICanvasContext;

  const activeClass: string = minimize ? classes.inactive : classes.active;
  const isPanelActive: boolean = image || text || drawings || edit || sections;
  const showLibrary: boolean =
    (!dragProps.id || dragProps?.type === 'new') &&
    !minimize &&
    !image &&
    !text &&
    !drawings &&
    !edit &&
    !sections;

  console.log({ dragProps, addItemProps });

  return (
    <div
      id='canvas-panel'
      className={activeClass}
      onDoubleClick={() => setMinimize(false)}>
      <Resize />
      {!isPanelActive &&
        (!addItemProps.edit &&
        dragProps?.type !== 'new' &&
        dragProps.id !== '' ? (
          <>
            <PropertiesPanel />
            <GridItemOptions />
          </>
        ) : (
          <SearchBar disabled={isModalActive} />
        ))}
      {showLibrary && <Libraries />}
      {addItemProps.edit && dragProps.type == 'asset' && <EditAssetPanel />}
      {!minimize && image && <EditGridItemPanel type='images' />}
      {!minimize && text && <EditGridItemPanel type='textBoxes' />}
      {!minimize && drawings && <EditGridItemPanel type='drawings' />}
      {!minimize && sections && <LeasePanel />}
      {!minimize && edit && <EditContainerPanel />}
    </div>
  );
};

export default CanvasPanel;
