import React, { useContext, useState, useEffect, useRef } from 'react';
import { IInputEvent, INumberProps, IStringProps } from 'iApp';
import {
  getFolderPathString,
  getParentFolderIds,
  sortByName,
} from 'helpers/helperFunctions';
import AddLicense from '../files/AddLicense';
import FolderButtons from './FolderButtons';
import UpdateFolder from './UpdateFolder';
import MoveFileFolder from './MoveFileFolder';
import Files from '../files/Files';
import axios from 'axios';
import {
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IServerContext,
  ICompanyContext,
  IHistoryContext,
  IDocumentContext,
} from 'state/iContext';
import {
  UserContext,
  SitesContext,
  CanvasContext,
  ServerContext,
  CompanyContext,
  HistoryContext,
  DocumentContext,
} from 'state/context';
import {
  ITower,
  ITowerList,
  IEditFileProps,
  ITowerDocument,
  IFolders,
} from 'state/iState';

const Folder = ({
  name,
  files,
  folders,
  folderID,
  subIndex,
  onFolderClick,
  parentFolderID,
}) => {
  const { setHistory, fetchHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { domain, uploadFilesToDB, updateDocumentsDB, addHistoryToDB } =
    useContext(ServerContext) as IServerContext;
  const { towerList, setTowerList, updateSitesDB, fetchSiteData } = useContext(
    SitesContext
  ) as ISitesContext;
  const { user, searchBar, searchDate, searchRange, rangeSearchActive } =
    useContext(UserContext) as IUserContext;
  const {
    newDocKey,
    activeSort,
    activeFolder,
    documentList,
    updateFolders,
    fetchDocuments,
    setActiveFolder,
    setDocumentList,
    setEditFileProps,
    setUpdateFolders,
  } = useContext(DocumentContext) as IDocumentContext;

  const [openFolder, setOpenFolder] = useState<boolean>(true);
  const [uploadLicense, setUploadLicense] = useState<boolean>(false);

  const storedFolders: IFolders = towerList[activeTowerID].documents.folders;
  const editable: boolean = user.admin && !towerList[activeTowerID].edit.active;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFolderClick = (e) => {
    if (updateFolders.active) {
      return e.preventDefault();
    }
    if (name !== 'root') {
      setOpenFolder((prev: boolean) => !prev);
      setUpdateFolders({ active: false, parent: '', folder: '', name: '' });
      setActiveFolder(activeFolder === +folderID ? +parentFolderID : +folderID);
    }
  };

  const filteredFiles = files.filter((id) => documentList[id]);
  const fileIds: string[] =
    filteredFiles.length > 1
      ? files
          .filter(
            (id): boolean =>
              documentList[id]?.name
                .toLowerCase()
                .includes(searchBar.toLowerCase().trim()) &&
              (rangeSearchActive && searchDate && searchRange
                ? documentList[id].upload >= Date.parse(searchDate) &&
                  documentList[id].upload <= Date.parse(searchRange)
                : new Date(documentList[id].upload)
                    .toString()
                    .slice(0, 15)
                    .includes(searchDate.slice(0, 15)))
          )
          .sort((a: string, b: string): number => {
            const sort: INumberProps = {
              upload: activeSort.decend ? +b - +a : +a - +b,
              name: activeSort.decend
                ? sortByName(documentList[a].name, documentList[b].name)
                : sortByName(documentList[b].name, documentList[a].name),
            };
            return sort[activeSort.target];
          })
      : filteredFiles;

  const classes: IStringProps = {
    empty:
      'flex w-full border border-stone-400 justify-center font-bold text-stone-400 even:bg-slate-100',
    open: `fa-regular fa-folder-open text-stone-500 mr-1`,
    closed: `fa-solid fa-folder-closed text-stone-600 mr-1`,
    indent: 'pl-2 border-dashed border-l border-l-stone-300',
    container:
      'group flex w-full border border-stone-400 items-center even:bg-slate-100',
    header: 'flex w-full items-center font-semibold text-stone-500',
    remove:
      'hidden fa-solid fa-folder-minus opacity-70 mr-2 text-red-600 hover:opacity-100 group-hover:flex',
  };

  const displayFiles =
    filteredFiles?.length > 0 || folders.length > 0 ? (
      <Files fileIds={fileIds} />
    ) : (
      <span className={classes.empty}>Empty Folder</span>
    );

  const folderPosition = {
    marginLeft: `${(subIndex + 2) * 8}px`,
  };

  const folderIcon: string = openFolder ? classes.open : classes.closed;

  const displayedFolders = Object.keys(storedFolders)
    .filter((folder: string) => folders.includes(+folder))
    .map((id) => ({ ...storedFolders[id], id }));

  const folderElements = Object.keys(displayedFolders).map((id) => (
    <Folder
      key={displayedFolders[id].name}
      name={displayedFolders[id].name}
      folders={displayedFolders[id].folders}
      files={displayedFolders[id].files}
      onFolderClick={onFolderClick}
      subIndex={subIndex + 2}
      folderID={displayedFolders[id].id}
      parentFolderID={folderID}
    />
  ));

  const getChildrenFolderIds = (parentID: number, folders: number[]) => {
    const childrenIds: number[] = [];
    const findChildrenIds = (id: number) => {
      if (folders.length > 0) {
        storedFolders[id].folders.forEach((childId) => {
          childrenIds.push(+childId);
          findChildrenIds(+childId);
        });
      }
    };

    findChildrenIds(parentID);
    return childrenIds;
  };

  const childrenFolderIds = getChildrenFolderIds(
    folderID,
    storedFolders[folderID].folders
  );

  const updateHistory = async (
    action: string,
    newData?: { name?: string; folder?: number },
    prevData?: { name?: string; folder?: number }
  ) => {
    const folderPath = getFolderPathString(+folderID, storedFolders);
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);
    const historyDetail = {
      move: [
        {
          updated: [
            {
              text: `Folder ${newData?.name} Moved`,
            },
            {
              text: `Path: ${folderPath.path}`,
            },
          ],
          before: [{ folder: prevData?.folder }],
          after: [{ folder: newData?.folder }],
          field: `Folder Moved`,
        },
      ],
      rename: [
        {
          before: [{ text: prevData?.name }],
          after: [{ text: newData?.name }],
          updated: [
            {
              text: `Path: ${folderPath.path}`,
            },
          ],
          field: `Folder Renamed`,
        },
      ],
      folder: [
        {
          added: [
            {
              text: `Folder ${newData?.name} Added`,
            },
            {
              folder: newData?.folder,
              currentName:
                towerList[activeTowerID].documents.folders[newData?.folder]
                  .name,
            },
            {
              text: `Path: ${folderPath.path}`,
            },
          ],
          field: `${folderPath.primaryParent} Folder Added`,
        },
      ],
      add: [
        {
          added: [
            {
              text: `File: ${newData?.name}`,
            },
            {
              folder: newData?.folder,
              currentName:
                towerList[activeTowerID].documents.folders[newData?.folder]
                  .name,
            },
            {
              text:
                folderPath.path === folderPath.primaryParent
                  ? ''
                  : `Path: ${folderPath.path}`,
            },
          ],
          field: `${folderPath.primaryParent} File Added`,
        },
      ],
      remove: [
        {
          added: [
            {
              text: `Folder ${newData?.name} Removed`,
            },
          ],
          field: `${folderPath.primaryParent} Folder Removed`,
        },
      ],
    };
    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: `File`,
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: historyDetail[action],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  useEffect(() => {
    if (
      +folderID > 0 &&
      activeFolder !== +folderID &&
      !childrenFolderIds.some((id) => +id === activeFolder)
    ) {
      setOpenFolder(false);
    }
  }, [updateFolders, folderID, childrenFolderIds, name]);

  const buttonHandlers = {
    move: (e) => {
      if (openFolder) {
        e.stopPropagation();
      }
      setUpdateFolders({
        active: true,
        move: true,
        folder: folderID,
        parent: parentFolderID,
        name,
      });
    },
    edit: (e) => {
      if (openFolder) {
        e.stopPropagation();
      }
      setUpdateFolders({
        active: true,
        edit: true,
        folder: folderID,
        parent: parentFolderID,
        name,
      });
    },
    folder: (e) => {
      if (openFolder) {
        e.stopPropagation();
      }
      setUpdateFolders({
        active: true,
        folder: folderID,
        parent: parentFolderID,
        name: '',
      });
    },
    upload: (e) => {
      if (openFolder) {
        e.stopPropagation();
      }
      storedFolders[folderID].datesRequired
        ? setUploadLicense(true)
        : fileInputRef.current!.click();
    },
    build: (e) => {
      setOpenFolder(true);
      e.stopPropagation();
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        type: 'doc',
        id: newDocKey,
        folder: folderID,
      }));
    },
    input: async (e: IInputEvent) => {
      const fetchedDocList = await fetchDocuments();
      const fetchedSiteData = await fetchSiteData(activeTowerID);
      await setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: fetchedSiteData,
      }));
      const date: number = Date.now();
      const file: File = e.target.files![0];
      const newFileName = `tower-${towerList[activeTowerID].name}-${folderID}-file-${file.name}`;
      const newFile = new File([file], newFileName, {
        ...file,
      });

      const docData: ITowerDocument = {
        name: file.name,
        img: `files/media/client/${company.name}/${newFile.name}`,
        upload: date,
        updated: date,
        user: user.userId,
        isPDF: true,
        folder: +folderID,
      };
      await updateDocumentsDB(newDocKey, 'POST', !docData.isPDF, docData);
      await uploadFilesToDB([newFile]);
      await setDocumentList(() => ({
        ...fetchedDocList,
        [newDocKey]: docData,
      }));
      await setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          documents: {
            ...prev[activeTowerID].documents,
            folders: {
              ...prev[activeTowerID].documents.folders,
              [folderID]: {
                ...prev[activeTowerID].documents.folders[folderID],
                files: [
                  ...prev[activeTowerID].documents.folders[folderID].files,
                  `${newDocKey}`,
                ],
              },
            },
          },
        },
      }));

      await updateHistory('add', { name: file.name, folder: folderID });

      await updateSitesDB(activeTowerID);

      setOpenFolder(true);
    },
    remove: async () => {
      const fetchedSiteData = await fetchSiteData(activeTowerID);
      const { [folderID]: toBeRemoved, ...rest } =
        fetchedSiteData.documents.folders;
      setTowerList((prev: ITower) => ({
        ...prev,
        [activeTowerID]: {
          ...fetchedSiteData,
          documents: {
            ...fetchedSiteData.documents,
            folders: {
              ...rest,
              [parentFolderID]: {
                ...fetchedSiteData.documents.folders[parentFolderID],
                folders: fetchedSiteData.documents.folders[
                  parentFolderID
                ].folders.filter((id: string) => +id !== +folderID),
              },
            },
          },
        },
      }));
    },
  };

  return (
    <div
      className={
        openFolder && subIndex > 0 ? classes.indent : 'even:bg-slate-100'
      }>
      <div
        className={classes.container}
        onClick={handleFolderClick}>
        <>
          <i
            className={folderIcon}
            style={folderPosition}
          />
          <div className={classes.header}>
            {name !== 'root' ? name : ''}
            {editable && (
              <>
                {updateFolders.folder === folderID &&
                folderID > 0 &&
                updateFolders.move ? (
                  <MoveFileFolder type='folder' />
                ) : (
                  <FolderButtons
                    name={name}
                    fileInputRef={fileInputRef}
                    handlers={buttonHandlers}
                  />
                )}
              </>
            )}
          </div>
          {filteredFiles.length === 0 &&
            folders.length === 0 &&
            folderID > 4 &&
            editable && (
              <i
                id='remove-folder'
                className={classes.remove}
                onClick={buttonHandlers.remove}
              />
            )}
        </>
      </div>
      {openFolder && (
        <>
          {uploadLicense && (
            <AddLicense
              folderID={folderID}
              close={() => setUploadLicense(false)}
            />
          )}
          {updateFolders.folder === folderID &&
            updateFolders.active &&
            !updateFolders.move && <UpdateFolder />}
          <>{folderElements}</>
          <>{displayFiles}</>
        </>
      )}
    </div>
  );
};

export default Folder;
