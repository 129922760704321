import { measurementConversion, measurementType } from 'helpers/helperFunctions';
import { ISettingsContext } from 'state/iContext';
import { SettingsContext } from 'state/context';
import { IMeasurelength } from 'state/iState';
import React, { ReactNode, useContext} from 'react';

import InfoWindowLine from './InfoWindowLine';
import {
  IInfoWindowLine,
  IMarkerTowerInfo,
  IMarkerTowerProps,
} from './iInfoWindow';

const MarkerTowerInfo = ({
  type,
  height,
  location,
  towerInfoClasses,
  dates
}: IMarkerTowerInfo) => {
  const { metric, measurements } = useContext(SettingsContext) as ISettingsContext;
  const measurement: IMeasurelength = measurementType(metric, measurements);

  // Props to pass to their respective elements
  const props: IMarkerTowerProps = {
    towerType: {
      title: 'Type',
      info: type.tower,
      classes: towerInfoClasses,
    },
    towerHeight: {
      title: 'Height',
      info: `${measurementConversion(metric, height)} ${measurement.short}`,
      classes: towerInfoClasses,
    },
    latitude: {
      title: 'Latitude',
      info: location.lat,
      classes: towerInfoClasses,
    },
    longitude: {
      title: 'Logitude',
      info: location.lng,
      classes: towerInfoClasses,
    },
    inspection: {
      title: 'Inspection Date',
      info: dates.inspection,
      classes: towerInfoClasses,
    },
    // license: {
    //   title: 'License Date',
    //   info: dates.licenses,
    //   classes: towerInfoClasses,
    // }
  };

  // Renders individual tower details
  const infoLines: ReactNode[] = Object.keys(props).map(
    (line: string): ReactNode => {
      const keyObj: IInfoWindowLine = props[line as keyof typeof props];
      return (
        <InfoWindowLine
          key={keyObj.title}
          {...keyObj}
        />
      );
    }
  );

  return (
    <div
      id='info-window-tower-info'
      className='flex flex-col w-[160px] md:w-full h-2/6 bg-zinc-100 px-4 py-2 mt-2 rounded-sm font-bold border border-slate-200 justify-center'>
      {infoLines}
    </div>
  );
};

export default MarkerTowerInfo;
