import React, { useContext } from 'react';
import { IContact } from 'state/iState';
import {
  UserContext,
  SitesContext,
  CanvasContext,
  HeaderContext,
  CompanyContext,
  HistoryContext,
  DocumentContext,
  SettingsContext,
} from 'state/context';
import {
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  ICompanyContext,
  IHistoryContext,
  IDocumentContext,
  ISettingsContext,
} from 'state/iContext';
import {
  formatDateTime,
  formatFieldName,
  measurementConversion,
} from 'helpers/helperFunctions';

const HistoryDetails = ({ onClick, historyId }) => {
  const { documentList } = useContext(DocumentContext) as IDocumentContext;
  const { activeCanvasTab } = useContext(HeaderContext) as IHeaderContext;
  const { icons: assets } = useContext(CompanyContext) as ICompanyContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { history } = useContext(HistoryContext) as IHistoryContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { userList } = useContext(UserContext) as IUserContext;
  const { metric, measurements, scale } = useContext(
    SettingsContext
  ) as ISettingsContext;

  const getFileName = (path) => path.split('/').pop();

  const renderIcon = () => <i className='fa-solid fa-caret-right mr-1' />;

  const renderSiteType = (item) => (
    <div className='flex mt-2 border p-0.5'>
      {renderIcon()}
      <div className='flex flex-col'>
        <span>Site Type: {item.site}</span>
        {item.site !== item.tower && <span>Tower Type: {item.tower}</span>}
      </div>
    </div>
  );

  const renderImage = (image) => (
    <img
      src={image}
      alt={getFileName(image)}
      style={{ maxWidth: '100px' }}
    />
  );

  const renderContact = (before: IContact, after: IContact) => {
    const changedFields: {
      [key: string]: { before: string | number; after: string | number };
    } = {};

    if (before.name !== after.name) {
      changedFields.name = {
        before: before.name || '',
        after: after.name || '',
      };
    }

    if (before.phone !== after.phone) {
      changedFields.phone = {
        before: before.phone || '',
        after: after.phone || '',
      };
    }

    if (before.email !== after.email) {
      changedFields.email = {
        before: before.email || '',
        after: after.email || '',
      };
    }

    return (
      <div>
        {Object.entries(changedFields).map(([key, value]) => (
          <div
            key={key}
            className='border p-0.5 m-0.5'>
            <div className='flex w-full justify-center font-semibold bg-stone-100 mb-1'>
              {formatFieldName(key)}
            </div>
            <div className='flex flex-col mt-2 border p-0.5'>
              <strong>Before:</strong>
              <div className='bg-red-100'>
                {renderIcon()}
                <span>{value.before}</span>
              </div>
            </div>
            <div className='flex flex-col mt-2 border p-0.5'>
              <strong>After:</strong>
              <div className='bg-green-100'>
                {renderIcon()}
                <span>{value.after}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderLocation = (label, item) => (
    <div>
      <div className='flex flex-col mt-2 border p-0.5'>
        <div className='flex w-full justify-center font-semibold bg-stone-100'>
          Latitude:
        </div>
        <div className={`bg-${label === 'Before' ? 'red' : 'green'}-100 mb-1`}>
          {renderIcon()}
          <span>{item.lat}</span>
        </div>
      </div>
      <div className='flex flex-col mt-2 border p-0.5'>
        <div className='flex w-full justify-center font-semibold bg-stone-100'>
          Longitude:
        </div>
        <div className={`bg-${label === 'Before' ? 'red' : 'green'}-100`}>
          {renderIcon()}
          <span>{item.lng}</span>
        </div>
      </div>
    </div>
  );

  const renderInspectionDate = (beforeDate, afterDate) => {
    const renderFormattedDate = (timestamp) => {
      const date = new Date(timestamp);
      return formatDateTime(date);
    };

    return (
      <div className='flex flex-col'>
        <div className='flex w-full justify-center font-semibold bg-stone-100'>
          Inspection Date:
        </div>
        <div>
          <div className='flex flex-col mt-2 border p-0.5'>
            <strong>Before:</strong>
            <div className='bg-red-100'>
              {renderIcon()}
              <span>{renderFormattedDate(beforeDate.inspection)}</span>
            </div>
          </div>
          <div className='flex flex-col mt-2 border p-0.5'>
            <strong>After:</strong>
            <div className='bg-green-100'>
              {renderIcon()}
              <span>{renderFormattedDate(afterDate.inspection)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderObjectItem = (item) => (
    <>
      {renderIcon()}
      <span>{item.label || item.text || item.name}</span>
    </>
  );

  const renderNumberItem = (item, field) => (
    <>
      {renderIcon()}
      {item.toString().length > 7
        ? formatDateTime(item)
        : field === 'locations'
        ? towerList[item]?.name
        : field === 'height'
        ? `${measurementConversion(metric, item)} (${
            metric ? measurements.metric.short : measurements.imperial.short
          })`
        : documentList[item]?.name}
    </>
  );

  const renderStringItem = (item, field) => (
    <>
      {renderIcon()}
      {userList[item]?.name ||
        (field === 'img' ? getFileName(item) : assets[item]?.name || item)}
    </>
  );

  const renderPosition = (item) =>
    item.text ? (
      renderObjectItem(item)
    ) : (
      <div className='flex flex-col'>
        <div className='flex'>
          {(item.botHeight || item.topHeight) && (
            <>
              {renderIcon()}
              <span>
                Position: {measurementConversion(metric, item.botHeight)}
                {scale} - {measurementConversion(metric, item.topHeight)}
                {scale}
              </span>
            </>
          )}
        </div>
        {item.height && (
          <div>
            {renderIcon()}
            <span>
              Height: {measurementConversion(metric, item.height)}
              {scale}
            </span>
          </div>
        )}
      </div>
    );

  const renderDimensions = (item) =>
    item.text ? (
      renderObjectItem(item)
    ) : (
      <div className='flex flex-col'>
        <div>
          {renderIcon()}
          <span>Height: {item.height} Units</span>
        </div>
        <div>
          {renderIcon()}
          <span>Width: {item.height} Units</span>
        </div>
      </div>
    );

  const renderFolders = (item) =>
    item.text ? (
      renderObjectItem(item)
    ) : item.folder ? (
      <div className='flex flex-col'>
        <div>
          {renderIcon()}
          <span>
            Folder:{' '}
            {!!towerList[activeTowerID].documents.folders[item.folder]
              ? towerList[activeTowerID].documents.folders[item.folder].name
              : item.currentName}
          </span>
        </div>
      </div>
    ) : null;

  const renderSingleItem = (item, field) => {
    const targetCanvas = formatFieldName(activeCanvasTab);
    if (field === 'img') {
      return renderImage(item);
    }

    if (field === 'type') {
      return renderSiteType(item);
    }

    if (field === 'Site Asset Resized') {
      return renderDimensions(item);
    }

    if (
      field.includes('File') ||
      field.includes('Drawing') ||
      field.includes('Folder')
    ) {
      return renderFolders(item);
    }

    if (
      field === `${targetCanvas} Asset Added` ||
      field === `${targetCanvas} Asset Moved` ||
      field === `${targetCanvas} Lease Section Moved` ||
      field === `${targetCanvas} Asset Resized` ||
      field === `${targetCanvas} Rack Resized` ||
      field === `${targetCanvas} Lease Section Resized` ||
      field === `${targetCanvas} Asset Renamed` ||
      field === `${targetCanvas} Rack Renamed` ||
      field === `${targetCanvas} Lease Section Renamed` ||
      field === `${targetCanvas} Leg Resized`
    ) {
      return renderPosition(item);
    }

    if (typeof item === 'object') {
      return renderObjectItem(item);
    }

    if (typeof item === 'number') {
      return renderNumberItem(item, field);
    }

    return renderStringItem(item, field);
  };

  const renderArray = (items, field) =>
    items.map((item, index) => (
      <div key={index}>{renderSingleItem(item, field)}</div>
    ));

  const renderDetailAttribute = (label, attribute, field) => (
    <div className='flex flex-col mt-2 border p-0.5'>
      <strong>{label}:</strong>
      <div
        className={
          label === 'Before' || label === 'Removed'
            ? 'bg-red-100'
            : 'bg-green-100'
        }>
        {field === 'location'
          ? renderLocation(label, attribute)
          : Array.isArray(attribute)
          ? renderArray(attribute, field)
          : renderSingleItem(attribute, field)}
      </div>
    </div>
  );

  const renderAddedOrRemovedItems = (label, items, field) =>
    items && (
      <div>
        <strong>{label} Items:</strong>
        <div
          className={
            label === 'Before' || label === 'Removed'
              ? 'bg-red-100'
              : 'bg-green-100'
          }>
          {renderArray(items, field)}
        </div>
      </div>
    );

  const renderDetailItem = (detail, index, field) => (
    <div key={index}>
      {detail.updated &&
        detail.updated.length > 0 &&
        renderDetailAttribute('Updated', detail.updated, field)}
      {detail.before &&
        detail.before.length > 0 &&
        renderDetailAttribute('Before', detail.before, field)}
      {detail.after &&
        detail.after.length > 0 &&
        renderDetailAttribute('After', detail.after, field)}
      {detail.added &&
        detail.added.length > 0 &&
        renderAddedOrRemovedItems('Added', detail.added, field)}
      {detail.removed &&
        detail.removed.length > 0 &&
        renderAddedOrRemovedItems('Removed', detail.removed, field)}
    </div>
  );

  return (
    <div>
      <button
        className='bg-red-600 text-slate-200 px-1 mb-2 rounded'
        onClick={onClick}>
        Back
      </button>
      {Object.entries(history[historyId])
        .filter(([key]) => key !== 'id' && key !== 'siteID')
        .map(([key, value]: [key: string, value: any]) => {
          if (value?.action === 'Created') return;
          return (
            <div
              key={key}
              className='border p-1 my-1'>
              <strong>{formatFieldName(key)}: </strong>
              {typeof value === 'string' || typeof value === 'number' ? (
                <span>
                  {typeof value === 'number'
                    ? formatDateTime(value)
                    : userList[value]?.name || formatFieldName(value)}
                </span>
              ) : (
                <div>
                  <div className='mb-2'>
                    <strong>Field:</strong>{' '}
                    <span>{formatFieldName(value?.details[0]?.field)}</span>
                  </div>
                  {value.details.map((detail, index) =>
                    detail.field === 'contact'
                      ? renderContact(detail.before, detail.after)
                      : detail.field === 'dates'
                      ? renderInspectionDate(detail.before, detail.after)
                      : renderDetailItem(
                          detail,
                          index,
                          value?.details[0]?.field
                        )
                  )}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default HistoryDetails;
