import { IDocumentContext, IUserContext } from 'state/iContext';
import React, { useContext, useEffect, useMemo } from 'react';
import { DocumentContext, UserContext } from 'state/context';
import { IStringProps } from 'iApp';

const DrawingTabs = () => {
  const { activeFile, setActiveFile, documentList, getAllFilesFromFolder } =
    useContext(DocumentContext) as IDocumentContext;
  const { user } = useContext(UserContext) as IUserContext;

  const classes: IStringProps = {
    container:
      'sticky border-b-2 border-b-red-600 bg-slate-200 px-2 top-0 flex p-0 z-50 flex-wrap',
    activeTab:
      'border-2 border-red-600 px-1 font-bold text-red-500 cursor-pointer',
    inactiveTab:
      'border-2 border-slate-200 font-bold text-stone-500 hover:border-red-600 px-1 cursor-pointer hover:text-red-500',
  };

  const drawingKeys = getAllFilesFromFolder('1');
  const filteredKeys = drawingKeys.filter((id) => !!documentList[id]);

  const drawingTabs = useMemo(
    () =>
      filteredKeys.map((key) => (
        <div
          key={key}
          className={
            activeFile === key ? classes.activeTab : classes.inactiveTab
          }
          onClick={() => {
            setActiveFile(key);
          }}>
          {documentList[key]?.name}
        </div>
      )),
    [documentList, activeFile]
  );

  useEffect(
    () => setActiveFile(filteredKeys.length > 0 ? filteredKeys[0] : 'add'),
    []
  );

  return (
    <div className={classes.container}>
      {drawingTabs}
      {user.admin && (
        <div
          className={
            activeFile === 'add' ? classes.activeTab : classes.inactiveTab
          }
          onClick={() => setActiveFile('add')}>
          Add Drawing
        </div>
      )}
    </div>
  );
};

export default DrawingTabs;
