import React, { useContext, useEffect, useState } from 'react';
import { IOpenStates } from 'state/iState';
import { IUserContext, ISitesContext, ICanvasContext } from 'state/iContext';
import { UserContext, SitesContext, CanvasContext } from 'state/context';
import SiteRow from './SiteRow';

const TableDetails = () => {
  const { searchBar, prevSearchBar, setPrevSearchBar } =
    useContext(UserContext) as IUserContext;
  const { towerList, filteredSiteIds, favoriteSites, favoritesTab } =
    useContext(SitesContext) as ISitesContext;
  const { setActiveTowerID } =
    useContext(CanvasContext) as ICanvasContext;
  const siteIds: number[] = favoritesTab ? favoriteSites : filteredSiteIds;

  const createInitialOpenStates = (ids: number[]) =>
    ids.reduce((acc, id) => {
      acc[id] = {};
      if (towerList[id].layout.tower?.legs) {
        Object.keys(towerList[id].layout.tower.legs).forEach((legId) => {
          acc[id][legId] = false;
        });
      }
      if (towerList[id].layout.data?.shelves) {
        Object.keys(towerList[id].layout.data.shelves).forEach((shelfId) => {
          acc[id][shelfId] = false;
        });
      }
      return acc;
    }, {} as Record<string, Record<string, boolean>>);

  // Maintain separate open states for tower assets and data assets
  const [towerOpenStates, setTowerOpenStates] = useState<IOpenStates>(
    createInitialOpenStates(siteIds)
  );
  const [dataOpenStates, setDataOpenStates] = useState<IOpenStates>(
    createInitialOpenStates(siteIds)
  );

  useEffect(() => {
    if (searchBar !== prevSearchBar) {
      setPrevSearchBar(searchBar);

      // Check if the searchBar is empty and reset the open states
      if (searchBar.trim() === '') {
        setTowerOpenStates(createInitialOpenStates(siteIds));
        setDataOpenStates(createInitialOpenStates(siteIds));
      } else {
        // Otherwise, conditionally set the open states based on your criteria
        setTowerOpenStates((prevOpenStates) =>
          siteIds.reduce(
            (acc, id) => {
              acc[id] = {};
              Object.keys(towerList[id].layout.tower.legs).forEach((legId) => {
                acc[id][legId] =
                  towerList[id].layout.tower.legs[legId].legGrid.name
                    .toLowerCase()
                    .includes(searchBar.trim().toLowerCase()) ||
                  Object.keys(
                    towerList[id].layout.tower.legs[legId].icons
                  ).some((assetId) => {
                    const asset =
                      towerList[id].layout.tower.legs[legId].icons[assetId];
                    return asset.name
                      .toLowerCase()
                      .includes(searchBar.trim().toLowerCase());
                  });
              });
              return acc;
            },
            { ...prevOpenStates }
          )
        );

        setDataOpenStates((prevOpenStates) =>
          siteIds.reduce(
            (acc, id) => {
              acc[id] = {};
              Object.keys(towerList[id].layout.data.shelves).forEach(
                (shelfId) => {
                  acc[id][shelfId] =
                    towerList[id].layout.data.shelves[shelfId].shelfGrid.name
                      .toLowerCase()
                      .includes(searchBar.trim().toLowerCase()) ||
                    Object.keys(
                      towerList[id].layout.data.shelves[shelfId].icons
                    ).some((assetId) => {
                      const asset =
                        towerList[id].layout.data.shelves[shelfId].icons[
                          assetId
                        ];
                      return asset.name
                        .toLowerCase()
                        .includes(searchBar.trim().toLowerCase());
                    });
                }
              );
              return acc;
            },
            { ...prevOpenStates }
          )
        );
      }
    }
  }, [searchBar, prevSearchBar, towerList, siteIds]);

  useEffect(() => {
    setActiveTowerID(0);
  }, []);

  return (
    <>
      {siteIds
        .filter((id) => id != null)
        .map((id) => (
          <React.Fragment key={id}>
            <SiteRow
              id={id}
              assetType='tower'
              containerIds={Object.keys(towerList[id].layout.tower?.legs || {})}
              towerOpenStates={towerOpenStates}
              setTowerOpenStates={setTowerOpenStates}
              dataOpenStates={dataOpenStates}
              setDataOpenStates={setDataOpenStates}
            />
            <SiteRow
              id={id}
              assetType='data'
              containerIds={Object.keys(
                towerList[id].layout.data?.shelves || {}
              )}
              towerOpenStates={towerOpenStates}
              setTowerOpenStates={setTowerOpenStates}
              dataOpenStates={dataOpenStates}
              setDataOpenStates={setDataOpenStates}
            />
          </React.Fragment>
        ))}
    </>
  );
};

export default TableDetails;
