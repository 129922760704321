import { ILibrariesList } from '../iManageLibraries';
import LibraryCheckBox from './LibraryCheckBox';
import React, { ReactNode, useMemo } from 'react';

const LibrariesList = ({ libraries, type, handler }: ILibrariesList) => {
  // Renders library list items
  const list: ReactNode[] = useMemo(
    () =>
      libraries.map(
        (id: string): ReactNode => (
          <LibraryCheckBox
            id={id}
            key={id}
            type={type}
            handler={() => handler(id)}
          />
        )
      ),
    [libraries, handler]
  );
  return <>{list}</>;
};

export default LibrariesList;
