import React, { useContext } from 'react';
import { SitesContext } from 'state/context';
import { ISitesContext } from 'state/iContext';

const TableFilters = () => {
  const { inventoryFilters, setInventoryFilters } = useContext(
    SitesContext
  ) as ISitesContext;

  const handleFilterToggle = (columnName: string) => {
    setInventoryFilters((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  return (
    <div>
      <label>
        <input
          type='checkbox'
          checked={inventoryFilters.type}
          onChange={() => handleFilterToggle('type')}
          className='m-2'
        />
        Type
      </label>
      <label>
        <input
          type='checkbox'
          checked={inventoryFilters.code}
          onChange={() => handleFilterToggle('code')}
          className='m-2'
        />
        Code
      </label>
      <label>
        <input
          type='checkbox'
          checked={inventoryFilters.height}
          onChange={() => handleFilterToggle('height')}
          className='m-2'
        />
        Height
      </label>
      <label>
        <input
          type='checkbox'
          checked={inventoryFilters.assets}
          onChange={() => handleFilterToggle('assets')}
          className='m-2'
        />
        Assets
      </label>
    </div>
  );
};

export default TableFilters;
