import ApplicationStateProvider from 'state/ApplicationStateProvider';
import HomeStateProvider from 'state/HomeStateProvider';
import TopStateProvider from 'state/TopStateProvider';
import 'react-datepicker/dist/react-datepicker.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import React from 'react';
import App from 'App';

const root = createRoot(document.getElementById('root')!);

const domain: string = process.env.REACT_APP_AUTH0_DOMAIN!;
const clientID: string = process.env.REACT_APP_AUTH0_CLIENTID!;

root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}>
    <TopStateProvider>
      <HomeStateProvider>
        <ApplicationStateProvider>
          <App />
        </ApplicationStateProvider>
      </HomeStateProvider>
    </TopStateProvider>
  </Auth0Provider>
);
