import { ICustomIconButtons, ICustomIconButtonsProps } from './iCustomIcon';
import { IAsset, IAssetList, ITowerList } from 'state/iState';
import Button from 'components/buttons/Button';
import React, { useContext } from 'react';
import { IStringProps } from 'iApp';
import axios from 'axios';
import {
  IUserContext,
  ICropContext,
  IModalContext,
  IServerContext,
  ILibraryContext,
  ICompanyContext,
  ISitesContext,
  ICanvasContext,
  IHistoryContext,
} from 'state/iContext';
import {
  CropContext,
  UserContext,
  ModalContext,
  ServerContext,
  LibraryContext,
  CompanyContext,
  SitesContext,
  CanvasContext,
  HistoryContext,
} from 'state/context';

const classes: IStringProps = {
  prev: 'absolute bottom-0 left-0 flex w-1/2 m-5',
  save: 'absolute bottom-0 right-0 flex w-1/2 m-5 justify-end text-center items-end',
};

const CustomIconButtons = ({
  stage,
  setStage,
  editIcon,
}: ICustomIconButtons) => {
  const { activeIcon, setActiveIcon, assetNameInvalid } = useContext(
    LibraryContext
  ) as ILibraryContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { isCropActive } = useContext(CropContext) as ICropContext;
  const { setSearchBar } = useContext(UserContext) as IUserContext;
  const { setTowerList } = useContext(SitesContext) as ISitesContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { icons, setIcons } = useContext(CompanyContext) as ICompanyContext;
  const { setHistory, fetchHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { updateAssetsDB, domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { setActiveModal, clearModal, setEditIcon, clearEditIcon } = useContext(
    ModalContext
  ) as IModalContext;

  const updateHistory = async () => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    // Extract relevant properties from editIcon and filter out objects with 'path' key
    const newProperties = editIcon.properties
      .filter((property: any) => !property.path)
      .map((property: any) => ({ name: property.name, value: property.value }));

    // Get the existing properties from the active icon
    const existingIcon = icons[activeIcon];
    const existingProperties = existingIcon.properties
      .filter((property: any) => !property.path)
      .map((property: any) => ({ name: property.name, value: property.value }));

    const addedProperties = newProperties
      .filter(
        (newProp) =>
          !existingProperties.some(
            (existingProp) => existingProp.name === newProp.name
          )
      )
      .map((property: any) => ({
        text: `Property - ${property.name}: ${property.value}`,
      }));

    let updatedProperties = newProperties
      .filter((newProp) =>
        existingProperties.some(
          (existingProp) =>
            existingProp.name === newProp.name &&
            existingProp.value !== newProp.value
        )
      )
      .map((property: any) => ({
        text: `Property - ${property.name}: ${property.value}`,
      }));

    const removedProperties = existingProperties
      .filter(
        (existingProp) =>
          !newProperties.some((newProp) => newProp.name === existingProp.name)
      )
      .map((property: any) => ({
        text: `Removed Property - ${property.name}: ${property.value}`,
      }));

    if (editIcon.edit && editIcon.type === 'new image') {
      updatedProperties.push({ text: `Icon Image Changed` });
    }

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'Icons',
      createdBy: user.userId,
      changes: {
        action: 'Updated',
        details: [
          {
            field: 'Updated Icon',
            added: addedProperties,
            updated: updatedProperties,
            removed: removedProperties,
          },
        ],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    await addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const props: ICustomIconButtonsProps = {
    prev: {
      name: 'PREV',
      handler: () => setStage((prev: number) => --prev),
    },
    cancel: {
      name: 'CANCEL',
      handler: () => {
        setStage(1);
        setSearchBar('');
        setActiveIcon('');
        setActiveModal(clearModal);
        setEditIcon(clearEditIcon);
      },
    },
    next: {
      name: 'NEXT',
      handler: () => {
        setSearchBar('');
        setStage((prev: number) => ++prev);
      },
      disabled: !activeIcon || !editIcon.id || assetNameInvalid,
    },
    save: {
      name: editIcon.edit ? 'SAVE' : 'SAVE TO',
      handler: async () => {
        if (editIcon.edit) {
          await setIcons((prev: IAssetList) => ({
            ...prev,
            [activeIcon]: {
              ...prev[activeIcon],
              name: editIcon.id,
              content: editIcon.content,
              properties: editIcon.properties,
            },
          }));
          const updatedAsset: IAsset = {
            ...icons[activeIcon],
            name: editIcon.id,
            content: editIcon.content,
            properties: editIcon.properties,
          };
          await updateHistory();

          updateAssetsDB('PUT', updatedAsset);
          setStage(1);
          setActiveIcon('');
          setEditIcon(clearEditIcon);
          setActiveModal(clearModal);
        } else {
          setStage(3);
        }
      },
      disabled: assetNameInvalid,
    },
  };

  return (
    <>
      {stage > 1 && (
        <div className={classes.prev}>
          {stage < 3 && <Button {...props.prev} />}
        </div>
      )}
      <div className={classes.save}>
        {!isCropActive && <Button {...props.cancel} />}
        {!isCropActive && stage < 2 && <Button {...props.next} />}
        {stage === 2 && <Button {...props.save} />}
      </div>
    </>
  );
};

export default CustomIconButtons;
