import React, { useState, useContext } from 'react';
import { IInputEvent, ISelectEvent } from 'iApp';
import SaveBox from 'components/buttons/SaveBox';
import EditUserSelect from './EditUserSelect';
import EditUserImage from './EditUserImage';
import EditUserInput from './EditUserInput';
import { IUser } from 'state/iState';
import {
  ICropContext,
  IUserContext,
  IServerContext,
  IHeaderContext,
  ICompanyContext,
} from 'state/iContext';
import {
  CropContext,
  UserContext,
  HeaderContext,
  ServerContext,
  CompanyContext,
} from 'state/context';
import {
  IEditUserProps,
  IEditUserClasses,
  IEditUserSaveProps,
} from './iEditUser';

const EditUser = () => {
  const { user, setUser } =
    useContext(UserContext) as IUserContext;
  const { company } =
    useContext(CompanyContext) as ICompanyContext;
  const { isCropActive, image } =
    useContext(CropContext) as ICropContext;
  const { setActiveHeaderButton, lastActiveView } =
    useContext(HeaderContext) as IHeaderContext;
  const { domain, uploadFilesToDB } =
    useContext(ServerContext) as IServerContext;

  const { phone } = user;

  // Input field states
  const [name, setName] = useState<string>(user.name);
  const [title, setTitle] = useState<string>(user.title);
  const [email, setEmail] = useState<string>(user.email);
  const [primaryNum, setPrimaryNum] = useState<string>(phone.primary);
  const [mobileNum, setMobileNum] = useState<string>(phone.mobile);
  const [extNum, setExtNum] = useState<string>(phone.ext);

  // Props used to save new user info
  const saveProps: IEditUserSaveProps = {
    image,
    name,
    title,
    email,
    phone: {
      primary: primaryNum,
      mobile: mobileNum,
      ext: extNum,
    },
  };

  // Component classes
  const classes: IEditUserClasses = {
    imageBox: 'flex w-full justify-between',
    imageClass:
      'rounded-full min-w-[100px] min-h-[100px] max-w-[100px] max-h-[100px] m-3 object-fill text-center border border-stone-200',
    row: 'flex flex-col sm:flex-row w-full sm:pr-4 md:w-3/5',
    organization: {
      container: 'flex flex-col w-full md:w-3/5 m-0 sm:m-2',
      label: 'font-medium',
      box: 'border border-stone-200 p-1 bg-stone-100 text-stone-400',
    },
    input: {
      container: 'flex flex-col w-full sm:w-1/2 m-0 sm:m-2',
      label: 'font-medium',
      box: 'border border-stone-200 p-1 my-1',
    },
    saveBox:
      'absolute flex -right-2 sm:right-0 bottom-5 w-[150px] justify-around sm:mx-5',
  };

  // Props to pass to their respective elements
  const props: IEditUserProps = {
    image: {
      image: saveProps.image ? saveProps.image : user.image,
    },
    organization: {
      type: 'text',
      value: company.name,
      disabled: true,
      name: 'Organization',
      classes: classes.organization,
    },
    first: {
      type: 'text',
      value: name,
      name: 'First',
      classes: classes.input,
      onChange: (e: IInputEvent) => setName(e.target.value),
    },
    title: {
      value: title,
      name: 'Job Title',
      classes: classes.input,
      onChange: (e: ISelectEvent) => setTitle(e.target.value),
    },
    email: {
      value: email,
      name: 'Email',
      type: 'email',
      classes: classes.input,
      onChange: (e: IInputEvent) => setEmail(e.target.value),
    },
    primaryNum: {
      type: 'tel',
      name: 'Primary',
      value: primaryNum,
      classes: classes.input,
      onChange: (e: IInputEvent) => setPrimaryNum(e.target.value),
    },
    extNum: {
      name: 'Ext',
      type: 'tel',
      value: extNum,
      classes: classes.input,
      onChange: (e: IInputEvent) => setExtNum(e.target.value),
    },
    mobileNum: {
      type: 'tel',
      name: 'Mobile',
      value: mobileNum,
      classes: classes.input,
      onChange: (e: IInputEvent) => setMobileNum(e.target.value),
    },
    saveBox: {
      classes: classes.saveBox,
      clickHandlers: {
        save: async () => {
          if (user.file) {
            await uploadFilesToDB([user.file!]);
          }
          setUser((prev: IUser) => {
            const { file, ...rest } = prev;
            return {
              ...rest,
              ...saveProps,
              image: user.file!
                ? `files/media/client/${company.name}/${user.file!.name}`
                : prev.image,
            };
          });
          setActiveHeaderButton(lastActiveView);
        },
        cancel: () => setActiveHeaderButton(lastActiveView),
      },
    },
  };

  return (
    <>
      <div className={classes.imageBox}>
        <EditUserImage {...props.image} />
        <img
          src={`${domain}files/static/client/${company.name}/logo-small.png`}
          alt='company logo'
          className={classes.imageClass}
        />
      </div>
      <EditUserInput {...props.organization} />
      <div className={classes.row}>
        <EditUserInput {...props.first} />
      </div>
      <EditUserSelect {...props.title} />
      <EditUserInput {...props.email} />
      <div className={classes.row}>
        <EditUserInput {...props.primaryNum} />
        <EditUserInput {...props.extNum} />
      </div>
      <div className={classes.row}>
        <EditUserInput {...props.mobileNum} />
      </div>
      {!isCropActive && <SaveBox {...props.saveBox} />}
    </>
  );
};

export default EditUser;
