import { IMapContext } from 'state/iContext';
import { MapContext } from 'state/context';
import React, { useContext} from 'react';


const LocateMe = () => {
  const { getUserLocation } = useContext(MapContext) as IMapContext;

  return (
    <i
      className='fa-solid fa-location-crosshairs text-lg m-2 border border-red-200 rounded-full w-[30px] h-[30px] text-center bg-slate-100 cursor-pointer transition ease-in-out delay-100 hover:bg-red-700 hover:text-slate-200 hover:border-stone-300'
      onClick={() => getUserLocation()}
    />
  );
};

export default LocateMe;