import { IEditContext, IHeaderContext, ISettingsContext } from 'state/iContext';
import { EditContext, HeaderContext, SettingsContext } from 'state/context';
import React, { useContext, useEffect } from 'react';
import SitesInventory from './siteInventory/SitesInventory';
import SitesPanel from './sitesPanel/SitesPanel';
import SiteEditPanel from './editPanel/SiteEditPanel';
import { IHomeClasses } from './iHome';
import Map from './map/Map';

const Home = () => {
  const { setIsSettingsOpen } =
    useContext(SettingsContext) as ISettingsContext;
  const {
    clearActiveButtons,
    activeHeaderButton: { map, inventory },
  } = useContext(HeaderContext) as IHeaderContext;
  const { isEditPanelOpen } =
    useContext(EditContext) as IEditContext;

  // Component classes
  const classes: IHomeClasses = {
    main: 'w-full h-full flex flex-col',
    secondary: 'relative w-full h-full flex flex-col-reverse md:flex-row',
  };

  // Closes header dropdown windows
  const mouseDownHandler = (): void => {
    clearActiveButtons();
    setIsSettingsOpen(false);
  };

  return (
    <div
      id='home'
      data-testid='home'
      className={classes.main}>
      <div
        id='site-map-container'
        className={classes.secondary}
        onMouseDown={mouseDownHandler}>
        <SitesPanel />
        {isEditPanelOpen && <SiteEditPanel />}
        {map && <Map />}
        {inventory && <SitesInventory />}
      </div>
    </div>
  );
};

export default Home;