import { IAddPanelProps, IAddPanelSaveHandlers } from '../iCanvasPanel';
import { IInputEvent, INumberProps, IStringProps } from 'iApp';
import { formatFieldName, nextAvailableKey } from 'helpers/helperFunctions';
import AddTextBox from '../addGridItem/AddTextBox';
import SaveBox from 'components/buttons/SaveBox';
import AddImage from '../addGridItem/AddImage';
import AddLink from '../addGridItem/AddLink';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import EditContainerPanel from './EditContainerPanel';
import axios from 'axios';
import {
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  IServerContext,
  ICompanyContext,
  IUserContext,
  IHistoryContext,
  IDocumentContext,
} from 'state/iContext';
import {
  SitesContext,
  CanvasContext,
  HeaderContext,
  ServerContext,
  CompanyContext,
  UserContext,
  HistoryContext,
  DocumentContext,
} from 'state/context';
import {
  ITowerList,
  IGridLayout,
  IAddItemProps,
  ICanvasLayoutSite,
  ICanvasLayoutTower,
  ICanvasLayoutData,
} from 'state/iState';

const placeholder: IStringProps = {
  images: 'Image Box Label',
  textBoxes: 'Text Box Label',
  drawings: 'Drawing Reference Label',
};

const classes: IStringProps = {
  container:
    'relative flex flex-col h-1/2 m-2 p-2 border rounded rounded-md bg-slate-100',
  title: 'font-bold text-lg text-stone-400',
  labelContainer: 'flex flex-col justify-center m-1',
  label: 'ml-1 font-bold text-stone-500',
  input: 'rounded rounded-md',
  alert: 'text-red-600 font-bold',
  saveBox:
    'absolute flex -right-2 sm:right-0 bottom-5 w-[150px] justify-around sm:mx-5',
};

const EditGridItemPanel = ({ type }: IStringProps) => {
  const { setTowerList, towerList } = useContext(SitesContext) as ISitesContext;
  const { documentList } = useContext(DocumentContext) as IDocumentContext;
  const { activeCanvasTab } = useContext(HeaderContext) as IHeaderContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { domain, uploadFilesToDB, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const {
    clearPanel,
    addItemProps,
    setDragProps,
    activeTowerID,
    clearDragProps,
    setAddItemProps,
  } = useContext(CanvasContext) as ICanvasContext;

  const [originalLabel, setOriginalLabel] = useState<string>('');

  useEffect(() => setOriginalLabel(addItemProps.label), []);

  const addingLink: boolean = type === 'drawings';

  const activeLayouts:
    | ICanvasLayoutSite
    | ICanvasLayoutTower
    | ICanvasLayoutData = towerList[activeTowerID].layout[activeCanvasTab];

  const labelList: IGridLayout[] = useMemo(
    () => [
      ...('legs' in activeLayouts
        ? Object.values(activeLayouts.legs).map((leg) => leg.legGrid)
        : []),
      ...('shelves' in activeLayouts
        ? Object.values(activeLayouts.shelves).map((shelf) => shelf.shelfGrid)
        : []),
      ...Object.values(activeLayouts.textBoxes),
      ...Object.values(activeLayouts.images),
    ],
    [activeLayouts]
  );

  const nextKey: INumberProps = {
    images: nextAvailableKey(activeLayouts.images, 1000)!,
    textBoxes: nextAvailableKey(activeLayouts.textBoxes, 2000)!,
    drawings: nextAvailableKey(activeLayouts.drawings, 3000)!,
  };

  const saveGridItem = {
    images: {
      edit: {
        name: addItemProps.label.trim(),
      },
      save: {
        img: `files/media/client/${company.name}/${addItemProps.file?.name}`,
      },
    },
    textBoxes: {
      edit: {
        name: addItemProps.label.trim(),
        text: addItemProps.content?.toString().trim(),
      },
      save: {
        text: addItemProps.content?.toString().trim(),
      },
    },
    drawings: {
      edit: {
        name: addItemProps.label.trim(),
        refID: addItemProps.content?.toString().trim(),
      },
      save: {
        refID: addItemProps.content?.toString().trim(),
      },
    },
  };

  const isLabelUnique = (label: string, labelList: IGridLayout[]): boolean => {
    if (label.trim() === originalLabel) {
      return true;
    }

    // Check if the label is unique among other items.
    return !labelList
      .filter((item) => item && item.name !== originalLabel)
      .some(
        (item) => item.name?.trim().toLowerCase() === label.trim().toLowerCase()
      );
  };

  const updateHistory = async (action: string) => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    const historyDetail = {
      images: [
        {
          added: [
            {
              text: `${addItemProps.label}`,
            },
          ],
          field: `${
            activeCanvasTab === 'tower' ? 'Tower' : 'Data'
          } Image Added`,
        },
      ],
      textBoxes: [
        {
          added: [
            {
              text: `Label: ${addItemProps.label}`,
            },
            {
              text: `Content: ${addItemProps.content}`,
            },
          ],
          field: `${
            activeCanvasTab === 'tower' ? 'Tower' : 'Data'
          } Text Box Added`,
        },
      ],
      drawings: [
        {
          added: [
            {
              text: `Label: ${addItemProps.label}`,
            },
            {
              text: `File Referenced: ${
                documentList[addItemProps?.content]?.name
              }`,
            },
          ],
          field: `${
            activeCanvasTab === 'tower' ? 'Tower' : 'Data'
          } Drawing Link Added`,
        },
      ],
    };

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'Tower Canvas',
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: historyDetail[action],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    await addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const stateSaveHandlers: IAddPanelSaveHandlers = {
    edit: (prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        height: prev[activeTowerID].height,
        layout: {
          ...prev[activeTowerID].layout,
          [activeCanvasTab]: {
            ...prev[activeTowerID].layout[activeCanvasTab],
            [type]: {
              ...prev[activeTowerID].layout[activeCanvasTab][type],
              [addItemProps.index!]: {
                ...prev[activeTowerID].layout[activeCanvasTab][type]![
                  addItemProps.index!
                ],
                ...saveGridItem[type].edit,
              },
            },
          },
        },
      },
    }),
    save: (prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        height: prev[activeTowerID].height,
        layout: {
          ...prev[activeTowerID].layout,
          [activeCanvasTab]: {
            ...prev[activeTowerID].layout[activeCanvasTab],
            [type]: {
              ...prev[activeTowerID].layout[activeCanvasTab][type]!,
              [nextKey[type]]: {
                i: nextKey[type],
                name: addItemProps.label.trim(),
                x: 1140,
                y: 515,
                w: addingLink ? 10 : 20,
                h: addingLink ? 5 : 20,
                maxH: 30,
                maxW: 30,
                minH: addingLink ? 5 : 10,
                minW: addingLink ? 5 : 10,
                resizeHandles: ['se'],
                ...saveGridItem[type].save,
              },
            },
          },
        },
      },
    }),
  };

  const props: IAddPanelProps = {
    input: {
      type: 'text',
      name: 'label',
      id: 'input-label',
      className: classes.input,
      defaultValue: addItemProps.label,
      placeholder: placeholder[type],
      onChange: (e: IInputEvent) =>
        setAddItemProps((prev: IAddItemProps) => ({
          ...prev,
          label: e.target.value,
        })),
    },
    saveBox: {
      classes: classes.saveBox,
      clickHandlers: {
        save: async () => {
          if (type === 'images') {
            await uploadFilesToDB([addItemProps.file!]);
          }
          await setTowerList(
            addItemProps.edit ? stateSaveHandlers.edit : stateSaveHandlers.save
          );
          updateHistory(type);
          clearPanel();
          setDragProps(clearDragProps);
        },
        cancel: () => {
          clearPanel();
          setDragProps(clearDragProps);
        },
      },
      disabled:
        !isLabelUnique(addItemProps.label, labelList) ||
        !addItemProps.label.trim() ||
        !addItemProps.content,
    },
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        {addItemProps.edit ? 'Edit' : 'Add'}{' '}
        {type[0].toUpperCase() + type.slice(1)}{' '}
        {addingLink && <span>Link</span>}
      </h1>
      <div className={classes.labelContainer}>
        <label
          htmlFor='label'
          className={classes.label}>
          Label:
        </label>
        <input {...props.input} />
        {!isLabelUnique(addItemProps.label, labelList) && (
          <span className={classes.alert}>Please choose a unique label</span>
        )}
      </div>
      {type === 'textBoxes' && <AddTextBox />}
      {type === 'images' && <AddImage />}
      {addingLink && <AddLink />}
      {type === 'edit' && <EditContainerPanel />}
      <SaveBox {...props.saveBox} />
    </div>
  );
};

export default EditGridItemPanel;
