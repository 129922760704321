import { DocumentContext, CanvasContext, HeaderContext, ServerContext, SitesContext, UserContext } from 'state/context';
import React, { useContext, useEffect, ReactNode } from 'react';
import { IAddItemProps, ITowerList } from 'state/iState';
import { IInputEvent } from 'iApp';
import {
  ICanvasContext,
  IHeaderContext,
  IDocumentContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';

const AddLink = () => {
  const { activeTowerID, addItemProps, setAddItemProps, setActiveToolBar } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const { setActiveHeaderButton } = useContext(HeaderContext) as IHeaderContext;
  const { setTowerList } = useContext(SitesContext) as ISitesContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { setActiveFile, documentList, getAllFilesFromFolder } = useContext(
    DocumentContext
  ) as IDocumentContext;
  const { acquireCanvasLock, releaseCanvasLock, checkCanvasLock } = useContext(
    ServerContext
  ) as IServerContext;

  const drawings: string[] = getAllFilesFromFolder('1');

  const options: ReactNode[] = drawings.map(
    (doc): ReactNode => (
      <option
        key={doc}
        value={doc}>
        {documentList[doc].name}
      </option>
    )
  );

  useEffect(() => {
    if (!addItemProps.content) {
      setAddItemProps((prev: IAddItemProps) => ({
        ...prev,
        content: drawings[0],
      }));
    }
  }, []);

  return (
    <>
      <button
        className='flex w-1/2 self-center rounded-md px-3 border border-blue-700 text-sm text-blue-700 justify-center transition ease-in-out delay-100 hover:bg-blue-100 disabled:text-gray-400 disabled:border-gray-400 disabled:bg-stone-200'
        onClick={async() => {
          await releaseCanvasLock(activeTowerID, user.userId);
          await setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              edit: {
                active: false,
                user: null,
                time: null,
              },
            },
          }));
          setActiveToolBar({
            select: true,
            image: false,
            text: false,
            drawings: false,
            sections: false,
            edit: false,
          });
          setActiveFile('add');
          setActiveHeaderButton({
            siteTypes: false,
            map: false,
            inventory: false,
            account: false,
            settings: false,
            support: false,
            tower: false,
            site: false,
            data: false,
            drawings: true,
            files: false,
            contacts: false,
            'work orders': false,
          });
        }}>
        Add Drawing
      </button>
      <select
        name='drawings'
        id='drawing-link'
        value={addItemProps.content || 0}
        className='m-1 rounded rounded-md'
        onChange={(e: IInputEvent) =>
          setAddItemProps((prev: IAddItemProps) => ({
            ...prev,
            content: e.target.value,
          }))
        }>
        {drawings.length > 0 ? (
          options
        ) : (
          <option
            value='none'
            key='no files'>
            No Files to Reference
          </option>
        )}
      </select>
    </>
  );
};

export default AddLink;
