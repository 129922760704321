import React, { useContext } from 'react';
import { IWorkOrderFile } from '../iWorkOrders';
import {
  IDocumentContext,
  IServerContext,
  IWorkOrderContext,
} from 'state/iContext';
import {
  DocumentContext,
  ServerContext,
  WorkOrderContext,
} from 'state/context';
import { IStringProps } from 'iApp';

const UploadedFiles = () => {
  const { workOrderStates, setWorkOrderStates } = useContext(
    WorkOrderContext
  ) as IWorkOrderContext;
  const { documentList } = useContext(DocumentContext) as IDocumentContext;
  const { domain } = useContext(ServerContext) as IServerContext;

  const getObjectURL = (file: IWorkOrderFile | File): string => {
    if ('path' in file && file.path) {
      return file.path;
    } else {
      return URL.createObjectURL(file as File);
    }
  };

  const handleRemoveImage = (
    index: number,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setWorkOrderStates((prev) => ({
      ...prev,
      fileList: prev.fileList.filter((_, i) => i !== index),
    }));
  };

  const classes: IStringProps = {
    header: 'text-2xl font-bold mb-4 ',
    mb4: 'mb-4',
    dropdowns: 'flex w-full justify-around mb-4 ',
    rows: 'flex items-center ',
    label: 'block mx-1 text-sm font-medium text-gray-700 ',
    input:
      'my-1 p-1 w-full rounded border-gray-300 focus:outline-none focus:ring focus:border-blue-300 ',
    image: 'w-24 h-24 object-cover rounded ',
    images: 'mt-1 flex space-x-4 ',
    remove:
      'absolute top-0 right-0 fa-solid fa-square-xmark text-red-600 hover:text-red-400',
    checklist: 'text-stone-400 text-xs mx-2 ',
    ul: 'mt-2 list-disc list-inside ',
    buttons: 'flex w-full justify-center',
    button: 'text-white px-4 py-2 mx-1 rounded ',
  };

  return (
    <div className={classes.mb4}>
      <label className={classes.label}>Uploaded Files:</label>
      <div className={classes.images}>
        {workOrderStates.fileList.map((file, index) => {
          const isPdf =
            typeof file === 'number' &&
            documentList[file]?.img?.endsWith('.pdf');
          return (
            <div
              key={index}
              className='relative items-center justify-center'>
              <button
                onClick={(e) => handleRemoveImage(index, e)}
                className={classes.remove}
              />
              <div className='flex flex-col items-center'>
                <img
                  src={
                    typeof file !== 'number' || isPdf
                      ? 'https://th.bing.com/th/id/OIP.vKzqPySUUpMsfieVdgmsqAAAAA?rs=1&pid=ImgDetMain'
                      : `${domain}${documentList[file as number].img}`
                  }
                  alt={
                    typeof file !== 'number'
                      ? file.name
                      : `${documentList[file].name}`
                  }
                  className='w-24 h-24 object-cover rounded'
                />
                <span className='text-[10px]'>{(file as File).name}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UploadedFiles;
