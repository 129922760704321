import WorkOrderButton from './WorkOrderButton';
import React, { useContext } from 'react';
import {
  UserContext,
  ServerContext,
  HistoryContext,
  DocumentContext,
  WorkOrderContext,
} from 'state/context';
import {
  IUserContext,
  IServerContext,
  IHistoryContext,
  IDocumentContext,
  IWorkOrderContext,
} from 'state/iContext';
import axios from 'axios';

const WorkOrderActions = ({
  onClose,
  handleTabClick,
  toggleHistoryVisibility,
  activeTab,
  historyVisible,
  Tabs,
}) => {
  const { user } = useContext(UserContext) as IUserContext;
  const { setHistory, fetchHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { activeFile } = useContext(DocumentContext) as IDocumentContext;
  const { domain, updateWorkOrdersDB, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { workOrders, setWorkOrders, fetchWorkOrder } = useContext(
    WorkOrderContext
  ) as IWorkOrderContext;

  if (typeof activeFile !== 'number') {
    return null;
  }

  const allChecklistCompleted =
    workOrders[activeFile].checklist?.every(
      (item) => item.completed || item.completionDate
    ) || false;

  const createHistoryEntry = async (type, userId, changes) => {
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);
    return {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type,
      createdBy: userId,
      changes,
    };
  };

  const updateWorkOrder = (workOrder, newValues) => ({
    ...workOrder,
    ...newValues,
    lastModified: Date.now(),
    lastModifiedBy: user.userId,
  });

  const handleConfirmClick = async () => {
    const fetchedHistory = await fetchHistory();
    const latestWorkOrder = await fetchWorkOrder(activeFile);
    if (!latestWorkOrder) {
      console.error('Failed to fetch the latest work order data');
      return;
    }

    const historyEntry = await createHistoryEntry('WorkOrder', user.userId, {
      action: 'Updated',
      details: [
        { field: 'CONFIRMED', updated: `${user.name} confirmed assignment` },
      ],
    });
    setHistory({ ...fetchedHistory, [historyEntry.id]: historyEntry });
    addHistoryToDB(historyEntry);

    const updatedConfirmed = {
      ...latestWorkOrder.confirmed,
      [user.userId]: true,
    };
    const progress = Object.values(updatedConfirmed).every((value) => value)
      ? 'In Progress'
      : latestWorkOrder.progress;

    const updatedWorkOrder = updateWorkOrder(latestWorkOrder, {
      confirmed: updatedConfirmed,
      progress,
      history: [...latestWorkOrder.history, historyEntry.id],
    });

    setWorkOrders((prev) => ({
      ...prev,
      [activeFile]: updatedWorkOrder,
    }));

    updateWorkOrdersDB('PUT', updatedWorkOrder);
  };

  const handleStatusChange = (status, action) => async () => {
    const fetchedHistory = await fetchHistory();
    const latestWorkOrder = await fetchWorkOrder(activeFile);
    if (!latestWorkOrder) {
      console.error('Failed to fetch the latest work order data');
      return;
    }

    const historyEntry = await createHistoryEntry('WorkOrder', user.userId, {
      action,
      details: [{ field: status }],
    });
    setHistory({ ...fetchedHistory, [historyEntry.id]: historyEntry });
    addHistoryToDB(historyEntry);

    const updatedWorkOrder = updateWorkOrder(latestWorkOrder, {
      progress: status,
      history: [...latestWorkOrder.history, historyEntry.id],
    });

    setWorkOrders((prev) => ({
      ...prev,
      [activeFile]: updatedWorkOrder,
    }));

    updateWorkOrdersDB('PUT', updatedWorkOrder);
  };

  return (
    <div className='flex w-full justify-between'>
      <div>
        <WorkOrderButton onClick={onClose}>Back</WorkOrderButton>
        {workOrders[activeFile].confirmed?.[user.userId] === false && (
          <WorkOrderButton onClick={handleConfirmClick}>
            Confirm
          </WorkOrderButton>
        )}
        {user.admin &&
          ['In Progress', 'Assigned'].includes(
            workOrders[activeFile].progress
          ) && (
            <>
              <WorkOrderButton onClick={() => handleTabClick(Tabs.EDIT)}>
                Edit
              </WorkOrderButton>
              <WorkOrderButton
                onClick={handleStatusChange('On Hold', 'ON HOLD')}>
                Put on Hold
              </WorkOrderButton>
            </>
          )}
        {user.admin && workOrders[activeFile].progress === 'On Hold' && (
          <WorkOrderButton
            onClick={handleStatusChange('In Progress', 'REACTIVATE')}>
            Reactivate
          </WorkOrderButton>
        )}
        {user.admin &&
          ['In Progress', 'Assigned', 'On Hold'].includes(
            workOrders[activeFile].progress
          ) && (
            <WorkOrderButton
              onClick={handleStatusChange('Cancelled', 'CANCELLED')}>
              Cancel Work Order
            </WorkOrderButton>
          )}
        {(user.admin || allChecklistCompleted) &&
          workOrders[activeFile].progress === 'In Progress' && (
            <WorkOrderButton
              onClick={handleStatusChange('Completed', 'COMPLETED')}>
              Completed
            </WorkOrderButton>
          )}
      </div>
      {activeTab !== Tabs.FILES && (
        <WorkOrderButton onClick={toggleHistoryVisibility}>
          {historyVisible ? 'Hide History' : 'Show History'}
        </WorkOrderButton>
      )}
    </div>
  );
};

export default WorkOrderActions;
