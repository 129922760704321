import React, { useState, useContext, useMemo, useEffect } from 'react';
import { IAddItemProps, IIconProperty, ITowerList } from 'state/iState';
import { formatFieldName } from 'helpers/helperFunctions';
import { IStringProps } from 'iApp';
import axios from 'axios';
import {
  ICanvasContext,
  IHeaderContext,
  IHistoryContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  CanvasContext,
  HeaderContext,
  HistoryContext,
  ServerContext,
  SitesContext,
  UserContext,
} from 'state/context';

const classes: IStringProps = {
  container:
    'relative flex flex-col h-full m-2 p-2 border rounded rounded-md bg-slate-100 overflow-y-auto',
  title: 'font-bold text-lg text-stone-400',
  propertyContainer: 'flex w-full border p-0.5 rounded',
  propertyName: 'w-full font-semibold',
  propertyValue: 'w-full text-center',
  propertyInput: 'w-full border p-1',
  targetCard:
    'flex flex-col w-full bg-stone-300 text-center border border-stone-700 rounded',
  editButtonsContainer: 'ml-4 flex',
  globalPropertiesContainer: 'flex flex-col w-full',
  addPropertyActive: 'flex flex-col w-full border p-0.5 rounded items-center',
  addPropertyInActive:
    'flex w-full justify-center items-center m-2 hover:font-bold',
  addPropertyTitle: 'font-sm mr-2 underline',
  addPropertyButtons: 'flex w-1/2 justify-between p-2',
  buttonCheck:
    'text-green-600 fa-solid fa-check self-center transition ease-in-out delay-100 hover: text-green-900',
  buttonCancel:
    'text-red-600 ml-2 fa-solid fa-xmark self-center transition ease-in-out delay-100 hover: text-red-900',
  buttonAdd:
    'text-green-600 ml-2 fa-solid fa-square-plus self-center transition ease-in-out delay-100 hover: text-green-900',
  buttonEdit:
    'text-blue-600 fa-solid fa-pen-to-square self-center transition ease-in-out delay-100 hover: text-blue-900',
  buttonDelete:
    'text-red-600 ml-2 fa-solid fa-trash self-center transition ease-in-out delay-100 hover: text-red-900',
  closeIcon:
    'fa-solid fa-xmark self-end text-stone-400 hover:text-red-600 transition ease-in-out delay-100',
  column: 'flex flex-col w-full',
  bold: 'font-bold',
  semibold: 'font-semibold',
  half: 'w-1/2',
};

const EditAssetPanel = () => {
  const {
    dragProps,
    setDragProps,
    addItemProps,
    setAddItemProps,
    clearAddProps,
    clearDragProps,
    activeTowerID,
  } = useContext(CanvasContext) as ICanvasContext;
  const { domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { towerList, setTowerList } = useContext(SitesContext) as ISitesContext;
  const { activeCanvasTab } = useContext(HeaderContext) as IHeaderContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editValue, setEditValue] = useState<string>('');
  const [addProp, setAddProp] = useState<boolean>(false);

  const activeGrid = {
    data: 'shelves',
    tower: 'legs',
  };

  useEffect(() => {
    setEditIndex(null);
    setEditValue('');
    setAddItemProps((prev: IAddItemProps) => ({ ...prev, label: '' }));
    setAddProp(false);
  }, []);

  const targetContainer =
    towerList[activeTowerID].layout[activeCanvasTab][
      activeGrid[activeCanvasTab]
    ][+dragProps.target!][activeCanvasTab === 'tower' ? 'legGrid' : 'shelfGrid']
      ?.name;
  const targetAsset =
    towerList[activeTowerID].layout[activeCanvasTab][
      activeGrid[activeCanvasTab]
    ][addItemProps.index].icons[dragProps.id];

  const globalProperties: IIconProperty[] = targetAsset?.properties || [];
  const uniqueProperties: IIconProperty[] = targetAsset?.uniqueProperties || [];
  const updateHistory = async (change: string, index?: number) => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);
    console.log({ change, targetAsset, targetContainer, index });

    const historyDetail = {
      added: [
        {
          updated: [
            {
              text: `Added on ${targetContainer}`,
            },
          ],
          added: [
            {
              text: `Property: ${addItemProps.label}`,
            },
            {
              text: `Value: ${addItemProps.content}`,
            },
          ],
          field: `${formatFieldName(activeCanvasTab)} Asset Property Added`,
        },
      ],
      edited: [
        {
          updated: [
            {
              text: `Edited on ${targetContainer}`,
            },
          ],
          before: [
            {
              text: `Property: ${
                targetAsset?.uniqueProperties?.[index!]?.name
              }`,
            },
            {
              text: `Value: ${targetAsset?.uniqueProperties?.[index!]?.value}`,
            },
          ],
          after: [
            {
              text: `Value: ${editValue}`,
            },
          ],
          field: `${formatFieldName(activeCanvasTab)} Asset Properties Updated`,
        },
      ],
      removed: [
        {
          updated: [
            {
              text: `Removed on ${targetContainer}`,
            },
          ],
          removed: [
            {
              text: `Property: ${
                targetAsset?.uniqueProperties?.[index!]?.name
              }`,
            },
            {
              text: `Value: ${targetAsset?.uniqueProperties?.[index!]?.value}`,
            },
          ],
          field: `${formatFieldName(activeCanvasTab)} Asset Property Removed`,
        },
      ],
    };

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: `${formatFieldName(activeCanvasTab)} Canvas`,
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: historyDetail[change],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const saveHandler = {
    add: async () => {
      const newProperty: IIconProperty = {
        name: addItemProps.label.trim(),
        value: addItemProps.content.trim(),
      };

      const updatedProperties = [
        ...(targetAsset?.uniqueProperties || []),
        newProperty,
      ];

      await updateHistory('added');
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [activeCanvasTab]: {
              ...prev[activeTowerID].layout[activeCanvasTab],
              [activeGrid[activeCanvasTab]]: {
                ...prev[activeTowerID].layout[activeCanvasTab][
                  activeGrid[activeCanvasTab]
                ],
                [addItemProps.index!]: {
                  ...prev[activeTowerID].layout[activeCanvasTab][
                    activeGrid[activeCanvasTab]
                  ][addItemProps.index],
                  icons: {
                    ...prev[activeTowerID].layout[activeCanvasTab][
                      activeGrid[activeCanvasTab]
                    ][addItemProps.index].icons,
                    [dragProps.id]: {
                      ...prev[activeTowerID].layout[activeCanvasTab][
                        activeGrid[activeCanvasTab]
                      ][addItemProps.index].icons[dragProps.id],
                      uniqueProperties: updatedProperties,
                    },
                  },
                },
              },
            },
          },
        },
      }));

      setAddProp(false);
      setAddItemProps((prev: IAddItemProps) => ({
        ...prev,
        content: '',
        label: '',
      }));
    },
    edit: async (index: number) => {
      const updatedProperties = [...targetAsset.uniqueProperties];

      updatedProperties[index] = {
        ...updatedProperties[index],
        value: editValue.trim(),
      };

      await updateHistory('edited');
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [activeCanvasTab]: {
              ...prev[activeTowerID].layout[activeCanvasTab],
              [activeGrid[activeCanvasTab]]: {
                ...prev[activeTowerID].layout[activeCanvasTab][
                  activeGrid[activeCanvasTab]
                ],
                [addItemProps.index!]: {
                  ...prev[activeTowerID].layout[activeCanvasTab][
                    activeGrid[activeCanvasTab]
                  ][addItemProps.index],
                  icons: {
                    ...prev[activeTowerID].layout[activeCanvasTab][
                      activeGrid[activeCanvasTab]
                    ][addItemProps.index].icons,
                    [dragProps.id]: {
                      ...prev[activeTowerID].layout[activeCanvasTab][
                        activeGrid[activeCanvasTab]
                      ][addItemProps.index].icons[dragProps.id],
                      uniqueProperties: updatedProperties,
                    },
                  },
                },
              },
            },
          },
        },
      }));

      setEditIndex(null);
    },
    remove: async (index: number) => {
      const updatedProperties = [...targetAsset.uniqueProperties];
      updatedProperties.splice(index, 1);

      await updateHistory('removed', index);

      await setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [activeCanvasTab]: {
              ...prev[activeTowerID].layout[activeCanvasTab],
              [activeGrid[activeCanvasTab]]: {
                ...prev[activeTowerID].layout[activeCanvasTab][
                  activeGrid[activeCanvasTab]
                ],
                [addItemProps.index!]: {
                  ...prev[activeTowerID].layout[activeCanvasTab][
                    activeGrid[activeCanvasTab]
                  ][addItemProps.index],
                  icons: {
                    ...prev[activeTowerID].layout[activeCanvasTab][
                      activeGrid[activeCanvasTab]
                    ][addItemProps.index].icons,
                    [dragProps.id]: {
                      ...prev[activeTowerID].layout[activeCanvasTab][
                        activeGrid[activeCanvasTab]
                      ][addItemProps.index].icons[dragProps.id],
                      uniqueProperties: updatedProperties,
                    },
                  },
                },
              },
            },
          },
        },
      }));
      setEditIndex(null);
    },
  };

  const staticProperties = useMemo(
    () =>
      globalProperties
        .filter((prop) => !prop.path)
        .map((property, index) => (
          <div
            className={classes.propertyContainer}
            key={index}>
            <div className='w-full font-semibold'>{property.name}:</div>
            <div className='w-full text-center'>{property.value}</div>
          </div>
        )),
    [globalProperties]
  );

  const editableProperties = uniqueProperties.map((property, index) => (
    <div
      className={classes.propertyContainer}
      key={index}>
      <div className={classes.column}>
        <div className={classes.propertyName}>{property.name}:</div>
        {editIndex === index ? (
          <input
            className={classes.propertyInput}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
        ) : (
          <div className={classes.propertyValue}>{property.value}</div>
        )}
      </div>
      <div
        id='asset-edit-buttons'
        className={classes.editButtonsContainer}>
        {editIndex === index ? (
          <>
            <button
              className={classes.buttonCheck}
              onClick={() => saveHandler.edit(index)}
            />
            <button
              className={classes.buttonCancel}
              onClick={() => setEditIndex(null)}
            />
          </>
        ) : (
          <>
            <button
              id='edit-asset-property-button'
              className={classes.buttonEdit}
              onClick={() => {
                setEditIndex(index);
                setEditValue(property.value!);
              }}
            />
            <button
              id='delte-asset-property-button'
              className={classes.buttonDelete}
              onClick={() => saveHandler.remove(index)}
            />
          </>
        )}
      </div>
    </div>
  ));

  const addProperty =
    !addProp && editIndex === null ? (
      <div
        className={classes.addPropertyInActive}
        onClick={() => setAddProp(true)}>
        <span className={classes.addPropertyTitle}>Add Property:</span>
        <i className={classes.buttonAdd} />
      </div>
    ) : (
      <div className={classes.addPropertyActive}>
        <input
          className={classes.propertyInput}
          type='text'
          placeholder='New Property Name'
          value={addItemProps.label}
          onChange={(e) =>
            setAddItemProps((prev: IAddItemProps) => ({
              ...prev,
              label: e.target.value,
            }))
          }
        />
        <input
          className={classes.propertyInput}
          type='text'
          placeholder='New Property Value'
          value={addItemProps.content}
          onChange={(e) =>
            setAddItemProps((prev: IAddItemProps) => ({
              ...prev,
              content: e.target.value,
            }))
          }
        />
        <div className={classes.addPropertyButtons}>
          <button
            className={classes.buttonAdd}
            onClick={() => saveHandler.add()}
          />
          <button
            className={classes.buttonCancel}
            onClick={() => {
              setAddProp(false);
              setAddItemProps((prev: IAddItemProps) => ({
                ...prev,
                content: '',
                label: '',
              }));
            }}
          />
        </div>
      </div>
    );

  return (
    <div className={classes.container}>
      <i
        className={classes.closeIcon}
        onClick={() => {
          setDragProps(clearDragProps);
          setAddItemProps(clearAddProps);
        }}
      />
      <h1 className={classes.title}>Edit Asset Properties</h1>
      <div className={classes.targetCard}>
        <div className={classes.semibold}>{targetContainer}</div>
        <div className={classes.bold}>{targetAsset?.name}</div>
      </div>
      {addProperty}
      {staticProperties.length > 0 && (
        <div
          id='static-properties'
          className={classes.globalPropertiesContainer}>
          <h2 className={classes.bold}>Global Properties</h2>
          {staticProperties}
        </div>
      )}
      <div
        id='edit-properties'
        className={classes.globalPropertiesContainer}>
        <h2 className={classes.bold}>Unique Properties</h2>
        {editableProperties}
      </div>
    </div>
  );
};

export default EditAssetPanel;
