import { IReportSiteOption } from 'components/reports/iReports';
import { IHeaderButtons } from 'state/iState';
import React, { useContext } from 'react';
import { IStringProps } from 'iApp';
import {
  HeaderContext,
  ServerContext,
  CompanyContext,
  DocumentContext,
} from 'state/context';
import {
  IHeaderContext,
  IServerContext,
  ICompanyContext,
  IDocumentContext,
} from 'state/iContext';
import {
  clearInspectionChecklist,
  maintenanceInspectionChecklist,
} from 'state/state';
import html2pdf from 'html2pdf.js';
import { kebabString } from 'helpers/helperFunctions';

const classes: IStringProps = {
  container: 'flex flex-col items-center overflow-y-auto overflow-x-hidden h-screen',
  report: 'flex flex-col items-center h-auto',
  header: 'text-3xl font-bold text-center text-gray-800 mb-8',
  table: 'table-auto w-full border-collapse m-4',
  section:
    'bg-slate-300 text-center justify-center items-center font-bold border border-stone-500 text-sm ',
  question: 'w-1/4 border border-stone-500 p-2 align-top font-bold text-sm',
  answer:
    'w-3/4 border border-stone-500 p-2 align-top text-center border-b text-sm ',
  hyperlink: 'text-blue-500 underline',
  imageList: 'flex flex-wrap mt-2',
  imageItem: 'w-20 h-20 mr-2 mb-2 object-cover',
  buttons: 'w-full flex items-center justify-start',
  backButton: 'p-2 text-red-700 m-2 mb-0 underline',
  button:
    'p-1 px-2 m-1 border border-stone-400 rounded rounded-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
};

const reports = {
  'Inspection Report': {
    position: 1,
    checklist: clearInspectionChecklist,
  },
  'Digi Maintenance Report': {
    position: 2,
    checklist: maintenanceInspectionChecklist,
  },
};

// TODO: Images need to save to db

const DisplayCustomFile = () => {
  const {
    documentList,
    activeFile,
    setActiveFile,
    setActiveReport,
    setReportFormData,
    setReportImageData,
  } = useContext(DocumentContext) as IDocumentContext;
  const { domain } = useContext(ServerContext) as IServerContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { setActiveHeaderButton } = useContext(HeaderContext) as IHeaderContext;

  const targetFile = documentList[activeFile!];
  const { formData, imageData } = targetFile.file;

  const targetReport = reports[targetFile.customType].checklist;

  const linkify = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/gi;

    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a
            key={index}
            href={part}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.hyperlink}>
            {part}
          </a>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  const renderAnswer = (answer: string | IReportSiteOption | undefined) => {
    if (!answer) {
      return <p>N/A</p>;
    } else if (typeof answer === 'string') {
      return <p>{linkify(answer)}</p>;
    } else if (typeof answer === 'object' && 'label' in answer) {
      return <p>{(answer as IReportSiteOption).label}</p>;
    }
    return <p>N/A</p>;
  };

  const handleDownload = () => {
    const element = document.getElementById('content-to-print');

    const options = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `Digi-${kebabString(targetFile.name)}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        useCORS: true,
        scrollX: 0,
        scrollY: 0,
      },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pagebreak: {
        mode: ['css', 'legacy'],
        avoid: 'tr',
      },
    };

    html2pdf().from(element).set(options).save();
  };

  return (
    <div className={classes.container}>
      <div className={classes.buttons}>
        <button
          onClick={() => setActiveFile('add')}
          className={classes.backButton}>
          Go Back
        </button>
        <button
          onClick={async () => {
            await setActiveHeaderButton((prev: IHeaderButtons) => ({
              ...prev,
              map: false,
              inventory: false,
              tower: false,
              site: false,
              data: false,
              drawings: false,
              files: false,
              contacts: false,
              'work orders': false,
              reports: true,
            }));
            await setActiveReport(reports[targetFile.customType].position);
            await setReportFormData(formData);
            await setReportImageData(imageData);
          }}
          className={classes.button}>
          Edit
        </button>
        <button
          onClick={() => {
            handleDownload();
          }}
          className={classes.button}>
          Download
        </button>
      </div>
      <div
        id='content-to-print'
        className={classes.report}>
        <h2 className={classes.header}>
          {documentList[activeFile!].customType}
        </h2>
        <table className={classes.table}>
          <tbody>
            {Object.keys(targetReport).map((section, sectionIdx) => (
              <React.Fragment key={sectionIdx}>
                <tr>
                  <td
                    colSpan={2}
                    className={classes.section}
                    style={{
                      padding: '5px',
                      textAlign: 'center',
                    }}>
                    {section}
                  </td>
                </tr>
                {targetReport[section].map((question, idx) => (
                  <tr key={idx}>
                    <td className={classes.question}>{question}</td>
                    <td className={classes.answer}>
                      {renderAnswer(formData[section]?.[question])}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.buttons}>
        <button
          onClick={() => setActiveFile('add')}
          className={classes.backButton}>
          Go Back
        </button>
      </div>
    </div>
  );
};

export default DisplayCustomFile;
