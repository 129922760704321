import { ISiteClasses, ISiteProps } from './iSiteList';
import { ITower, IMeasurelength, ITowerList } from 'state/iState';
import React, { useContext, useEffect, useState } from 'react';
import FavoritesButton from '../../FavoritesButton';
import ImageThumbnail from '../../ImageThumbnail';
import {
  isMinClass,
  measurementConversion,
  highlightSearch,
  measurementType,
  inspectionAlert,
} from 'helpers/helperFunctions';
import {
  MapContext,
  EditContext,
  UserContext,
  SitesContext,
  CanvasContext,
  ServerContext,
  SettingsContext,
  HeaderContext,
  DocumentContext,
} from 'state/context';
import {
  IMapContext,
  IEditContext,
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IServerContext,
  ISettingsContext,
  IHeaderContext,
  IDocumentContext,
} from 'state/iContext';

const Site = ({
  towerId,
  img,
  name,
  type,
  location,
  geoCode,
  height,
  dates,
}: ITower) => {
  const { metric, measurements, minimize, setMinimize } =
    useContext(SettingsContext) as ISettingsContext;
  const { isEditPanelOpen, openEditPanel, editProps } =
    useContext(EditContext) as IEditContext;
  const { searchBar, user } =
    useContext(UserContext) as IUserContext;
  const { setActiveTowerID } =
    useContext(CanvasContext) as ICanvasContext;
  const { domain, checkCanvasLock } =
    useContext(ServerContext) as IServerContext;
  const { documentList } =
    useContext(DocumentContext) as IDocumentContext;
  const {
    activeHeaderButton: { map },
  } = useContext(HeaderContext) as IHeaderContext;
  const {
    siteTypes,
    towerList,
    setTowerList,
    setWarningClicked,
    fetchSiteData,
  } = useContext(SitesContext) as ISitesContext;
  const {
    mapRef,
    setZoom,
    infoWindowID,
    measureDistance,
    setInfoWindowID,
    setMeasureDistance,
    changeMeasureEndPoints,
  } = useContext(MapContext) as IMapContext;

  const [hover, setHover] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { site, tower } = type!;

  // Component classes
  const classes: ISiteClasses = {
    main: {
      min: 'flex min-w-[100px] h-[100px] pb-[15px] border border-gray-200 items-start cursor-pointer transition ease-in-out delay-100 hover:bg-stone-200',
      max: 'group relative flex w-full h-[125px] border border-gray-200 cursor-pointer transition ease-in-out delay-100 hover:bg-stone-100',
    },
    details: {
      panel: 'flex flex-col w-full h-1/4 text-gray-500 text-xs',
      hover:
        'flex absolute -top-[125px] left-0 md:top-auto md:left-[130px] bg-white z-50 h-[120px] text-gray-500 text-xs pl-1 border border-2 border-stone-300 rounded-md',
      card: 'flex-col w-full h-1/4 text-gray-500 text-xs pl-1',
    },
    title: 'flex w-full justify-between text-black font-bold items-end',
    towerName: {
      hover: 'truncate max-w-[120px] md:w-[150px]',
      card: 'truncate max-w-[120px] sm:max-w-[270px] md:max-w-[75px]',
    },
    icons: 'flex w-[100px] justify-end items-center text-lg',
    towerClass: 'w-[20px] h-[20px] self-center mr-1',
    nonFavorite: 'fa-regular fa-star m-1 text-stone-600 hover:text-red-600',
    favorite: 'fa-solid fa-star m-1 text-red-600',
    edit: 'hidden fa-solid fa-pen-to-square text-red-600 bg-zinc-200 mx-1 rounded rounded-md cursor-copy hover:border-red-700 hover:text-red-700 group-hover:flex',
    locked:
      'hidden fa-solid fa-lock text-red-600 bg-zinc-200 mx-1 rounded rounded-md cursor-copy hover:border-red-700 hover:text-red-700 group-hover:flex',
  };

  const checkExpiry = (date: number) => {
    const currentDate = Date.now();
    const expiryDate = new Date(date).getTime();
    const timeRemaining = expiryDate - currentDate;
    const isSoonExpired = timeRemaining <= 90 * 24 * 60 * 60 * 1000;

    return isSoonExpired;
  };

  const warningRequired = towerList[towerId].dates.licenses.some((id: string) =>
    checkExpiry(documentList[id])
  );

  const measurement: IMeasurelength = measurementType(metric, measurements);
  const towerColor: string = siteTypes[site as keyof typeof siteTypes]!;
  const mainClasses: string = isMinClass(minimize, classes.main);
  const towerNameClass: string =
    minimize && hover ? classes.towerName.hover : classes.towerName.card;
  const detailCardClass: string =
    minimize && hover ? classes.details.hover : classes.details.panel!;
  const hoverCardClass: string =
    minimize && hover
      ? classes.details.card
      : minimize && !hover
      ? 'hidden'
      : '';
  const highlightOnEdit: string =
    (editProps.towerId === towerId && isEditPanelOpen) ||
    infoWindowID === towerId
      ? mainClasses + ' bg-stone-200'
      : mainClasses;

  const editClickHandler = async (): Promise<void> => {
    const locked: boolean = await checkCanvasLock(towerId);
    if (locked) {
      const fetchedSiteData = await fetchSiteData(towerId);
      await setTowerList((prev: ITowerList) => ({
        ...prev,
        [towerId]: fetchedSiteData,
      }));
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 5000);
    } else {
      setActiveTowerID(towerId);
      openEditPanel(towerList[towerId]);
      setMeasureDistance(false);
    }
  };

  // Controls hover state
  const mouseOverHandler = (): void => setHover(true);
  const mouseOutHandler = (): void => setHover(false);

  // Pans to selected marker and/or adds/removes from measure distance tool
  const siteClickHandler = (): void => {
    setActiveTowerID((prev: number) => (prev === towerId ? '' : towerId));
    if (map) {
      mapRef.current.panTo(location);
      setInfoWindowID(towerId);
      setZoom(16);
    }

    if (measureDistance) {
      changeMeasureEndPoints(towerId);
    }
  };

  // Opens sites panel if minimized
  const doubleClickhandler = (): void => {
    if (minimize) setMinimize(false);
  };

  // Props to pass to their respective elements
  const props: ISiteProps = {
    siteProps: {
      className: highlightOnEdit,
      onMouseOver: mouseOverHandler,
      onMouseOut: mouseOutHandler,
      onClick: siteClickHandler,
      onDoubleClick: doubleClickhandler,
    },
    thumbnail: {
      img: `${domain}${img!}`,
      position: 'center',
    },
    towerIcon: {
      src: towerColor,
      className: classes.towerClass,
    },
    favorite: {
      towerId,
    },
    editIcon: {
      className: towerList[towerId].edit?.active ? classes.locked : classes.edit,
      onClick: editClickHandler,
    },
  };

  useEffect(() => {}, [towerList]);

  return (
    <div {...props.siteProps}>
      <ImageThumbnail {...props.thumbnail} />
      <div className={detailCardClass}>
        {hover && minimize && <ImageThumbnail {...props.thumbnail} />}
        <div
          id={'hovercard' + towerId}
          className={hoverCardClass}>
          <div className={classes.title}>
            <div className={towerNameClass}>
              {highlightSearch(name!, searchBar.trim())}
            </div>
            <div className={classes.icons}>
              {warningRequired && (
                <i
                  className='fa-solid fa-triangle-exclamation text-red-600 mr-1 cursor-help'
                  onClick={() => {
                    setWarningClicked(true);
                  }}
                />
              )}
              {!isEditPanelOpen && user.admin && <i {...props.editIcon} />}
              <img
                key={`${domain}${img!}`}
                alt='tower thumbnail'
                {...props.towerIcon}
              />
              <FavoritesButton {...props.favorite} />
            </div>
          </div>
          {site}
          <div className='text-ellipsis'>
            {highlightSearch(geoCode!, searchBar.trim())}
          </div>
          <div>
            Height ({measurement.short}) {measurementConversion(metric, height)}
          </div>
          Tower Type: {tower}
          <div className={'text-[11px] ' + inspectionAlert(dates.inspection)}>
            <>
              {checkExpiry(dates.inspection) && (
                <i className='fa-solid fa-triangle-exclamation text-red-600 mr-1 cursor-help' />
              )}
              Next Inspection:{' '}
              {new Date(dates.inspection).toUTCString().slice(5, 16)}
            </>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div
          className='absolute z-50 bg-white p-2 rounded shadow-md'
          style={{ top: '10px', right: '10px' }}>
          <p>Currently Being Updated</p>
        </div>
      )}
    </div>
  );
};

export default Site;
