import React, { useContext, useEffect, useMemo, useState } from 'react';
import { maintenanceInspectionChecklist } from 'state/state';
import UploadedReportImages from '../UploadedReportImages';
import { camelCaseStr } from 'helpers/helperFunctions';
import ReportSection from '../ReportSection';
import { IStringProps } from 'iApp';
import axios from 'axios';
import {
  ICanvasContext,
  ICompanyContext,
  IDocumentContext,
  IHeaderContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  CanvasContext,
  CompanyContext,
  DocumentContext,
  HeaderContext,
  ServerContext,
  SitesContext,
  UserContext,
} from 'state/context';
import {
  IReportDocData,
  IReportFormData,
  IReportImageData,
  IReportSiteOption,
} from '../iReports';
import {
  IDocumentList,
  IHeaderButtons,
  ITowerDocument,
  ITowerList,
} from 'state/iState';

const MaintenanceInspectionReport = () => {
  const { setActiveHeaderButton } = useContext(HeaderContext) as IHeaderContext;
  const { setActiveTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { user } = useContext(UserContext) as IUserContext;
  const {
    fetchDocuments,
    activeFile,
    setActiveFile,
    setDocumentList,
    reportFormData,
    setReportFormData,
    reportImageData,
    setReportImageData,
    setActiveReport,
  } = useContext(DocumentContext) as IDocumentContext;
  const { setTowerList, fetchSiteData } = useContext(
    SitesContext
  ) as ISitesContext;
  const { domain, updateDocumentsDB, uploadFilesToDB } = useContext(
    ServerContext
  ) as IServerContext;

  const [tab, setTab] = useState<'form' | 'images'>('form');
  const newForm: boolean = activeFile === 'add';

  useEffect(() => {
    setTab('form');
    if (newForm) {
      setReportImageData({});
      setReportFormData({});
    }
  }, [activeFile]);

  const classes: IStringProps = {
    container: 'w-full flex flex-col items-center p-2 m-2 pb-4',
    header: 'text-4xl font-bold text-center text-gray-800 mb-8',
    tabContainer: 'w-full flex pl-4',
    tabButton:
      'p-2 px-4 border border-stone-400 rounded-tr rounded-tl text-lg transition ease-in-out delay-100',
    tabForm: `text-slate-100 bg-red-700 hover:bg-red-600 ${
      tab === 'form'
        ? 'bg-red-700 text-slate-100'
        : 'text-stone-600 bg-slate-200 hover:bg-red-400'
    }`,
    tabImages: `text-slate-100 bg-red-700 hover:bg-red-600 ${
      tab === 'images'
        ? 'bg-red-700 text-slate-100'
        : 'border-b border-b-0 text-stone-600 bg-slate-200 hover:bg-red-400'
    }`,
    content: 'relative w-full flex flex-col border rounded-lg p-1',
    topButton: 'w-full flex items-start',
    button:
      'w-1/4 p-2 rounded-lg mt-8 bg-red-700 text-white disabled:bg-stone-400 disabled:text-slate-500',
    backButton: 'p-2 text-red-700 m-2 mb-0 underline',
  };

  const handleReturn = () => {
    setActiveFile('add');
    setActiveReport(null);
  };

  const handleInputChange = (
    section: string,
    question: string,
    value: string
  ) => {
    setReportFormData((prevData) => ({
      ...prevData,
      [section]: { ...prevData[section], [question]: value },
    }));
  };

  // Handle image uploads, creating new file names
  const handleImageUpload = (
    section: string,
    question: string,
    files: FileList | null
  ) => {
    if (files) {
      const newFiles = Array.from(files).map((file) => file);

      setReportImageData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [question]: [...(prevData[section]?.[question] || []), ...newFiles],
        },
      }));
    }
  };

  const convertFilesToPaths = (
    imageData: IReportImageData,
    reportName: string
  ): { [section: string]: { [question: string]: string[] } } => {
    const imagePaths: { [section: string]: { [question: string]: string[] } } =
      {};

    Object.entries(imageData).forEach(([section, questionFiles]) => {
      imagePaths[section] = {};

      Object.entries(questionFiles).forEach(([question, files]) => {
        imagePaths[section][question] = (files as File[]).map((file, index) => {
          const convertFileName: string = camelCaseStr(file.name);
          const convertedSection: string = camelCaseStr(section);
          const convertedQuestion: string = camelCaseStr(question);
          const fileName = `${reportName}-${convertedSection}-${convertedQuestion}-${index}-${convertFileName}`;
          return `files/media/client/${company?.name}/${fileName}`;
        });
      });
    });

    return imagePaths;
  };

  const prepareDocumentData = async (
    formData: IReportFormData,
    imageData: IReportImageData,
    reportID: number
  ): Promise<ITowerDocument> => {
    const date: number = Date.now();
    const reportName: string = `maintenanceReport-${reportID}-${date}`;
    const preparedImageData: any = convertFilesToPaths(imageData, reportName);

    const docData: IReportDocData = {
      formData,
      imageData: preparedImageData,
    };

    const siteValue: IReportSiteOption = formData?.ID
      ?.Site as IReportSiteOption;

    const completeDocumentData = {
      name: `Maintenance Report ${reportID}`,
      // img: `files/media/client/${company.name}/${reportName}`,
      upload: date,
      updated: date,
      folder: 4,
      user: user.userId,
      isPDF: false,
      file: docData,
      towerIds: siteValue ? [+siteValue.value] : [],
      customType: 'Digi Maintenance Report',
    };

    await setDocumentList((prev: IDocumentList) => ({
      ...prev,
      [reportID]: {
        custom: true,
        ...completeDocumentData,
      },
    }));

    if (siteValue?.value) {
      const fetchedSiteData = await fetchSiteData(siteValue?.value);

      await setTowerList((prev: ITowerList) => ({
        ...prev,
        [+siteValue.value]: {
          ...fetchedSiteData,
          documents: {
            ...fetchedSiteData.documents,
            folders: {
              ...fetchedSiteData.documents.folders,
              '4': {
                ...fetchedSiteData.documents.folders['4'],
                files: newForm
                  ? [...fetchedSiteData.documents.folders['4'].files, reportID]
                  : fetchedSiteData.documents.folders['4'].files,
              },
            },
          },
        },
      }));
      await setActiveTowerID(+siteValue.value);
    }
    await setActiveFile(+reportID);

    return completeDocumentData;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    const method: string = !newForm ? 'PUT' : 'POST';
    const reportID: number = !newForm
      ? activeFile
      : (await axios.get(`${domain}get-next-pk/documents/`)).data.next_pk;

    e.preventDefault();
    const preparedData = await prepareDocumentData(
      reportFormData,
      reportImageData,
      reportID
    );
    await updateDocumentsDB(reportID, method, true, preparedData);
    await fetchDocuments();
    await setActiveHeaderButton((prev: IHeaderButtons) => ({
      ...prev,
      map: false,
      inventory: false,
      tower: false,
      site: false,
      data: false,
      drawings: false,
      files: true,
      contacts: false,
      'work orders': false,
      reports: false,
    }));
    await setActiveFile(+reportID);
  };

  return (
    <div className={classes.container}>
      <div className={classes.topButton}>
        <button
          onClick={handleReturn}
          className={classes.backButton}>
          Go Back
        </button>
      </div>
      <h2 className={classes.header}>Maintenance Inspection Report</h2>

      <div className={classes.tabContainer}>
        <div
          className={`${classes.tabButton} ${classes.tabForm}`}
          onClick={() => setTab('form')}>
          Form
        </div>
        {/* <div
          className={`${classes.tabButton} ${classes.tabImages}`}
          onClick={() => setTab('images')}>
          Images
        </div> */}
      </div>

      <div className={classes.content}>
        {tab === 'form' &&
          Object.entries(maintenanceInspectionChecklist).map(
            ([section, questions], idx) => (
              <ReportSection
                key={idx}
                section={section}
                questions={questions}
                handleInputChange={handleInputChange}
                handleImageUpload={handleImageUpload}
              />
            )
          )}

        {/* {tab === 'images' && (
          <UploadedReportImages
            imageData={reportImageData}
            handleImageUpload={handleImageUpload}
          />
        )} */}
      </div>

      <button
        onClick={handleSubmit}
        className={classes.button}
        disabled={!!!reportFormData?.ID?.Site}>
        Submit Report
      </button>
      <button
        onClick={handleReturn}
        className={classes.backButton}>
        Go Back
      </button>
    </div>
  );
};

export default MaintenanceInspectionReport;
