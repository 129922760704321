import React, { ReactNode, useContext, useEffect, useMemo } from 'react';
import { ICanvasContext, IHeaderContext } from 'state/iContext';
import { CanvasContext, HeaderContext } from 'state/context';
import RooftopGrid from './views/grids/RooftopGrid';
import TowerGrid from './views/grids/TowerGrid';
import { IActiveToolBar } from 'state/iState';
import DataGrid from './views/grids/DataGrid';
import GridLines from 'react-gridlines';
import { IStringProps } from 'iApp';

const CanvasGridLines = ({ active }: { active?: string }) => {
  const { activeToolBar, setActiveToolBar, dragProps } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const {
    activeHeaderButton: { tower, site },
  } = useContext(HeaderContext) as IHeaderContext;

  const classes: IStringProps = useMemo(
    () => ({
      container:
        'w-0 ' + (dragProps.dragging ? 'cursor-grab' : 'cursor-grabbing'),
      lineColor: activeToolBar.Grid ? '#c7c7c7' : '',
      gridLines: `grid-area z-20 w-[1000vw] h-[1000vh] pl-1`,
    }),
    [dragProps.dragging, activeToolBar.Grid]
  );

  const activeTower: boolean = active === 'tower';
  const activeRooftop: boolean = active === 'rooftop';

  useEffect(
    () =>
      setActiveToolBar((prev: IActiveToolBar) => ({
        ...prev,
        select: true,
        image: false,
        text: false,
        drawings: false,
        section: false,
        edit: false,
        history: false,
      })),
    [setActiveToolBar]
  );

  const activeGrid: ReactNode = useMemo(
    () =>
      tower || activeTower ? (
        <TowerGrid />
      ) : site || activeRooftop ? (
        <RooftopGrid />
      ) : (
        <DataGrid />
      ),
    [tower, site, activeTower, activeRooftop]
  );

  return (
    <div className={classes.container}>
      <GridLines
        strokeWidth={1}
        cellWidth={7.55}
        cellHeight={7.55}
        className={classes.gridLines}
        lineColor={classes.lineColor}>
        {activeGrid}
      </GridLines>
    </div>
  );
};

export default CanvasGridLines;
