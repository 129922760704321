import React from 'react';

const UpdateBanner = () => {
  return (
    <div className='w-full justify-center text-center self-center border border-slate-600 bg-stone-500 text-slate-200'>
      Currently Being Updated
    </div>
  );
};

export default UpdateBanner;
