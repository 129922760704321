import { ICompanyContext } from 'state/iContext';
import { CompanyContext } from 'state/context';
import React, { useContext } from 'react';
import { IStringProps } from 'iApp';

const FolderButtons = ({ name, fileInputRef, handlers }) => {
  const { company } = useContext(CompanyContext) as ICompanyContext;

  const classes: IStringProps = {
    container: 'flex mx-2',
    button:
      ' fa-solid hidden opacity-70 mx-2 text-red-600 hover:opacity-100 group-hover:flex',
  };

  return (
    <div className={classes.container}>
      {name !== 'root' && (
        <>
          <i
            className={'fa-arrows-up-down-left-right' + classes.button}
            onClick={handlers.move}
          />
          <i
            className={'fa-pen-to-square' + classes.button}
            onClick={handlers.edit}
          />
        </>
      )}
      <i
        className={'fa-folder-plus' + classes.button}
        onClick={handlers.folder}
      />
      <i
        className={'fa-upload' + classes.button}
        onClick={handlers.upload}
      />
      {/* {(company.subscription.id >= 3 || company.add_ons.includes(3)) && (
        <i
          className={'fa-file-signature' + classes.button}
          onClick={handlers.build}
        />
      )} */}
      <input
        type='file'
        id='file-upload'
        name='file-upload'
        className='hidden'
        ref={fileInputRef}
        onChange={handlers.input}
      />
    </div>
  );
};

export default FolderButtons;
