import { ILibraryContext, IUserContext } from 'state/iContext';
import { LibraryContext, UserContext } from 'state/context';
import LibrariesList from './LibrariesList';
import React, { useContext } from 'react';
import { IUser } from 'state/iState';
import {
  ILibrariesPanelClasses,
  ILibrariesPanelHandlers,
} from '../iManageLibraries';

const LibrariesPanel = () => {
  const { activePrivateLibraries, activeStandardLibraries } =
    useContext(LibraryContext) as ILibraryContext;
  const { setUser } = useContext(UserContext) as IUserContext;
  // Component classes
  const classes: ILibrariesPanelClasses = {
    container:
      'flex flex-col h-1/3 w-full border border-stone-300 bg-stone-100 overflow-y-auto sm:h-full',
    libraries: 'h-full',
    title: 'bg-slate-200 text-stone-600 font-semibold p-2',
  };

  // Library selection behaviour
  const handlers: ILibrariesPanelHandlers = {
    privateLib: (id: string): void =>
      setUser((prev: IUser) => ({
        ...prev,
        private_libraries: {
          ...prev.private_libraries,
          [id]: !prev.private_libraries[id],
        },
      })),
    standardLib: (id: string): void =>
      setUser((prev: IUser) => ({
        ...prev,
        standard_libraries: prev.standard_libraries.includes(id)
          ? prev.standard_libraries.filter((target) => target !== id)
          : [...prev.standard_libraries, id],
      })),
  };

  return (
    <div className={classes.container}>
      <div className={classes.libraries}>
        <h3 className={classes.title}>Private Libraries</h3>
        <LibrariesList
          libraries={activePrivateLibraries}
          handler={handlers.privateLib}
          type={'private'}
        />
        <h3 className={classes.title}>Standard Libraries</h3>
        <LibrariesList
          libraries={activeStandardLibraries}
          handler={handlers.standardLib}
          type={'standard'}
        />
      </div>
    </div>
  );
};

export default LibrariesPanel;
