import React, { ReactNode, useContext, useState, useEffect } from 'react';
import { IEditProps, ISaveBoxTower, IUpdateImageProps } from '../iEdit';
import { isImageListEnd, threeMinimum } from 'helpers/editHelpers';
import { IFourImages, IUpdateImageClasses } from './iEditImage';
import { IEditContext, ISettingsContext } from 'state/iContext';
import btnEventHandlers from '../../saveClose/btnEventHandlers';
import { EditContext, SettingsContext } from 'state/context';
import { convertImageToWebP } from 'helpers/helperFunctions';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import 'react-image-crop/dist/ReactCrop.css';
import Close from 'components/buttons/Close';
import ReactCrop from 'react-image-crop';
import Save from '../../saveClose/Save';
import { IMapElement } from 'iApp';
import {
  ICropSaveMethods,
  IChangeImageStates,
  IFileSelectComplete,
} from 'helpers/iHelpers';

const UpdateImage = () => {
  const {
    fileTypes: { acceptedImageTypes },
  } = useContext(SettingsContext) as ISettingsContext;
  const edit = useContext(EditContext) as IEditContext;
  const { cancel } = btnEventHandlers(edit!, true);
  const { openImagePanel, editProps, setEditProps, stockImages } = edit!;

  const [currentImages, setCurrentImages] = useState<string[]>(stockImages);
  const imageListLength: number = currentImages.length;

  // State to control what 4 stock images to display
  const [fourImages, setFourImages] = useState<IFourImages>({
    first: 0,
    last: 3,
  });
  const { first, last } = fourImages;
  const [displayedImage, setDisplayedImage] = useState<string>(
    currentImages[first]
  );

  const {
    crop,
    imgSrc,
    setImgSrc,
    setImgSrcExt,
    fileInputRef,
    onFileSelect,
    setFileReader,
    onCropSave,
    onCropChange,
    onCropComplete,
    clearToDefault,
    imagePreviewCanvasRef,
    getImageFileExtension,
    setScaleDimensions,
  } = ImageCropHelpers(displayedImage);

  // Component classes
  const classes: IUpdateImageClasses = {
    main: 'absolute flex flex-col right-0 z-[60] p-5 bg-white text-gray-500 border border-t-4 border-gray-300 w-[270px] h-1/2 md:rounded-tr-md md:rounded-br-md md:border-t md:top-0 md:left-[540px] md:w-[270px]',
    title: 'font-bold text-sm text-gray-500 mb-2',
    displayed:
      'flex min-w-[208px] min-h-[208px] max-w-[208px] max-h-[208px] bg-stone-100 object-contain self-center items-center justify-center border border-stone-300',
    imagesContainer: 'flex h-[50px] w-full items-end justify-center mt-2',
    stockImage:
      'min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] object-fill rounded rounded-md border border-1 border-gray-600 m-0.5 cursor-pointer',
    arrow: {
      left: 'fa-solid fa-chevron-left text-2xl self-center mx-0.5 cursor-pointer',
      right:
        'fa-solid fa-chevron-right text-2xl self-center mx-0.5 cursor-pointer',
    },
    folder: 'ml-2 mt-3 text-sm text-blue-500',
  };

  // Handler for clicking any displayed stock images
  const imageClickHandler = (index: number): void => {
    setImgSrc('');
    setDisplayedImage(currentImages[index + first]);
    setImgSrcExt(getImageFileExtension(currentImages[index + first]));
    setFourImages((prev) => ({
      first: threeMinimum(prev, imageListLength),
      last: prev.first + 3,
    }));
  };

  // Renders each stock image
  const images: ReactNode[] = currentImages
    .filter((_: string, i: number) => i >= first && i <= last)
    .map(
      (image: string, i: number): IMapElement => (
        <img
          src={image.toString()}
          key={`stock-img-${i + 1}`}
          alt={`tower preview ${i + 1}`}
          className={classes.stockImage}
          onClick={() => imageClickHandler(i)}
        />
      )
    );

  // Changes/saves the state of the image and file extension
  const changeImageStates = async ({
    image,
    save,
  }: IChangeImageStates): Promise<void> => {
    setCurrentImages((prev): string[] => [image, ...prev]);
    setImgSrc(image);
    setImgSrcExt(getImageFileExtension(image));
    setEditProps((prev: IEditProps) => ({
      ...prev,
      img: image,
    }));
    const fileName: string = `tower-${editProps.towerId}-image`;
    const imageFile = await convertImageToWebP(image, fileName);

    if (save)
      setEditProps(
        (prev: IEditProps): IEditProps => ({
          ...prev,
          file: imageFile,
        })
      );
  };

  // Additional methods to envoke once a file is selected
  const onFileSelectComplete = ({ e, file }: IFileSelectComplete) => {
    setFileReader({ file, changeImageStates });
    setFourImages({ first: 1, last: 3 });
    clearToDefault(e);
  };

  // Methods to envoke while saving the cropped image
  const cropSaveMethods: ICropSaveMethods = {
    changeImageStates,
    onComplete: () => openImagePanel(),
  };

  // Props to pass to their respective elements
  const props: IUpdateImageProps = {
    reactCrop: {
      crop,
      aspect: 1,
      src: imgSrc,
      circularCrop: true,
      ruleOfThirds: true,
      onChange: onCropChange,
      crossorigin: 'anonymous',
      onComplete: onCropComplete,
      className: classes.displayed,
    },
    img: {
      displayed: {
        src: displayedImage,
        id: 'crop-tower-image',
        className: classes.displayed,
        onClick: () => {
          setImgSrc(displayedImage);
          setFourImages((prev) => ({ ...prev, last: prev.first + 2 }));
        },
      },
      crop: {
        id: 'displayed-tower-image',
        className: classes.displayed,
        src: imgSrc,
      },
    },
    arrows: {
      left: {
        className: isImageListEnd(first, imageListLength, classes.arrow.left),
        onClick: (): void => {
          if (first > 0) {
            setFourImages((prev) => ({
              first: --prev.first,
              last: --prev.last,
            }));
          }
        },
      },
      right: {
        className: isImageListEnd(last, imageListLength, classes.arrow.right),
        onClick: (): void => {
          if (last < imageListLength - 1) {
            setFourImages((prev) => ({
              first: ++prev.first,
              last: ++prev.last,
            }));
          }
        },
      },
    },
    canvas: {
      ref: imagePreviewCanvasRef,
      className: classes.stockImage,
    },
    input: {
      type: 'file',
      multiple: false,
      ref: fileInputRef!,
      name: 'photo-input',
      id: 'tower-photo-input',
      className: classes.folder,
      accept: acceptedImageTypes,
      onChange: (e) => onFileSelect({ e, onFileSelectComplete }),
    },
    saveBoxTower: {
      save: {
        name: 'CONFIRM',
        handler: (e) => onCropSave({ e, cropSaveMethods }),
      },
      cancel: cancel(),
    } as ISaveBoxTower,
  };

  const { reactCrop, img, arrows, canvas, input, saveBoxTower } = props;

  // Displays a static/croppable image
  const imageElementToDisplay = imgSrc ? (
    <ReactCrop {...reactCrop}>
      <img
        alt='tower thumbnail'
        {...img.crop}
      />
    </ReactCrop>
  ) : (
    <img
      alt='tower thumbnail'
      {...img.displayed}
    />
  );

  useEffect(
    () =>
      setScaleDimensions({
        w: 210,
        h: 210,
      }),
    [setScaleDimensions]
  );

  return (
    <div
      id='edit-image-panel'
      className={classes.main}>
      <div className={classes.title}>UPDATE IMAGE</div>
      <Close handler={openImagePanel} />
      <div className={classes.displayed}>{imageElementToDisplay}</div>
      <div className={classes.imagesContainer}>
        <i {...arrows.left} />
        {imgSrc && <canvas {...canvas} />}
        {images}
        <i {...arrows.right} />
      </div>
      <span>Square Images Reccommended</span>
      <input {...input} />
      <Save {...saveBoxTower} />
    </div>
  );
};

export default UpdateImage;
