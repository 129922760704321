import React, { ReactNode, useContext, useMemo } from 'react';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import FileLine from './FileLine';

const Files = ({ fileIds }) => {
  const { documentList } =
    useContext(DocumentContext) as IDocumentContext;

  const files: ReactNode[] = useMemo(
    () =>
      fileIds.map(
        (id: string): ReactNode => (
          <FileLine
            id={id}
            key={id}
            file={documentList[+id]}
          />
        )
      ),
    [fileIds, documentList]
  );
  return <>{files}</>;
};

export default Files;
