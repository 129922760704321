import {
  highlightSearch,
  measurementConversion,
} from 'helpers/helperFunctions';
import PropertyDetails from './PropertyDetails';
import { IStringProps } from 'iApp';
import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext, SitesContext, UserContext } from 'state/context';
import { ISettingsContext, ISitesContext, IUserContext } from 'state/iContext';

const AssetDetails = ({ icons, height }) => {
  const { searchBar } =
    useContext(UserContext) as IUserContext;
  const { scale, metric } =
    useContext(SettingsContext) as ISettingsContext;
  const { inventoryFilters, activeInventoryFilterCount } =
    useContext(SitesContext) as ISitesContext;
  const iconIds: string[] = Object.keys(icons);

  const initialOpenStates = iconIds.reduce((acc, iconKey) => {
    acc[iconKey] = false;
    return acc;
  }, {});

  const [openStates, setOpenStates] = useState(initialOpenStates);
  const classes: IStringProps = {
    blank: 'p-0.5 border border-stone-300 bg-stone-100',
    column: 'relative p-0.5 border border-stone-300 text-center text-sm',
    height:
      'relative flex p-0.5 border-b border-b-stone-300 text-center text-sm justify-end',
    heightbox: 'flex w-3/5',
    clickable: ' cursor-pointer',
  };

  const toggleOpen = (iconKey) => {
    setOpenStates((prevOpenStates) => ({
      ...prevOpenStates,
      [iconKey]: !prevOpenStates[iconKey],
    }));
  };

  const renderAssets = iconIds
    .filter((iconKey) => +iconKey >= 0)
    .map((iconKey) => {
      const icon = icons[iconKey];
      const isOpen: boolean = openStates[iconKey];
      const numProperties: number = icon.properties.filter(
        (property) => !!property.name
      ).length;

      return (
        <React.Fragment key={iconKey}>
          <tr>
            <td
              colSpan={
                inventoryFilters.height
                  ? activeInventoryFilterCount - 1
                  : activeInventoryFilterCount
              }
              className={classes.blank}
            />
            {inventoryFilters.height && (
              <td
                colSpan={1}
                className={classes.height}>
                <div className={classes.heightbox}>
                  {measurementConversion(metric, height - icon.y - icon.h)}{' '}
                  {'  '}
                  {scale} - {measurementConversion(metric, height - icon.y)}
                  {'  '} {scale}
                </div>
              </td>
            )}
            <td
              colSpan={2}
              className={
                classes.column +
                (Object.keys(icon.properties).length > 1
                  ? ' cursor-pointer'
                  : '')
              }
              onClick={() => toggleOpen(iconKey)}>
              {highlightSearch(icon.name, searchBar.trim())}
              {!isOpen && numProperties > 0 && (
                <>
                  {'   '}
                  <span>({numProperties})</span>
                  <i className='fa-solid fa-chevron-right absolute right-4 top-2' />
                </>
              )}
              {isOpen && (
                <i className='fa-solid fa-chevron-down absolute right-4 top-2' />
              )}
            </td>
          </tr>
          {isOpen && <PropertyDetails properties={icon.properties} />}
        </React.Fragment>
      );
    });

  useEffect(() => {
    if (!inventoryFilters.assets) {
      const newOpenStates = { ...openStates };
      iconIds.forEach((iconKey) => (newOpenStates[iconKey] = false));

      setOpenStates(newOpenStates);
    }
  }, [inventoryFilters.assets]);

  return <>{inventoryFilters.assets && renderAssets}</>;
};

export default AssetDetails;
