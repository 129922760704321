import { getDescendants, getFolderPathString } from 'helpers/helperFunctions';
import React, { useContext, useEffect, useState, ReactNode } from 'react';
import { ISelectEvent, IStringProps } from 'iApp';
import {
  ITower,
  ITowerList,
  IDocumentList,
  IUpdateFolders,
} from 'state/iState';
import axios from 'axios';
import {
  UserContext,
  SitesContext,
  CanvasContext,
  ServerContext,
  HistoryContext,
  DocumentContext,
} from 'state/context';
import {
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IServerContext,
  IHistoryContext,
  IDocumentContext,
} from 'state/iContext';

const MoveFileFolder = ({ type }) => {
  const { towerList, setTowerList } = useContext(SitesContext) as ISitesContext;
  const { setDocumentList } = useContext(DocumentContext) as IDocumentContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { updateFolders, setUpdateFolders } = useContext(
    DocumentContext
  ) as IDocumentContext;
  const { setHistory, fetchHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;

  const [target, setTarget] = useState<number>(0);

  const folders = towerList[activeTowerID].documents.folders;
  const folderKeys: string[] = Object.keys(folders);
  const removedFolder = folders[+updateFolders.parent].folders.filter(
    (id: string) => +id !== +updateFolders.folder
  );

  const options: ReactNode[] = folderKeys
    .filter((id) => {
      // Exclude the current folder being moved and its descendants
      const descendants = getDescendants(updateFolders.folder, folders);
      return type === 'folder'
        ? +id !== +updateFolders.folder && !descendants.includes(+id)
        : +id !== +updateFolders.folder;
    })
    .map((id, index): ReactNode => {
      const optionStyle = {
        textIndent: `${index * 10}px`,
      };

      return (
        <option
          key={id}
          value={+id}
          style={optionStyle}>
          {folders[id].name}
        </option>
      );
    });

  const updateHistory = async () => {
    const prevPath = getFolderPathString(
      +updateFolders.parent,
      towerList[activeTowerID].documents.folders
    );
    const newPath = getFolderPathString(
      target,
      towerList[activeTowerID].documents.folders
    );
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    const historyDetail = {
      file: [
        {
          before: [
            {
              folder: +updateFolders.parent,
              currentName:
                towerList[activeTowerID].documents.folders[
                  +updateFolders.parent
                ].name,
            },
            {
              text:
                prevPath.path === prevPath.primaryParent
                  ? ''
                  : `Path: ${prevPath.path}`,
            },
          ],
          after: [
            {
              folder: target,
              currentName:
                towerList[activeTowerID].documents.folders[target].name,
            },
            {
              text:
                newPath.path === newPath.primaryParent
                  ? ''
                  : `Path: ${newPath.path}`,
            },
          ],
          field: `${prevPath.primaryParent} File Moved`,
        },
      ],
      folder: [
        {
          before: [
            {
              text: `Path: ${prevPath.path} > ${updateFolders.name}`,
            },
          ],
          after: [
            {
              text: `Path: ${newPath.path} > ${updateFolders.name}`,
            },
          ],
          field: `${updateFolders.name} Folder Moved`,
        },
      ],
    };

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: type === 'file' ? 'File' : `Folder`,
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: historyDetail[type],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  useEffect(() => {
    if (+updateFolders.folder === 0) {
      setTarget(+folderKeys.filter((id) => +id > 0)[0]);
    }
  }, [updateFolders.folder, setTarget, folderKeys]);

  const handler = {
    change: (e: ISelectEvent) => setTarget(+e.target.value),
    save: async () => {
      await updateHistory();
      await setTowerList((prev: ITower) =>
        type === 'folder'
          ? {
              ...prev,
              [activeTowerID]: {
                ...prev[activeTowerID],
                documents: {
                  ...prev[activeTowerID].documents,
                  folders: {
                    ...prev[activeTowerID].documents.folders,
                    [target!]: {
                      ...prev[activeTowerID].documents.folders[target],
                      folders: [
                        ...prev[activeTowerID].documents.folders[target]
                          .folders,
                        +updateFolders.folder,
                      ],
                    },
                    [updateFolders.parent]: {
                      ...prev[activeTowerID].documents.folders[
                        updateFolders.parent
                      ],
                      folders: removedFolder,
                    },
                  },
                },
              },
            }
          : {
              ...prev,
              [activeTowerID]: {
                ...prev[activeTowerID],
                documents: {
                  ...prev[activeTowerID].documents,
                  folders: {
                    ...prev[activeTowerID].documents.folders,
                    [+updateFolders.folder]: {
                      ...prev[activeTowerID].documents.folders[
                        +updateFolders.folder
                      ],
                      files: prev[activeTowerID].documents.folders[
                        +updateFolders.folder
                      ].files.filter((id) => +id !== +updateFolders.name),
                    },
                    [+target!]: {
                      ...prev[activeTowerID].documents.folders[+target!],
                      files: [
                        ...prev[activeTowerID].documents.folders[+target!]
                          .files,
                        +updateFolders.name,
                      ],
                    },
                  },
                },
              },
            }
      );
      if (type === 'file') {
        await setDocumentList((prev: IDocumentList) => ({
          ...prev,
          [+updateFolders.name]: {
            ...prev[updateFolders.name],
            folder: target,
          },
        }));
      }
      setUpdateFolders((prev: IUpdateFolders) => ({
        ...prev,
        active: false,
        move: false,
      }));
    },
    cancel: () => {
      setUpdateFolders((prev: IUpdateFolders) => ({
        active: false,
        parent: '',
        folder: '',
        name: '',
      }));
    },
  };

  const classes: IStringProps = {
    container: 'flex justify-center items-center w-full',
    title: 'font-bold text-stone-500 mr-2',
    select: 'py-0',
    button:
      'p-1 px-2 m-1 border border-stone-400 rounded rounded-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
  };

  return (
    <div className={classes.container}>
      <label
        className={classes.title}
        htmlFor='name'>
        New Folder Location:
      </label>
      <select
        id='target-folder'
        name='target-folder'
        className={classes.select}
        defaultValue={updateFolders.parent}
        onChange={handler.change}>
        {options}
      </select>
      <button
        className={classes.button}
        onClick={handler.save}>
        Save
      </button>
      <button
        className={classes.button}
        onClick={handler.cancel}>
        Cancel
      </button>
    </div>
  );
};

export default MoveFileFolder;
