import React from 'react';

const WorkOrderButton = ({ onClick, children: text }) => (
  <button
    className={`text-white px-2 py-0.5 mx-1 my-4 rounded bg-red-600`}
    onClick={onClick}>
    {text}
  </button>
);

export default WorkOrderButton;
