import { IInfoCardClasses, ISiteInfoCardProps } from '../IMap';
import React, { useContext, useEffect, useState } from 'react';
import { highlightSearch } from 'helpers/helperFunctions';
import { IHeaderButtons, ITower, ITowerList } from 'state/iState';
import InfoWindowComments from './infoWindowComments';
import FavoritesButton from '../../FavoritesButton';
import ImageThumbnail from '../../ImageThumbnail';
import MarkerTowerInfo from './MarkerTowerInfo';
import MarkerSiteInfo from './MarkerSiteInfo';
import LicensePanel from './LicensePanel';
import { IBooleanProps } from 'iApp';
import ReadMore from './ReadMore';
import {
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  IServerContext,
  IDocumentContext,
  ISettingsContext,
} from 'state/iContext';
import {
  UserContext,
  SitesContext,
  CanvasContext,
  HeaderContext,
  ServerContext,
  DocumentContext,
  SettingsContext,
} from 'state/context';

const InfoWindowCard = ({
  towerId,
  code,
  comments,
  company,
  contact,
  height,
  img,
  location,
  name,
  type,
  dates,
}: ITower) => {
  // const sitesPanel = useContext(SitesContext) as ISitesContext;
  const {
    towerList,
    setTowerList,
    warningClicked,
    setWarningClicked,
    fetchSiteData,
  } = useContext(SitesContext) as ISitesContext;
  const { setActiveHeaderButton } = useContext(HeaderContext) as IHeaderContext;
  const { searchBar } = useContext(UserContext) as IUserContext;
  const { setActiveTowerID, setScale } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const { domain, checkCanvasLock } = useContext(
    ServerContext
  ) as IServerContext;
  const { documentList } = useContext(DocumentContext) as IDocumentContext;
  const { setMinimize } = useContext(SettingsContext) as ISettingsContext;

  const [panel, setPanel] = useState<string>('');

  const checkExpiry = (date: number) => {
    const currentDate = Date.now();
    const expiryDate = new Date(date).getTime();
    const timeRemaining = expiryDate - currentDate;
    const isSoonExpired = timeRemaining <= 90 * 24 * 60 * 60 * 1000;
    return isSoonExpired;
  };

  const warningRequired: boolean = towerList[towerId].dates.licenses.some(
    (id: string) => checkExpiry(documentList[id].expiry)
  );

  const viewDetailsTarget: IBooleanProps =
    type.site === 'Rooftop' ? { site: true } : { tower: true };

  // Component classes
  const classes: IInfoCardClasses = {
    main: 'relative flex flex-col overflow-visible w-[170px] max-h-[300px] md:w-[350px] md:min-h-[350px]',
    container: 'flex flex-col w-full text-[10px] md:flex-row md:text-[13px]',
    left: 'flex flex-wrap max-w-[90px] items-center justify-center',
    viewDataClass:
      'flex w-full justify-center text-center max-w-[80%] p-1 text-slate-200 text-xs font-bold bg-red-700 rounded rounded-lg outline-none transition ease-in-out delay-100 hover:bg-red-800 hover:text-slate-100',
    licenseClass:
      'flex w-full justify-center text-center max-w-[80%] p-1 text-slate-200 text-xs font-bold bg-blue-600 rounded rounded-lg outline-none transition ease-in-out delay-100 hover:bg-blue-700 hover:text-slate-100',
    licenseWarning:
      'flex w-full justify-center text-center max-w-[80%] p-1 text-slate-200 text-xs font-bold bg-orange-600 rounded rounded-lg outline-none transition ease-in-out delay-100 hover:bg-orange-700 hover:text-slate-100',
    viewDataText: 'outline-none',
    favorite: 'absolute right-3 top-2 m-2 text-lg',
    siteInfoClasses: {
      container: {
        short: 'flex justify-between w-[170px] md:w-[245px] md:my-1',
        long: 'flex flex-col justify-between w-full md:my-1',
      },
      label: 'flex justify-between w-[75px] md:w-[100px]',
      labelTitle: 'text-gray-600',
      position: {
        short: 'flex justify-between w-1/2',
        long: 'flex justify-between md:w-[245px]',
      },
      details: {
        short: 'w-full',
        long: 'flex-col w-full pl-2 pt-1 justify-center flex-wrap',
      },
    },
    towerInfoClasses: {
      container:
        'flex justify-around w-[100px] text-[10px] md:text-[13px] md:w-1/2 md:my-2',
      label: 'flex justify-between w-1/2',
      labelTitle: 'text-gray-600',
      position: 'w-1/4',
      details: 'flex justify-between w-[200px]',
    },
  };

  // Toggles Read More page
  const readMoreHandler = (): void =>
    setPanel((prev: string) => (prev === 'readMore' ? '' : 'readMore'));

  // Props to pass to their respective elements
  const props: ISiteInfoCardProps = {
    infoCard: {
      id: 'info-window-card',
      className: classes.main,
    },
    imgProps: {
      img: `${domain}${img}`,
      position: 'start',
    },
    siteInfo: {
      company: highlightSearch(company, searchBar.trim()),
      name: highlightSearch(name, searchBar.trim()),
      code: highlightSearch(code, searchBar.trim()),
      contact: {
        name: highlightSearch(contact.name, searchBar.trim()),
        phone: contact.phone,
        email: contact.email,
      },
      siteInfoClasses: classes.siteInfoClasses,
    },
    towerInfo: {
      type,
      height,
      location,
      towerInfoClasses: classes.towerInfoClasses,
      dates,
    },
    favoriteButton: {
      towerId,
    },
    commentInfo: {
      comments: highlightSearch(comments, searchBar.trim()),
      handler: () => readMoreHandler(),
    },
    readMoreInfo: {
      comments: highlightSearch(comments, searchBar.trim()),
      handler: () => readMoreHandler(),
    },
    licenses: {
      href: '#',
      className: classes.viewDataText,
      onClick: (e: Event) => {
        e.preventDefault();
        setPanel('license');
      },
    },
    viewData: {
      href: '#',
      className: classes.viewDataText,
      onClick: async (e: Event) => {
        e.preventDefault();
        const editable: boolean = await checkCanvasLock(towerId);

        if (!editable) {
          const fetchedSiteData = await fetchSiteData(towerId);
          await setTowerList((prev: ITowerList) => ({
            ...prev,
            [towerId]: fetchedSiteData,
          }));
        }

        if (type.site === 'Rooftop' && towerList[towerId].layout.site.image) {
          await setScale(0.55);
        } else {
          await setScale(1);
        }

        await setActiveHeaderButton((prev: IHeaderButtons) => ({
          ...prev,
          ...viewDetailsTarget,
        }));
        await setMinimize(true);
        await setActiveTowerID(towerId);
      },
    },
  };

  useEffect(() => {
    if (warningClicked) {
      setPanel('license');
      setWarningClicked(false);
    }
  }, [warningClicked, setWarningClicked]);

  return (
    <div {...props.infoCard}>
      {!panel && (
        <>
          <div className={classes.container}>
            <div className={classes.favorite}>
              <FavoritesButton {...props.favoriteButton} />
            </div>
            <div className={classes.left}>
              <ImageThumbnail {...props.imgProps} />
              <div className={classes.viewDataClass}>
                <a {...props.viewData}>View Tower Data</a>
              </div>
              <div
                className={
                  warningRequired
                    ? classes.licenseWarning
                    : classes.licenseClass
                }>
                <a {...props.licenses}>
                  License{' '}
                  {warningRequired ? (
                    <i className='fa-solid fa-circle-exclamation text-white' />
                  ) : (
                    <i className='fa-solid fa-angles-right' />
                  )}
                </a>
              </div>
            </div>
            <MarkerSiteInfo {...props.siteInfo} />
          </div>
          <MarkerTowerInfo {...props.towerInfo} />
          <InfoWindowComments {...props.commentInfo} />
        </>
      )}
      {panel === 'readMore' && <ReadMore {...props.readMoreInfo} />}
      {panel === 'license' && (
        <LicensePanel
          close={() => setPanel('')}
          towerID={towerId}
        />
      )}
    </div>
  );
};

export default InfoWindowCard;
