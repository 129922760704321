import {
  ICompanyContext,
  ILibraryContext,
  IModalContext,
  IUserContext,
} from 'state/iContext';
import {
  CompanyContext,
  LibraryContext,
  ModalContext,
  UserContext,
} from 'state/context';
import { ISaveToLibrary, ISaveToLibraryClasses } from '../iCustomIcon';
import React, { useContext, useMemo, ReactNode } from 'react';

import SaveBox from 'components/buttons/SaveBox';

const SaveToLibrary = ({ handlers, saveToLibrary }: ISaveToLibrary) => {
  const { company } =
    useContext(CompanyContext) as ICompanyContext;
  const {
    user: { private_libraries: userLibraries },
  } = useContext(UserContext) as IUserContext;
  const { libraries, activeIcon } =
    useContext(LibraryContext) as ILibraryContext;
  const { editIcon } =
    useContext(ModalContext) as IModalContext;

  const classes: ISaveToLibraryClasses = {
    container:
      'absolute flex flex-col z-50 bottom-1 right-1 flex w-1/2 h-1/2 p-5 bg-slate-100 border border-2 border-slate-400 rounded rounded-md',
    title: 'font-bold text-stone-500 m-1',
    list: 'flex flex-col justify-start h-5/6 bg-white border border-2 border-stone-300 rounded rounded-md overflow-hidden hover:overflow-y-auto',
    listItem: {
      active:
        'w-full h-fit px-2 pt-1 font-semibold text-slate-200 border-b border-black bg-red-600 cursor-pointer hover:text-slate-300 transition ease-in-out delay-100 hover:bg-red-700',
      inactive:
        'w-full h-fit px-2 pt-1 font-semibold text-stone-400 border-b cursor-pointer hover:text-slate-500 transition ease-in-out delay-100 hover:bg-red-200 even:bg-neutral-100',
    },
    saveBox: 'flex w-full justify-between self-end mt-3 lg:w-1/3',
  };

  const libraryList: string[] = useMemo(
    () => [...Object.keys({ ...userLibraries }), ...company.libraries],
    [userLibraries, company.libraries]
  );
  const saveToList: ReactNode[] = useMemo(
    () =>
      libraryList
        .filter(
          (id) =>
            !libraries[id].assets.includes(activeIcon) ||
            editIcon.type !== 'save'
        )
        .map((lib): ReactNode => {
          const activeClass: string =
            lib === saveToLibrary
              ? classes.listItem.active
              : classes.listItem.inactive;
          return (
            <div
              key={lib}
              onClick={() => handlers.setSaveToLibrary(lib)}
              className={activeClass}>
              {libraries[lib].name}
            </div>
          );
        }),
    [saveToLibrary, libraryList, classes.listItem, handlers, libraries]
  );

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Choose Library</h1>
      <div className={classes.list}>{saveToList}</div>
      <SaveBox
        classes={classes.saveBox}
        clickHandlers={handlers.saveBox}
        disabled={!saveToLibrary}
      />
    </div>
  );
};

export default SaveToLibrary;
