import React from 'react';
import { ICloseProp } from 'iApp';

const Close = ({ handler }: ICloseProp) => {
  return (
    <i
      className='fa-solid fa-xmark absolute text-stone-500 text-2xl top-1 right-3 cursor-pointer'
      onClick={handler}
    />
  );
};

export default Close;
