import { getFolderPathString, nextAvailableKey } from 'helpers/helperFunctions';
import { ITower, ITowerList, IUpdateFolders } from 'state/iState';
import React, { useContext, useState } from 'react';
import { IInputEvent, IStringProps } from 'iApp';
import axios from 'axios';
import {
  DocumentContext,
  HistoryContext,
  CanvasContext,
  ServerContext,
  SitesContext,
  UserContext,
} from 'state/context';
import {
  IDocumentContext,
  IHistoryContext,
  ICanvasContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';

const UpdateFolder = () => {
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { towerList, setTowerList, fetchSiteData } = useContext(
    SitesContext
  ) as ISitesContext;
  const { updateFolders, setUpdateFolders } = useContext(
    DocumentContext
  ) as IDocumentContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;

  const folder =
    towerList[activeTowerID].documents.folders[updateFolders.folder];

  const [datesRequired, setDatesRequired] = useState<boolean>(
    folder.datesRequired
  );

  const nextKey: number = nextAvailableKey(
    towerList[activeTowerID].documents.folders,
    0
  )!;

  const updateFolder = updateFolders.edit
    ? {
        [updateFolders.folder]: {
          name: updateFolders.name.trim(),
          folders: folder.folders,
          files: folder.files,
          datesRequired,
        },
      }
    : {
        [nextKey]: {
          name: updateFolders.name.trim(),
          folders: [],
          files: [],
          datesRequired,
        },
      };

  const updateHistory = async () => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    const filePath = getFolderPathString(
      +updateFolders.folder,
      towerList[activeTowerID].documents.folders
    );

    const folderNameChanged: boolean = folder.name !== updateFolders.name;

    const folderRequirementChanged: boolean =
      folder?.datesRequired !== datesRequired;

    const folderRequiredString = (value: boolean): string =>
      value
        ? 'Files require dates upon upload'
        : 'File no longer require dates';

    const historyDetail = {
      edit: [
        {
          before: [
            {
              text: folderNameChanged ? folder.name : '',
            },
            {
              text: folderRequirementChanged
                ? folderRequiredString(folder?.datesRequired)
                : '',
            },
          ],
          after: [
            {
              text: folderNameChanged ? updateFolders.name : '',
            },
            {
              text: folderRequirementChanged
                ? folderRequiredString(datesRequired)
                : '',
            },
            {
              text: filePath.path,
            },
          ],
          field: `${filePath.primaryParent} Folder ${
            folderRequirementChanged ? 'Modified' : 'Renamed'
          }`,
        },
      ],
      add: [
        {
          added: [
            {
              text: folder.name,
            },
            {
              text: `${filePath.path} > ${updateFolders.name}`,
            },
          ],
          field: `${filePath.primaryParent} Folder Added`,
        },
      ],
    };

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: `Folder`,
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: updateFolders.edit ? historyDetail.edit : historyDetail.add,
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const handler = {
    text: (e: IInputEvent) =>
      setUpdateFolders((prev: IUpdateFolders) => ({
        ...prev,
        name: e.target.value,
      })),
    date: () => setDatesRequired((prev: boolean) => !prev),
    save: async () => {
      const fetchedSiteData = await fetchSiteData(activeTowerID);
      await setTowerList((prev: ITower) => ({
        ...prev,
        [activeTowerID]: {
          ...fetchedSiteData,
          documents: {
            ...fetchedSiteData.documents,
            folders: {
              ...fetchedSiteData.documents.folders,
              [updateFolders.folder]: {
                ...fetchedSiteData.documents.folders[updateFolders.folder],
                folders: [
                  ...fetchedSiteData.documents.folders[updateFolders.folder]
                    .folders,
                  nextKey,
                ],
              },
              ...updateFolder,
            },
          },
        },
      }));
      await updateHistory();
      setUpdateFolders((prev: IUpdateFolders) => ({ ...prev, active: false }));
    },
    cancel: () => {
      setUpdateFolders((prev: IUpdateFolders) => ({ ...prev, active: false }));
    },
  };

  const classes: IStringProps = {
    container:
      'flex justify-center items-center w-full border border-stone-400 even:bg-slate-100',
    title: 'font-bold text-stone-500',
    text: 'mx-4 p-0',
    checkbox: 'mx-2',
    button:
      'p-1 px-2 m-1 border border-stone-400 rounded rounded-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
  };

  return (
    <div className={classes.container}>
      <label
        htmlFor='name'
        className={classes.title}>
        {updateFolders.edit ? <span>Edit</span> : <span>New</span>} Folder Name:
      </label>
      <input
        type='text'
        name='folder'
        onChange={handler.text}
        className={classes.text}
        value={updateFolders.name}
      />
      <label htmlFor='datesRequired'>Files Require Expiry Date:</label>
      <input
        type='checkbox'
        name='datesRequired'
        checked={datesRequired}
        onChange={handler.date}
        id='dates-required-checkbox'
        className={classes.checkbox}
      />
      <button
        className={classes.button}
        onClick={handler.save}>
        Save
      </button>
      <button
        className={classes.button}
        onClick={handler.cancel}>
        Cancel
      </button>
    </div>
  );
};

export default UpdateFolder;
