import { IWorkOrderList } from './iWorkOrders';
import React, { useContext } from 'react';
import axios from 'axios';
import {
  CompanyContext,
  DocumentContext,
  HistoryContext,
  ServerContext,
  SitesContext,
  UserContext,
  WorkOrderContext,
} from 'state/context';
import {
  ICompanyContext,
  IDocumentContext,
  IHistoryContext,
  IServerContext,
  ISitesContext,
  IUserContext,
  IWorkOrderContext,
} from 'state/iContext';

const WorkOrderDetail = ({ label, value }) => {
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { user, userList } = useContext(UserContext) as IUserContext;
  const { icons: assets } = useContext(CompanyContext) as ICompanyContext;
  const { domain, addHistoryToDB, updateWorkOrdersDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { setHistory, fetchHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { documentList, activeFile } = useContext(
    DocumentContext
  ) as IDocumentContext;
  const { workOrders, setWorkOrders, fetchWorkOrder } = useContext(
    WorkOrderContext
  ) as IWorkOrderContext;

  const classes = {
    container:
      'w-full mb-2 mx-0.5 p-2 rounded rounded-sm border border-stone-300 bg-stone-100',
    label: 'text-gray-700 font-medium',
    valueContainer: 'ml-2',
    list: 'list-disc list-inside ml-6',
    imageContainer: 'mb-2 flex flex-wrap',
    imageItem: 'w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mb-2',
    image: 'w-[80px] h-auto',
    checkbox: 'mr-2',
    strikethrough: 'line-through',
  };

  if (typeof activeFile !== 'number') {
    return null;
  }

  const handleCheckboxChange = async (id) => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);
    const latestWorkOrder = await fetchWorkOrder(activeFile);


    let afterText = '';
    const updatedChecklist = workOrders[activeFile!].checklist.map((item) => {
      if (item.id === id) {
        if (item.completionDate) {
          const { completionDate, completedBy, ...rest } = item;
          afterText = `${rest.text}: NOT COMPLETE`;
          return { ...rest };
        } else {
          const updatedItem = {
            ...item,
            completedBy: user.userId,
            completionDate: Date.now(),
          };
          afterText = `${updatedItem.text}: COMPLETED`;
          return updatedItem;
        }
      }
      return item;
    });

    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'WorkOrder',
      createdBy: user.userId,
      changes: {
        action: 'Updated',
        details: [
          {
            field: 'Checklist',
            updated: [{ text: afterText }],
          },
        ],
      },
    };

    // Update the history
    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    addHistoryToDB(historyEntry);
    setWorkOrders((prev: IWorkOrderList) => {
      const updatedWorkOrder = {
        ...latestWorkOrder,
        checklist: updatedChecklist,
        history: [...latestWorkOrder.history, nextHistoryKey.data.next_pk],
        lastModified: Date.now(),
        lastModifiedBy: user.userId,
      };
      updateWorkOrdersDB('PUT', updatedWorkOrder);

      return {
        ...prev,
        [activeFile!]: updatedWorkOrder,
      };
    });
  };

  const formatValue = (label, value) => {
    if (label === 'Work Order ID') {
      return String(value).padStart(8, '0');
    } else if (Array.isArray(value)) {
      if (label === 'Checklist') {
        return (
          <ul className={classes.list}>
            {value.map((item, index) => (
              <li key={index}>
                {workOrders[activeFile].progress === 'In Progress' &&
                workOrders[activeFile].assigned.includes(user.userId) &&
                workOrders[activeFile].confirmed[user.userId] ? (
                  <input
                    type='checkbox'
                    className={classes.checkbox}
                    checked={!!item.completionDate}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                ) : (
                  <span className={classes.checkbox} />
                )}
                <span
                  className={item.completionDate ? classes.strikethrough : ''}>
                  {item.text}
                </span>
              </li>
            ))}
          </ul>
        );
      } else {
        return (
          <div className={classes.imageContainer}>
            {value
              .filter((item) => item)
              .map((item, index) => (
                <div
                  key={index}
                  className={classes.imageItem}>
                  {typeof item === 'object' ? (
                    <div className='flex flex-col items-center'>
                      {item.timestamp ? (
                        <li className='text-sm'>
                          {label}:{' '}
                          {new Date(item.timestamp).toLocaleDateString()}
                        </li>
                      ) : (
                        <>
                          <li className='text-sm'>
                            {item.name || item.label || item.text}
                          </li>
                          <img
                            src={item.image}
                            alt={item.label}
                            className={classes.image}
                          />
                        </>
                      )}
                    </div>
                  ) : typeof item === 'number' ? (
                    <li>
                      {label === 'Files'
                        ? documentList[item]?.name
                        : towerList[item]?.name}
                    </li>
                  ) : (
                    <li>{userList[item]?.name || assets[item]?.name}</li>
                  )}
                </div>
              ))}
          </div>
        );
      }
    } else {
      return (
        <>
          {typeof value === 'number'
            ? new Date(value).toLocaleDateString()
            : userList[value]?.name || String(value)}
        </>
      );
    }
  };

  return (
    <div className={classes.container}>
      <span className={classes.label}>
        <strong>{label}:</strong>
      </span>
      <span className={classes.valueContainer}>
        {formatValue(label, value)}
      </span>
    </div>
  );
};

export default WorkOrderDetail;
