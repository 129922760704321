import { ISettingsContext, ISitesContext } from 'state/iContext';
import { SitesContext, SettingsContext } from 'state/context';
import React, { useContext, useMemo} from 'react';

import { IMinMaxClasses } from '../iSites';
import Site from './Site';

const SiteList = () => {
  const { minimize } = useContext(SettingsContext) as ISettingsContext;
  const { towerList, filteredSiteIds, favoriteSites, favoritesTab } =
    useContext(SitesContext) as ISitesContext;

  // Component classes
  const classes: IMinMaxClasses = {
    min: 'flex h-[100px] w-full overflow-y-hidden overflow-x-auto md:block md:max-h-screen md:h-full md:overflow-x-hidden md:overflow-y-auto',
    max: 'max-h-screen h-full border-y-2 border-stone-200 overflow-y-auto md:overflow-x-hidden md:pb-[5px]',
  };

  const minMaxList: string = minimize ? classes.min : classes.max;
  const siteIds: number[] = favoritesTab ? favoriteSites : filteredSiteIds;

  // Renders all site elements
  const sites: JSX.Element[] = useMemo(
    () =>
      siteIds.map(
        (tower: number): JSX.Element => (
          <Site
            key={tower}
            {...towerList[tower]!}
          />
        )
      ),
    [siteIds, towerList]
  );

  return (
    <div
      id='site-list'
      className={minMaxList}>
      {sites}
    </div>
  );
};

export default SiteList;
