import React, { useState, useContext, useEffect, useMemo } from 'react';
import { searchMatch } from 'helpers/helperFunctions';
import { useAuth0 } from '@auth0/auth0-react';
import { IBooleanProps } from 'iApp';
import axios from 'axios';
import {
  clearModal,
  clearAddProps,
  clearEditIcon,
  clearDragProps,
  emptyPortBlock,
  clearEditLibrary,
  activeDataToolBar,
  clearEditFileProps,
  clearDocumentTowerInfo,
  clearActiveTowerDetails,
  clearInspectionChecklist,
} from './state';
import {
  ILibraries,
  IGridLayout,
  IActiveSort,
  IActiveModal,
  IAppChildren,
  IEditLibrary,
  ILibraryIcon,
  IAddItemProps,
  IActiveToolBar,
  IEditFileProps,
  IUpdateFolders,
  IDocumentTowerInfo,
  IInspectionChecklist,
  ITowerDocument,
  IDocumentList,
} from './iState';
import {
  UserContext,
  ModalContext,
  CanvasContext,
  ServerContext,
  CompanyContext,
  LibraryContext,
  DocumentContext,
  SitesContext,
} from './context';
import {
  IUserContext,
  IServerContext,
  ICompanyContext,
  IApplicationProviderProps,
  ISitesContext,
} from './iContext';
import { IReportFormData, IReportImageData } from 'components/reports/iReports';

const ApplicationStateProvider = ({ children }: IAppChildren) => {
  const { user: authUser } = useAuth0();
  const {
    user: { private_libraries, standard_libraries, userId },
    lowCaseSearch,
  } = useContext(UserContext) as IUserContext;
  const { company, icons } = useContext(CompanyContext) as ICompanyContext;
  const { domain, updateLibrariesDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;

  // Canvas States
  const [scale, setScale] = useState<number>(1);
  const [activeTowerID, setActiveTowerID] = useState<number>(0);
  const [activeToolBar, setActiveToolBar] =
    useState<IActiveToolBar>(activeDataToolBar);
  const [dragProps, setDragProps] = useState<ILibraryIcon>(clearDragProps);
  const [addItemProps, setAddItemProps] =
    useState<IAddItemProps>(clearAddProps);

  // Library States
  const [activeIcon, setActiveIcon] = useState<string>('');
  const [activeLibrary, setActiveLibrary] = useState<string>('');
  const [libraries, setLibraries] = useState<ILibraries>({});
  const [libraryList, setLibraryList] = useState<string[]>([]);
  const [activeStandardLibraries, setActiveStandardLibraries] = useState<
    string[]
  >([]);
  const [activePrivateLibraries, setActivePrivateLibraries] = useState<
    string[]
  >([]);
  const [editLibrary, setEditLibrary] =
    useState<IEditLibrary>(clearEditLibrary);

  // Modal States
  const [activeModal, setActiveModal] = useState<IActiveModal>(clearModal);
  const [customIconStage, setCustomIconStage] = useState<number>(1);
  const [editIcon, setEditIcon] = useState<ILibraryIcon>(clearEditIcon);

  // Documents State
  const [documentList, setDocumentList] = useState<IDocumentList>({});
  const [activeFile, setActiveFile] = useState<number | null | 'add'>(null);
  const [editFileProps, setEditFileProps] =
    useState<IEditFileProps>(clearEditFileProps);
  const [activeSort, setActiveSort] = useState<IActiveSort>({
    target: 'upload',
    decend: true,
  });
  const [activeFilePanel, setActiveFilePanel] = useState<string>('tower');
  const [documentTowerInfo, setDocumentTowerInfo] =
    useState<IDocumentTowerInfo>(clearDocumentTowerInfo);
  const [activeTowerDetails, setActiveTowerDetails] = useState<IBooleanProps>(
    clearActiveTowerDetails
  );
  const [checklistData, setChecklistData] = useState<IInspectionChecklist>(
    clearInspectionChecklist
  );
  const [activeFolder, setActiveFolder] = useState<number>(0);
  const [updateFolders, setUpdateFolders] = useState<IUpdateFolders>({
    active: false,
    folder: '',
    parent: '',
    name: '',
  });
  const [activeReport, setActiveReport] = useState<number | null>(null);
  const [reportFormData, setReportFormData] = useState<IReportFormData>({});
  const [reportImageData, setReportImageData] = useState<IReportImageData>({});

  const clearPanel = () => {
    setActiveToolBar((prev: IActiveToolBar) => ({
      ...prev,
      select: true,
      image: false,
      text: false,
      drawings: false,
      edit: false,
      sections: false,
    }));
    setAddItemProps(clearAddProps);
  };

  const iconIndex: number = activeLibrary
    ? +Object.keys(libraries[activeLibrary]).filter(
        (num) => libraries[activeLibrary][num].id === activeIcon
      )[0]
    : 0;

  const assetNameList: string[] = useMemo(
    () => Object.keys(icons).map((id) => icons[id].name),
    [icons]
  );

  const assetNameSet: Set<string> = new Set(assetNameList);
  const assetNameInvalid: boolean =
    assetNameSet.has(editIcon.id) && editIcon.id !== icons[activeIcon]?.name;

  const {
    customIcon,
    createDocument,
    manageLibraries,
    downloadDocument,
    permissionSettings,
  } = activeModal;
  const isModalActive: boolean =
    customIcon ||
    createDocument ||
    manageLibraries ||
    downloadDocument ||
    permissionSettings;

  const searchFiltered: string[] = useMemo(
    () =>
      assetNameList
        .filter((name): boolean =>
          searchMatch({ searchInList: [name], lowCaseSearch })
        )
        .map((icon) => icon),
    [lowCaseSearch, assetNameList]
  );

  // Toggles what library will be displayed
  const toggleLibrary = (name: string): void => {
    const libraryChange: string = activeLibrary === name ? '' : name;
    setActiveLibrary(libraryChange);
  };

  // Toggles what icon will be focused
  const toggleIcon = (id: string): void => {
    const iconChange: string = activeIcon === id ? '' : id;
    setActiveIcon(iconChange);
    setEditIcon((prev: ILibraryIcon) => ({
      ...prev,
      content: icons[id].content,
      properties: icons[id].properties,
    }));
    console.log({ iconChange, activeIcon, editIcon });
  };

  const openGridItemOptions = (layout: IGridLayout, type: string) =>
    setDragProps((prev: ILibraryIcon) => ({
      ...prev,
      dragging: true,
      id: layout.i.toString(),
      content: layout.name!.toString(),
      type,
      target: '',
    }));

  const getAllFilesFromFolder = (folderId: string): string[] => {
    const folder = towerList[activeTowerID]?.documents?.folders[folderId];
    let files: string[] = folder?.files || [];

    (folder?.folders || []).forEach((subFolderId: string) => {
      files = files.concat(getAllFilesFromFolder(subFolderId));
    });

    return files;
  };

  const fetchDocuments = async () => {
    try {
      if (!authUser) return;

      const documentsResponse = await fetch(`${domain}documents/`);

      if (!documentsResponse.ok) {
        console.log('Attempt to load Tower Documents Failed');
        return;
      }

      const documentsData = await documentsResponse.json();

      // Process documentsData
      const transformedDocumentsData = {};
      documentsData.documents.forEach((document) => {
        transformedDocumentsData[document.id] = {
          custom: document.custom,
          ...document.document_data,
        };
      });

      setDocumentList(transformedDocumentsData);
      return transformedDocumentsData;
    } catch (error: any) {
      console.error('Error fetching documents:', error.message);
    }
  };

  const fetchLibraries = async () => {
    try {
      if (!authUser) return;

      const librariesResponse = await fetch(`${domain}libraries/`);

      if (!librariesResponse.ok) {
        console.log('Attempt to load Libraries Data Failed');
        return;
      }

      const librariesData = await librariesResponse.json();

      // Process librariesData
      const transformedLibrariesData = {};
      librariesData.libraries.forEach((library) => {
        transformedLibrariesData[library.libraryId] = {
          libraryId: library.libraryId,
          name: library.name,
          assets: library.assets,
        };
      });
      setLibraries(transformedLibrariesData);
    } catch (error: any) {
      console.error('Error fetching libraries:', error.message);
    }
  };

  useEffect(() => {
    if (!company) return;

    const standardlibraries: string[] = [
      ...company.libraries,
      '2',
      '3',
      '4',
    ].sort((a, b) => libraries[a].name.localeCompare(libraries[b].name));

    setActiveStandardLibraries(standardlibraries);
  }, [company, setActiveStandardLibraries]);

  useEffect(() => {
    if (!company) return;
    const privateList: string[] = [
      userId,
      ...Object.keys(private_libraries).filter((id) => id !== userId),
    ].sort((a, b) => libraries[a].name.localeCompare(libraries[b].name));

    setActivePrivateLibraries(privateList);
  }, [userId, private_libraries, company, setActivePrivateLibraries]);

  useEffect(() => setActiveFile('add'), [activeTowerID]);

  // Controls what libraries will be displayed
  useEffect(() => {
    if (!company) return;

    const privateLibraries: string[] = activePrivateLibraries.filter(
      (lib) => private_libraries[lib]
    );
    const combinedLibraries: string[] = [
      ...privateLibraries,
      ...standard_libraries,
    ].sort((a, b) => libraries[a].name.localeCompare(libraries[b].name));

    setLibraryList(combinedLibraries);
  }, [
    setLibraryList,
    activePrivateLibraries,
    private_libraries,
    standard_libraries,
  ]);

  useEffect(() => {
    const checkUserLibrary = async () => {
      try {
        if (authUser) {
          const userId: string = authUser.sub!.split('|')[1];

          const userLibraryResponse = await fetch(
            `${domain}libraries/${userId}/`
          );

          if (userLibraryResponse.status === 404) {
            updateLibrariesDB('POST', {
              libraryId: userId,
              name: authUser.name!,
              assets: [],
            });
          }
        }
      } catch (error: any) {
        console.log(error.message);
      }
    };

    checkUserLibrary();
  }, [domain, authUser]);

  const [newDocKey, setNewDocKey] = useState<number>(0);

  useEffect(() => {
    const fetchNextDocKey = async () => {
      try {
        const response = await axios.get(`${domain}get-next-pk/documents/`);
        response.data && response.data.next_pk !== undefined
          ? setNewDocKey(response.data.next_pk)
          : console.error('Invalid response received:', response.data);
      } catch (error) {
        console.error('Error fetching next document key:', error);
      }
    };

    fetchNextDocKey();
  }, [documentList]);

  useEffect(() => {
    fetchLibraries();
    fetchDocuments();
  }, [authUser, domain]);

  // Props to pass to their respective elements
  const providerProps: IApplicationProviderProps = {
    data: {
      scale,
      setScale,
      activeTowerID,
      setActiveTowerID,
      activeToolBar,
      setActiveToolBar,
      emptyPortBlock,
      clearDragProps,
      dragProps,
      setDragProps,
      clearAddProps,
      addItemProps,
      setAddItemProps,
      clearPanel,
      openGridItemOptions,
    },
    libraries: {
      iconIndex,
      searchFiltered,
      clearEditLibrary,
      activeLibrary,
      setActiveLibrary,
      editLibrary,
      setEditLibrary,
      toggleLibrary,
      libraries,
      setLibraries,
      activeIcon,
      setActiveIcon,
      toggleIcon,
      libraryList,
      setLibraryList,
      activePrivateLibraries,
      setActivePrivateLibraries,
      activeStandardLibraries,
      setActiveStandardLibraries,
      assetNameInvalid,
      fetchLibraries,
    },
    modals: {
      clearModal,
      clearEditIcon,
      activeModal,
      setActiveModal,
      isModalActive,
      customIconStage,
      setCustomIconStage,
      editIcon,
      setEditIcon,
    },
    documents: {
      documentList,
      setDocumentList,
      activeFile,
      setActiveFile,
      editFileProps,
      setEditFileProps,
      clearEditFileProps,
      activeSort,
      setActiveSort,
      activeFilePanel,
      setActiveFilePanel,
      clearDocumentTowerInfo,
      documentTowerInfo,
      setDocumentTowerInfo,
      activeTowerDetails,
      setActiveTowerDetails,
      clearActiveTowerDetails,
      clearInspectionChecklist,
      checklistData,
      setChecklistData,
      updateFolders,
      setUpdateFolders,
      activeFolder,
      setActiveFolder,
      getAllFilesFromFolder,
      newDocKey,
      setNewDocKey,
      fetchDocuments,
      activeReport,
      setActiveReport,
      reportFormData,
      setReportFormData,
      reportImageData,
      setReportImageData,
    },
  };

  return (
    <CanvasContext.Provider value={providerProps.data}>
      <ModalContext.Provider value={providerProps.modals}>
        <LibraryContext.Provider value={providerProps.libraries}>
          <DocumentContext.Provider value={providerProps.documents}>
            {children}
          </DocumentContext.Provider>
        </LibraryContext.Provider>
      </ModalContext.Provider>
    </CanvasContext.Provider>
  );
};

export default ApplicationStateProvider;
