import { IEditSave } from '../editPanel/iEdit';
import Button from 'components/buttons/Button';
import React from 'react';

const Save = ({ save, cancel }: { [key: string]: IEditSave }) => (
  <div className='flex w-full justify-end p-2'>
    <Button
      name={cancel.name!}
      handler={cancel.handler}
    />
    <Button
      name={save.name!}
      handler={save.handler}
    />
  </div>
);

export default Save;