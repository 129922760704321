import { ISiteInfoShortLong } from '../components/home/map/infoWindowDetails/iInfoWindow';
import { IMinMaxClasses } from '../components/home/sitesPanel/iSites';
import React, { ReactElement, ReactNode } from 'react';
import { ISearchMatch } from './iHelpers';
import { IStringProps } from 'iApp';
import {
  ITower,
  IFolders,
  ILibraries,
  IMeasurements,
  LatLngLiteral,
  IDocumentProps,
  IMeasurelength,
  IGridLayoutProps,
  ITowerLayoutProps,
  IVerticalityContent,
  IDocumentImageProps,
  IDocumentDeficiencies,
} from 'state/iState';
import moment from 'moment-timezone';
import { Layout } from 'react-grid-layout';

// Returns red/blue class if 'all-sites-tab' is provided
export const isAllType = (id: string): string =>
  id === 'all-sites-tab' ? 'text-blue-500' : 'text-red-600';

// Returns classes based on minimize state of side panel
export const isMinClass = (state: boolean, classes: IMinMaxClasses): string =>
  state ? classes.min : classes.max;

// Converts 'white spaced' strings 'to-kebab-format'
export const kebabString = (string: string): string =>
  string.trim().toLowerCase().split(' ').join('-');

// Converts number 'telephone-kebab-format'
export const kebabNumber = (num: number): string => {
  const numStr = num.toString();
  return numStr.replace(/(\d{3})(\d{4})/, '$1-$2-');
};

// Converts a string of multiple words to Camel Case
export const camelCaseStr = (str) =>
  str.replace(/\s+(.)/g, (_, match) => match.toUpperCase());

// Highlights parts of provided string that match the search bar
export const highlightSearch = (
  str: string,
  search: string
): ReactNode[] | string => {
  const searchFor: string = search.toLowerCase().trim();
  const searchIn: string = str.toLowerCase();
  const searchMatch: boolean = searchIn.includes(searchFor);
  const index: number = searchIn.indexOf(searchFor);
  const splitStr: string[] = [
    str.slice(0, index),
    str.slice(index, index + searchFor.length),
    str.slice(index + searchFor.length),
  ];

  const splitSearchMatch = (arr: string[]): string[] => {
    const lastItem: string = arr[arr.length - 1];
    if (lastItem.toLowerCase().includes(searchFor) && search !== '') {
      const nextIndex: number = lastItem.toLowerCase().indexOf(searchFor);
      const spliced: string[] = [
        ...arr.slice(0, -1),
        lastItem.slice(0, nextIndex),
        lastItem.slice(nextIndex, nextIndex + searchFor.length),
        lastItem.slice(nextIndex + searchFor.length),
      ];
      return splitSearchMatch(spliced);
    } else {
      return arr;
    }
  };
  const output: string[] = splitSearchMatch(splitStr);

  if (searchMatch && search !== '') {
    const highlightOutput: ReactNode[] = output.map((str, i): ReactNode => {
      const highlight: string =
        str.toLowerCase() === searchFor ? 'bg-yellow-200' : '';
      return str.toLowerCase() === searchFor ? (
        <span
          key={`highlight-${str}-${i}`}
          className={highlight}>
          {str}
        </span>
      ) : (
        <span key={`highlight-${str}-${i}`}>{str}</span>
      );
    });
    return highlightOutput;
  } else {
    return str;
  }
};

// Converts provided info to a string if it is an array of JSX components
export const infoObjectToString = (info: string | ReactElement[]): string => {
  let infoString: string = '';

  if (typeof info === 'object') {
    info.forEach(
      (obj: ReactElement, i: number) => (infoString += info[i].props.children)
    );
  } else {
    infoString = info;
  }
  return infoString;
};

// Returns short/long classes based on max length provided
export const isInfoLong = (
  data: string | ISiteInfoShortLong,
  isMaxLength: boolean
): string => {
  if (typeof data === 'string' || !isMaxLength) {
    return data as string;
  } else {
    return data.short;
  }
};

// Returns mile distance between two lat/long coordinates
export const haversineDistance = (
  tower1: ITower,
  tower2: ITower,
  measurement: string
): number => {
  const earthRadius: number = measurement === 'kms' ? 6371 : 3958.8;

  // Converts degrees to radians
  const radianlat1: number = tower1.location.lat * (Math.PI / 180);
  const radianlat2: number = tower2.location.lat * (Math.PI / 180);

  // Radian differences in lat/long
  const difflat: number = radianlat2 - radianlat1;
  const difflng: number =
    (tower2.location.lng - tower1.location.lng) * (Math.PI / 180);

  const distance: number =
    2 *
    earthRadius *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(radianlat1) *
            Math.cos(radianlat2) *
            Math.sin(difflng / 2) *
            Math.sin(difflng / 2)
      )
    );
  return distance;
};

// Returns mid coordinate between two lat/long coodinates
export const midPoint = (tower1: ITower, tower2: ITower): LatLngLiteral => {
  const { atan2, sin, cos, sqrt, PI } = Math;
  const { location: loc1 } = tower1;
  const { location: loc2 } = tower2;

  // Convert all coordinates into radians
  const radLat1: number = loc1.lat * (PI / 180);
  const radLng1: number = loc1.lng * (PI / 180);
  const radLat2: number = loc2.lat * (PI / 180);
  const radLng2: number = loc2.lng * (PI / 180);

  // Convert lat/long to cartesian (x,y,z) coordinates
  const cartX1: number = cos(radLat1) * cos(radLng1);
  const cartY1: number = cos(radLat1) * sin(radLng1);
  const cartZ1: number = sin(radLat1);
  const cartX2: number = cos(radLat2) * cos(radLng2);
  const cartY2: number = cos(radLat2) * sin(radLng2);
  const cartZ2: number = sin(radLat2);

  // Compute combined weighted cartesian coordinate
  const weightedCartX: number = (cartX1 + cartX2) / 2;
  const weightedCartY: number = (cartY1 + cartY2) / 2;
  const weightedCartZ: number = (cartZ1 + cartZ2) / 2;

  // Convert cartesian coordinate to latitude and longitude for the midpoint
  const cartRadLng: number = atan2(weightedCartY, weightedCartX);
  const cartRadHyp: number = sqrt(
    weightedCartX * weightedCartX + weightedCartY * weightedCartY
  );
  const cartRadLat: number = atan2(weightedCartZ, cartRadHyp);

  // Convert midpoint lat and long from radians to decimal degrees
  const lat: number = cartRadLat * (180 / PI);
  const lng: number = cartRadLng * (180 / PI);

  return { lat, lng };
};

// Returns measurement unit type
export const measurementType = (
  metricActive: boolean,
  measurements: IMeasurements
): IMeasurelength =>
  metricActive ? measurements.metric : measurements.imperial;

// Converts between Metric/Imperial
export const measurementConversion = (
  metric: boolean,
  height: number
): number => (metric ? +(height / 3.28084).toFixed(2) : height);

// Checks if provided search is found in data
export const searchMatch = ({
  searchInList,
  lowCaseSearch,
}: ISearchMatch): boolean =>
  lowCaseSearch === '' ||
  searchInList.some((name) => name.toLowerCase().includes(lowCaseSearch));

// Returns next available key in object
export const nextAvailableKey = (
  obj:
    | IGridLayoutProps
    | ITowerLayoutProps
    | IDocumentProps
    | IVerticalityContent
    | IDocumentDeficiencies
    | ILibraries
    | { [key: number]: IStringProps }
    | { [key: number]: IDocumentImageProps }
    | {
        [key: number]: {
          id: number;
          timestamp: number;
          type: string;
          changes: {
            action: string;
            details: Array<{ field: string; before: any; after: any }>;
          };
          createdBy: string;
        };
      },
  offset: number
): number | void => {
  if (!obj) {
    return offset;
  }

  const existingKeys = Object.keys(obj)
    .map(Number)
    .filter((key) => !isNaN(key));

  const maxKey = Math.max(...existingKeys, 0);

  return maxKey + offset + 1;
};

// Sorting logic for string variables
export const sortByName = (a: string, b: string): number => b.localeCompare(a);

export const inspectionAlert = (date: number): string => {
  const days: IStringProps = {
    due: 'text-red-600 font-bold',
    within30: 'text-yellow-600 font-bold',
    notDue: 'font-bold',
  };

  // Converts dates to UTC strings
  const now: Date = new Date();
  const dueDate: Date = new Date(date);
  const within30Date: Date = new Date(
    new Date(dueDate).setDate(new Date(dueDate).getDate() - 30)
  );

  // Converts UTC strings to UTC number values
  const nowUTC: number = Date.parse(now.toString());
  const within30UTC: number = Date.parse(within30Date.toString());

  if (nowUTC > within30UTC && nowUTC < date) {
    return days.within30;
  } else if (nowUTC > date) {
    return days.due;
  } else {
    return days.notDue;
  }
};

export const getDescendants = (
  folderId: string,
  folders: IFolders
): number[] => {
  const folder = folders[folderId];
  let descendants: number[] = [];

  folder.folders.forEach((childId: string) => {
    descendants.push(+childId);
    descendants.push(...getDescendants(childId, folders));
  });

  return descendants;
};

export const displayImageProperty = (layout, domain): string => {
  return layout.name && !!layout.properties
    ? `${domain}${layout.properties.find((obj) => obj.path)!.path}`
    : '';
};

export const convertImageToWebP = async (
  imageDataUrl: string,
  fileName: string,
  icon?: boolean
): Promise<File> => {
  return new Promise(async (resolve) => {
    // Create an image element to manipulate the image
    const img = new Image();
    img.src = imageDataUrl;

    // Wait for the image to load
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas dimensions to match the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      ctx?.drawImage(img, 0, 0, img.width, img.height);

      // Convert the canvas content to WebP format
      const webPImageDataUrl = canvas.toDataURL('image/webp');

      // Convert the data URL to a Blob
      const blob = await fetch(webPImageDataUrl).then((res) => res.blob());

      // Create a File object with the Blob
      const file = new File(
        [blob],
        `${fileName}${icon ? '' : Date.now()}.webp`,
        {
          type: 'image/webp',
        }
      );

      // Resolve with the File object containing the WebP image
      resolve(file);
    };
  });
};

export const arraysEqual = (a: any[], b: any[]): boolean => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const sortedA = a.map((item) => JSON.stringify(item)).sort();
  const sortedB = b.map((item) => JSON.stringify(item)).sort();

  for (let i = 0; i < sortedA.length; i++) {
    if (sortedA[i] !== sortedB[i]) {
      return false;
    }
  }

  return true;
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const formatDateTime = (timestamp) => {
  const date = moment(timestamp);
  return date.format('ddd, DD MMM YYYY HH:mm z');
};

export const formatFieldName = (fieldName) => {
  if (typeof fieldName !== 'string') {
    return fieldName;
  }

  if (fieldName === 'partsAssets') {
    return 'Parts/Assets';
  }

  if (fieldName === 'img') {
    return 'Image';
  }

  const words = fieldName.replace(/([a-z])([A-Z])/g, '$1 $2');

  const formatted = words
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formatted;
};

export const compareObjects = (updatedObj, currentObj, key) => {
  if (!updatedObj || !currentObj) {
    return false;
  }

  const keysToCompare = ['x', 'y', 'h', 'w'];

  for (const index in updatedObj) {
    if (!Object.prototype.hasOwnProperty.call(currentObj, index)) {
      return true;
    }

    const updatedItem = updatedObj[index];
    const currentItem = currentObj[index];

    if (typeof updatedItem === 'object' && updatedItem !== null) {
      if (key === 'legs' && updatedItem.legGrid && currentItem.legGrid) {
        for (const prop of keysToCompare) {
          if (updatedItem.legGrid[prop] !== currentItem.legGrid[prop]) {
            return true;
          }
        }
        continue;
      }

      for (const prop of keysToCompare) {
        if (updatedItem[prop] !== currentItem[prop]) {
          return true;
        }
      }
    } else if (updatedItem !== currentItem) {
      return true;
    }
  }
  return false;
};

export const compareLayouts = (updated, current) => {
  if (!updated || !current) {
    return true;
  }

  const compareSubObjects = (key) => {
    const updatedSub = updated[key];
    const currentSub = current[key];

    if (Array.isArray(updatedSub)) {
      if (updatedSub.length !== (currentSub?.length || 0)) {
        return true;
      }
      for (let i = 0; i < updatedSub.length; i++) {
        if (compareObjects(updatedSub[i], currentSub[i], key)) {
          return true;
        }
      }
    } else if (typeof updatedSub === 'object' && updatedSub !== null) {
      if (compareObjects(updatedSub, currentSub, key)) {
        return true;
      }
    } else if (updatedSub !== currentSub) {
      return true;
    }

    return false;
  };

  // Compare relevant sub-objects in the tower layout
  const subKeys = ['legs', 'textBoxes', 'images', 'drawings'];
  for (const key of subKeys) {
    if (compareSubObjects(key)) {
      return true;
    }
  }

  return false;
};

export const compareIconLayouts = (layout1: Layout, layout2: Layout) => {
  const keys1 = Object.keys(layout1);
  const keys2 = Object.keys(layout2);

  if (keys1.length !== keys2.length) {
    return true;
  }

  for (let key of keys1) {
    if (
      layout1[key].h !== layout2[key].h ||
      layout1[key].y !== layout2[key].y
    ) {
      return true;
    }
  }

  return false;
};

export const getParentFolderIds = (folderId: number, folders: IFolders) => {
  const path: number[] = [];
  let currentFolderId: number = folderId;

  while (currentFolderId !== 0 && currentFolderId !== undefined) {
    const parentFolder = Object.entries(folders).find(([_, folder]) =>
      folder.folders.includes(currentFolderId)
    );

    if (!parentFolder) break;

    const [parentId] = parentFolder;
    const parentIdNumber = parseInt(parentId, 10);
    path.push(parentIdNumber);
    currentFolderId = parentIdNumber;
  }

  return path.reverse();
};

export const getFolderPathString = (
  currentFolderId: number,
  folders: IFolders
): { primaryParent: string; path: string } => {
  const parentFolderIds = getParentFolderIds(currentFolderId, folders);
  const parentFolderNames = parentFolderIds
    .filter((id) => id !== 0)
    .map((id) => folders[id].name);

  // Get the current folder name
  const currentFolderName = folders[currentFolderId].name;

  // Format the string
  const folderPath = [...parentFolderNames, currentFolderName].join(' > ');

  return { primaryParent: parentFolderNames.length === 0 ? folderPath : folders[parentFolderIds[1]]?.name, path: folderPath };
};
