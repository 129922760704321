import { ICanvasContext, ISettingsContext } from 'state/iContext';
import { CanvasContext, SettingsContext } from 'state/context';
import { IClasses } from './iResize';
import React, { useContext } from 'react';

const ResizeButton = ({ main, min, max }: IClasses) => {
  const { minimize, changePanelSize } = useContext(
    SettingsContext
  ) as ISettingsContext;
  const { clearPanel } = useContext(CanvasContext) as ICanvasContext;

  const iconClasses: string = minimize ? min : max;

  return (
    <div className={main}>
      <i
        className={iconClasses}
        onClick={() => {
          clearPanel();
          changePanelSize();
        }}
      />
    </div>
  );
};

export default ResizeButton;
