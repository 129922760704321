import React, { useState } from 'react';

const TableHeader = ({ header, accessor, sortBy, setSortBy }) => {
  const [sortedColumn, setSortedColumn] = useState<string>('');

  const upArrowIcon = (
    <i className='fa-solid fa-sort-up text-red-600 inline-block align-text-bottom' />
  );
  const downArrowIcon = (
    <i className='fa-solid fa-sort-down text-red-600 inline-block align-text-top' />
  );
  const neutralIcon = (
    <i className='fa-solid fa-sort text-blue-400 inline-block align-text-middle' />
  );

  const sortableColumns = [
    'id',
    'priority',
    'project',
    'startDate',
    'endDate',
    'locations',
  ];

  const getSortIcon = (columnAccessor: string) => {
    return sortableColumns.includes(
      columnAccessor as (typeof sortableColumns)[number]
    ) ? (
      <span>
        {sortedColumn === columnAccessor
          ? sortBy.direction === 'asc'
            ? upArrowIcon
            : downArrowIcon
          : neutralIcon}
      </span>
    ) : null;
  };

  const handleSort = (field: string) => {
    if (sortableColumns.includes(field as (typeof sortableColumns)[number])) {
      setSortBy((prevSortBy) => {
        const isDesc =
          prevSortBy.field === field && prevSortBy.direction === 'asc';

        return {
          field,
          direction: isDesc ? 'desc' : 'asc',
        };
      });

      setSortedColumn(field);
    }
  };

  const classes = {
    header: 'p-0.5 border border-stone-300 bg-stone-200 text-stone-500 text-xs',
    flex: 'flex w-full items-center justify-center text-base',
    margin: 'm-0.5',
  };

  return (
    <th onClick={() => handleSort(accessor)} className={classes.header}>
      <div className={classes.flex}>
        <div className={classes.margin}>{header}</div>
        <div className={classes.margin}>{getSortIcon(accessor)}</div>
      </div>
    </th>
  );
};

export default TableHeader;
