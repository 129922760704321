import { DocumentContext, UserContext, WorkOrderContext } from 'state/context';
import WorkOrderHistory from 'components/history/WorkOrderHistory';
import WorkOrderActions from './page/WorkOrderActions';
import React, { useContext, useState } from 'react';
import WorkOrderForm from './form/WorkOrderForm';
import WorkOrderDetail from './WorkOrderDetail';
import { clearWorkOrder } from 'state/state';
import FilesTab from './files/FilesTab';
import {
  IDocumentContext,
  IUserContext,
  IWorkOrderContext,
} from 'state/iContext';

enum Tabs {
  DETAILS = 'DETAILS',
  FILES = 'FILES',
  EDIT = 'EDIT',
}

const classes = {
  container:
    'w-full h-full p-4 border border-stone-300 bg-stone-100 overflow-y-auto overflow-x-hidden',
  title: 'w-full text-center text-2xl font-bold mb-4',
  innerContainer: 'flex justify-around w-full h-full',
  flexContainer: 'flex justify-around w-full',
  buttons: 'flex w-full justify-between',
  tabContainer: 'flex mb-4',
  tab: 'cursor-pointer px-4 py-2 border-b-2',
  activeTab: 'border-stone-600',
};

const labelMapping = {
  id: 'Work Order ID',
  progress: 'Progress',
  project: 'Project',
  priority: 'Priority',
  startDate: 'Start Date',
  endDate: 'End Date',
  description: 'Description',
  locations: 'Location/Site(s)',
  assigned: 'Assigned',
  partsAssets: 'Parts | Assets',
  checklist: 'Checklist',
  files: 'Files',
  notes: 'Notes',
  createdBy: 'Created By',
  createdDate: 'Created Date',
  lastModifiedBy: 'Last Modified By',
  lastModified: 'Last Modified',
};

const sectionKeys = {
  line1: ['id', 'progress', 'project'],
  line2: ['priority', 'startDate', 'endDate'],
  line3: ['description'],
  line4: ['locations', 'assigned'],
  line5: ['partsAssets', 'checklist'],
  line6: ['files', 'notes'],
  line7: ['createdBy', 'createdDate', 'lastModifiedBy', 'lastModified'],
};

const WorkOrderPage = ({ id, onClose }) => {
  const { workOrders, setWorkOrderStates } = useContext(
    WorkOrderContext
  ) as IWorkOrderContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { activeFile } = useContext(DocumentContext) as IDocumentContext;

  const [activeTab, setActiveTab] = useState(Tabs.DETAILS);
  const [historyVisible, setHistoryVisible] = useState(true);

  const handleTabClick = (tab: React.SetStateAction<Tabs>) => setActiveTab(tab);

  const toggleEditMode = () => {
    if (activeTab === Tabs.EDIT) setWorkOrderStates(clearWorkOrder);
    setActiveTab((prevTab) =>
      prevTab === Tabs.EDIT ? Tabs.DETAILS : Tabs.EDIT
    );
  };

  const toggleHistoryVisibility = () =>
    setHistoryVisible((prevVisibility) => !prevVisibility);

  return (
    <div className={classes.container}>
      {activeTab === Tabs.EDIT ? (
        <WorkOrderForm
          toggleWorkOrder={toggleEditMode}
          workOrder={id}
        />
      ) : (
        <>
          <h2 className={classes.title}>Work Order Details</h2>
          <WorkOrderActions
            onClose={onClose}
            handleTabClick={handleTabClick}
            toggleHistoryVisibility={toggleHistoryVisibility}
            activeTab={activeTab}
            historyVisible={historyVisible}
            Tabs={Tabs}
          />
          {(user.admin || workOrders[activeFile]?.confirmed?.[user.userId]) && (
            <div className={classes.tabContainer}>
              {Object.keys(Tabs)
                .filter((tab) => tab !== 'EDIT')
                .map((tab) => (
                  <div
                    key={tab}
                    className={`${classes.tab} ${
                      activeTab === Tabs[tab] && classes.activeTab
                    }`}
                    onClick={() => handleTabClick(Tabs[tab])}>
                    {Tabs[tab]}
                  </div>
                ))}
            </div>
          )}
          {activeTab === Tabs.DETAILS && (
            <div className={classes.innerContainer}>
              <div className={classes.container}>
                {Object.keys(sectionKeys).map((key) => (
                  <div
                    key={key}
                    className={classes.flexContainer}>
                    {sectionKeys[key].map((label) => (
                      <WorkOrderDetail
                        key={label}
                        label={labelMapping[label]}
                        value={workOrders[id][label]}
                      />
                    ))}
                  </div>
                ))}
              </div>
              {historyVisible && (
                <WorkOrderHistory history={workOrders[id]?.history || []} />
              )}
            </div>
          )}
          {activeTab === Tabs.FILES && (
            <FilesTab files={workOrders[activeFile!].files} />
          )}
        </>
      )}
    </div>
  );
};

export default WorkOrderPage;
