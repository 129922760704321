import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from 'react';
import axios from 'axios';
import {
  IAddItemProps,
  ICanvasLayoutData,
  ICanvasLayoutSite,
  ICanvasLayoutTower,
  IGridLayout,
  ITowerList,
} from 'state/iState';
import { nextAvailableKey } from 'helpers/helperFunctions';
import SaveBox from 'components/buttons/SaveBox';
import { IInputEvent, IStringProps } from 'iApp';
import {
  SitesContext,
  CanvasContext,
  HeaderContext,
  ServerContext,
  UserContext,
  HistoryContext,
} from 'state/context';
import {
  ICanvasContext,
  IHeaderContext,
  IHistoryContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';

const classes: IStringProps = {
  alert: 'text-red-600 font-bold',
  container: 'w-full h-1/3 p-5',
  card: 'relative w-full h-full p-3 border border-stone-300 bg-slate-200 rounded rounded-md',
  modify: 'flex justify-between items-center py-2',
  label: 'font-bold text-lg text-stone-500',
  select: 'rounded rounded-md',
  section: 'flex justify-between py-2',
  savebox: 'absolute flex bottom-5 right-0 w-[150px] justify-around sm:mx-5',
};

const EditContainerPanel = () => {
  const { setTowerList, towerList } = useContext(SitesContext) as ISitesContext;
  const { deleteFileFromDB, domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const {
    clearPanel,
    addItemProps,
    activeTowerID,
    clearAddProps,
    setAddItemProps,
    setDragProps,
    clearDragProps,
  } = useContext(CanvasContext) as ICanvasContext;
  const {
    activeCanvasTab,
    activeHeaderButton: { data, tower },
  } = useContext(HeaderContext) as IHeaderContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;

  const [originalLabel, setOriginalLabel] = useState<string>('');

  const activeLayouts:
    | ICanvasLayoutSite
    | ICanvasLayoutTower
    | ICanvasLayoutData = towerList[activeTowerID].layout[activeCanvasTab];

  const labelList: IGridLayout[] = useMemo(
    () => [
      ...('legs' in activeLayouts
        ? Object.values(activeLayouts.legs).map((leg) => leg.legGrid)
        : []),
      ...('shelves' in activeLayouts
        ? Object.values(activeLayouts.shelves).map((shelf) => shelf.shelfGrid)
        : []),
      ...Object.values(activeLayouts.textBoxes),
      ...Object.values(activeLayouts.images),
    ],
    [activeLayouts]
  );

  const siteTabActive = activeCanvasTab === 'site';
  const dataTarget = useMemo(
    () =>
      data
        ? ['data', 'shelves', 'shelfGrid']
        : tower
        ? ['tower', 'legs', 'legGrid']
        : ['site'],
    [data]
  );

  const itemList =
    data || tower
      ? towerList[activeTowerID].layout[dataTarget[0]][dataTarget[1]]
      : towerList[activeTowerID].layout.site;

  const nextKey = nextAvailableKey(itemList, 0)!;
  const keys = Object.keys(itemList);
  const gridItems = siteTabActive
    ? [towerList[activeTowerID].layout.site]
    : keys.map((key) => itemList[key][dataTarget[2]]);
  const itemPositions =
    data || tower
      ? Object.keys(itemList).map((item) => itemList[item][dataTarget[2]].x)
      : [0];
  const nextPosition = Math.max(...itemPositions) + 35;

  const [modifyType, setModifyType] = useState<string>(
    siteTabActive ? 'remove' : 'add'
  );
  const [target, setTarget] = useState<number>(addItemProps.index || 0);

  const { [target]: tobeRemoved, ...rest } = itemList;

  const getItemName = () =>
    (data ? 'Data' : 'Tower') + ' ' + (data ? 'Rack' : 'Leg');

  const createHistoryEntry = (type, content) => ({
    field: `${getItemName()} ${type}`,
    ...content,
  });

  const options: ReactNode[] = gridItems.map((container) => (
    <option
      key={container.i}
      value={container.i}>
      {container.name}
    </option>
  ));

  const gridHeight = data
    ? Object.keys(itemList).length > 0
      ? itemList[target]?.[dataTarget[2]]?.h
      : 40
    : towerList[activeTowerID]?.height;

  const isLabelUnique = (label: string, labelList: IGridLayout[]): boolean => {
    if (label.trim().toLowerCase() === originalLabel.trim().toLowerCase()) {
      return true;
    }

    // Check if the label is unique among other items.
    return !labelList
      .filter((item) => item && item.name !== originalLabel)
      .some(
        (item) => item.name?.trim().toLowerCase() === label.trim().toLowerCase()
      );
  };

  const saveHandler = useMemo(
    () => ({
      add: (prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [dataTarget[0]]: {
              ...prev[activeTowerID].layout[dataTarget[0]],
              [dataTarget[1]]: {
                ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]],
                [nextKey]: {
                  [dataTarget[2]]: {
                    i: nextKey,
                    name: addItemProps.label,
                    x: nextPosition,
                    y: 500,
                    w: 10,
                    h: gridHeight,
                    resizeHandles: ['n', 's'],
                  },
                  icons: {
                    '-1': {
                      i: -1,
                      name: '',
                      x: 0,
                      y: gridHeight,
                      w: 0,
                      minW: 0,
                      minH: 0,
                      h: 0,
                      static: true,
                      isResizable: false,
                    },
                  },
                  sections: {
                    '-1': {
                      i: -1,
                      name: '',
                      x: 0,
                      y: gridHeight,
                      w: 0,
                      minW: 0,
                      minH: 0,
                      h: 0,
                      static: true,
                      isResizable: false,
                    },
                  },
                },
              },
            },
          },
        },
      }),
      rename: (prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [dataTarget[0]]: {
              ...prev[activeTowerID].layout[dataTarget[0]],
              [dataTarget[1]]: {
                ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]],
                [target]: {
                  ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]][
                    target
                  ],
                  [dataTarget[2]]: {
                    ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]][
                      target
                    ][dataTarget[2]],
                    name: addItemProps.label,
                  },
                },
              },
            },
          },
        },
      }),
      copy: (prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [dataTarget[0]]: {
              ...prev[activeTowerID].layout[dataTarget[0]],
              [dataTarget[1]]: {
                ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]],
                [nextKey]: {
                  ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]][
                    target
                  ],
                  [dataTarget[2]]: {
                    i: nextKey,
                    name: addItemProps.label,
                    x: nextPosition,
                    y: 500,
                    w: 10,
                    h: gridHeight,
                    resizeHandles: data ? [] : ['n', 's'],
                  },
                },
              },
            },
          },
        },
      }),
      remove: (prev: ITowerList) =>
        siteTabActive
          ? {
              ...prev,
              [activeTowerID]: {
                ...prev[activeTowerID],
                layout: {
                  ...prev[activeTowerID].layout,
                  site: {
                    ...prev[activeTowerID].layout.site,
                    image: null,
                    imageGrid: null,
                    assets: {},
                    images: {},
                    textBoxes: {},
                    drawings: {},
                  },
                },
              },
            }
          : {
              ...prev,
              [activeTowerID]: {
                ...prev[activeTowerID],
                layout: {
                  ...prev[activeTowerID].layout,
                  [dataTarget[0]]: {
                    ...prev[activeTowerID].layout[dataTarget[0]],
                    [dataTarget[1]]: rest,
                  },
                },
              },
            },
    }),
    [
      activeTowerID,
      addItemProps.label,
      dataTarget,
      nextKey,
      nextPosition,
      gridHeight,
      siteTabActive,
      target,
      rest,
    ]
  );

  const saveBox = {
    classes: classes.savebox,
    clickHandlers: {
      save: async () => {
        if (modifyType === 'remove') {
          await setAddItemProps(clearAddProps);
          await setDragProps(clearDragProps);
        }
        if (siteTabActive) {
          await deleteFileFromDB(gridItems[0].image!);
        }
        await setTowerList(saveHandler[modifyType]);

        const modifyName = {
          add: 'Added',
          copy: 'Copied',
          remove: 'Removed',
          rename: 'Renamed',
        };

        const historyUpdate = {
          add: [
            createHistoryEntry('Added', {
              added: [
                {
                  text: `${getItemName()} ${addItemProps.label} ${
                    modifyName[modifyType]
                  }`,
                },
              ],
            }),
          ],
          copy: [
            createHistoryEntry('Copied', {
              added: [
                {
                  text: `${getItemName()} ${
                    towerList[activeTowerID].layout?.[dataTarget[0]]?.[
                      dataTarget[1]
                    ]?.[target]?.[dataTarget[2]].name
                  } ${modifyName[modifyType]}`,
                },
                {
                  text: `${getItemName()} ${addItemProps.label} Created`,
                },
              ],
            }),
          ],
          remove: [
            createHistoryEntry('Removed', {
              removed: [
                {
                  text: `${getItemName()} ${
                    towerList[activeTowerID].layout?.[dataTarget[0]]?.[
                      dataTarget[1]
                    ]?.[target]?.[dataTarget[2]].name
                  } ${modifyName[modifyType]}`,
                },
              ],
            }),
          ],
          rename: [
            createHistoryEntry('Renamed', {
              before: [
                {
                  text: towerList[activeTowerID].layout?.[dataTarget[0]]?.[
                    dataTarget[1]
                  ]?.[target]?.[dataTarget[2]].name,
                },
              ],
            }),
            {
              after: [
                {
                  text: addItemProps.label,
                },
              ],
            },
          ],
        };

        // Update history state and add to DB asynchronously
        const updateHistory = async () => {
          const fetchedHistory = await fetchHistory();
          const nextHistoryKey = await axios.get(
            `${domain}get-next-pk/history/`
          );
          // Create history entry
          const historyEntry = {
            id: nextHistoryKey.data.next_pk,
            timestamp: Date.now(),
            type: 'Site',
            createdBy: user.userId,
            siteID: activeTowerID,
            changes: {
              action: 'Updated',
              details: [...historyUpdate[modifyType]],
            },
          };
          setHistory({
            ...fetchedHistory,
            [nextHistoryKey.data.next_pk]: historyEntry,
          });

          await addHistoryToDB(historyEntry);
          await setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              history: [
                ...prev[activeTowerID].history,
                nextHistoryKey.data.next_pk,
              ],
            },
          }));
        };

        if (
          modifyType !== 'rename' ||
          (modifyType === 'rename' &&
            addItemProps.label !==
              towerList[activeTowerID].layout[dataTarget[0]][dataTarget[1]][
                target
              ][dataTarget[2]].name)
        ) {
          await setTowerList(saveHandler[modifyType]);
          await updateHistory();
        } else {
          await setTowerList(saveHandler[modifyType]);
        }

        setAddItemProps(clearAddProps);

        clearPanel();
      },
      cancel: () => {
        clearPanel();
      },
    },
    disabled: modifyType.match(/add|rename|copy/)
      ? (!addItemProps.label.trim() ||
        !isLabelUnique(addItemProps.label, labelList))
      : false,
  };

  useEffect(() => {
    if (addItemProps.content === 'rename') {
      setModifyType('rename');
    }
  }, [addItemProps.content]);

  useEffect(() => {
    if (modifyType === 'rename') {
      if (addItemProps.index) {
        setTarget(addItemProps.index);
      }
      setAddItemProps({
        content: '',
        label: itemList[target][dataTarget[2]].name,
      });
    }
  }, [
    target,
    itemList,
    dataTarget,
    modifyType,
    activeTowerID,
    setAddItemProps,
    addItemProps.index,
  ]);

  useEffect(() => {
    if (
      activeCanvasTab === 'site' &&
      towerList[activeTowerID].layout.site.image
    ) {
      setAddItemProps((prev) => ({ ...prev, label: 'rooftop' }));
    }
  }, [activeCanvasTab, activeTowerID, towerList, setAddItemProps]);

  useEffect(() => {
    if (!keys.includes(target.toString())) {
      setTarget(+keys[0]);
    }
  }, [modifyType, keys, target]);

  useEffect(() => {
    if (modifyType === 'rename') {
      setOriginalLabel(addItemProps.label);
    }
  },[modifyType]);

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <div className={classes.modify}>
          <label
            htmlFor='edit-canvas'
            className={classes.label}>
            Modify {siteTabActive ? 'Image' : data ? 'Rack' : 'Leg'}
          </label>
          <select
            id='edit-canvas'
            name='edit-canvas'
            value={modifyType}
            className={classes.select}
            onChange={(e: IInputEvent) => {
              setAddItemProps(clearAddProps);
              setModifyType(e.target.value);
            }}>
            {!siteTabActive && (
              <>
                <option value='add'>Add</option>
                {options.length > 0 && (
                  <>
                    <option value='rename'>Rename</option>
                    <option value='copy'>Copy</option>
                  </>
                )}
              </>
            )}
            {options.length > 0 && <option value='remove'>Remove</option>}
          </select>
        </div>
        {!siteTabActive && modifyType !== 'add' && (
          <div className={classes.section}>
            <label
              htmlFor='target-canvas-item'
              className={classes.label}>
              Target {siteTabActive ? 'Image' : data ? 'Rack' : 'Leg'}
            </label>
            <select
              defaultValue={target}
              id='target-canvas-item'
              name='target-canvas-item'
              className={classes.select}
              onChange={(e: IInputEvent) => {
                setTarget(Number(e.target.value));
              }}>
              {options}
            </select>
          </div>
        )}
        {modifyType.match(/add|rename|copy/) && !siteTabActive && (
          <div className={classes.section}>
            <label
              htmlFor='add-title'
              className={classes.label}>
              {data ? 'Rack' : 'Leg'} Title
            </label>
            <div className='flex flex-col'>
              <input
                type='text'
                id='add-title'
                name='add-title'
                className={classes.select}
                value={addItemProps.label}
                onChange={(e: IInputEvent) =>
                  setAddItemProps((prev) => ({
                    ...prev,
                    label: e.target.value,
                  }))
                }
              />
              {!isLabelUnique(addItemProps.label, labelList) && (
                <span className={classes.alert}>
                  Please choose a unique label
                </span>
              )}
            </div>
          </div>
        )}
        <SaveBox {...saveBox} />
      </div>
    </div>
  );
};

export default EditContainerPanel;
