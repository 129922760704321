import { IDocumentContext, IUserContext } from 'state/iContext';
import { DocumentContext, UserContext } from 'state/context';
import FolderContainer from '../folders/FolderContainer';
import React, { useContext, useEffect } from 'react';
import FileListHeader from './FileListHeader';
import DatePicker from 'react-datepicker';
import { IStringProps } from 'iApp';

const FileList = () => {
  const {
    searchBar,
    setSearchBar,
    searchDate,
    setSearchDate,
    searchRange,
    setSearchRange,
    rangeSearchActive,
    setRangeSearchActive,
  } = useContext(UserContext) as IUserContext;
  const { documentList } = useContext(DocumentContext) as IDocumentContext;

  const classes: IStringProps = {
    container: 'flex flex-col w-full h-5/6 m-2 p-3',
    header:
      'flex w-full border border-stone-400 border-b-2 bg-slate-100 text-lg',
    name: 'w-5/6 font-bold text-center text-stone-500',
    date: 'w-1/6 font-bold text-center text-stone-500',
    action: 'mx-1 font-bold text-stone-500',
    files: 'w-full h-full min-h-3/4 overflow-y-auto',
    empty: 'flex w-full justify-center font-bold text-stone-400',
    pagination: 'flex w-full h-1/4 justify-between',
    searchBoxes:
      'flex w-full justify-between items-center border border-stone-400',
    searchFile: 'w-1/2 px-5 h-full lg:w-4/5',
    searchUpload: 'flex w-1/3 h-full lg:w-1/5 pl-7 justify-center items-center',
    datePicker: 'relative flex w-full h-full p-2 text-center',
    range: 'text-xs text-center',
    clear:
      'w-[100px] text-xs p-1 mx-1 border border-stone-400 rounded rounded-lg disabled:bg-stone-200 bg-red-700 text-slate-100 disabled:text-stone-400 text-sm transition ease-in-out delay-100 hover:bg-red-600',
  };

  useEffect(() => {}, [documentList]);

  return (
    <div className={classes.container}>
      <FileListHeader />
      <div className={classes.searchBoxes}>
        <input
          className={classes.searchFile}
          placeholder='Search File Name'
          value={searchBar}
          onChange={(e) => setSearchBar(e.target.value)}
        />
        <label
          htmlFor='range-search'
          className={classes.range}>
          Search Date Range
        </label>
        <input
          type='checkbox'
          name='range-search'
          id='range-search'
          checked={rangeSearchActive}
          onChange={() => setRangeSearchActive((prev: boolean) => !prev)}
        />
        <div className={classes.searchUpload}>
          <DatePicker
            className={classes.datePicker}
            isClearable={true}
            allowSameDay={true}
            closeOnScroll={true}
            name='search-file-date'
            selected={searchDate ? new Date(searchDate) : null}
            placeholderText='Search Upload Date'
            onChange={(updatedDate) => {
              const updateSearch: string = updatedDate
                ? updatedDate!.toString()
                : '';
              setSearchDate(updateSearch);
            }}
          />
          {rangeSearchActive && (
            <DatePicker
              className={classes.datePicker}
              isClearable={true}
              allowSameDay={true}
              closeOnScroll={true}
              name='search-range-date'
              selected={searchRange ? new Date(searchRange) : null}
              placeholderText='End Range Date'
              onChange={(updatedDate) => {
                const updateSearch: string = updatedDate
                  ? updatedDate!.toString()
                  : '';
                setSearchRange(updateSearch);
              }}
            />
          )}
        </div>
        <button
          disabled={!searchBar && !searchDate}
          className={classes.clear}
          onClick={() => {
            setSearchBar('');
            setSearchDate('');
            setRangeSearchActive(false);
          }}>
          Clear Search
        </button>
      </div>
      <div className={classes.files}>
        <FolderContainer />
      </div>
    </div>
  );
};

export default FileList;
