import { kebabString } from 'helpers/helperFunctions';
import { IEditSelect } from './iEdit';
import React, { ReactNode} from 'react';


const EditDropdown = ({
  title,
  options,
  defaultValue,
  changeHandler,
}: IEditSelect) => {
  const kebabTitle: string = kebabString(title);

  // Renders edit panel available select option
  const optionList: ReactNode[] = options.map(
    (option): ReactNode => (
      <option
        key={option}
        value={option}>
        {option}
      </option>
    )
  );

  return (
    <div className='flex flex-col px-5'>
      <label htmlFor={kebabTitle}>{title}</label>
      <select
        name={kebabTitle}
        onChange={changeHandler}
        id={`edit-${kebabTitle}`}
        defaultValue={defaultValue}
        className='flex flex-col px-1 cursor-pointer'>
        {optionList}
      </select>
    </div>
  );
};

export default EditDropdown;
