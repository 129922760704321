import React, { ReactNode, useContext, useEffect} from 'react';
import { IGridLayoutProps } from 'state/iState';
import SectionBlocks from './SectionBlocks';
import { IStringProps } from 'iApp';
import {
  SitesContext,
  CanvasContext,
  HeaderContext,
  SettingsContext,
} from 'state/context';
import {
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  ISettingsContext,
} from 'state/iContext';

const LeasePanel = () => {
  const { scale } = useContext(SettingsContext) as ISettingsContext;
  const { activeTowerID, addItemProps, setAddItemProps, clearAddProps } =
    useContext(CanvasContext) as ICanvasContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { activeCanvasTab } = useContext(HeaderContext) as IHeaderContext;

  const classes: IStringProps = {
    container:
      'relative flex flex-col h-full m-2 p-2 rounded rounded-md bg-slate-100',
    sections: 'flex flex-col justify-center m-1 overflow-y-auto',
    name: 'font-bold text-lg text-stone-400',
    label: 'ml-1 text-xl font-bold text-stone-400',
    empty: 'text-center font-bold text-stone-500',
  };

  const gridItem: { tower: string[]; data: string[] } = {
    tower: ['legs', 'legGrid', scale],
    data: ['shelves', 'shelfGrid', 'U'],
  };

  const containers: IGridLayoutProps =
    towerList[activeTowerID].layout[activeCanvasTab][
      gridItem[activeCanvasTab][0]
    ]!;

  const containerNames: string[] = Object.keys(containers).map(
    (item) => containers[item][gridItem[activeCanvasTab][1]].name
  );

  const sectionBlocks: ReactNode[] = containerNames.map(
    (name, i): ReactNode => {

      return (
        <div
          key={name}
          className={classes.sections}>
          <h1 className={classes.name}>{name}</h1>
          <SectionBlocks
            i={i}
            name={name}
            containers={containers}
          />
        </div>
      );
    }
  );

  useEffect(() => {
    if (!addItemProps.edit) {
      setAddItemProps(clearAddProps);
    }
  }, [addItemProps.edit, setAddItemProps, clearAddProps]);

  return (
    <div className={classes.container}>
      <label className={classes.label}>Lease Sections:</label>
      {sectionBlocks.length > 0 ? (
        <>{sectionBlocks}</>
      ) : (
        <div className={classes.empty}>No Lease Sections To Display</div>
      )}
    </div>
  );
};

export default LeasePanel;
