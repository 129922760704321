import { IHeaderContext, ICanvasContext, ISitesContext, IUserContext } from 'state/iContext';
import { HeaderContext, CanvasContext, SitesContext, UserContext } from 'state/context';
import PropertyLabel from '../PropertyLabel';
import React, { useContext } from 'react';
import { ITowerList } from 'state/iState';
import { IGridItem } from '../iGridItems';
import DragHandle from '../DragHandle';
import { IInputEvent } from 'iApp';

const GridTextBoxes = ({ layout }: IGridItem) => {
  const { activeCanvasTab } =
    useContext(HeaderContext) as IHeaderContext;
  const { towerList, setTowerList } =
    useContext(SitesContext) as ISitesContext;
  const { activeToolBar, activeTowerID } =
    useContext(CanvasContext) as ICanvasContext;
    const { user } = useContext(UserContext) as IUserContext;

  const editable: boolean = towerList[activeTowerID].edit.active && towerList[activeTowerID].edit.user === user.userId;

  return (
    <>
      <textarea
        cols={30}
        rows={10}
        id={layout.name}
        name={layout.name}
        onDrop={(e) => e.preventDefault()}
        className='overflow-hidden focus:outline-none hover:overflow-auto'
        defaultValue={layout.text}
        onChange={(e: IInputEvent) =>
          setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              layout: {
                ...prev[activeTowerID].layout,
                [activeCanvasTab]: {
                  ...prev[activeTowerID].layout[activeCanvasTab],
                  textBoxes: {
                    ...prev[activeTowerID].layout[activeCanvasTab].textBoxes,
                    [layout.i]: {
                      ...prev[activeTowerID].layout[activeCanvasTab].textBoxes![
                        layout.i
                      ],
                      text: e.target.value,
                    },
                  },
                },
              },
            },
          }))
        }
        readOnly={true}
      />
      {editable && <DragHandle />}{' '}
      {activeToolBar.Properties && (
        <PropertyLabel
          label={layout.name!}
          type='import'
        />
      )}
    </>
  );
};

export default GridTextBoxes;
