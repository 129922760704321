import { IGridItem } from 'components/application/canvas/gridItems/iGridItems';
import { ICanvasContext, ISitesContext, IUserContext } from 'state/iContext';
import { CanvasContext, SitesContext, UserContext } from 'state/context';
import GridTowerData from '../items/GridTowerData';
import LeaseSections from '../LeaseSections';
import PropertyLabel from '../PropertyLabel';
import GridBodyScale from './GridBodyScale';
import React, { useContext } from 'react';
import DragHandle from '../DragHandle';

const GridBody = ({ layout, type }: IGridItem) => {
  const {
    activeToolBar: { Properties, Lease },
    activeTowerID,
  } = useContext(CanvasContext) as ICanvasContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { user } = useContext(UserContext) as IUserContext;
  const editable =
    towerList[activeTowerID].edit.active &&
    towerList[activeTowerID].edit.user === user.userId;


  return (
    <>
      {editable && <DragHandle />}
      {Properties && (
        <PropertyLabel
          label={layout.name!}
          type='box'
        />
      )}
      <GridTowerData
        layout={[layout]}
        id={layout.i!}
        type={type}
      />
      {Lease && (
        <LeaseSections
          legLayout={[layout]}
          id={layout.i!}
          type={type}
        />
      )}
      <GridBodyScale height={layout.h} />
    </>
  );
};

export default GridBody;
