import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IDocumentContext, IWorkOrderContext } from 'state/iContext';
import { DocumentContext, WorkOrderContext } from 'state/context';
import WorkOrderTable from './table/WorkOrderTable';
import SearchBar from 'components/search/SearchBar';
import WorkOrderForm from './form/WorkOrderForm';
import WorkOrdersPanel from './WorkOrdersPanel';
import WorkOrderPage from './WorkOrderPage';
import { IWorkOrder } from './iWorkOrders';
import { IStringProps } from 'iApp';

const WorkOrdersHome = () => {
  const { workOrders } = useContext(WorkOrderContext) as IWorkOrderContext;
  const { activeFile, setActiveFile } = useContext(
    DocumentContext
  ) as IDocumentContext;

  const [activeTab, setActiveTab] = useState<string>('Assigned');
  const [showForm, setShowForm] = useState<boolean>(false);

  const noFile: boolean = activeFile === 'add';

  const workOrderList = useMemo(
    () =>
      Object.values(workOrders).filter(
        (order) => (order as IWorkOrder).progress === activeTab
      ),
    [workOrders, activeTab]
  );

  const handleTabChange = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
      setActiveFile('add');
    }
  };

  const handleCreateNewClick = () => {
    setActiveTab('');
    setShowForm((prev) => !prev);
    setActiveFile('add');
  };

  const toggleWorkOrder = () => {
    setActiveTab('Assigned');
    setShowForm((prev) => !prev);
    setActiveFile('add');
  };

  const handleRowClick = (workOrder) => {
    setActiveFile(workOrder.id);
    setActiveTab('');
  };

  const classes: IStringProps = {
    container: 'flex h-full w-full overflow-y-auto',
    workOrders: 'flex flex-col w-full h-full p-5',
    mainContent: 'flex flex-col w-4/5 h-full',
    top: 'flex w-full justify-between mb-4',
  };

  useEffect(() => {
    setActiveFile('add');
    setShowForm(false);
  }, []);

  return (
    <div className={classes.container}>
      <div className='w-1/5'>
        <WorkOrdersPanel
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          handleCreateNewClick={handleCreateNewClick}
        />
      </div>
      <div className={classes.mainContent}>
        {showForm ? (
          <WorkOrderForm toggleWorkOrder={toggleWorkOrder} />
        ) : !noFile ? (
          <WorkOrderPage
            id={activeFile}
            onClose={() => {
              if (!noFile) {
                setActiveTab(workOrders[activeFile].progress);
              }
              setActiveFile('add');
            }}
          />
        ) : (
          <div className={classes.workOrders}>
            <div className={classes.top}>
              <SearchBar />
            </div>
            <WorkOrderTable
              workOrderList={workOrderList}
              onRowClick={handleRowClick}
              title={activeTab}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkOrdersHome;
