import { convertImageToWebP } from 'helpers/helperFunctions';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import SearchBar from 'components/search/SearchBar';
import SaveBox from 'components/buttons/SaveBox';
import { ILibraryIcon } from 'state/iState';
import Icons from 'components/icons/Icons';
import React, { useContext } from 'react';
import ReactCrop from 'react-image-crop';
import { IInputEvent } from 'iApp';
import {
  CropContext,
  SitesContext,
  CanvasContext,
  CompanyContext,
  LibraryContext,
  SettingsContext,
} from 'state/context';
import {
  IImageStage,
  IImageStageProps,
  IImageStageClasses,
} from '../iCustomIcon';
import {
  ICropContext,
  ISitesContext,
  ICanvasContext,
  ICompanyContext,
  ILibraryContext,
  ISettingsContext,
} from 'state/iContext';
import {
  ICropSaveMethods,
  IChangeImageStates,
  IFileSelectComplete,
} from 'helpers/iHelpers';

const ImageStage = ({ editIcon, setEditIcon, setStage }: IImageStage) => {
  const {
    fileTypes: { acceptedImageTypes },
  } = useContext(SettingsContext) as ISettingsContext;
  const { isCropActive, setIsCropActive, image } = useContext(
    CropContext
  ) as ICropContext;
  const { libraries } = useContext(LibraryContext) as ILibraryContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;

  const classes: IImageStageClasses = {
    container: 'w-full h-[60%] mt-3',
    icons:
      'flex w-full h-full justify-center border border-stone-300 bg-stone-100 overflow-auto',
    reactCrop: 'min-w-[208px] min-h-[208px] max-w-[208px] max-h-[208px]',
    canvas: 'hidden',
    img: 'rounded-full min-w-[100px] min-h-[100px] max-w-[100px] max-h-[100px] m-3 object-fill text-center border border-stone-200',
    input: 'ml-2 mt-3 text-sm text-blue-500',
    saveBox:
      'absolute flex -right-2 sm:right-0 bottom-5 w-[150px] justify-around sm:mx-5',
  };

  const {
    crop,
    imgSrc,
    setImgSrc,
    setImgSrcExt,
    fileInputRef,
    onFileSelect,
    setFileReader,
    onCropSave,
    onCropChange,
    onCropComplete,
    clearToDefault,
    imagePreviewCanvasRef,
  } = ImageCropHelpers(image);

  // Changes/saves the state of the image and file extension
  const changeImageStates = async ({
    image,
    save,
  }: IChangeImageStates): Promise<void> => {
    setImgSrc(image);
    setImgSrcExt('webp');

    if (save) {
      const fileName: string = `tower-asset-${towerList[activeTowerID].name}-${editIcon.id}`;
      const convertedFile = await convertImageToWebP(image, fileName, true);

      setEditIcon((prev: ILibraryIcon) => {
        const nonImageProperties = prev.properties.filter((props) => !props.path);
        return {
          ...prev,
          content: convertedFile,
          type: 'new image',
          properties: [
            {
              path: `files/media/client/${company.name}/${fileName}.webp`,
            },
            ...nonImageProperties,
          ],
        };
      });
    }
  };

  // Additional methods to envoke once a file is selected
  const onFileSelectComplete = ({ e, file }: IFileSelectComplete): void => {
    setIsCropActive(true);
    setEditIcon((prev: ILibraryIcon) => ({ ...prev, content: '' }));
    setFileReader({
      file,
      changeImageStates,
    });
    clearToDefault(e);
  };

  // Methods to envoke while saving the cropped image
  const cropSaveMethods: ICropSaveMethods = {
    changeImageStates,
    onComplete: () => setIsCropActive(false),
  };

  // Props to pass to their respective elements
  const props: IImageStageProps = {
    crop: {
      crop,
      src: imgSrc,
      onChange: onCropChange,
      onComplete: onCropComplete,
      ruleOfThirds: true,
      crossorigin: 'anonymous',
      className: classes.reactCrop,
      aspect: 1,
    },
    canvas: {
      ref: imagePreviewCanvasRef,
      className: classes.canvas,
    },
    img: {
      crop: {
        id: 'crop-user-image',
        src: imgSrc,
      },
      displayed: {
        id: 'displayed-user-image',
        src: editIcon.img,
        className: classes.img,
      },
    },
    input: {
      type: 'file',
      name: 'custom-icon-input',
      id: 'custom-icon-input',
      ref: fileInputRef!,
      accept: acceptedImageTypes,
      multiple: false,
      className: classes.input,
      onChange: (e: IInputEvent) => {
        onFileSelect({ e, onFileSelectComplete })
      },
    },
    saveBox: {
      classes: classes.saveBox,
      clickHandlers: {
        save: (e: IInputEvent) => {
          onCropSave({ e, cropSaveMethods });
          setStage(2);
        },
        cancel: () => {
          setImgSrc('');
          setIsCropActive(false);
          setEditIcon((prev: ILibraryIcon) => ({ ...prev, content: '' }));
        },
      },
    },
  };

  // Displays a static/croppable image
  const imageElementToDisplay = isCropActive ? (
    <>
      <ReactCrop {...props.crop}>
        <img
          alt='imported icon thumbail'
          {...props.img.crop}
        />
      </ReactCrop>
      <SaveBox {...props.saveBox} />
    </>
  ) : (
    <img
      alt='imported icon thumbail'
      {...props.img.displayed}
    />
  );

  return (
    <div className={classes.container}>
      <SearchBar />
      <div className={classes.icons}>
        {imgSrc && isCropActive ? (
          <>
            {imageElementToDisplay}
            <canvas {...props.canvas} />
          </>
        ) : (
          <Icons library={[...libraries[3].assets, ...libraries[4].assets]} />
        )}
      </div>
      <input {...props.input} />
    </div>
  );
};

export default ImageStage;
