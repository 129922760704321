import React, { useState, useMemo, useContext } from 'react';
import {
  CanvasContext,
  CompanyContext,
  DocumentContext,
  SitesContext,
  UserContext,
} from 'state/context';
import {
  ICanvasContext,
  ICompanyContext,
  IDocumentContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import TableHeader from './ColumnHeader';
import TableRow from './TableRow';

const WorkOrderTable = ({ workOrderList, title, onRowClick }) => {
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { user, userList, searchBar } = useContext(UserContext) as IUserContext;
  const { icons: assets } = useContext(CompanyContext) as ICompanyContext;
  const { documentList } = useContext(DocumentContext) as IDocumentContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;

  const [sortBy, setSortBy] = useState<{
    field: string;
    direction: 'asc' | 'desc';
  }>({ field: 'id', direction: 'asc' });

  const priorityWeights: Record<string, number> = {
    high: 1,
    medium: 2,
    low: 3,
  };

  const sortedAndFilteredData = useMemo(() => {
    let filteredData = workOrderList;

    // Filter work orders by activeTowerID
    if (activeTowerID && activeTowerID > 0) {
      filteredData = workOrderList.filter(
        (workOrder) =>
          Array.isArray(workOrder.locations) &&
          workOrder.locations.includes(activeTowerID)
      );
    }

    filteredData = filteredData.filter(
      (workOrder) =>
        workOrder.assigned.includes(user.userId) ||
        workOrder.additionalUsers.includes(user.userId) ||
        workOrder.createdBy === user.userId
    );

    if (searchBar) {
      const filterLowerCase = searchBar.toLowerCase();
      filteredData = filteredData.filter((workOrder) => {
        for (const key in workOrder) {
          if (Object.prototype.hasOwnProperty.call(workOrder, key)) {
            const value = workOrder[key];

            switch (key) {
              case 'assigned':
                if (
                  value.some((id) =>
                    userList[id]?.name?.toLowerCase().includes(filterLowerCase)
                  )
                ) {
                  return true;
                }
                break;
              case 'checklist':
                if (
                  value.some((task) =>
                    task.text.toLowerCase().includes(filterLowerCase)
                  )
                ) {
                  return true;
                }
                break;
              case 'createdBy':
                if (
                  userList[value]?.name?.toLowerCase().includes(filterLowerCase)
                ) {
                  return true;
                }
                break;
              case 'files':
                if (
                  value.some((id) =>
                    documentList[id]?.name
                      ?.toLowerCase()
                      .includes(filterLowerCase)
                  )
                ) {
                  return true;
                }
                break;
              case 'locations':
                if (
                  value.some((id) =>
                    towerList[id]?.name?.toLowerCase().includes(filterLowerCase)
                  )
                ) {
                  return true;
                }
                break;
              case 'partsAssets':
                if (
                  value.some((id) =>
                    assets[id]?.name?.toLowerCase().includes(filterLowerCase)
                  )
                ) {
                  return true;
                }
                break;
              case 'startDate':
              case 'endDate':
                if (
                  new Date(value)
                    .toDateString()
                    .toString()
                    .toLowerCase()
                    .includes(filterLowerCase)
                ) {
                  return true;
                }
                break;
              case 'id':
              case 'description':
              case 'notes':
              case 'project':
                if (value.toString().toLowerCase().includes(filterLowerCase))
                  return true;
                break;
              default:
                break;
            }
          }
        }
        return false;
      });
    }

    const sortedData = [...filteredData].sort((a, b) => {
      switch (sortBy.field) {
        case 'id':
          return sortBy.direction === 'asc' ? a.id - b.id : b.id - a.id;
        case 'priority':
          return sortBy.direction === 'asc'
            ? priorityWeights[a.priority.toLowerCase()] -
                priorityWeights[b.priority.toLowerCase()]
            : priorityWeights[b.priority.toLowerCase()] -
                priorityWeights[a.priority.toLowerCase()];
        case 'project':
          return sortBy.direction === 'asc'
            ? a.project.localeCompare(b.project)
            : b.project.localeCompare(a.project);
        case 'startDate':
          return sortBy.direction === 'asc'
            ? new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
            : new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
        case 'endDate':
          return sortBy.direction === 'asc'
            ? new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
            : new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
        case 'locations':
          const aLocations = Array.isArray(a.locations)
            ? a.locations.join(', ')
            : a.locations;
          const bLocations = Array.isArray(b.locations)
            ? b.locations.join(', ')
            : b.locations;

          return sortBy.direction === 'asc'
            ? aLocations.localeCompare(bLocations)
            : bLocations.localeCompare(aLocations);
        default:
          return 0;
      }
    });

    return sortedData;
  }, [workOrderList, searchBar, sortBy]);

  const headerClasses =
    'text-2xl border border-stone-300 bg-stone-200 text-stone-500 text-center font-bold offset-8';

  const columns = [
    { Header: 'Work Order', accessor: 'id', maxWidthClass: 'w-[7%]' },
    {
      Header: 'Description',
      accessor: 'description',
      maxWidthClass: 'w-[10%]',
    },
    { Header: 'Assigned', accessor: 'assigned', maxWidthClass: 'w-[10%]' },
    { Header: 'Priority', accessor: 'priority', maxWidthClass: 'w-[3%]' },
    { Header: 'Progress', accessor: 'progress', maxWidthClass: 'w-[3%]' },
    { Header: 'Project', accessor: 'project', maxWidthClass: 'w-[3%]' },
    { Header: 'Start Date', accessor: 'startDate', maxWidthClass: 'w-[7%]' },
    { Header: 'End Date', accessor: 'endDate', maxWidthClass: 'w-[7%]' },
    { Header: 'Location', accessor: 'locations', maxWidthClass: 'w-[10%]' },
    { Header: 'Files', accessor: 'files', maxWidthClass: 'w-[10%]' },
    {
      Header: 'Parts/Assets',
      accessor: 'partsAssets',
      maxWidthClass: 'w-[10%]',
    },
    { Header: 'Checklist', accessor: 'checklist', maxWidthClass: 'w-[10%]' },
    { Header: 'Notes', accessor: 'notes', maxWidthClass: 'w-[10%]' },
  ];

  return (
    <div>
      <h2 className={headerClasses}>
        {title} {title === 'Assigned' && '(Pending Confirmation / Start Date)'}{' '}
        {sortedAndFilteredData.length > 0 &&
          `(${sortedAndFilteredData.length})`}
      </h2>
      <table className='w-full'>
        <thead>
          <tr>
            {sortedAndFilteredData.length === 0 ? (
              <th
                colSpan={columns.length}
                className='bg-stone-200'>
                No {title} Work Orders to display{' '}
                {searchBar && (
                  <>
                    "<span className='text-red-500'>{searchBar}</span>"
                  </>
                )}
              </th>
            ) : (
              columns.map(({ Header, accessor }) => (
                <TableHeader
                  key={accessor}
                  header={Header}
                  accessor={accessor}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              ))
            )}
          </tr>
        </thead>
        <tbody>
          {sortedAndFilteredData.map((workOrder) => (
            <TableRow
              key={workOrder.id}
              workOrder={workOrder}
              columns={columns}
              onRowClick={onRowClick}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WorkOrderTable;
