import React from 'react';
import { components } from 'react-select';

const WorkOrderOption = (props) => (
  <components.Option {...props}>
    <div className='flex w-[24px] mr-[8px] items-center'>
      <img
        src={props.data.image}
        alt={props.label}
      />
      {props.label}
    </div>
  </components.Option>
);

export default WorkOrderOption;
