import { IHeaderButtons } from 'state/iState';
import React, { useContext } from 'react';
import { IGridItem } from '../iGridItems';
import DragHandle from '../DragHandle';
import {
  IDocumentContext,
  ICanvasContext,
  IHeaderContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  DocumentContext,
  CanvasContext,
  HeaderContext,
  SitesContext,
  UserContext,
} from 'state/context';

const GridLink = ({ layout }: IGridItem) => {
  const { setActiveHeaderButton } = useContext(HeaderContext) as IHeaderContext;
  const { setActiveFile } = useContext(DocumentContext) as IDocumentContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { user } = useContext(UserContext) as IUserContext;

  return (
    <>
      <button
        className='text-start p-0 underline bg-none hover:text-red-300'
        onClick={() => {
          setActiveFile(layout.refID!.toString());
          setActiveHeaderButton((prev: IHeaderButtons) => ({
            ...prev,
            drawings: true,
            tower: false,
            site: false,
            contacts: false,
            'work orders': false,
            reports: false,
          }));
        }}>
        {layout.name}
      </button>
      {towerList[activeTowerID].edit.active &&
        towerList[activeTowerID].edit.user === user.userId && <DragHandle />}
    </>
  );
};

export default GridLink;
