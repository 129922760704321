import MaintenanceInspectionReport from './digi maintenance/MaintenanceInspectionReport';
import { ICompanyContext, IDocumentContext } from 'state/iContext';
import { CompanyContext, DocumentContext } from 'state/context';
import React, { useContext, useState } from 'react';
import { IStringProps } from 'iApp';

const Reports = () => {
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { activeReport, setActiveReport } = useContext(
    DocumentContext
  ) as IDocumentContext;

  const classes: IStringProps = {
    container: 'flex w-full h-full relative overflow-y-auto',
    reports:
      'relative flex flex-col w-full h-full items-center bg-gray-100 p-8',
    header: 'text-4xl font-bold text-center text-gray-800 mb-8',
    cards: 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6',
    card: 'p-6 bg-white border border-gray-300 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 hover:bg-gray-50 cursor-pointer text-center',
    title: 'text-lg font-semibold text-gray-700 mb-2',
  };

  const reportDisplayed = {
    1: <div>Site Inspection CheckList</div>,
    2: <MaintenanceInspectionReport />,
  };

  return (
    <div className={classes.container}>
      {!activeReport && (
        <div className={classes.reports}>
          <h1 className={classes.header}>Select a Report to Build</h1>
          <div className={classes.cards}>
            {/* <div className={classes.card}>
              <h2 className={classes.title}>Site Inspection Checklist</h2>
            </div> */}
            {company.name === 'Digi' && (
              <div
                className={classes.card}
                onClick={() => setActiveReport(2)}>
                <h2 className={classes.title}>Maintenance Report</h2>
              </div>
            )}
          </div>
        </div>
      )}
      {activeReport && reportDisplayed[activeReport]}
    </div>
  );
};

export default Reports;
