import React, { MutableRefObject, useContext, useRef } from 'react';
import { isMinClass } from 'helpers/helperFunctions';
import { IBooleanProps, IInputEvent } from 'iApp';
import { ISearchClasses } from './iSearch';
import {
  IMapContext,
  IUserContext,
  IHeaderContext,
  ISettingsContext,
  ILibraryContext,
} from 'state/iContext';
import {
  MapContext,
  UserContext,
  HeaderContext,
  LibraryContext,
  SettingsContext,
} from 'state/context';

const SearchBar = ({ disabled }: IBooleanProps) => {
  const { setInfoWindowID, setMeasureDistance } =
    useContext(MapContext) as IMapContext;
  const { searchBar, setSearchBar } =
    useContext(UserContext) as IUserContext;
  const { homePage } =
    useContext(HeaderContext) as IHeaderContext;
  const { setActiveIcon } =
    useContext(LibraryContext) as ILibraryContext;
  const { minimize, changePanelSize } =
    useContext(SettingsContext) as ISettingsContext;

  const searchRef = useRef<HTMLDivElement>(
    null
  ) as MutableRefObject<HTMLInputElement>;

  // Component classes
  const classes: ISearchClasses = {
    main: {
      min: 'flex h-full items-center md:h-[60px] md:justify-start',
      max: 'flex w-content border border-stone-300 rounded-sm my-1 mx-1 items-center',
    },
    input: {
      min: 'md:ml-10 w-[5px] z-0 focus:outline-none opacity-10',
      max: 'ml-1 w-full border border-none focus:outline-none focus:ring-0',
    },
    icon: {
      min: 'fa-solid fa-magnifying-glass text-3xl md:m-[40px] p-1 text-gray-300 hover:text-red-300 cursor-pointer',
      max: 'fa-solid fa-magnifying-glass text-sm p-1 text-gray-300 self-center',
    },
    data: {
      min: 'flex h-full w-full justify-center items-center mt-1 -mx-1 md:justify-start md:w-[50px] md:-mx-9 md:mt-2',
      max: 'flex w-content border border-stone-300 rounded-sm ',
    },
  };

  const inputClasses: string = isMinClass(minimize, classes.input);
  const iconClasses: string = isMinClass(minimize, classes.icon);
  const mainClasses: string = homePage
    ? isMinClass(minimize, classes.main)
    : isMinClass(minimize, classes.data);
  const value: string = disabled ? '' : searchBar;

  // Closes measure distance tool, any active toolbars in data view and maximizes sites panel
  const handleClick = (): void => {
    setMeasureDistance(false);
    changePanelSize(true);
    searchRef.current.focus();
  };

  // Closes any open info windows from the map && any active toolbars in data view
  const changeHandler = (e: IInputEvent): void => {
    setInfoWindowID('');
    setActiveIcon('');
    if (!disabled) {
      setSearchBar(e.target.value);
    }
  };

  return (
    <div className={mainClasses}>
      <i
        className={iconClasses}
        onClick={handleClick}
      />
      <input
        id='search'
        value={value}
        ref={searchRef}
        disabled={disabled}
        placeholder='Search'
        onChange={changeHandler}
        className={inputClasses}
      />
    </div>
  );
};

export default SearchBar;
