import {
  IHeaderContext,
  ICanvasContext,
  ISitesContext,
  IServerContext,
  IHistoryContext,
  IUserContext,
} from 'state/iContext';
import {
  HeaderContext,
  CanvasContext,
  SitesContext,
  ServerContext,
  HistoryContext,
  UserContext,
} from 'state/context';
import { ITowerDataLayout, ITowerLayout } from 'state/iState';
import { nextAvailableKey } from 'helpers/helperFunctions';
import React, { useContext, useMemo } from 'react';
import { IStringProps } from 'iApp';
import axios from 'axios';

const AddCanvasButton = () => {
  const { activeTowerID, setActiveToolBar, addItemProps, setAddItemProps } =
    useContext(CanvasContext) as ICanvasContext;
  const { towerList, setTowerList } = useContext(SitesContext) as ISitesContext;
  const {
    activeHeaderButton: { data },
  } = useContext(HeaderContext) as IHeaderContext;
  const { domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;

  const legs = towerList[activeTowerID]?.layout?.tower?.legs || {};
  const datas = towerList[activeTowerID]?.layout?.data?.shelves || {};
  const noLegs = Object.keys(legs).length === 0;
  const noData = Object.keys(datas).length === 0;

  const nextDataKey = !noData ? nextAvailableKey(datas, 0)! : 0;
  const nextTowerKey = !noLegs ? nextAvailableKey(legs, 0)! : 0;

  const dataPositions: number[] = Object.values(datas).map(
    (data) => (data as ITowerDataLayout).shelfGrid?.x || 0
  );
  const towerPositions: number[] = Object.values(legs).map(
    (leg) => (leg as ITowerLayout).legGrid?.x || 0
  );

  const maxDataPosition = Math.max(...dataPositions, 0);
  const nextDataPosition = maxDataPosition ? maxDataPosition + 35 : 1135;
  const maxTowerPosition = Math.max(...towerPositions, 0);
  const nextTowerPosition = maxTowerPosition ? maxTowerPosition + 35 : 1140;

  const newItemKey: number = useMemo(
    () => (data ? nextDataKey : nextTowerKey),
    [data, nextTowerKey, nextDataKey]
  );
  const addClickHandler = async () => {
    const layout = towerList[activeTowerID]?.layout;
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    const getItemName = () =>
      (data ? 'Data' : 'Tower') + ' ' + (data ? 'Rack' : 'Leg');

    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: data ? 'Data' : 'Tower',
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: [
          {
            added: [
              {
                text: `${getItemName()} ${newItemKey! + 1} Added`,
              },
            ],
            field: `${getItemName()} Added`,
          },
        ],
      },
    };

    layout[data ? 'data' : 'tower'][data ? 'shelves' : 'legs'][newItemKey!] =
      data
        ? {
            shelfGrid: {
              i: newItemKey!,
              name: `Rack ${newItemKey! + 1}`,
              x: nextDataPosition,
              y: 500,
              w: 10,
              h: 40,
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
            },
          }
        : {
            legGrid: {
              i: newItemKey!,
              name: `Leg ${newItemKey! + 1}`,
              x: nextTowerPosition,
              y: 500,
              w: 10,
              h: towerList[activeTowerID]?.height,
              minW: 10,
              maxW: 10,
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: towerList[activeTowerID]?.height,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: towerList[activeTowerID]?.height,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
            },
          };

    setTowerList((prev) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        layout: {
          ...prev[activeTowerID].layout,
          ...layout,
        },
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });
    addHistoryToDB(historyEntry);

    setAddItemProps((prev) => ({
      ...prev,
      label: `${data ? 'Data' : 'Leg'} ${newItemKey! + 1}`,
      index: newItemKey!,
      content: 'rename',
    }));

    setActiveToolBar((prev) => ({
      ...prev,
      edit: !prev.edit,
      select: prev.edit,
      image: false,
      text: false,
      drawings: false,
      sections: false,
    }));
  };

  const classes: IStringProps = {
    container: 'absolute right-0 top-10 flex flex-col px-4',
    button: 'group flex items-center rounded hover:bg-red-600',
    label: 'hidden p-1 text-slate-200 group-hover:flex',
    icon: 'fa-solid fa-square-plus px-1 text-2xl text-slate-400 hover:text-slate-300',
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.button}
        onClick={addClickHandler}>
        <span className={classes.label}>New {data ? 'Rack' : 'Leg'}</span>
        <i className={classes.icon} />
      </div>
    </div>
  );
};

export default AddCanvasButton;
