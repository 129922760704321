import React, { useState, useCallback, useContext } from 'react';
import { IReportQuestionProps } from './iReports';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';

const ReportQuestions: React.FC<IReportQuestionProps> = ({
  section,
  question,
  handleInputChange,
  handleImageUpload,
}) => {
  const { reportFormData, reportImageData } = useContext(
    DocumentContext
  ) as IDocumentContext;

  // const [showFileInput, setShowFileInput] = useState(false);

  const classes = {
    container: 'relative w-full flex justify-between my-2',
    label: 'block w-1/2 font-bold pr-4',
    inputContainer: 'w-1/2 flex flex-col',
    textarea: 'block w-full p-2 resize-none',
    button: 'm-2 p-2 bg-red-500 text-white rounded-lg self-center',
    // buttonIcon: `fa-solid ${showFileInput ? 'fa-x' : 'fa-file-import'}`,
    fileInput: 'mt-2',
    uploadedFilesContainer: 'mt-2',
    uploadedFilesTitle: 'font-semibold',
    fileListItem: 'text-sm',
  };

  // const toggleFileInput = useCallback(
  //   () => setShowFileInput((prev) => !prev),
  //   []
  // );

  return (
    <div className={classes.container}>
      <label className={classes.label}>{question}</label>
      <div className={classes.inputContainer}>
        <textarea
          className={classes.textarea}
          rows={1}
          value={(reportFormData[section]?.[question] as string) || ''}
          onChange={(e) =>
            handleInputChange(section, question, e.target.value)
          }
        />
{/* IMAGES */}
        {/* <div className='flex'>
          <button
            className={`${classes.button} ${classes.buttonIcon}`}
            onClick={toggleFileInput}
          />

          {showFileInput && (
            <input
              type='file'
              accept='image/*'
              multiple
              onChange={(e) =>
                handleImageUpload(section, question, e.target.files)
              }
              className={classes.fileInput}
            />
          )}
        </div>
        {reportImageData[section]?.[question] && (
          <div className={classes.uploadedFilesContainer}>
            <h4 className={classes.uploadedFilesTitle}>Uploaded Files:</h4>
            <ul>
              {reportImageData[section][question].map((file, idx) => (
                <li
                  key={idx}
                  className={classes.fileListItem}>
                  {file.name}
                </li>
              ))}
            </ul>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default React.memo(ReportQuestions, (prevProps, nextProps) => {
  return (
    prevProps.section === nextProps.section &&
    prevProps.question === nextProps.question &&
    prevProps.handleInputChange === nextProps.handleInputChange &&
    prevProps.handleImageUpload === nextProps.handleImageUpload
  );
});
