import { ITowerScaleClasses } from 'components/application/canvas/iCanvas';
import React, { useCallback, useContext} from 'react';
import { IHeaderContext } from 'state/iContext';
import { HeaderContext } from 'state/context';
import { INumberProps } from 'iApp';

const GridBodyScale = ({ height }: INumberProps) => {
  const {
    activeHeaderButton: { data },
  } = useContext(HeaderContext) as IHeaderContext;

  const classes: ITowerScaleClasses = {
    legs: {
      left: 'absolute z-30 -bottom-2 -left-[1px] flex border border-4 border-stone-400 w-1/3 justify-center self-center text-center',
      right:
        'absolute z-30 -bottom-2 -right-[1px] flex border border-4 border-stone-400 w-1/3 justify-center self-center text-center',
    },
    scale: {
      container: 'absolute bottom-0.5 -left-5 h-full -m-0.5',
      short:
        '-z-10 border border-t-0.5 border-t-stone-400 w-[5px] h-[7.55px] text-[0px]',
      long: '-z-10 border border-t-0.5 border-t-stone-400 w-[10px] h-[7.55px] text-[9px] text-end pl-1',
    },
  };

  // Takes tower height and creates ruler ticks every 5 units
  const dispalyScale: () => JSX.Element[] = useCallback((): JSX.Element[] => {
    let line: number = height;
    let scales: JSX.Element[] = [];

    while (line >= 0) {
      const activeClass: string =
        line % 5 ? classes.scale.short : classes.scale.long;
      scales = [
        ...scales,
        <div
          key={line}
          className={activeClass}>
          {line}
          {data && 'U'}
        </div>,
      ];
      line--;
    }
    return scales;
  }, [height, classes.scale, data]);

  return (
    <>
      <div className={classes.scale.container}>{dispalyScale()}</div>
    </>
  );
};

export default GridBodyScale;
