import { ISitesContext } from 'state/iContext';
import { SitesContext } from 'state/context';
import React, { useContext } from 'react';
import TableDetails from './TableDetails';
import TableFilters from './TableFilters';
import ExcelExport from './ExcelExport';
import { IStringProps } from 'iApp';

const SitesInventory = () => {
  const { inventoryFilters } = useContext(SitesContext) as ISitesContext;

  const classes: IStringProps = {
    container: 'w-full h-full overflow-y-auto',
    table: 'min-w-full',
    header:
      'text-2xl text-stone-500 text-center font-bold underline offset-8 mb-5',
    column: 'p-0.5 border border-stone-300 bg-stone-200 text-stone-500',
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.header}>Sites Inventory</h1>
      <ExcelExport />
      <TableFilters />
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.column}>Site</th>
            {inventoryFilters.type && <th className={classes.column}>Type</th>}
            {inventoryFilters.code && <th className={classes.column}>Code</th>}
            {inventoryFilters.height && (
              <th className={classes.column}>Height</th>
            )}
            {inventoryFilters.assets && (
              <th
                colSpan={2}
                className={classes.column}>
                Assets
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <TableDetails />
        </tbody>
      </table>
    </div>
  );
};

export default SitesInventory;
