import { IBooleanProps, IStringProps } from 'iApp';
import React, { useContext, useEffect } from 'react';
import AssetDetails from './AssetDetails';
import {
  highlightSearch,
  measurementConversion,
} from 'helpers/helperFunctions';
import {
  UserContext,
  SitesContext,
  CanvasContext,
  HeaderContext,
  SettingsContext,
} from 'state/context';
import {
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  ISettingsContext,
} from 'state/iContext';
import {
  IOpenStates,
  IHeaderButtons,
  ISetOpenStatesFunction,
} from 'state/iState';

const SiteRow = ({
  id,
  assetType,
  containerIds,
  dataOpenStates,
  setDataOpenStates,
  towerOpenStates,
  setTowerOpenStates,
}: {
  id: number;
  assetType: string;
  containerIds: string[];
  dataOpenStates: IOpenStates;
  setDataOpenStates: ISetOpenStatesFunction;
  towerOpenStates: IOpenStates;
  setTowerOpenStates: ISetOpenStatesFunction;
}) => {
  const { searchBar } =
    useContext(UserContext) as IUserContext;
  const { towerList, inventoryFilters, activeInventoryFilterCount } =
    useContext(SitesContext) as ISitesContext;
  const { activeTowerID, setActiveTowerID } =
    useContext(CanvasContext) as ICanvasContext;
  const { setActiveHeaderButton } =
    useContext(HeaderContext) as IHeaderContext;
  const { scale, metric } =
    useContext(SettingsContext) as ISettingsContext;

  const classes: IStringProps = {
    blank: 'p-0.5 border border-stone-300 bg-stone-100',
    column: 'relative p-0.5 border border-stone-300 text-center text-sm',
    height:
      'relative flex p-0.5 border-b border-b-stone-300 text-center text-sm justify-end',
    heightbox: 'flex w-3/5',
    bold: ' font-bold text-stone-400 underline',
    semibold: ' font-semibold text-stone-400 underline',
    open: 'fa-solid fa-chevron-down absolute right-4 top-2',
    close: 'fa-solid fa-chevron-right absolute right-4 top-2',
    clickable: ' cursor-pointer',
    highlight: ' bg-yellow-400',
  };

  const towerAssetContainerIds = Object.keys(towerList[id].layout.tower.legs);

  const viewDetailsTarget: IBooleanProps =
    towerList[id].type.site === 'Rooftop' ? { site: true } : { tower: true };

  const toggleOpen = (id: number, assetId: string, assetType: string): void => {
    const setOpenStates: ISetOpenStatesFunction =
      assetType === 'tower' ? setTowerOpenStates : setDataOpenStates;

    setOpenStates((prevOpenStates) => {
      const updatedOpenStates = { ...prevOpenStates };
      updatedOpenStates[id] = { ...updatedOpenStates[id] };
      updatedOpenStates[id][assetId] = !updatedOpenStates[id][assetId];
      return updatedOpenStates;
    });
  };
  const isCellOpen = (id: number, assetId: string, assetType: string) => {
    const openStates = assetType === 'tower' ? towerOpenStates : dataOpenStates;
    return openStates[id] ? openStates[id][assetId] : false;
  };

  const getSiteTypeLabel = (id: number) => {
    const asset = towerList[id];
    return towerList[id].type.site === 'Monopole' ||
      towerList[id].type.site === 'Rooftop'
      ? asset.type.site
      : `${asset.type.site} | ${asset.type.tower}`;
  };

  const getContainerHeight = (id: number, assetType: string, assetId: string) =>
    assetType === 'tower'
      ? towerList[id].height
      : towerList[id].layout.data.shelves[assetId].shelfGrid.h;

  const getContainerName = (id: number, assetType: string, assetId: string) =>
    assetType === 'tower'
      ? towerList[id].layout.tower.legs[assetId].legGrid.name
      : towerList[id].layout.data.shelves[assetId].shelfGrid.name;

  const getContainerAssets = (id: number, assetType: string, assetId: string) =>
    assetType === 'tower'
      ? towerList[id].layout.tower.legs[assetId].icons
      : towerList[id].layout.data.shelves[assetId].icons;

  useEffect(() => {
    if (!inventoryFilters.assets) {
      // Reset open states for tower assets
      if (assetType === 'tower') {
        setTowerOpenStates((prevOpenStates) => {
          const updatedOpenStates = { ...prevOpenStates };
          updatedOpenStates[id] = containerIds.reduce((acc, container) => {
            acc[container] = false;
            return acc;
          }, {});
          return updatedOpenStates;
        });
      } else {
        // Reset open states for data assets
        setDataOpenStates((prevOpenStates) => {
          const updatedOpenStates = { ...prevOpenStates };
          updatedOpenStates[id] = containerIds.reduce((acc, container) => {
            acc[container] = false;
            return acc;
          }, {});
          return updatedOpenStates;
        });
      }
    }
  }, [inventoryFilters.assets]);

  if (assetType === 'tower' && towerAssetContainerIds.length === 0) {
    return (
      <tr>
        <td
          className={
            classes.column +
            classes.clickable +
            (activeTowerID === +id ? classes.highlight : '')
          }>
          <a
            onClick={() => {
              setActiveTowerID(+id);
              setActiveHeaderButton((prev: IHeaderButtons) => ({
                ...prev,
                ...viewDetailsTarget,
              }));
            }}>
            {highlightSearch(towerList[id].name, searchBar.trim())}
          </a>
        </td>
        {inventoryFilters.type && (
          <td className={classes.column}>{getSiteTypeLabel(id)}</td>
        )}
        {inventoryFilters.code && (
          <td className={classes.column}>
            {highlightSearch(towerList[id].code, searchBar.trim())}
          </td>
        )}
        {inventoryFilters.height && (
          <td className={classes.height}>
            <div className={classes.heightbox}>
              {measurementConversion(
                metric,
                getContainerHeight(id, assetType, '')
              )}{' '}
              {scale}
            </div>
          </td>
        )}
        {inventoryFilters.assets && (
          <td
            colSpan={3}
            className={classes.column}>
            No assets to show
          </td>
        )}
      </tr>
    );
  }

  const row = containerIds.map((container, index) => {
    const openDetails = isCellOpen(id, container, assetType);

    return (
      <React.Fragment key={container}>
        <tr>
          {index === 0 && assetType === 'tower' ? (
            <>
              <td
                rowSpan={1}
                className={
                  classes.column +
                  classes.clickable +
                  (activeTowerID === +id ? classes.highlight : '')
                }>
                <a
                  onClick={() => {
                    setActiveTowerID(+id);
                    setActiveHeaderButton((prev: IHeaderButtons) => ({
                      ...prev,
                      ...viewDetailsTarget,
                    }));
                  }}>
                  {highlightSearch(towerList[id].name, searchBar.trim())}
                </a>
              </td>
              {inventoryFilters.type && (
                <td
                  rowSpan={1}
                  className={classes.column}>
                  {getSiteTypeLabel(id)}
                </td>
              )}
              {inventoryFilters.code && (
                <td
                  rowSpan={1}
                  className={classes.column}>
                  {highlightSearch(towerList[id].code, searchBar.trim())}
                </td>
              )}
              {inventoryFilters.height && (
                <td className={classes.height}>
                  <div className={classes.heightbox}>
                    {measurementConversion(
                      metric,
                      getContainerHeight(id, assetType, container)
                    )}{' '}
                    {scale}
                  </div>
                </td>
              )}
            </>
          ) : (
            <>
              {inventoryFilters.assets && (
                <td
                  colSpan={activeInventoryFilterCount}
                  className={classes.blank}
                />
              )}
            </>
          )}
          {inventoryFilters.assets && (
            <td
              colSpan={3}
              className={classes.column + classes.clickable + classes.bold}
              onClick={() => toggleOpen(id, container, assetType)}>
              {highlightSearch(
                getContainerName(id, assetType, container),
                searchBar.trim()
              )}
              {openDetails && <i className={classes.open} />}
              {!openDetails && <i className={classes.close} />}
            </td>
          )}
        </tr>
        {openDetails && (
          <AssetDetails
            icons={getContainerAssets(id, assetType, container)}
            height={getContainerHeight(id, assetType, container)}
          />
        )}
      </React.Fragment>
    );
  });

  return <>{row}</>;
};

export default SiteRow;
