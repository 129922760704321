import { ISiteTypeMenuClasses, ISiteTypeProps } from './iSiteTypes';
import { kebabString } from 'helpers/helperFunctions';
import { ISitesContext } from 'state/iContext';
import SiteTypeOption from './SiteTypeOption';
import React, { ReactNode, useContext} from 'react';

import { SitesContext } from 'state/context';

const SiteTypeMenu = () => {
  const { siteTypes } = useContext(SitesContext) as ISitesContext;

  // Componenet classes
  const classes: ISiteTypeMenuClasses = {
    button:
      'absolute right-0 z-10 w-56 origin-top-right rounded-sm bg-stone-100 shadow-lg ring-1 ring-gray-400 cursor-pointer',
    menu: 'bg-gray-200 border-b-2 border-stone-300 text-gray-700 block flex flex-col',
    option: 'flex w-full px-4 py-2',
  };

  // Renders available site type options
  const siteTypeDropdownList: ReactNode[] = Object.keys(siteTypes)
    .filter((siteType: string, i: number) => i > 0)
    .map((siteType: string, i: number): ReactNode => {
      const type: string = kebabString(siteType);
      return (
        <SiteTypeOption
          key={type}
          name={siteType}
          type={type}
          menuStyle={classes.option}
        />
      );
    });

  // Props to pass to their respective elements
  const props: ISiteTypeProps = {
    buttonProps: {
      className: classes.button,
      role: 'siteTypeMenu',
    },
    menuProps: {
      id: 'site-type-option-all-types',
      className: classes.menu,
      role: 'siteTypeMenuItem',
    },
    optionProps: {
      key: 'All Types',
      name: 'All Types',
      type: 'all-types',
      menuStyle: classes.option,
    },
  };

  return (
    <div {...props.buttonProps}>
      <div {...props.menuProps}>
        <SiteTypeOption {...props.optionProps} />
      </div>
      <>{siteTypeDropdownList}</>
    </div>
  );
};

export default SiteTypeMenu;
