import TowerFiles from 'components/application/canvas/views/files/TowerFiles';
import Drawings from 'components/application/canvas/views/drawings/Drawings';
import AccountSettings from './components/header/settings/AccountSettings';
import WorkOrdersHome from 'components/workOrders/WorkOrdersHome';
import Canvas from 'components/application/canvas/Canvas';
import DirectoryView from 'components/crm/DirectoryView';
import Modal from 'components/application/modals/Modal';
import React, { useContext, useEffect } from 'react';
import Reports from 'components/reports/Reports';
import { useAuth0 } from '@auth0/auth0-react';
import Header from 'components/header/Header';
import Home from 'components/home/Home';
import Login from 'Login';
import 'styles/main.css';
import {
  ICompanyContext,
  IHeaderContext,
  IModalContext,
  IUserContext,
} from 'state/iContext';
import {
  CompanyContext,
  HeaderContext,
  ModalContext,
  UserContext,
} from 'state/context';

const App = () => {
  const { isAuthenticated } = useAuth0();
  const { isModalActive } = useContext(ModalContext) as IModalContext;
  const { setSearchBar } = useContext(UserContext) as IUserContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const {
    activeHeaderButton: {
      settings,
      support,
      tower,
      site,
      data,
      drawings,
      files,
      contacts,
      'work orders': wo,
      reports
    },
    homePage,
  } = useContext(HeaderContext) as IHeaderContext;

  useEffect(() => {
    setSearchBar('');
  }, [tower, site, data, drawings, files, wo, reports, contacts, setSearchBar]);

  return (
    <div
      id='app'
      className='max-h-screen w-full h-screen m-0 p-0 pb-12 overflow-hidden'>
      {isAuthenticated && company ? (
        <>
          <Header />
          {homePage && <Home />}
          {(settings || support) && <AccountSettings />}
          {drawings && <Drawings />}
          {files && <TowerFiles />}
          {(tower || site || data) && <Canvas />}
          {wo && <WorkOrdersHome />}
          {reports && <Reports />}
          {contacts && <DirectoryView />}
          {isModalActive && <Modal />}
        </>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default App;
