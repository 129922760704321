import { IUserDropdownMenuClasses } from './iUserDropdown';
import UserDropdownOption from './UserDropdownOption';
import { kebabString } from 'helpers/helperFunctions';
import { userDropdownMenuIcons } from '../MenuIcons';
import React, { useContext } from 'react';
import {
  IUserContext,
  IHeaderContext,
  IServerContext,
  ICompanyContext,
} from 'state/iContext';
import {
  UserContext,
  HeaderContext,
  ServerContext,
  CompanyContext,
} from 'state/context';

const UserDropdownMenu = () => {
  const { userDropdown } =
    useContext(HeaderContext) as IHeaderContext;
  const { company } =
    useContext(CompanyContext) as ICompanyContext;
  const {
    user: { name, image },
  } = useContext(UserContext) as IUserContext;
  const { domain } =
    useContext(ServerContext) as IServerContext;

  // Component classes
  const classes: IUserDropdownMenuClasses = {
    main: 'absolute right-0.5 top-10 z-10 mt-2 w-56 rounded-sm bg-stone-100 shadow-lg ring-1 ring-gray-400',
    secondary: 'py-1 font-semibold',
    top: 'flex border-b-2 border-stone-300 text-gray-700 block px-4 py-2 text-sm',
    container: 'self-center rounded-full h-full w-1/4 mr-2',
    img: 'rounded-full object-fill border border-gray-300',
    company: 'flex font-normal text-slate-400 text-xs items-center truncate',
    companyLogo:
      'w-[22px] h-[22px] object-cover rounded-full border border-grey-300',
  };

  // Renders account menu dropdown options
  const userDropdownList = userDropdown.map((option: string) => (
    <UserDropdownOption
      icon={userDropdownMenuIcons[option as keyof typeof userDropdownMenuIcons]}
      id={kebabString(option)}
      name={option}
      key={option}
    />
  ));

  return (
    <div className={classes.main}>
      <div className={classes.secondary}>
        <div
          id='user-card'
          className={classes.top}>
          <div className={classes.container}>
            <img
              src={`${domain}${image}`}
              alt='user-profile'
              className={classes.img}
            />
          </div>
          <div>
            {name}
            <div className={classes.company}>
              <img
                src={`${domain}files/static/client/${company.name}/logo-small.png`}
                alt='company logo'
                className={classes.companyLogo}
              />
              {company.name}
            </div>
          </div>
        </div>
        <>{userDropdownList}</>
      </div>
    </div>
  );
};

export default UserDropdownMenu;
