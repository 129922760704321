import { IChangeImageStates, IFileSelectComplete } from 'helpers/iHelpers';
import {
  IAddItemProps,
  IDocumentList,
  ITowerDocument,
  ITowerList,
} from 'state/iState';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import SaveBox from 'components/buttons/SaveBox';
import { IInputEvent, IStringProps } from 'iApp';
import { IAddDrawingsProps } from './iDrawings';
import {
  ISitesContext,
  ICanvasContext,
  IDocumentContext,
  ISettingsContext,
  ICompanyContext,
  IServerContext,
  IUserContext,
  IHistoryContext,
} from 'state/iContext';
import {
  SitesContext,
  CanvasContext,
  DocumentContext,
  SettingsContext,
  CompanyContext,
  ServerContext,
  UserContext,
  HistoryContext,
} from 'state/context';
import axios from 'axios';

const AddDrawing = () => {
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { addItemProps, activeTowerID, clearAddProps, setAddItemProps } =
    useContext(CanvasContext) as ICanvasContext;
  const { towerList, setTowerList, updateSitesDB, fetchSiteData } = useContext(
    SitesContext
  ) as ISitesContext;
  const { setActiveFile, setDocumentList, fetchDocuments, newDocKey } =
    useContext(DocumentContext) as IDocumentContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const {
    fileTypes: { acceptedImageTypes },
  } = useContext(SettingsContext) as ISettingsContext;
  const {
    domain,
    uploadFilesToDB,
    updateDocumentsDB,
    checkCanvasLock,
    addHistoryToDB,
  } = useContext(ServerContext) as IServerContext;

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    setAddItemProps(clearAddProps);
  }, [setAddItemProps, clearAddProps]);

  const {
    imgSrc,
    setImgSrc,
    fileInputRef,
    onFileSelect,
    setImgSrcExt,
    setFileReader,
    clearToDefault,
    getImageFileExtension,
  } = ImageCropHelpers(addItemProps.content);

  // Changes/saves the state of the image and file extension
  const changeImageStates = ({ image }: IChangeImageStates): void => {
    setImgSrc(image);
    setAddItemProps((prev: IAddItemProps) => ({
      ...prev,
      content: image,
    }));
    setImgSrcExt(getImageFileExtension(image));
  };

  // Additional methods to envoke once a file is selected
  const onFileSelectComplete = ({ e, file }: IFileSelectComplete): void => {
    setAddItemProps((prev: IAddItemProps) => ({
      ...prev,
      label: file.name,
    }));
    setFileReader({
      file,
      changeImageStates,
    });
    clearToDefault(e);
  };

  const classes: IStringProps = {
    container: 'flex flex-col w-full h-full justify-center items-center',
    heading: 'font-bold text-xl text-stone-500',
    imgImported:
      'relative w-5/6 h-5/6 flex flex-col justify-around items-center border border-stone-300 rounded rounded-lg bg-slate-100',
    embed: 'h-4/5 self-center border border-2 border-stone-400',
    img: 'h-2/3 w-fit self-center object-contain border border-2 border-stone-400',
    input: 'flex flex-col w-full h-full',
    saveBox:
      'absolute flex -right-2 sm:right-0 bottom-5 w-[150px] justify-around sm:mx-5',
  };

  const props: IAddDrawingsProps = {
    file: {
      type: 'file',
      multiple: false,
      className: 'm-2',
      ref: fileInputRef!,
      id: 'imported-drawing',
      name: 'imported-drawing',
      accept: acceptedImageTypes,
      onChange: async (e: IInputEvent) => {
        const locked: boolean = await checkCanvasLock(activeTowerID);
        if (locked) {
          e.preventDefault();
        } else {
          setFile(e.target.files[0]);
          onFileSelect({ e, onFileSelectComplete });
        }
      },
    },
    img: {
      id: 'displayed-user-image',
      src: addItemProps.content,
      className: classes.img,
    },
    text: {
      type: 'text',
      name: 'name',
      id: 'drawing-name',
      placeholder: 'Reference Title',
      value: addItemProps.label,
      onChange: (e: IInputEvent) =>
        setAddItemProps((prev: IAddItemProps) => ({
          ...prev,
          label: e.target.value,
        })),
    },
    saveBox: {
      classes: classes.saveBox,
      clickHandlers: {
        save: async () => {
          const fetchedHistory = await fetchHistory();
          const nextHistoryKey = await axios.get(
            `${domain}get-next-pk/history/`
          );

          // Create history entry
          const historyEntry = {
            id: nextHistoryKey.data.next_pk,
            timestamp: Date.now(),
            type: 'File',
            createdBy: user.userId,
            siteID: activeTowerID,
            changes: {
              action: 'Updated',
              details: [
                {
                  added: [
                    {
                      text: `Drawing ${file?.name} Added`,
                    },
                    {
                      folder: 1,
                      currentName:
                        towerList[activeTowerID].documents.folders[1].name,
                    },
                  ],
                  field: `Drawing Added`,
                },
              ],
            },
          };

          const fetchedDocData = await fetchDocuments();
          const fetchedSiteData = await fetchSiteData(activeTowerID);
          await setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: fetchedSiteData,
          }));
          const date: number = Date.now();
          const newFileName = `tower-${towerList[activeTowerID].name}-1-file-${
            file!.name
          }`;
          const newFile = new File([file!], newFileName, {
            ...file,
          });
          const docData: ITowerDocument = {
            name: addItemProps.label.trim(),
            img: `files/media/client/${company.name}/${newFile.name}`,
            upload: date,
            updated: date,
            user: user.userId,
            isPDF: true,
            folder: 1,
          };

          await setHistory({
            ...fetchedHistory,
            [nextHistoryKey.data.next_pk]: historyEntry,
          });
          await updateDocumentsDB(newDocKey, 'POST', !docData.isPDF, docData);
          await uploadFilesToDB([newFile!]);
          await setDocumentList(() => ({
            ...fetchedDocData,
            [newDocKey]: docData,
          }));
          await setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              documents: {
                ...prev[activeTowerID].documents,
                folders: {
                  ...prev[activeTowerID].documents.folders,
                  1: {
                    ...prev[activeTowerID].documents.folders[1],
                    files: [
                      ...prev[activeTowerID].documents.folders[1].files,
                      `${newDocKey}`,
                    ],
                  },
                },
              },
              history: [
                ...prev[activeTowerID].history,
                nextHistoryKey.data.next_pk,
              ],
            },
          }));

          addHistoryToDB(historyEntry);
          await updateSitesDB(activeTowerID);
          setImgSrc('');
          setAddItemProps(clearAddProps);
          setActiveFile(newDocKey);
          setFile(null);
        },
        cancel: () => {
          setImgSrc('');
          setAddItemProps(clearAddProps);
        },
      },
      disabled: !addItemProps.content || !addItemProps.label,
    },
  };

  const displayedImage = useMemo(
    () =>
      imgSrc.search('application/pdf;') > 0 ? (
        <embed
          src={imgSrc}
          type='application/pdf'
          className={classes.embed}
          width='100%'
          height='100%'
        />
      ) : (
        <img
          alt='imported thumbnail'
          {...props.img}
        />
      ),
    [imgSrc, props.img, classes.embed]
  );

  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>Add Drawing Reference</h1>
      {imgSrc ? (
        <div className={classes.imgImported}>
          <div className={classes.input}>
            <input {...props.text} />
            {displayedImage}
          </div>
          <SaveBox {...props.saveBox} />
        </div>
      ) : (
        <input {...props.file} />
      )}
    </div>
  );
};

export default AddDrawing;
