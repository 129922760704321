import { formatDateTime, formatFieldName } from 'helpers/helperFunctions';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import HistoryDetails from 'components/history/HistoryDetails';
import { IHistoryEntry } from 'state/iState';
import {
  UserContext,
  SitesContext,
  CanvasContext,
  HeaderContext,
  HistoryContext,
} from 'state/context';
import {
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  IHistoryContext,
} from 'state/iContext';

const SiteHistoryPanel = () => {
  const { history: allHistory } = useContext(HistoryContext) as IHistoryContext;
  const { towerList } = useContext(SitesContext) as ISitesContext;
  const { userList } = useContext(UserContext) as IUserContext;
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { activeCanvasTab } = useContext(HeaderContext) as IHeaderContext;

  const [selectedHistory, setSelectedHistory] = useState<number | null>(null);
  const sortedHistoryList = useMemo(
    () =>
      towerList[activeTowerID].history
        .map((historyId: string) => allHistory[historyId])
        .filter((history: IHistoryEntry) =>
          activeCanvasTab === 'files'
            ? history.type === 'File' || history.type === 'Folder'
            : history
        )
        .sort((a, b) => b.timestamp - a.timestamp),
    [allHistory, activeTowerID, towerList]
  );

  const handleHistoryItemClick = (entry) =>
    setSelectedHistory(selectedHistory ? null : entry.id);

  useEffect(() => {
    setSelectedHistory(null);
  }, []);

  return (
    <div className='w-1/6 h-full work-order-history border border-stone-200 border-l border-l-2 border-l-slate-400 p-1 text-[10px] overflow-y-auto'>
      <ul className='list-inside'>
        {selectedHistory ? (
          <HistoryDetails
            onClick={handleHistoryItemClick}
            historyId={selectedHistory}
          />
        ) : (
          sortedHistoryList.map((entry) => (
            <li
              key={entry.id}
              className='history-entry flex flex-col'
              onClick={() => handleHistoryItemClick(entry)}>
              <div className='timestamp pr-2'>
                <strong>{formatDateTime(entry.timestamp)}</strong>
              </div>
              <div className='flex w-full'>
                <div className='timestamp pr-2 flex flex-col w-1/2 font-semibold'>
                  <span>{entry.changes.action} by:</span>
                </div>
                <div className='details w-1/2 text-end'>
                  {userList[entry.createdBy].name}
                </div>
              </div>
              {entry.changes.action === 'Updated' && (
                <div className='flex flex-col w-full'>
                  {entry.changes.details.map((detail, index) => (
                    <div key={index}>
                      {detail.field === 'Files' ? (
                        <div className='flex flex-col w-full font-semibold'>
                          {detail?.added &&
                            !detail?.updated &&
                            detail.added.length > 0 && (
                              <span>Added {detail.field}</span>
                            )}
                          {detail?.removed &&
                            !detail?.updated &&
                            detail.removed.length > 0 && (
                              <span>Removed {detail.field}</span>
                            )}
                          {detail?.updated && (
                            <span>
                              {detail.updated.length > 1
                                ? `Updated ${detail.field} Data`
                                : detail.updated[0].text}
                            </span>
                          )}
                          {detail?.before && <span>File Updated</span>}
                        </div>
                      ) : (
                        <>
                          {detail.field && (
                            <div>
                              <span className='font-semibold'>Field:</span>{' '}
                              <span className='italic'>
                                {formatFieldName(detail.field)}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <hr />
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default SiteHistoryPanel;
