import React, { useRef, useMemo, useState, ReactNode, useContext } from 'react';
import {
  CanvasContext,
  SitesContext,
  SettingsContext,
  UserContext,
  ServerContext,
  HistoryContext,
} from 'state/context';
import GridTextBoxes from '../../gridItems/items/GridTextBoxes';
import GridBody from '../../gridItems/gridContainers/GridBody';
import GridImages from '../../gridItems/items/GridImages';
import GridLayout, { Layout } from 'react-grid-layout';
import GridLink from '../../gridItems/items/GridLink';
import { ICanvasGridHandlers } from './iCanvasGrid';
import { ResizableBox } from 'react-resizable';
import { IStringProps } from 'iApp';
import {
  ISitesContext,
  ICanvasContext,
  ISettingsContext,
  IUserContext,
  IServerContext,
  IHistoryContext,
} from 'state/iContext';
import {
  ITowerList,
  IGridLayout,
  ILibraryIcon,
  IGridLayoutProps,
  ITowerLayout,
} from 'state/iState';
import { compareIconLayouts, formatFieldName } from 'helpers/helperFunctions';
import axios from 'axios';
import { clearDragProps } from 'state/state';

const TowerGrid = () => {
  const { scale, dragProps, setDragProps, activeTowerID } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const { setTowerList, towerList } = useContext(SitesContext) as ISitesContext;
  const { metric } = useContext(SettingsContext) as ISettingsContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;

  const [expandTop, setExpandTop] = useState<boolean>(true);
  const [previousY, setPreviousY] = useState<number | null>(null);
  const [prevHeight, setPrevHeight] = useState<number | null>(null);

  const editable: boolean =
    towerList[activeTowerID].edit.active &&
    towerList[activeTowerID].edit.user === user.userId;

  const classes: IStringProps = {
    container: 'relative ',
    gridItem:
      'peer relative h-full flex flex-col bg-stone-100 border border-stone-400 justify-center',
  };

  // Sets min/max parameters for tower height
  const minHeight: number = metric ? 15 : 50;
  const maxHeight: number = metric ? 182 : 600;

  const canvasTextBoxes: IGridLayoutProps =
    towerList[activeTowerID].layout.tower.textBoxes;
  const canvasImages: IGridLayoutProps =
    towerList[activeTowerID].layout.tower.images;
  const canvasLinks: IGridLayoutProps =
    towerList[activeTowerID].layout.tower.drawings;
  const allTowerLegs: ITowerLayout = towerList[activeTowerID].layout.tower.legs;

  const towerLegList: string[] = useMemo(
    () => Object.keys(allTowerLegs),
    [allTowerLegs]
  );

  const textBoxesList: string[] = useMemo(
    () => Object.keys(canvasTextBoxes),
    [canvasTextBoxes]
  );

  const imagesList: string[] = useMemo(
    () => Object.keys(canvasImages),
    [canvasImages]
  );

  const linksList: string[] = useMemo(
    () => Object.keys(canvasLinks),
    [canvasLinks]
  );

  const gridRef = useRef(null);

  const legLayout = Array.from(towerLegList, (leg) => {
    const { legGrid } = allTowerLegs[leg];
    return {
      ...legGrid,
      minH: minHeight,
      maxH: maxHeight,
    };
  });

  const layoutOnLoad = useMemo(
    () => [
      {
        i: -1,
        name: 'boundry',
        x: 3000,
        y: 1250,
        w: 0,
        h: 0,
        minW: 0,
        minH: 0,
        static: true,
      },
      ...legLayout,
    ],
    [legLayout]
  );

  const updateCanvasLegs = (layout: Layout[]) => {
    let updateLegs: { [key: string]: any } = {};
    layout.forEach((leg: Layout, i: number) => {
      let offsetY = 0;
      const legIndex: string = towerLegList.filter(
        (id) => allTowerLegs[id].legGrid.name === leg.i
      )[0];
      if (i > 0 && i <= towerLegList.length) {
        const { '-1': bottom, ...rest } = allTowerLegs[legIndex].icons;
        const newBottomY = leg.h + offsetY;
        offsetY += leg.h;
        updateLegs[legIndex] = {
          ...allTowerLegs[legIndex],
          legGrid: {
            ...allTowerLegs[legIndex].legGrid,
            h: towerList[activeTowerID].height,
            x: layout[i].x,
            y: layout[i].y,
          },
          icons: {
            '-1': {
              ...bottom,
              y: newBottomY,
            },
            ...rest,
          },
        };
      }
    });
    return updateLegs;
  };

  const openGridItemOptions = (layout: IGridLayout, type: string) =>
    setDragProps((prev: ILibraryIcon) => ({
      ...prev,
      dragging: true,
      id: layout.i,
      content: layout.name,
      type,
      target: '',
    }));


  const updateHistory = async (
    action: string,
    prevData?: {
      top?: number;
      bot?: number;
      height?: number;
      x?: number;
      y?: number;
    },
    newData?: {
      top?: number;
      bot?: number;
      height?: number;
      x?: number;
      y?: number;
    },
    target?: { type: string; name: string }
  ) => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    // Determine the direction of movement
    const xDifference = (newData?.x ?? 0) - (prevData?.x ?? 0);
    const yDifference = (newData?.y ?? 0) - (prevData?.y ?? 0);

    const xDirection = xDifference > 0 ? 'RIGHT' : 'LEFT';
    const yDirection = yDifference > 0 ? 'DOWN' : 'UP';

    // Create historyDetail with prevData and newData details
    const historyDetail = {
      move: [
        {
          updated: [
            {
              text: `${target?.name} Repositioned`,
            },
            {
              text: `${Math.abs(xDifference)} unit(s) to the ${xDirection}`,
            },
            {
              text: `${Math.abs(yDifference)} unit(s) to the ${yDirection}`,
            },
          ],
          field: `Tower ${target?.type} Moved`,
        },
      ],
      resize: [
        {
          before: [{ height: prevData?.height }],
          after: [{ height: newData?.height }],
          field: `Tower ${target?.type} Resized`,
        },
      ],
    };

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'Tower',
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: historyDetail[action],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const layoutCategory = (prevLayoutIndex) => {
    const towerLegsEndIndex = towerLegList.length + 1;
    const textBoxesEndIndex = towerLegsEndIndex + textBoxesList.length;
    const imagesEndIndex = textBoxesEndIndex + imagesList.length;
    const linksEndIndex = imagesEndIndex + linksList.length;

    if (prevLayoutIndex >= 1 && prevLayoutIndex < towerLegsEndIndex) {
      return 'Leg';
    }
    if (
      prevLayoutIndex >= towerLegsEndIndex &&
      prevLayoutIndex < textBoxesEndIndex
    ) {
      return 'Text Box';
    }
    if (
      prevLayoutIndex >= textBoxesEndIndex &&
      prevLayoutIndex < imagesEndIndex
    ) {
      return 'Image';
    }
    if (prevLayoutIndex >= imagesEndIndex && prevLayoutIndex < linksEndIndex) {
      return 'Reference Link';
    }
    return 'Canvas Item';
  };

  const images: ReactNode[] = imagesList.map((image: string): ReactNode => {
    return (
      <div
        className={classes.gridItem}
        id={canvasImages[image].name}
        key={canvasImages[image].name}
        data-grid={canvasImages[image]}
        onClick={() => openGridItemOptions(canvasImages[image], 'images')}
        onTouchStart={() => openGridItemOptions(canvasImages[image], 'images')}>
        <GridImages layout={canvasImages[image]} />
      </div>
    );
  });

  const textBoxes: ReactNode[] = textBoxesList.map(
    (box: string): ReactNode => (
      <div
        className={classes.gridItem}
        id={canvasTextBoxes[box].name}
        key={canvasTextBoxes[box].name}
        data-grid={canvasTextBoxes[box]}
        onClick={async () =>
          await openGridItemOptions(canvasTextBoxes[box], 'textBoxes')
        }
        onTouchStart={() =>
          openGridItemOptions(canvasTextBoxes[box], 'textBoxes')
        }>
        <GridTextBoxes layout={canvasTextBoxes[box]} />
      </div>
    )
  );

  const links: ReactNode[] = linksList.map(
    (link: string): ReactNode => (
      <div
        className='peer relative h-full flex flex-col bg-stone-50 -ml-[0.5px] -mt-[0.5px] pl-2 border border-red-400 justify-center font-bold text-xs text-red-700 rounded rounded-lg'
        id={canvasLinks[link].name}
        key={canvasLinks[link].name}
        data-grid={canvasLinks[link]}
        onClick={() => openGridItemOptions(canvasLinks[link], 'drawings')}
        onTouchStart={() => openGridItemOptions(canvasLinks[link], 'drawings')}>
        <GridLink layout={canvasLinks[link]} />
      </div>
    )
  );

  const towerLegs: ReactNode[] = layoutOnLoad.map((leg, i): ReactNode => {
    if (i > 0 && i <= towerLegList.length) {
      return (
        <div
          id={leg.name}
          key={leg.name}
          data-grid={leg}
          className={classes.gridItem}>
          <ResizableBox
            height={leg.h * 7.55}
            width={75.5}
            minConstraints={[75.5, minHeight * 7.55]}
            maxConstraints={[75.5, maxHeight * 7.55]}
            className='relative absolute flex'
            resizeHandles={editable ? ['n', 's'] : []}
            handle={
              <div>
                <i className='fa-solid fa-grip-lines react-resizable-handle-n' />
                <i className='fa-solid fa-grip-lines react-resizable-handle-s' />
              </div>
            }
            onResizeStart={() => {
              setPreviousY(allTowerLegs[i - 1].legGrid.y);
              setPrevHeight(towerList[activeTowerID].height);
              setTowerList((prev: ITowerList) => ({
                ...prev,
                [activeTowerID]: {
                  ...prev[activeTowerID],
                  layout: prev[activeTowerID].layout,
                },
              }));
            }}
            onResize={(event) => {
              const { movementY } = event as unknown as MouseEvent;
              const target = event.target as Element;

              setDragProps((prev: ILibraryIcon) => ({
                ...prev,
                dragging: true,
              }));
              if (target.classList.contains('react-resizable-handle-n')) {
                setExpandTop(true);
              }
              if (target.classList.contains('react-resizable-handle-s')) {
                setExpandTop(false);
              }
              setTowerList((prev: ITowerList) => {
                const updatedHeight = expandTop
                  ? prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h -
                    movementY
                  : prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h +
                    movementY;

                // Validate against minHeight and maxHeight
                const clampedHeight = Math.min(
                  Math.max(updatedHeight, minHeight),
                  maxHeight
                );

                // Check if the height actually changes
                if (
                  clampedHeight ===
                  prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h
                ) {
                  return prev; // No need to update if height doesn't change
                }

                const updatedLegGrid = {
                  ...prev[activeTowerID].layout.tower.legs[i - 1].legGrid,
                  h: clampedHeight,
                  y: expandTop
                    ? prev[activeTowerID].layout.tower.legs[i - 1].legGrid.y! +
                      movementY
                    : prev[activeTowerID].layout.tower.legs[i - 1].legGrid.y,
                };

                const updatedSections = {
                  ...prev[activeTowerID].layout.tower.legs[i - 1].sections,
                  '-1': {
                    ...prev[activeTowerID].layout.tower.legs[i - 1].sections[
                      '-1'
                    ],
                    h: clampedHeight,
                  },
                };

                return {
                  ...prev,
                  [activeTowerID]: {
                    ...prev[activeTowerID],
                    height: clampedHeight,
                    layout: {
                      ...prev[activeTowerID].layout,
                      tower: {
                        ...prev[activeTowerID].layout.tower,
                        legs: {
                          ...prev[activeTowerID].layout.tower.legs,
                          [i - 1]: {
                            ...prev[activeTowerID].layout.tower.legs[i - 1],
                            legGrid: updatedLegGrid,
                            sections: updatedSections,
                          },
                        },
                      },
                    },
                  },
                };
              });
            }}
            onResizeStop={(event) => {
              const { movementY } = event as unknown as MouseEvent;
              const height = towerList[activeTowerID].height;

              setDragProps((prev: ILibraryIcon) => ({
                ...prev,
                dragging: false,
              }));
              if (
                allTowerLegs[i - 1].legGrid.h < minHeight ||
                allTowerLegs[i - 1].legGrid.h > maxHeight
              ) {
                setTowerList((prev: ITowerList) => ({
                  ...prev,
                  [activeTowerID]: {
                    ...prev[activeTowerID],
                    height: expandTop
                      ? prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h -
                        movementY
                      : prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h +
                        movementY,
                    layout: {
                      ...prev[activeTowerID].layout,
                      tower: {
                        ...prev[activeTowerID].layout.tower,
                        legs: {
                          ...prev[activeTowerID].layout.tower.legs,
                          [i - 1]: {
                            ...prev[activeTowerID].layout.tower.legs[i - 1],
                            legGrid: {
                              ...prev[activeTowerID].layout.tower.legs[i - 1]
                                .legGrid,
                              h: 50,
                              y: previousY,
                            },
                          },
                        },
                      },
                    },
                  },
                }));
              }
              updateHistory(
                'resize',
                { height: prevHeight! },
                { height },
                {
                  type: 'Leg',
                  name: towerList[activeTowerID].layout.tower.legs[i - 1]
                    .legGrid.name,
                }
              );

              setPrevHeight(null);
              setPreviousY(null);
            }}>
            <GridBody
              key={leg.name}
              layout={leg}
              type='tower'
            />
          </ResizableBox>
        </div>
      );
    } else {
      return (
        <div
          id={leg.name}
          key={leg.name}
          data-grid={leg}
          className={classes.gridItem}
        />
      );
    }
  });

  const updateTextBoxes = (layout: Layout[]): IGridLayoutProps => {
    const updatedTextBoxes: IGridLayoutProps = {};
    for (let i = 0; i < layout.length; i++) {
      const box = layout[i];
      const textBox = canvasTextBoxes[textBoxesList[i]];
      updatedTextBoxes[i] = {
        ...box,
        i,
        name: box.i,
        text: textBox.text,
        maxH: 30,
        maxW: 30,
        minH: 10,
        minW: 10,
      };
    }
    return updatedTextBoxes;
  };

  const updateImages = (layout: Layout[]) => {
    const updatedImages: Record<string, any> = {};
    for (let i = 0; i < layout.length; i++) {
      const image = layout[i];
      updatedImages[i] = {
        ...image,
        i,
        name: image.i,
        img: canvasImages[imagesList[i]].img,
        maxH: 30,
        maxW: 30,
        minH: 10,
        minW: 10,
      };
    }
    return updatedImages;
  };

  const updateLinks = (layout: Layout[]) => {
    const updatedLinks: Record<string, any> = {};
    for (let i = 0; i < layout.length; i++) {
      const link = layout[i];
      const { i: linkI, name, refID } = canvasLinks[linksList[i]];
      updatedLinks[linkI] = {
        ...link,
        i: linkI,
        name,
        refID,
        isDraggable: true,
        isResizable: true,
      };
    }
    return updatedLinks;
  };

  const handler: ICanvasGridHandlers = {
    onResizeStop: () => {},
    onLayoutChange: async (layout) => {
      const currentCanvasLayout = towerList[activeTowerID].layout.tower;
      let hasChanges = false;

      // Utility function to check if layout properties have changed
      const hasLayoutChanged = (current, updated) => {
        if (!current || !updated) {
          // If either current or updated is undefined, consider it a change
          return true;
        }
        return (
          current.x !== updated.x ||
          current.y !== updated.y ||
          current.h !== updated.h ||
          current.w !== updated.w
        );
      };

      // Filter the layout items based on their types
      const filteredTextBoxes = layout.filter((item) =>
        Object.values(canvasTextBoxes).some((obj) => obj.name === item.i)
      );
      const filteredImages = layout.filter((item) =>
        Object.values(canvasImages).some((obj) => obj.name === item.i)
      );
      const filteredLinks = layout.filter((item) =>
        Object.values(canvasLinks).some((obj) => obj.name === item.i)
      );

      // Update layout items
      const updatedLegs = updateCanvasLegs(layout);
      const updatedTextBoxes = updateTextBoxes(filteredTextBoxes);
      const updatedImages = updateImages(filteredImages);
      const updatedLinks = updateLinks(filteredLinks);

      // Check for changes in tower legs
      if (!hasChanges) {
        for (const legIndex of towerLegList) {
          const currentLeg = currentCanvasLayout.legs[legIndex];
          const updatedLeg = updatedLegs[legIndex];
          if (hasLayoutChanged(currentLeg.legGrid, updatedLeg.legGrid)) {
            hasChanges = true;
            break;
          }
        }
      }

      if (!hasChanges) {
        // Check for changes in text boxes
        for (const textBoxName in currentCanvasLayout.textBoxes) {
          const currentTextBox = currentCanvasLayout.textBoxes[textBoxName];
          const updatedTextBox = updatedTextBoxes[textBoxName];
          if (hasLayoutChanged(currentTextBox, updatedTextBox)) {
            hasChanges = true;
            break;
          }
        }
      }

      if (!hasChanges) {
        // Check for changes in images
        for (const imageName in currentCanvasLayout.images) {
          const currentImage = currentCanvasLayout.images[imageName];
          const updatedImage = updatedImages[imageName];
          if (hasLayoutChanged(currentImage, updatedImage)) {
            hasChanges = true;
            break;
          }
        }
      }

      if (!hasChanges) {
        // Check for changes in links
        for (const linkName in currentCanvasLayout.drawings) {
          const currentLink = currentCanvasLayout.drawings[linkName];
          const updatedLink = updatedLinks[linkName];
          if (hasLayoutChanged(currentLink, updatedLink)) {
            hasChanges = true;
            break;
          }
        }
      }

      if (hasChanges) {
        const updatedTower = {
          ...currentCanvasLayout,
          legs: updatedLegs,
          textBoxes: updatedTextBoxes,
          images: updatedImages,
          drawings: updatedLinks,
        };

        setTowerList((prev: ITowerList) => {
          return {
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              layout: { ...prev[activeTowerID].layout, tower: updatedTower },
            },
          };
        });
      }
    },
    onDragStop: (layout, prevLayout, newLayout) => {
      const hasChanged =
        prevLayout.y !== newLayout.y ||
        prevLayout.x !== newLayout.x ||
        prevLayout.h !== newLayout.h;
      // Find the index of prevLayout.i in the layout array
      if (hasChanged) {
        const prevLayoutIndex = layout.findIndex(
          (item) => item.i === prevLayout.i
        );
        const category = layoutCategory(prevLayoutIndex);

        updateHistory(
          'move',
          { x: prevLayout.x, y: prevLayout.y },
          { x: newLayout.x, y: newLayout.y },
          {
            type: category,
            name: newLayout.i,
          }
        );
        handler.onLayoutChange(layout);
      }
    },
    onResize: () => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: true,
      }));
    },
  };

  return (
    <div
      id='grid-layout'
      className={classes.container}
      onMouseUp={() =>
        setDragProps((prev: ILibraryIcon) => ({
          ...prev,
          dragging: false,
        }))
      }
      onMouseDown={() => {
        setDragProps((prev: ILibraryIcon) => ({
          ...prev,
          dragging: true,
        }));
      }}>
      <GridLayout
        cols={10040}
        width={75600}
        margin={[0, 0]}
        autoSize={false}
        rowHeight={7.56}
        innerRef={gridRef}
        isDraggable={editable}
        isResizable={editable}
        allowOverlap={true}
        className='relative'
        layout={layoutOnLoad}
        transformScale={scale}
        useCSSTransforms={true}
        resizeHandles={['se']}
        compactType={'horizontal'}
        draggableHandle='.dragHandle'
        onDragStop={handler.onDragStop}
        onResize={handler.onLayoutChange}
        onLayoutChange={handler.onLayoutChange}>
        {towerLegs}
        {textBoxes}
        {images}
        {links}
      </GridLayout>
    </div>
  );
};

export default TowerGrid;
