import { ISettingsContext } from 'state/iContext';
import { SettingsContext } from 'state/context';
import { IEditHeight } from './iEdit';
import React, { useContext} from 'react';


const EditHeight = ({ length, changeHandler }: IEditHeight) => {
  const { metric } = useContext(SettingsContext) as ISettingsContext;

  // Sets min/max parameters for tower height
  const minHeight: number = metric ? 15 : 50;
  const maxHeight: number = metric ? 182 : 600;

  // Adds red highlight if number is below minimum
  const heightClass: string =
    (metric && length < 15) || (!metric && length < 50)
      ? 'focus:bg-blue-100 px-2 text-sm border border-2 border-red-600'
      : 'focus:bg-blue-100 px-2 text-sm';

  return (
    <div className='flex flex-col px-5 w-1/2'>
      <label htmlFor='height'>Height</label>
      <input
        required
        step={0.01}
        type='number'
        name='height'
        value={length}
        min={minHeight}
        max={maxHeight}
        id='edit-height'
        className={heightClass}
        onChange={changeHandler}
      />
    </div>
  );
};

export default EditHeight;