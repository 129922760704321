import ApplicationView from './applicationView/ApplicationView';
import UserDropdown from './userDropdown/UserDropdown';
import QuickSettings from './settings/QuickSettings';
import SiteType from './siteType/SiteType';
import { IHeaderClasses } from './iHeader';
import React, { useContext, useEffect } from 'react';
import Logo from './Logo';
import {
  IMapContext,
  ICanvasContext,
  IHeaderContext,
  IServerContext,
  ICompanyContext,
  ISettingsContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  MapContext,
  CanvasContext,
  HeaderContext,
  ServerContext,
  CompanyContext,
  SettingsContext,
  SitesContext,
  UserContext,
} from 'state/context';
import HeaderTabs from './applicationView/HeaderTabs';
import { ITower, ITowerList } from 'state/iState';

const Header = () => {
  const { setInfoWindowID } = useContext(MapContext) as IMapContext;
  const { setDragProps, clearAddProps, activeTowerID } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const { homePage, activeHeaderButton, setActiveHeaderButton } = useContext(
    HeaderContext
  ) as IHeaderContext;
  const { isSettingsOpen } = useContext(SettingsContext) as ISettingsContext;
  const { company } = useContext(CompanyContext) as ICompanyContext;
  const { domain, releaseCanvasLock, checkCanvasLock } = useContext(
    ServerContext
  ) as IServerContext;
  const { towerList, setTowerList, fetchSiteData } = useContext(
    SitesContext
  ) as ISitesContext;
  const { user } = useContext(UserContext) as IUserContext;

  const activeEditor: boolean =
    towerList[activeTowerID]?.edit.user === user.userId;

  // Component classes
  const classes: IHeaderClasses = {
    header:
      'w-full h-62 z-60 flex justify-between border border-stone-500 bg-stone-100',
    center: 'flex w-full',
    clientLogo:
      'absolute top-1 left-1/2 max-h-[40px] object-fill rounded-full -ml-14 hidden sm: inline',
    container: 'flex md:justify-end',
  };

  const handler = (): void => {
    setDragProps(clearAddProps);
    setInfoWindowID('');
  };

  const exitCanvasEdit = async () => {
    if (activeEditor) {
      await releaseCanvasLock(activeTowerID, user.userId);
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          edit: {
            active: false,
            user: null,
            time: null,
          },
        },
      }));
    }
  };

  const updateCanvasLock = async (): Promise<void> => {
    try {
      const canvasLocked: boolean = await checkCanvasLock(activeTowerID);
      if (canvasLocked) {
        const fetchedSiteData: ITower = await fetchSiteData(activeTowerID);

        setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: fetchedSiteData,
        }));
      } else {
        setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            edit: {
              active: false,
              user: null,
              time: null,
            },
          },
        }));
      }
    } catch (error) {
      console.error('Failed to update canvas lock:', error);
    }
  };

  useEffect(() => {
    if (activeTowerID) {
      updateCanvasLock();
    }
  }, [activeHeaderButton]);

  return (
    <div
      id='header'
      onClick={handler}
      onTouchStart={handler}
      className={classes.header}>
      <div className={classes.center}>
        <Logo />
        {company && (homePage || activeHeaderButton.settings) ? (
          <>
            <div className='flex text-xs ml-5 font-semibold'>
              <HeaderTabs />
            </div>
            <img
              src={`${domain}${company?.logo}`}
              id='client-logo'
              alt='company logo'
              className={classes.clientLogo}
            />
          </>
        ) : (
          <ApplicationView />
        )}
      </div>
      <div
        id='header-right'
        className={classes.container}
        onClick={exitCanvasEdit}>
        {homePage && <SiteType />}
        <div
          onClick={async () => {
            setActiveHeaderButton(() => ({
              map: false,
              inventory: false,
              tower: false,
              site: false,
              data: false,
              drawings: false,
              files: false,
              contacts: true,
              'work orders': false,
            }));
            exitCanvasEdit();
          }}
          className={
            'flex flex-col justify-end px-4 text-slate-600 transition ease-in-out delay-100 hover:bg-red-700 hover:text-slate-200'
          }>
          <div
            className={
              'flex flex-col w-auto max-h-[20px] justify-center items-center text-center'
            }>
            <i className='fa-regular fa-address-book text-lg' />
          </div>
          <span className='text-xs font-semibold'>CONTACTS</span>
        </div>
        <UserDropdown />
        {isSettingsOpen && <QuickSettings />}
      </div>
    </div>
  );
};

export default Header;
