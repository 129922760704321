import { ICanvasContext, IServerContext } from 'state/iContext';
import { displayImageProperty } from 'helpers/helperFunctions';
import { CanvasContext, ServerContext } from 'state/context';
import PropertyLabel from '../PropertyLabel';
import React, { useContext } from 'react';
import { IGridItem } from '../iGridItems';

const GridAsset = ({ layout }: IGridItem) => {
  const {
    activeToolBar: { Properties },
  } = useContext(CanvasContext) as ICanvasContext;
  const { domain } = useContext(ServerContext) as IServerContext;

  return (
    <>
      <img
        className='w-full h-full object-contain'
        src={displayImageProperty(layout, domain)}
        key='icon/asset'
        alt=''
      />
      {Properties && (
        <PropertyLabel
          label={layout.name!}
          type='icon'
        />
      )}
    </>
  );
};

export default GridAsset;
