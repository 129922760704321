import ManageLibraries from '../canvas/panel/manageLibraries/ManageLibraries';
import CustomIcon from './customIcon/CustomIcon';
import { IModalContext } from 'state/iContext';
import { ModalContext } from 'state/context';
import React, { useContext} from 'react';
import { IStringProps } from 'iApp';


const Modal = () => {
  const {
    activeModal: {
      customIcon,
      createDocument,
      manageLibraries,
      downloadDocument,
      permissionSettings,
    },
  } = useContext(ModalContext) as IModalContext;

  const classes: IStringProps = {
    container:
      'absolute top-0 left-0 z-20 flex w-screen h-screen justify-center items-center',
    screen: 'absolute w-full h-full bg-black opacity-50',
  };

  return (
    <div className={classes.container}>
      <div className={classes.screen} />
      {permissionSettings && <div>PERMISSION SETTINGS</div>}
      {downloadDocument && <div>DOWNLOAD DOCUMENT</div>}
      {createDocument && <div>CREATE DOCUMENT</div>}
      {manageLibraries && <ManageLibraries />}
      {customIcon && <CustomIcon />}
    </div>
  );
};

export default Modal;
