import { IIconButtons, IIconOptionClasses } from '../../../../icons/iIcons';
import { IActiveModal, ILibraries } from 'state/iState';
import React, { ReactNode, useContext } from 'react';
import { IMapElement } from 'iApp';
import {
  IUserContext,
  IModalContext,
  IServerContext,
  ICompanyContext,
  ILibraryContext,
} from 'state/iContext';
import {
  UserContext,
  ModalContext,
  ServerContext,
  CompanyContext,
  LibraryContext,
} from 'state/context';

const IconOptions = () => {
  const { activeLibrary, activeIcon, setActiveIcon, libraries, setLibraries } =
    useContext(LibraryContext) as ILibraryContext;
  const { setCustomIconStage, setActiveModal, setEditIcon } = useContext(
    ModalContext
  ) as IModalContext;
  const {
    user: { private_libraries: library },
  } = useContext(UserContext) as IUserContext;
  const { icons } = useContext(CompanyContext) as ICompanyContext;
  const { updateLibrariesDB } = useContext(ServerContext) as IServerContext;

  const classes: IIconOptionClasses = {
    container: 'flex h-[32px] w-full bg-red-600',
    button:
      'flex w-full justify-center items-center text-slate-200 transition ease-in-out delay-100 hover:bg-red-700',
    icons: {
      save: 'fa-regular fa-floppy-disk',
      copy: 'fa-solid fa-copy',
      edit: 'fa-solid fa-pen-to-square',
      // promote: 'fa-solid fa-circle-up',
      delete: 'fa-solid fa-trash-can',
    },
  };

  const buttonIcons: string[] = Object.keys(classes.icons);
  const customLibrary = Object.keys(library);
  const renderCondition: number = customLibrary.includes(activeLibrary) ? 0 : 1;

  const buttons: ReactNode[] = buttonIcons
    .filter((_, i) => {
      if (activeIcon.startsWith('2') && i === 2) {
        return false;
      }
      return i < buttonIcons.length - renderCondition;
    })
    .map((icon): IMapElement => {
      const iconButtons: IIconButtons = {
        save: () => {
          setCustomIconStage(3);
          setActiveModal((prev: IActiveModal) => ({
            ...prev,
            customIcon: true,
          }));
          setEditIcon({
            content: icons[activeIcon].content,
            id: icons[activeIcon].name,
            properties: icons[activeIcon].properties,
            type: 'save',
          });
        },
        copy: () => {
          setCustomIconStage(2);
          setActiveModal((prev: IActiveModal) => ({
            ...prev,
            customIcon: true,
          }));
          setEditIcon({
            content: icons[activeIcon].content,
            id: icons[activeIcon].name,
            properties: icons[activeIcon].properties,
            type: 'copy',
          });
        },
        edit: () => {
          setCustomIconStage(2);
          setActiveModal((prev: IActiveModal) => ({
            ...prev,
            customIcon: true,
          }));
          setEditIcon({
            content: icons[activeIcon].content,
            id: icons[activeIcon].name,
            properties: icons[activeIcon].properties,
            edit: true,
          });
        },
        // promote: () => console.log(icon),
        delete: () => {
          setLibraries((prev: ILibraries) => ({
            ...prev,
            [activeLibrary]: {
              ...prev[activeLibrary],
              assets: prev[activeLibrary].assets.filter(
                (id) => id !== activeIcon
              ),
            },
          }));
          updateLibrariesDB('PUT', {
            ...libraries[activeLibrary],
            assets: libraries[activeLibrary].assets.filter(
              (id) => id !== activeIcon
            ),
          });
          setActiveIcon('');
        },
      };
      return (
        <div
          key={icon}
          className={classes.button}
          onClick={iconButtons[icon]}>
          <i className={classes.icons[icon]} />
        </div>
      );
    });

  return <div className={classes.container}>{buttons}</div>;
};

export default IconOptions;
