import {
  ICanvasContext,
  IHeaderContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  CanvasContext,
  HeaderContext,
  ServerContext,
  SitesContext,
  UserContext,
} from 'state/context';
import logo from 'assets/header/logo.png';
import React, { useContext } from 'react';
import { ITowerList } from 'state/iState';

const Logo = () => {
  const { setActiveHeaderButton } = useContext(HeaderContext) as IHeaderContext;
  const { activeTowerID, setActiveTowerID } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const { towerList, setTowerList } = useContext(SitesContext) as ISitesContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { releaseCanvasLock } = useContext(ServerContext) as IServerContext;

  // Returns to home page
  const clickHandler = async () => {
    setActiveTowerID(0);
    setActiveHeaderButton({
      siteTypes: false,
      map: true,
      inventory: false,
      account: false,
      settings: false,
      tower: false,
      drawings: false,
      site: false,
      contacts: false,
      'work orders': false,
    });

    if (activeTowerID && towerList[activeTowerID].edit.user === user.userId) {
      await releaseCanvasLock(activeTowerID, user.userId);
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          edit: {
            active: false,
            user: null,
            time: null,
          },
        },
      }));
    }
  };

  return (
    <img
      id='logo'
      src={logo}
      // sizes='150px'
      alt='rigstar logo'
      onClick={clickHandler}
      className='cursor-pointer w-[150px]'
      // srcSet='https://mlemukba83pq.i.optimole.com/w:300/h:93/q:mauto/https://www.rigstar.ca/wp-content/uploads/2017/01/Rigstar_Horizontal_Main_industrial_telecom300.png 300w, https://mlemukba83pq.i.optimole.com/w:auto/h:auto/q:mauto/https://www.rigstar.ca/wp-content/uploads/2017/01/Rigstar_Horizontal_Main_industrial_telecom600.png 600w'
    />
  );
};

export default Logo;
