import GridTextBoxes from '../../gridItems/items/GridTextBoxes';
import {
  ISitesContext,
  ICanvasContext,
  IUserContext,
  IHistoryContext,
  IServerContext,
} from 'state/iContext';
import GridBody from '../../gridItems/gridContainers/GridBody';
import {
  SitesContext,
  CanvasContext,
  UserContext,
  HistoryContext,
  ServerContext,
} from 'state/context';
import GridImages from '../../gridItems/items/GridImages';
import GridLayout, { Layout } from 'react-grid-layout';
import GridLink from '../../gridItems/items/GridLink';
import { ICanvasGridHandlers } from './iCanvasGrid';
import { IStringProps } from 'iApp';
import React, {
  useRef,
  useMemo,
  ReactNode,
  useEffect,
  useContext,
} from 'react';
import {
  ITowerList,
  IGridLayout,
  ILibraryIcon,
  IGridLayoutProps,
} from 'state/iState';
import axios from 'axios';

const classes: IStringProps = {
  container: 'relative',
  gridItem:
    'peer group relative h-full flex flex-col bg-stone-100 -ml-[0.5px] -mt-[0.5px] -pb-[1px] border border-stone-400 justify-center',
  link: 'peer relative h-full flex flex-col bg-stone-50 -ml-[0.5px] -mt-[0.5px] pl-2 border border-red-400 justify-center font-bold text-xs text-red-700 rounded rounded-lg',
  buttons: 'hidden absolute -top-[10px] flex w-full h-[10px] group-hover:flex',
  up: 'fa-solid fa-caret-up flex w-1/2 h-[10px] justify-center items-center text-sm text-stone-500 hover:text-red-600 hover:cursor-default disabled:text-stone-300',
  down: 'fa-solid fa-caret-down flex w-1/2 h-[10px] justify-center items-center text-sm text-stone-500 hover:text-red-600 hover:cursor-default disabled:text-stone-300',
  data: 'relative absolute flex',
  grid: 'relative',
};

const DataGrid = () => {
  const { scale, setDragProps, activeTowerID, openGridItemOptions } =
    useContext(CanvasContext) as ICanvasContext;
  const { setTowerList, towerList, fetchSiteData } = useContext(
    SitesContext
  ) as ISitesContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;

  const editable: boolean =
    towerList[activeTowerID].edit.active &&
    towerList[activeTowerID].edit.user === user.userId;

  const canvasTextBoxes: IGridLayoutProps =
    towerList[activeTowerID].layout.data.textBoxes;
  const canvasImages: IGridLayoutProps =
    towerList[activeTowerID].layout.data.images;
  const canvasLinks: IGridLayoutProps =
    towerList[activeTowerID].layout.data.drawings;
  const allSiteRacks = towerList[activeTowerID].layout.data.shelves;

  const textBoxesList: string[] = Object.keys(canvasTextBoxes);
  const rackList: string[] = Object.keys(allSiteRacks);
  const imagesList: string[] = Object.keys(canvasImages);
  const linksList: string[] = Object.keys(canvasLinks);

  const gridRef = useRef(null);

  const dataLayout = Array.from(rackList, (data) => {
    const { shelfGrid } = allSiteRacks[data];
    return { ...shelfGrid, maxH: 40, minH: 10 };
  });

  const layoutOnLoad = useMemo(
    () => [
      {
        i: -1,
        name: 'boundry',
        x: 2000,
        y: 200,
        w: 0,
        h: 0,
        minW: 0,
        minH: 0,
        static: true,
      },
      ...dataLayout,
    ],
    [dataLayout]
  );

  const updateHistory = async (
    action: string,
    prevData?: {
      top?: number;
      bot?: number;
      height?: number;
      x?: number;
      y?: number;
    },
    newData?: {
      top?: number;
      bot?: number;
      height?: number;
      x?: number;
      y?: number;
    },
    target?: { type: string; name: string }
  ) => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    // Determine the direction of movement
    const xDifference = (newData?.x ?? 0) - (prevData?.x ?? 0);
    const yDifference = (newData?.y ?? 0) - (prevData?.y ?? 0);

    const xDirection = xDifference > 0 ? 'RIGHT' : 'LEFT';
    const yDirection = yDifference > 0 ? 'DOWN' : 'UP';

    // Create historyDetail with prevData and newData details
    const historyDetail = {
      move: [
        {
          updated: [
            {
              text: `${target?.name} Repositioned`,
            },
            {
              text: `${Math.abs(xDifference)} unit(s) to the ${xDirection}`,
            },
            {
              text: `${Math.abs(yDifference)} unit(s) to the ${yDirection}`,
            },
          ],
          field: `Data ${target?.type} Moved`,
        },
      ],
      resize: [
        {
          before: [{ height: prevData?.height }],
          after: [{ height: newData?.height }],
          field: `Data ${target?.type} Resized`,
        },
      ],
    };

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'Data',
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: historyDetail[action],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const layoutCategory = (prevLayoutIndex) => {
    const towerRackEndIndex = rackList.length + 1;
    const textBoxesEndIndex = towerRackEndIndex + textBoxesList.length;
    const imagesEndIndex = textBoxesEndIndex + imagesList.length;
    const linksEndIndex = imagesEndIndex + linksList.length;

    if (prevLayoutIndex >= 1 && prevLayoutIndex < towerRackEndIndex) {
      return 'Rack';
    }
    if (
      prevLayoutIndex >= towerRackEndIndex &&
      prevLayoutIndex < textBoxesEndIndex
    ) {
      return 'Text Box';
    }
    if (
      prevLayoutIndex >= textBoxesEndIndex &&
      prevLayoutIndex < imagesEndIndex
    ) {
      return 'Image';
    }
    if (prevLayoutIndex >= imagesEndIndex && prevLayoutIndex < linksEndIndex) {
      return 'Reference Link';
    }
    return 'Canvas Item';
  };

  const images: ReactNode[] = imagesList.map((image: string): ReactNode => {
    return (
      <div
        className={classes.gridItem}
        id={canvasImages[image].name}
        key={canvasImages[image].name}
        data-grid={canvasImages[image]}
        onClick={() => openGridItemOptions(canvasImages[image], 'images')}
        onTouchStart={() => openGridItemOptions(canvasImages[image], 'images')}>
        <GridImages layout={canvasImages[image]} />
      </div>
    );
  });

  const textBoxes: ReactNode[] = textBoxesList.map(
    (box: string): ReactNode => (
      <div
        className={classes.gridItem}
        id={canvasTextBoxes[box].name}
        key={canvasTextBoxes[box].name}
        data-grid={canvasTextBoxes[box]}
        onClick={() => openGridItemOptions(canvasTextBoxes[box], 'textBoxes')}
        onTouchStart={() =>
          openGridItemOptions(canvasTextBoxes[box], 'textBoxes')
        }>
        <GridTextBoxes layout={canvasTextBoxes[box]} />
      </div>
    )
  );

  const links: ReactNode[] = linksList.map(
    (link: string): ReactNode => (
      <div
        className={'peer relative h-full flex flex-col bg-stone-50 -ml-[0.5px] -mt-[0.5px] pl-2 border border-red-400 justify-center font-bold text-xs text-red-700 rounded rounded-lg'}
        id={canvasLinks[link].name}
        key={canvasLinks[link].name}
        data-grid={canvasLinks[link]}
        onClick={() => openGridItemOptions(canvasLinks[link], 'drawings')}
        onTouchStart={() => openGridItemOptions(canvasLinks[link], 'drawings')}>
        <GridLink layout={canvasLinks[link]} />
      </div>
    )
  );

  const dataRacks: ReactNode[] = layoutOnLoad.map(
    (box, i): ReactNode =>
      i > 0 && i <= rackList.length ? (
        <div
          id={box.name}
          key={box.name}
          data-grid={box}
          className={classes.gridItem}>
          {editable && (
            <div className={classes.buttons}>
              <button
                className={classes.up}
                disabled={box.h === 40}
                onClick={async () => {
                  const expandRate =
                    towerList[activeTowerID].layout.data.shelves[i - 1]
                      .shelfGrid.h > 10
                      ? 20
                      : 10;

                  const updatedIcons = Object.keys(
                    towerList[activeTowerID].layout.data.shelves[i - 1].icons
                  ).reduce((acc, id) => {
                    return {
                      ...acc,
                      [id]: {
                        ...towerList[activeTowerID].layout.data.shelves[i - 1]
                          .icons[id],
                        y:
                          towerList[activeTowerID].layout.data.shelves[i - 1]
                            .icons[id].y + expandRate,
                      },
                    };
                  }, {});

                  await setTowerList((prev: ITowerList) => ({
                    ...prev,
                    [activeTowerID]: {
                      ...prev[activeTowerID],
                      layout: {
                        ...prev[activeTowerID].layout,
                        data: {
                          ...prev[activeTowerID].layout.data,
                          shelves: {
                            ...prev[activeTowerID].layout.data.shelves,
                            [i - 1]: {
                              ...prev[activeTowerID].layout.data.shelves[i - 1],
                              shelfGrid: {
                                ...prev[activeTowerID].layout.data.shelves[
                                  i - 1
                                ].shelfGrid,
                                y:
                                  prev[activeTowerID].layout.data.shelves[i - 1]
                                    .shelfGrid.y! - expandRate,
                                h:
                                  prev[activeTowerID].layout.data.shelves[i - 1]
                                    .shelfGrid.h! + expandRate,
                              },
                              icons: updatedIcons,
                            },
                          },
                        },
                      },
                    },
                  }));
                  updateHistory(
                    'resize',
                    {
                      height:
                        towerList[activeTowerID].layout.data.shelves[i - 1]
                          .shelfGrid.h!,
                    },
                    {
                      height:
                        towerList[activeTowerID].layout.data.shelves[i - 1]
                          .shelfGrid.h! + expandRate,
                    },
                    {
                      type: 'Rack',
                      name: towerList[activeTowerID].layout.data.shelves[i - 1]
                        .shelfGrid.name,
                    }
                  );
                }}
              />
              <button
                className={classes.down}
                disabled={box.h === 10}
                onClick={async () => {
                  const dropRate =
                    towerList[activeTowerID].layout.data.shelves[i - 1]
                      .shelfGrid.h > 20
                      ? 20
                      : 10;
                  const updateIcons = Object.keys(
                    towerList[activeTowerID].layout.data.shelves[i - 1].icons
                  ).filter(
                    (id) =>
                      towerList[activeTowerID].layout.data.shelves[i - 1].icons[
                        id
                      ].y <=
                      box.h -
                        towerList[activeTowerID].layout.data.shelves[i - 1]
                          .icons[id].h -
                        dropRate
                  );

                  const updateSections = Object.keys(
                    towerList[activeTowerID].layout.data.shelves[i - 1].sections
                  ).filter(
                    (id) =>
                      towerList[activeTowerID].layout.data.shelves[i - 1]
                        .sections[id].y <=
                      box.h -
                        towerList[activeTowerID].layout.data.shelves[i - 1]
                          .sections[id].h -
                        dropRate
                  );

                  const updatedIcons = updateIcons.reduce(
                    (acc, id) => ({
                      ...acc,
                      [id]: towerList[activeTowerID].layout.data.shelves[i - 1]
                        .icons[id],
                    }),
                    {}
                  );

                  const updatedSections = updateSections.reduce(
                    (acc, id) => ({
                      ...acc,
                      [id]: towerList[activeTowerID].layout.data.shelves[i - 1]
                        .sections[id],
                    }),
                    {}
                  );

                  await setTowerList((prev: ITowerList) => ({
                    ...prev,
                    [activeTowerID]: {
                      ...prev[activeTowerID],
                      layout: {
                        ...prev[activeTowerID].layout,
                        data: {
                          ...prev[activeTowerID].layout.data,
                          shelves: {
                            ...prev[activeTowerID].layout.data.shelves,
                            [i - 1]: {
                              ...prev[activeTowerID].layout.data.shelves[i - 1],
                              shelfGrid: {
                                ...prev[activeTowerID].layout.data.shelves[
                                  i - 1
                                ].shelfGrid,
                                y:
                                  prev[activeTowerID].layout.data.shelves[i - 1]
                                    .shelfGrid.y! + dropRate,
                                h:
                                  prev[activeTowerID].layout.data.shelves[i - 1]
                                    .shelfGrid.h! - dropRate,
                              },
                              icons: {
                                '-1': {
                                  i: -1,
                                  name: '',
                                  x: 0,
                                  y: dropRate,
                                  w: 0,
                                  minW: 0,
                                  minH: 0,
                                  h: 0,
                                  static: true,
                                  isResizable: false,
                                },
                                ...updatedIcons,
                              },
                              sections: {
                                '-1': {
                                  i: -1,
                                  name: '',
                                  x: 0,
                                  y: dropRate,
                                  w: 0,
                                  minW: 0,
                                  minH: 0,
                                  h: 0,
                                  static: true,
                                  isResizable: false,
                                },
                                ...updatedSections,
                              },
                            },
                          },
                        },
                      },
                    },
                  }));
                  updateHistory(
                    'resize',
                    {
                      height:
                        towerList[activeTowerID].layout.data.shelves[i - 1]
                          .shelfGrid.h!,
                    },
                    {
                      height:
                        towerList[activeTowerID].layout.data.shelves[i - 1]
                          .shelfGrid.h! - dropRate,
                    },
                    {
                      type: 'Rack',
                      name: towerList[activeTowerID].layout.data.shelves[i - 1]
                        .shelfGrid.name,
                    }
                  );
                }}
              />
            </div>
          )}
          <GridBody
            key={box.name}
            layout={box}
            type='data'
          />
        </div>
      ) : (
        <div
          id={box.name}
          key={box.name}
          data-grid={box}
          className={classes.gridItem}
        />
      )
  );

  const updateCanvasRacks = (layout: Layout[]) => {
    let updatedData: { [key: string]: any } = {};
    let offsetY = 0;
    layout.forEach((data: Layout, i: number) => {
      const dataIndex: string = rackList.filter(
        (id) =>
          towerList[activeTowerID].layout.data.shelves[id].shelfGrid.name ===
          data.i
      )[0];
      if (i > 0 && i <= rackList.length) {
        const { '-1': bottom, ...rest } = allSiteRacks[dataIndex].icons;
        const newBottomY = data.h + offsetY;
        offsetY += data.h;
        updatedData[dataIndex] = {
          ...allSiteRacks[dataIndex],
          shelfGrid: {
            ...allSiteRacks[dataIndex].shelfGrid,
            x: data.x,
            y: data.y,
            h: data.h,
          },
          icons: {
            '-1': {
              ...bottom,
              y: newBottomY,
            },
            ...rest,
          },
        };
      }
    });
    return updatedData;
  };

  const updateTextBoxes = (layout: Layout[]): IGridLayoutProps => {
    const updatedTextBoxes: IGridLayoutProps = {};
    for (let i = 0; i < layout.length; i++) {
      const box = layout[i];
      const textBox = canvasTextBoxes[textBoxesList[i]];
      updatedTextBoxes[i] = {
        ...box,
        i,
        name: box.i,
        text: textBox.text,
        maxH: 30,
        maxW: 30,
        minH: 10,
        minW: 10,
      };
    }
    return updatedTextBoxes;
  };

  const updateImages = (layout: Layout[]) => {
    const updatedImages: Record<string, any> = {};
    for (let i = 0; i < layout.length; i++) {
      const image = layout[i];
      updatedImages[i] = {
        ...image,
        i,
        name: image.i,
        img: canvasImages[imagesList[i]].img,
        maxH: 30,
        maxW: 30,
        minH: 10,
        minW: 10,
      };
    }
    return updatedImages;
  };

  const updateLinks = (layout: Layout[]) => {
    const updatedLinks: Record<string, any> = {};
    for (let i = 0; i < layout.length; i++) {
      const link = layout[i];
      const { i: linkI, name, refID } = canvasLinks[linksList[i]];
      updatedLinks[linkI] = {
        ...link,
        i: linkI,
        name,
        refID,
        isDraggable: true,
        isResizable: true,
      };
    }
    return updatedLinks;
  };

  const handler: ICanvasGridHandlers = {
    onResizeStop: () => {
    },
    onLayoutChange: async (layout) => {
      const currentCanvasLayout = towerList[activeTowerID].layout.data;
      let hasChanges = false;

      // Utility function to check if layout properties have changed
      const hasLayoutChanged = (current, updated) => {
        if (!current || !updated) {
          // If either current or updated is undefined, consider it a change
          return true;
        }
        return (
          current.x !== updated.x ||
          current.y !== updated.y ||
          current.h !== updated.h ||
          current.w !== updated.w
        );
      };

      // Filter the layout items based on their types
      const filteredTextBoxes = layout.filter((item) =>
        Object.values(canvasTextBoxes).some((obj) => obj.name === item.i)
      );
      const filteredImages = layout.filter((item) =>
        Object.values(canvasImages).some((obj) => obj.name === item.i)
      );
      const filteredLinks = layout.filter((item) =>
        Object.values(canvasLinks).some((obj) => obj.name === item.i)
      );

      // Update layout items
      const updatedRacks = updateCanvasRacks(layout);
      const updatedTextBoxes = updateTextBoxes(filteredTextBoxes);
      const updatedImages = updateImages(filteredImages);
      const updatedLinks = updateLinks(filteredLinks);

      // Check for changes in data racks
      if (!hasChanges) {
        for (const rackIndex of rackList) {
          const currentRack = currentCanvasLayout.shelves[rackIndex];
          const updatedRack = updatedRacks[rackIndex];
          if (hasLayoutChanged(currentRack.legGrid, updatedRack.legGrid)) {
            hasChanges = true;
            break;
          }
        }
      }

      if (!hasChanges) {
        // Check for changes in text boxes
        for (const textBoxName in currentCanvasLayout.textBoxes) {
          const currentTextBox = currentCanvasLayout.textBoxes[textBoxName];
          const updatedTextBox = updatedTextBoxes[textBoxName];
          if (hasLayoutChanged(currentTextBox, updatedTextBox)) {
            hasChanges = true;
            break;
          }
        }
      }

      if (!hasChanges) {
        // Check for changes in images
        for (const imageName in currentCanvasLayout.images) {
          const currentImage = currentCanvasLayout.images[imageName];
          const updatedImage = updatedImages[imageName];
          if (hasLayoutChanged(currentImage, updatedImage)) {
            hasChanges = true;
            break;
          }
        }
      }

      if (!hasChanges) {
        // Check for changes in links
        for (const linkName in currentCanvasLayout.drawings) {
          const currentLink = currentCanvasLayout.drawings[linkName];
          const updatedLink = updatedLinks[linkName];
          if (hasLayoutChanged(currentLink, updatedLink)) {
            hasChanges = true;
            break;
          }
        }
      }

      if (hasChanges) {
        const updatedDataRacks = {
          ...currentCanvasLayout,
          shelves: updatedRacks,
          textBoxes: updatedTextBoxes,
          images: updatedImages,
          drawings: updatedLinks,
        };

        setTowerList((prev: ITowerList) => {
          return {
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              layout: { ...prev[activeTowerID].layout, data: updatedDataRacks },
            },
          };
        });
      }
    },
    onDragStop: (layout, prevLayout, newLayout) => {
      const hasChanged =
        prevLayout.y !== newLayout.y ||
        prevLayout.x !== newLayout.x ||
        prevLayout.h !== newLayout.h;
      // Find the index of prevLayout.i in the layout array
      if (hasChanged) {
        const prevLayoutIndex = layout.findIndex(
          (item) => item.i === prevLayout.i
        );
        const category = layoutCategory(prevLayoutIndex);

        updateHistory(
          'move',
          { x: prevLayout.x, y: prevLayout.y },
          { x: newLayout.x, y: newLayout.y },
          {
            type: category,
            name: newLayout.i,
          }
        );
        handler.onLayoutChange(layout);
      }
    },
    onResize: () => {},
  };

  useEffect(
    () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
        content: '',
        id: '',
        target: '',
        type: '',
      })),
    [setDragProps]
  );

  return (
    <div
      id='grid-layout'
      className={classes.container}
      onMouseUp={() =>
        setDragProps((prev: ILibraryIcon) => ({
          ...prev,
          dragging: false,
        }))
      }
      onMouseDown={() => {
        setDragProps((prev: ILibraryIcon) => ({
          ...prev,
          dragging: true,
        }));
      }}>
      <GridLayout
        cols={10040}
        width={75600}
        margin={[0, 0]}
        autoSize={false}
        rowHeight={7.55}
        innerRef={gridRef}
        isDraggable={true}
        isResizable={true}
        allowOverlap={true}
        layout={layoutOnLoad}
        transformScale={scale}
        useCSSTransforms={true}
        className={classes.grid}
        compactType={'horizontal'}
        draggableHandle='.dragHandle'
        onDragStop={handler.onDragStop}
        onResizeStop={handler.onResizeStop}
        onLayoutChange={handler.onLayoutChange}>
        {dataRacks}
        {textBoxes}
        {images}
        {links}
      </GridLayout>

    </div>
  );
};

export default DataGrid;
