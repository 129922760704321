import React, { useContext, useEffect, useState } from 'react';
import { IActiveTab, ITabControl } from './iSettings';
import { IHeaderContext } from 'state/iContext';
import { HeaderContext } from 'state/context';
import SettingsCard from './SettingsCard';
import SettingsTabs from './SettingsTab';

const AccountSettings = () => {
  const {
    activeHeaderButton: { account, settings, support },
  } = useContext(HeaderContext) as IHeaderContext;

  // Active Tab State
  const [activeTab, setActiveTab] = useState<IActiveTab>({
    account,
    settings,
    support,
  });

  const tabControl: ITabControl = {
    activeTab,
    setActiveTab,
  };

  useEffect(() => {
    setActiveTab({
      account: settings ? true : false,
      settings: false,
      support,
    });
  }, [account, settings, support]);

  return (
    <div className='flex flex-col md:flex-row w-full h-full pb-10 overflow-y-auto border-t border-stone-400'>
      <SettingsTabs {...tabControl} />
      <SettingsCard {...activeTab} />
    </div>
  );
};

export default AccountSettings;
