import {
  DocumentContext,
  CanvasContext,
  SitesContext,
  UserContext,
} from 'state/context';
import { getDescendants } from 'helpers/helperFunctions';
import React, { useContext, useEffect } from 'react';
import ActiveDrawing from './ActiveDrawing';
import DrawingTabs from './DrawingTabs';
import AddDrawing from './AddDrawing';
import Footer from 'Footer';
import {
  ISitesContext,
  ICanvasContext,
  IDocumentContext,
  IUserContext,
} from 'state/iContext';
import UpdateBanner from '../../UpdateBanner';
import { IStringProps } from 'iApp';

const classes: IStringProps = {
  container: 'relative flex flex-col w-full h-full overflow-y-auto',
  empty:
    'flex w-full h-full justify-center align-center items-center text-center font-bold underline',
};

const Drawings = () => {
  const { activeTowerID } = useContext(CanvasContext) as ICanvasContext;
  const { towerList, updateSitesDB } = useContext(
    SitesContext
  ) as ISitesContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { activeFile, documentList, getAllFilesFromFolder } = useContext(
    DocumentContext
  ) as IDocumentContext;

  const drawingfolders: number[] = [
    1,
    ...getDescendants('1', towerList[activeTowerID].documents.folders),
  ];

  const drawingKeys: string[] = getAllFilesFromFolder('1');

  const inactiveDrawing: boolean =
    !!!drawingKeys || activeFile === 'add' || !activeFile;

  useEffect(() => {
    updateSitesDB(activeTowerID);
  }, [updateSitesDB, activeTowerID, towerList]);

  return (
    <div className={classes.container}>
      <DrawingTabs />
      {towerList[activeTowerID].edit.active && inactiveDrawing ? (
        <>
          <UpdateBanner />
          <div className={classes.empty}>
            <span>Currently unable to add additional files at this time</span>
          </div>
        </>
      ) : inactiveDrawing ? (
        <>
          {user.admin ? (
            <AddDrawing />
          ) : (
            <div className={classes.empty}>No Files to Display</div>
          )}
        </>
      ) : (
        <ActiveDrawing drawing={documentList[+activeFile!]} />
      )}
      <Footer />
    </div>
  );
};

export default Drawings;
