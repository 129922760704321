import React, { useContext, useEffect, useState } from 'react';
import ReportSiteDropdown from './ReportSiteDropdown';
import { IDocumentContext } from 'state/iContext';
import { IReportSectionProps, IReportSiteOption } from './iReports';
import { DocumentContext } from 'state/context';
import ReportQuestions from './ReportQuestions';

const classes = {
  container: 'flex flex-col justify-center mb-4',
  sectionTitle: 'text-xl font-bold text-gray-400 ml-5',
  questionsContainer: 'flex flex-col justify-center pl-4 p-2 my-2 bg-slate-100',
};

const ReportSection: React.FC<IReportSectionProps> = ({
  section,
  questions,
  handleInputChange,
  handleImageUpload,
}) => {
  const { activeFile, documentList } = useContext(
    DocumentContext
  ) as IDocumentContext;
  const [selectedSite, setSelectedSite] = useState<IReportSiteOption | null>(
    null
  );

  const handleSiteChange = (site) => {
    setSelectedSite(site);
    handleInputChange(section, 'Site', site);
  };

  useEffect(() => {
    if (activeFile !== 'add' && section === 'ID') {
      setSelectedSite(documentList[activeFile!]?.file.formData?.ID?.Site);
    }
  }, [activeFile, section, documentList]);

  return (
    <div
      className={
        classes.container +
        (section === 'ID' && !selectedSite
          ? ' z-10 sticky top-0 bg-red-700'
          : '')
      }>
      <h2 className={classes.sectionTitle}>{section}</h2>
      <div className={classes.questionsContainer}>
        {questions.map((question, idx) =>
          question === 'Site' ? (
            <ReportSiteDropdown
              key={idx}
              selectedSite={selectedSite}
              handleSiteChange={handleSiteChange}
            />
          ) : (
            <ReportQuestions
              key={idx}
              section={section}
              question={question}
              handleInputChange={handleInputChange}
              handleImageUpload={handleImageUpload}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ReportSection;
