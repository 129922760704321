import React, { useContext, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  IDocumentContext,
  IHistoryContext,
  IServerContext,
  IUserContext,
  IWorkOrderContext,
} from 'state/iContext';
import {
  DocumentContext,
  HistoryContext,
  ServerContext,
  UserContext,
  WorkOrderContext,
} from 'state/context';
import { IWorkOrderList } from '../iWorkOrders';
import axios from 'axios';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const FileViewer = ({ file, onClose }) => {
  const { documentList, setDocumentList, fetchDocuments, activeFile } =
    useContext(DocumentContext) as IDocumentContext;
  const { domain, deleteDocumentFromDB, updateDocumentsDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { setHistory, fetchHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { addHistoryToDB, updateWorkOrdersDB } = useContext(
    ServerContext
  ) as IServerContext;
  const { setWorkOrders, fetchWorkOrder } = useContext(
    WorkOrderContext
  ) as IWorkOrderContext;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(1);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newFileName, setNewFileName] = useState<string>(
    documentList[file].name
  );

  if (typeof activeFile !== 'number') {
    return null;
  }

  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, numPages));

  const handleLoadSuccess = ({ numPages }: { numPages: number }) =>
    setNumPages(numPages);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFileName(event.target.value);
  };

  const handleSaveClick = async () => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);
    const latestWorkOrder = await fetchWorkOrder(activeFile);

    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'WorkOrder',
      createdBy: user.userId,
      changes: {
        action: 'Updated',
        details: [
          {
            field: 'Files',
            before: documentList[file].name,
            after: newFileName,
          },
        ],
      },
    };

    const updatedDoc = {
      ...documentList[file],
      name: newFileName,
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });
    addHistoryToDB(historyEntry);
    setWorkOrders((prev: IWorkOrderList) => {
      const updatedWorkOrder = {
        ...latestWorkOrder,
        history: [...latestWorkOrder.history, nextHistoryKey.data.next_pk],
        lastModified: Date.now(),
        lastModifiedBy: user.userId,
      };
      updateWorkOrdersDB('PUT', updatedWorkOrder);

      return {
        ...prev,
        [activeFile!]: updatedWorkOrder,
      };
    });
    await updateDocumentsDB(file, 'PUT', false, updatedDoc);
    await fetchDocuments();
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setNewFileName(documentList[file].name);
  };

  const handleDeleteClick = async () => {
    const latestWorkOrder = await fetchWorkOrder(activeFile);
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);

    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: 'WorkOrder',
      createdBy: user.userId,
      changes: {
        action: 'Updated',
        details: [
          {
            field: 'Files',
            removed: [file],
          },
        ],
      },
    };

    await deleteDocumentFromDB(activeFile!.toString());
    await fetchDocuments();
    setDocumentList((prevList) => {
      const { [file]: _, ...rest } = prevList;
      return rest;
    });

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });
    addHistoryToDB(historyEntry);
    setWorkOrders((prev) => {
      const updatedWorkOrder = {
        ...latestWorkOrder,
        files: latestWorkOrder.files.filter((id: number) => id !== file),
        history: [...latestWorkOrder.history, nextHistoryKey.data.next_pk],
        lastModified: Date.now(),
        lastModifiedBy: user.userId,
      };

      updateWorkOrdersDB('PUT', updatedWorkOrder);
      return {
        ...prev,
        [activeFile]: updatedWorkOrder,
      };
    });
    onClose();
  };

  const classes = {
    viewerContainer: 'w-full text-center',
    pdfViewer: 'w-full mx-auto mb-4 items-center',
    pageButtons: 'flex justify-around',
    button: 'px-4 py-2 bg-gray-200 text-gray-700 rounded',
    image: 'mx-auto block',
    edit: 'fa-regular fa-pen-to-square text-lg m-1 font-bold text-stone-400 cursor-pointer hover:text-red-700',
    trash:
      'fa-regular fa-trash-can text-lg m-1 font-bold text-stone-400 cursor-pointer hover:text-red-700',
  };

  const isOwner = documentList[file].user === user.userId;

  return (
    <div className={classes.viewerContainer}>
      {isEditing ? (
        <>
          <input
            type='text'
            value={newFileName}
            onChange={handleInputChange}
          />
          <button
            onClick={handleSaveClick}
            className={classes.button}>
            Save
          </button>
          <button
            onClick={handleCancelClick}
            className={classes.button}>
            Cancel
          </button>
        </>
      ) : (
        <p>{documentList[file].name}</p>
      )}
      {!isEditing && (
        <>
          {documentList[file].name.endsWith('.pdf') ? (
            <div className={classes.pdfViewer}>
              <div className={classes.pageButtons}>
                <button
                  onClick={handlePreviousPage}
                  className={classes.button}>
                  Previous Page
                </button>
                <div>
                  <p>
                    Page {currentPage} of {numPages}
                  </p>
                  <button
                    onClick={onClose}
                    className={classes.button}>
                    Close
                  </button>
                </div>
                <button
                  onClick={handleNextPage}
                  className={classes.button}>
                  Next Page
                </button>
              </div>
              {isOwner && (
                <>
                  <i
                    className={classes.edit}
                    onClick={handleEditClick}
                  />
                  <i
                    className={classes.trash}
                    onClick={handleDeleteClick}
                  />
                </>
              )}
              <Document
                file={`${domain}${documentList[file].img}`}
                onLoadSuccess={handleLoadSuccess}>
                <Page pageNumber={currentPage} />
              </Document>
            </div>
          ) : (
            <>
              <img
                src={`${domain}${documentList[file].img}`}
                alt={documentList[file].name}
                className={classes.image}
              />
              {isOwner && (
                <>
                  <i
                    className={classes.edit}
                    onClick={handleEditClick}
                  />
                  <i
                    className={classes.trash}
                    onClick={handleDeleteClick}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FileViewer;
