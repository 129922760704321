import { IGridLayoutProps, ILibraryIcon, ITowerList } from 'state/iState';
import {
  ICanvasContext,
  IHeaderContext,
  IHistoryContext,
  IServerContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import React, { ReactNode, useContext, useEffect, useMemo } from 'react';
import {
  CanvasContext,
  HeaderContext,
  HistoryContext,
  ServerContext,
  SitesContext,
  UserContext,
} from 'state/context';
import { formatFieldName, nextAvailableKey } from 'helpers/helperFunctions';
import GridLayout, { Layout } from 'react-grid-layout';
import PropertyLabel from './PropertyLabel';
import { IStringProps } from 'iApp';
import axios from 'axios';

const gridItem: IStringProps = {
  tower: 'legs',
  data: 'shelves',
};

const classes: IStringProps = {
  container: 'absolute group z-10 h-full w-1/2 top-0 -left-11',
  button:
    'hidden absolute fa-solid fa-square-plus -top-5 w-full justify-center px-1 text-sm text-slate-400 hover:text-red-600 group-hover:flex',
  grid: 'group w-full',
  section:
    'relative flex flex-nowrap z-10 w-full justify-center cursor-row-resize',
};

const colors: string[] = [
  '#ff0000', // red
  '#0000ff', // blue
  '#00ff00', // green
  '#ee82ee', // violet
  '#008000', // green
  '#ffa500', // orange
  '#008080', // teal
  '#7b7b7b', // zinc
  '#ffbf00', // amber
  '#00ff00', // lime
  '#000000', // black
];

const LeaseSections = ({ id, legLayout, type }) => {
  const { setTowerList, towerList } = useContext(SitesContext) as ISitesContext;
  const { activeCanvasTab } = useContext(HeaderContext) as IHeaderContext;
  const { user } = useContext(UserContext) as IUserContext;
  const { fetchHistory, setHistory } = useContext(
    HistoryContext
  ) as IHistoryContext;
  const { domain, addHistoryToDB } = useContext(
    ServerContext
  ) as IServerContext;
  const {
    scale,
    setDragProps,
    activeTowerID,
    activeToolBar: { Properties },
  } = useContext(CanvasContext) as ICanvasContext;

  const sections: IGridLayoutProps =
    towerList[activeTowerID].layout[type][gridItem[type]][id].sections;
  const sectionIds: string[] = Object.keys(sections);
  const editable: boolean =
    towerList[activeTowerID].edit.active &&
    towerList[activeTowerID].edit.user === user.userId;

  const height: number =
    type === 'tower'
      ? towerList[activeTowerID].height
      : towerList[activeTowerID].layout.data.shelves[legLayout[0].i].shelfGrid
          .h;

  const nextKey: number = useMemo(
    () => nextAvailableKey(sections, 0)!,
    [sections]
  );

  const layoutOnLoad = useMemo(
    () => [
      {
        ...legLayout[0],
        name: `${legLayout[0].name} sections`,
        w: 4,
        h: height,
        x: legLayout[0].x || 0,
        y: legLayout[0].y || 0,
      },
    ],
    [legLayout, height]
  );

  // Checks if the length of the grid item is fully covered by lease sections
  const gridCoverage: number = useMemo(
    () =>
      sectionIds.length > 1
        ? sectionIds
            .map((section) => sections[section].h)
            .reduce((a, b) => +a + +b, 0)
        : 0,
    [sections]
  );

  const openAssetOptions = (id: string): void =>
    setDragProps((prev: ILibraryIcon) => ({
      ...prev,
      content: sections[id].name,
      id,
      dragging: true,
      target: legLayout[0].i.toString(),
      type: 'sections',
    }));

  const updateSections = (layout: Layout[]) => {
    const updatedSections = {};

    layout.forEach((section, i) => {
      // Calculate the height of the section, ensuring it stays within bounds
      const resizeInBounds =
        +height - +section.y < +section.h ? +height - +section.y : +section.h;

      section.i === ''
        ? (updatedSections[-1] = {
            ...sections[-1],
            i: -1,
            name: '',
            y: height,
            h: +resizeInBounds,
            w: 4,
            x: section.x,
          })
        : (updatedSections[i] = {
            ...sections[section.i],
            i,
            name: section.i,
            y: section.y,
            h: +resizeInBounds,
            w: 4,
            x: section.x,
          });
    });

    return updatedSections;
  };

  const updateHistory = async (
    action: string,
    newData?: { name?: string; y?: number; height?: number },
    prevData?: { name?: string; y?: number; height?: number }
  ) => {
    const fetchedHistory = await fetchHistory();
    const nextHistoryKey = await axios.get(`${domain}get-next-pk/history/`);
    const historyDetail = {
      add: [
        {
          added: [
            {
              text: `${newData?.name} Added to ${legLayout[0].name}`,
            },
          ],
          field: `${
            activeCanvasTab === 'tower' ? 'Tower' : 'Data'
          } Lease Section Added`,
        },
      ],
      move: [
        {
          updated: [
            {
              text: `${newData?.name} Moved on ${legLayout[0].name}`,
            },
          ],
          before: [
            {
              topHeight: legLayout[0].h - prevData?.y!,
              botHeight: legLayout[0].h - prevData?.y! - prevData?.height!,
            },
          ],
          after: [
            {
              topHeight: legLayout[0].h - newData?.y!,
              botHeight: legLayout[0].h - newData?.y! - newData?.height!,
            },
          ],
          field: `${
            activeCanvasTab === 'tower' ? 'Tower' : 'Data'
          } Lease Section Moved`,
        },
      ],
      resize: [
        {
          updated: [
            {
              text: `${newData?.name} Resized on ${legLayout[0].name}`,
            },
          ],
          before: [
            {
              topHeight: legLayout[0].h - prevData?.y!,
              botHeight: legLayout[0].h - prevData?.y! - prevData?.height!,
              height: prevData?.height,
            },
          ],
          after: [
            {
              topHeight: legLayout[0].h - newData?.y!,
              botHeight: legLayout[0].h - newData?.y! - newData?.height!,
              height: newData?.height!,
            },
          ],
          field: `${type === 'tower' ? 'Tower' : 'Data'} Lease Section Resized`,
        },
      ],
    };

    // Create history entry
    const historyEntry = {
      id: nextHistoryKey.data.next_pk,
      timestamp: Date.now(),
      type: `${formatFieldName(activeCanvasTab)} Canvas`,
      createdBy: user.userId,
      siteID: activeTowerID,
      changes: {
        action: 'Updated',
        details: historyDetail[action],
      },
    };

    setHistory({
      ...fetchedHistory,
      [nextHistoryKey.data.next_pk]: historyEntry,
    });

    await addHistoryToDB(historyEntry);
    await setTowerList((prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        history: [...prev[activeTowerID].history, nextHistoryKey.data.next_pk],
      },
    }));
  };

  const handler = {
    onLayoutChange: (newLayout: Layout[]) => {
      const currentSections =
        towerList[activeTowerID].layout[type][gridItem[type]][legLayout[0].i]
          .sections;

      // Convert current sections into a comparable format with the new layout
      const currentLayout = Object.values(currentSections).map(
        (section: any) => ({
          i: section.name,
          x: section.x,
          y: section.y,
          w: section.w,
          h: section.h,
        })
      );

      // Function to compare two layouts
      const areLayoutsDifferent = (
        layout1: Layout[],
        layout2: Layout[]
      ): boolean => {
        if (layout1.length !== layout2.length) return true;
        for (let i = 0; i < layout1.length; i++) {
          if (
            layout1[i].i !== layout2[i].i ||
            layout1[i].x !== layout2[i].x ||
            layout1[i].y !== layout2[i].y ||
            layout1[i].w !== layout2[i].w ||
            layout1[i].h !== layout2[i].h
          ) {
            return true;
          }
        }
        return false;
      };

      // Check if the new layout is different from the current layout
      if (areLayoutsDifferent(currentLayout, newLayout)) {
        setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: {
              ...prev[activeTowerID].layout,
              [type]: {
                ...prev[activeTowerID].layout[type],
                [gridItem[type]]: {
                  ...prev[activeTowerID].layout[type][gridItem[type]],
                  [legLayout[0].i]: {
                    ...prev[activeTowerID].layout[type][gridItem[type]][
                      legLayout[0].i
                    ],
                    sections: updateSections(newLayout),
                  },
                },
              },
            },
          },
        }));
      }
    },
    onDragStop: (layout, prevLayout, newLayout) => {
      const hasChanged =
        prevLayout.y !== newLayout.y ||
        prevLayout.x !== newLayout.x ||
        prevLayout.h !== newLayout.h;
      // Find the index of prevLayout.i in the layout array
      if (hasChanged) {
        updateHistory(
          'move',
          {
            name: newLayout.i,
            y: newLayout.y,
            height: newLayout.h,
          },
          {
            name: prevLayout.i,
            y: prevLayout.y,
            height: prevLayout.h,
          }
        );
        setDragProps((prev: ILibraryIcon) => ({
          ...prev,
          dragging: false,
        }));
        setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: {
              ...prev[activeTowerID].layout,
              [type]: {
                ...prev[activeTowerID].layout[type],
                [gridItem[type]]: {
                  ...prev[activeTowerID].layout[type][gridItem[type]],
                  [legLayout[0].i]: {
                    ...prev[activeTowerID].layout[type][gridItem[type]][
                      legLayout[0].i
                    ],
                    sections: updateSections(layout),
                  },
                },
              },
            },
          },
        }));
      }
    },
    onResizeStop: (_, prevLayout, newLayout) => {
      const hasChanged =
        prevLayout.y !== newLayout.y ||
        prevLayout.x !== newLayout.x ||
        prevLayout.h !== newLayout.h;
      // Find the index of prevLayout.i in the layout array
      if (hasChanged) {
        updateHistory(
          'resize',
          {
            name: newLayout.i,
            y: newLayout.y,
            height: newLayout.h,
          },
          {
            name: prevLayout.i,
            y: prevLayout.y,
            height: prevLayout.h,
          }
        );
      }
    },
    onResizeStart: () => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: true,
      }));
    },
    onDragStart: () => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: true,
      }));
    },
    onMouseDown: () => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: true,
      }));
    },
    onMouseUp: () => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
      }));
    },
    addSection: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [type]: {
              ...prev[activeTowerID].layout[type],
              [gridItem[type]]: {
                ...prev[activeTowerID].layout[type][gridItem[type]],
                [id]: {
                  ...prev[activeTowerID].layout[type][gridItem[type]][id],
                  sections: {
                    ...prev[activeTowerID].layout[type][gridItem[type]][id]
                      .sections,
                    [nextKey]: {
                      name: `New Lease ${nextKey + 1}`,
                      i: nextKey,
                      x: 50,
                      y: 1,
                      h: gridCoverage <= 40 ? 10 : height - gridCoverage,
                      w: 4,
                      minW: 4,
                      maxW: 4,
                    },
                    [-1]: {
                      name: '',
                      i: -1,
                      x: 0,
                      y: height,
                      h: 0,
                      w: 4,
                      minW: 0,
                      maxW: 0,
                      isResizable: false,
                      static: true,
                    },
                  },
                },
              },
            },
          },
        },
      }));
      updateHistory('add', { name: `New Lease ${nextKey + 1}` });
    },
  };

  const sectionsToDisplay: ReactNode[] | null =
    sectionIds.length > 1
      ? sectionIds.map((section: string): ReactNode => {
          const borderColor = colors[+section % colors.length];
          const sectionStyle = {
            borderColor,
            borderWidth: '1px',
            borderRightWidth: 0,
            borderStyle: 'solid',
          };

          return (
            <div
              style={sectionStyle}
              className={classes.section}
              key={sections[section].name}
              data-grid={sections[section]}
              onMouseUp={handler.onMouseUp}
              onMouseDown={handler.onMouseDown}
              onClick={() => (editable ? openAssetOptions(section) : {})}
              onTouchStart={() => (editable ? openAssetOptions(section) : {})}>
              {Properties && (
                <PropertyLabel
                  label={sections[section].name}
                  type='section'
                />
              )}
            </div>
          );
        })
      : null;

  return (
    <div className={classes.container}>
      {gridCoverage < height && editable && (
        <button
          className={classes.button}
          onClick={handler.addSection}
        />
      )}
      <GridLayout
        cols={4}
        width={42}
        margin={[0, 0]}
        isBounded={true}
        rowHeight={7.55}
        compactType={null}
        isDraggable={editable}
        isResizable={editable}
        isDroppable={false}
        allowOverlap={false}
        layout={layoutOnLoad}
        transformScale={scale}
        className={classes.grid}
        onResizeStart={handler.onResizeStart}
        onResizeStop={handler.onResizeStop}
        onDragStart={handler.onDragStart}
        onDragStop={handler.onDragStop}
        onLayoutChange={handler.onLayoutChange}
        key={legLayout.i + '-grid-section-layout'}>
        {sectionsToDisplay}
      </GridLayout>
    </div>
  );
};

export default LeaseSections;
