import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { SitesContext } from 'state/context';
import { ISitesContext } from 'state/iContext';

const ReportSiteDropdown = ({ selectedSite, handleSiteChange }) => {
  const { towerList } = useContext(SitesContext) as ISitesContext;

  // Convert towerList to an options array for react-select
  const siteOptions = Object.keys(towerList).map((id) => ({
    value: towerList[+id].towerId,
    label: towerList[+id].name,
  }));

  return (
    <div>
      <label
        htmlFor='site-select'
        className='block text-lg font-medium text-gray-700'>
        Select Site
      </label>
      <Select
        id='site-select'
        options={siteOptions}
        value={selectedSite}
        onChange={handleSiteChange}
        placeholder='Choose a site'
        isSearchable={true}
      />
    </div>
  );
};

export default ReportSiteDropdown;
