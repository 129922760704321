import { IHeaderContext, IMapContext } from 'state/iContext';
import { IFilterChange, ISiteOptions } from './iSiteTypes';
import { HeaderContext, MapContext } from 'state/context';
import React, { useContext, useEffect} from 'react';

import { IMenuFilters } from 'state/iState';

const SiteTypeOption = ({ name, type, menuStyle }: ISiteOptions) => {
  const { menuFilters, setMenuFilters } = useContext(HeaderContext) as IHeaderContext;
  const { setMeasureDistance } = useContext(MapContext) as IMapContext;

  const checked: boolean = menuFilters[type as keyof typeof menuFilters];
  const {
    'all-types': allTypes,
    monopole,
    guyed,
    'self-supporting': selfSupporting,
    rooftop,
  } = menuFilters;

  useEffect(() => {
    if (monopole && guyed && selfSupporting && rooftop && !allTypes) {
      setMenuFilters((prev: IMenuFilters) => ({
        ...prev,
        [allTypes]: true,
      }));
    }
  }, [
    guyed,
    rooftop,
    allTypes,
    monopole,
    menuFilters,
    selfSupporting,
    setMenuFilters,
  ]);

  // Displays active site type filters
  const filters: IFilterChange =
    type === 'all-types'
      ? (prev: IMenuFilters) => ({
          'all-types': !prev['all-types'],
          monopole: !prev['all-types'],
          guyed: !prev['all-types'],
          'self-supporting': !prev['all-types'],
          rooftop: !prev['all-types'],
        })
      : (prev: IMenuFilters) => ({
          ...prev,
          'all-types': false,
          [type]: !prev[type as keyof typeof prev],
        });

  // Toggles active site type filters
  const clickHandler = (): void => {
    setMeasureDistance(false);
    setMenuFilters(filters);
  };

  return (
    <div
      className={menuStyle}
      onClick={clickHandler}>
      <input
        name={type}
        type='checkbox'
        checked={checked}
        onChange={() => {}}
        id={`site-menu-option-${type}`}
      />
      <div className='ml-3'>{name}</div>
    </div>
  );
};

export default SiteTypeOption;
