import { IFileListButtonHandlers } from '../../iTowerFiles';
import React, { useContext } from 'react';
import { IStringProps } from 'iApp';
import {
  ITowerList,
  IDocumentList,
  IEditFileProps,
  ITowerDocument,
} from 'state/iState';
import {
  UserContext,
  SitesContext,
  CanvasContext,
  ServerContext,
  DocumentContext,
} from 'state/context';
import {
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IServerContext,
  IDocumentContext,
} from 'state/iContext';

const FileListButtons = ({ isActive, id, parentID }: IStringProps) => {
  const {
    editFileProps,
    setEditFileProps,
    clearEditFileProps,
    setUpdateFolders,
    documentList,
    setDocumentList,
    fetchDocuments,
  } = useContext(DocumentContext) as IDocumentContext;
  const { activeTowerID, clearAddProps, setAddItemProps } = useContext(
    CanvasContext
  ) as ICanvasContext;
  const { towerList, setTowerList, fetchSiteData } = useContext(
    SitesContext
  ) as ISitesContext;
  const { deleteFileFromDB, deleteDocumentFromDB, updateDocumentsDB } =
    useContext(ServerContext) as IServerContext;
  const { user } = useContext(UserContext) as IUserContext;

  const classes: IStringProps = {
    container: 'flex w-[100px] items-center',
    move:
      isActive === 'edit'
        ? 'hidden'
        : 'fa-solid fa-arrows-up-down-left-right mx-2 text-stone-500 hover:text-red-600',
    left:
      isActive === 'edit'
        ? 'fa-solid fa-check ml-5 mr-2 text-stone-500 valid:hover:text-red-600 disabled:opacity-50 '
        : 'fa-solid fa-pen-to-square mx-2 text-stone-500 hover:text-red-600',
    right:
      isActive === 'edit'
        ? 'fa-solid fa-x mx-2 text-stone-500 hover:text-red-600'
        : 'fa-solid fa-trash-can mx-2 text-stone-500 hover:text-red-600',
  };

  const handlers: IFileListButtonHandlers = {
    edit: {
      left: async () => {
        await fetchDocuments();
        const docData: ITowerDocument = {
          ...documentList[id],
          name: editFileProps.contents.name,
          updated: Date.now(),
          user: user.userId,
        };

        await updateDocumentsDB(+id, 'PUT', documentList[id].custom, docData);
        await setDocumentList((prev: IDocumentList) => ({
          ...prev,
          [id]: docData,
        }));
        setEditFileProps(clearEditFileProps);
      },
      right: () => {
        setEditFileProps(clearEditFileProps);
        setAddItemProps(clearAddProps);
      },
    },
    static: {
      move: () => {
        setUpdateFolders({
          active: true,
          move: true,
          folder: parentID,
          parent: '',
          name: id,
        });
      },
      left: () => {
        documentList[id].custom
          ? setEditFileProps({
              //TODO: Correct logic
              type: 'doc',
              id: id,
              contents: documentList[id].file,
            })
          : setEditFileProps((prev: IEditFileProps) => ({
              type: 'pdf',
              id: id,
              contents: {
                ...prev.contents,
                name: documentList[id].name,
              },
            }));
      },
      right: async () => {
        await fetchDocuments();
        const fetchedSiteData = await fetchSiteData(activeTowerID);

        const { [+id]: toBeRemoved, ...rest } = documentList;
        await deleteFileFromDB(documentList[id].img);
        await deleteDocumentFromDB(id);
        setDocumentList(rest);
        setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...fetchedSiteData,
            dates: {
              ...fetchedSiteData.dates,
              licenses: fetchedSiteData.dates.licenses.filter(
                (num: number) => +num !== +id
              ),
            },
            documents: {
              ...fetchedSiteData.documents,
              folders: {
                ...fetchedSiteData.documents.folders,
                [parentID]: {
                  ...fetchedSiteData.documents.folders[parentID],
                  files: fetchedSiteData.documents.folders[
                    parentID
                  ].files.filter((file: string) => file !== id),
                },
              },
            },
          },
        }));
        setEditFileProps(clearEditFileProps);
        setAddItemProps(clearAddProps);
      },
    },
  };

  return (
    <div className={classes.container}>
      {!towerList[activeTowerID].edit.active && user.admin && (
        <>
          <button
            onClick={handlers[isActive].move}
            className={classes.move}
            disabled={
              isActive === 'edit' ? !editFileProps.contents.name : false
            }
          />
          <button
            onClick={handlers[isActive].left}
            className={classes.left}
            disabled={
              isActive === 'edit' ? !editFileProps.contents.name : false
            }
          />
          <button
            onClick={handlers[isActive].right}
            className={classes.right}
          />
        </>
      )}
    </div>
  );
};

export default FileListButtons;
