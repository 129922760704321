import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { IActiveSort } from 'state/iState';
import { IStringProps } from 'iApp';
import React, { useContext } from 'react';

const FileListHeader = () => {
  const { activeSort, setActiveSort } =
    useContext(DocumentContext) as IDocumentContext;

  const classes: IStringProps = {
    container:
      'flex w-full border border-stone-400 border-b-2 bg-slate-100 text-lg',
    name: 'flex w-5/6 font-bold text-center text-stone-500 justify-center items-center',
    date: 'flex w-1/6 font-bold text-center text-stone-500 justify-start items-center',
    action: 'mx-1 font-bold text-stone-500',
    sortInactive: 'fa-solid fa-sort text-sm ml-2 hover:text-red-600',
    sortUp: 'fa-solid fa-sort-up text-sm ml-2 hover:text-red-600',
    sortDown: 'fa-solid fa-sort-down text-sm ml-2 hover:text-red-600',
  };

  const sortIcon = (name: string): string => {
    if (activeSort.target === name && activeSort.decend) {
      return classes.sortDown;
    }
    if (activeSort.target === name && !activeSort.decend) {
      return classes.sortUp;
    }
    return classes.sortInactive;
  };

  return (
    <div className='flex w-[95%] text-center justify-around'>
      <div className='flex w-[20%] justify-around' />
      <div className='flex w-[40%] font-bold text-center text-stone-500 justify-center items-center truncate'>
        <span>File Name</span>{' '}
        <i
          onClick={() => {
            setActiveSort((prev: IActiveSort) => ({
              target: 'name',
              decend: prev.target === 'name' ? !prev.decend : true,
            }));
          }}
          className={sortIcon('name')}
        />
      </div>
      <div className='w-1/2 flex justify-around items-center'>
        <div className='flex w-1/3 ml-2 text-sm items-center' />
        <div className='flex w-1/3 ml-2 text-sm items-center' />
        <div className='flex w-1/3 font-bold text-center text-stone-500 items-center justify-center'>
          <span>Date Uploaded</span>{' '}
          <i
            onClick={() => {
              setActiveSort((prev: IActiveSort) => ({
                target: 'upload',
                decend: prev.target === 'upload' ? !prev.decend : true,
              }));
            }}
            className={sortIcon('upload')}
          />
        </div>
      </div>
    </div>
  );
};

export default FileListHeader;
